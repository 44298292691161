import type { KeenSliderInstance, KeenSliderPlugin } from "keen-slider/react";
import type { MutableRefObject } from "react";

export function ThumbnailPlugin(
	mainRef: MutableRefObject<KeenSliderInstance | null>
): KeenSliderPlugin {
	return (slider) => {
		function removeActive() {
			slider.slides.forEach((slide) => {
				slide.classList.remove("active");
			});
		}

		function addActive(index: number) {
			slider.slides[index].classList.add("active");
		}

		function addClickEvents() {
			slider.slides.forEach((slide, index) => {
				slide.addEventListener("click", () => {
					if (mainRef.current) mainRef.current.moveToIdx(index);
				});
			});
		}

		slider.on("created", () => {
			if (!mainRef.current) return;
			addActive(slider.track.details.rel);
			addClickEvents();
			mainRef.current.on("animationStarted", (main) => {
				removeActive();
				const next = main.animator.targetIdx || 0;
				addActive(main.track.absToRel(next));
				slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
			});
		});
	};
}
