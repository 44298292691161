import type { Question } from "@components/views/(app)/faq/FaqTabSection/FaqTabSection.types.ts";

export const CoachiniSolutionsFAQ: Question[] = [
	{
		en: {
			title: "What tools are used to facilitate online coaching?",
			content:
				"Online coaching is provided through a range of innovative features to ensure a meaningful experience (calendar and notifications, messaging, videoconferencing, account management), allowing smooth and transparent communication between coaches, staff members, and supervisors (sponsors).",
		},
		fr: {
			title:
				"Quels outils sont utilisés pour faciliter l’accompagnement en ligne ?",
			content:
				"Le coaching en ligne est fourni par le biais d’une palette de fonctionnalités innovantes pour garantir une expérience impactante (calendrier et notifications, messagerie, visioconférence, gestion de compte), permettant une communication fluide et transparente entre les coachs, les membres du personnel et les superviseurs (commanditaires).",
		},
	},
	{
		en: {
			title: "What guarantees do I have that I will receive quality coaching?",
			content:
				"The coaches are certified, having completed training accredited by internationally recognized certifying bodies. In addition to certification, they are qualified with a significant number of coaching hours and missions under their belts.",
		},
		fr: {
			title:
				"Qu'est-ce qui me garantit que je vais bénéficier d’un accompagnement de qualité ?",
			content:
				"Les coachs sont garantis certifiés, ayant suivi des formations accréditées par des organismes certificateurs reconnus à l’échelle internationale. En plus de la certification, ils sont qualifiés, ayant à leur actif un nombre important d’heures et de missions de coaching.",
		},
	},
	{
		en: {
			title: "How is a coaching mission scheduled?",
			content:
				"Currently, Coachini is a B2B solution: everything is managed between the sponsor (manager, HR, executives, funders, etc.) and Coachini. We analyze the need with them and propose a coaching program.",
		},
		fr: {
			title:
				"Comment se passe la programmation d’une mission d’accompagnement ?",
			content:
				"Pour le moment, Coachini est une solution B to B : tout se passe entre le commanditaire (manager, RH, dirigeants, bailleurs de fonds…) et Coachini. Nous analysons avec lui le besoin et lui proposons un programme d’accompagnement.\n",
		},
	},
	{
		en: {
			title:
				"I am a coach, how can I register and what is the selection process for coaches at Coachini?",
			content:
				"You just need to fill out an online registration form providing your personal information and areas of expertise. Once your application is submitted, our team carefully reviews your profile and experience to ensure they meet our quality standards. If your application is accepted, you will receive a confirmation email with instructions to complete your profile and start offering your coaching services on our platform.",
		},
		fr: {
			title:
				"Je suis un coach, comment puis-je m'inscrire et quel est le processus de sélection des coachs au sein de Coachini ?",
			content:
				"Il vous suffit de remplir un formulaire d'inscription en ligne en fournissant vos informations personnelles et vos domaines d'expertise. Une fois votre demande soumise, notre équipe examine attentivement votre profil et votre expérience pour vérifier leur adéquation avec nos standards de qualité. Si votre candidature est acceptée, vous recevrez un email de confirmation avec les instructions pour compléter votre profil et commencer à offrir vos services de coaching sur notre plateforme.",
		},
	},
	{
		en: {
			title: "What coaching programs are available on your platform?",
			content:
				"Our platform offers a wide range of themes to meet the specific needs and requirements of businesses, startups, recent graduates, and researcher-entrepreneurs.",
		},
		fr: {
			title:
				"Quels programmes de coaching sont disponibles sur votre plateforme ?",
			content:
				"Notre plateforme offre un large choix de thèmes pour répondre aux besoins et aux exigences spécifiques des entreprises, des startups, des jeunes diplômés et des chercheurs-entrepreneurs.",
		},
	},
	{
		en: {
			title: "What are the differences between the types of coaching offered?",
			content:
				"By choosing themes that are priorities for each target and collaborating with expert coaching professionals in these various fields, our platform offers personalized coaching that meets the specific requirements of each sector: business, startup, recent graduates, and researcher-entrepreneur.",
		},
		fr: {
			title:
				"Quelles sont les différences entre les types d'accompagnement offerts ?",
			content:
				"En choisissant des thèmes qui constituent les priorités de chaque cible et en collaborant avec des professionnels d’accompagnement experts dans ces différents domaines, notre plateforme offre un accompagnement personnalisé qui répond aux exigences spécifiques de chacun de ces univers : entreprise, startup, jeunes diplômés et chercheur-entrepreneur.",
		},
	},
	{
		en: {
			title: "What are the key aspects of business-oriented coaching?",
			content:
				"Business coaching focuses on the development of employee skills and key aspects for business success, emphasizing leadership, communication, time management, team strengthening, and employee experience.",
		},
		fr: {
			title:
				"Quels sont les aspects clés sur lesquels se concentre le coaching orienté entreprise ?",
			content:
				"Le coaching d'entreprise met l'accent sur le développement des compétences des salariés et des aspects clés pour le succès de l'entreprise, en se concentrant sur le leadership, la communication, la gestion du temps, le renforcement de l'équipe et l'expérience collaborateur.",
		},
	},
	{
		en: {
			title: "What are the key aspects of startup-oriented coaching?",
			content:
				"Startup-oriented coaching focuses on the development and growth of the startup, emphasizing the strengthening of entrepreneurial skills, addressing specific challenges faced by young startups, and supporting their expansion.",
		},
		fr: {
			title:
				"Quels sont les aspects clés sur lesquels se concentre le coaching orienté startup ?",
			content:
				"Le coaching orienté startup se concentre sur le développement et la croissance de la startup, en mettant l'accent sur le renforcement des compétences entrepreneuriales, la résolution des défis spécifiques rencontrés par les jeunes startups et le soutien à leur expansion.",
		},
	},
	{
		en: {
			title: "What are the key aspects of employability-oriented coaching?",
			content:
				"Employability-oriented coaching focuses on developing professional skills demanded in the job market, interview preparation, resume writing, job searching, as well as boosting self-confidence and creating an effective career plan.",
		},
		fr: {
			title:
				"Quels sont les aspects clés sur lesquels se concentre le coaching orienté employabilité ?",
			content:
				"Le coaching orienté employabilité se concentre sur le développement des compétences professionnelles demandées sur le marché de l’emploi, la préparation aux entretiens, la rédaction de CV et la recherche d'emploi, ainsi que sur le renforcement de la confiance en soi et le développement d’un plan de carrière efficace.",
		},
	},
	{
		en: {
			title:
				"What are the key aspects of researcher-entrepreneur oriented coaching?",
			content:
				"Researcher-entrepreneur oriented coaching focuses on developing entrepreneurial skills specific to the research field, transforming ideas into commercial projects, seeking funding and partnerships, as well as enhancing creativity and innovation management.",
		},
		fr: {
			title:
				"Quels sont les aspects clés sur lesquels se concentre le coaching orienté chercheur - entrepreneur ?",
			content:
				"Le coaching orienté chercheur - entrepreneur se concentre sur le développement des compétences entrepreneuriales spécifiques au domaine de la recherche, la transformation des idées en projets commerciaux, la recherche de financement et de partenariats, ainsi que sur le renforcement de la créativité et de la gestion de l'innovation.",
		},
	},
	{
		en: {
			title:
				"Besides the themes tailored for each target, what other differences does Coachini offer for these different types of coaching?",
			content:
				"For businesses, a coaching plan is developed based on various diagnostic and needs re-assessment meetings. For startups, recent graduates, and researcher-entrepreneurs, different types of packages are offered depending on the number of themes chosen by the sponsor.",
		},
		fr: {
			title:
				"En plus des thèmes qui sont adaptés pour chaque cible, quelles autres différences propose Coachini pour ces différents types de coaching ?",
			content:
				"Pour les entreprises, un plan de coaching est élaboré à partir des différentes réunions de diagnostic et de recadrage des besoins. Pour les startups, les jeunes diplômés et les chercheurs-entrepreneurs, différents types de packs sont proposés en fonction du nombre de thèmes choisis par le commanditaire.",
		},
	},
];

export const CoachiniStudioFAQ = [
	{
		en: {
			title: "What is Coachini Podcast and what is it about?",
			content:
				"Coachini Podcast is a series of episodes in video format covering a wide range of coaching themes: personal and professional development, entrepreneurship, mental well-being, and much more.",
		},
		fr: {
			title: "Qu’est-ce que Coachini Podcast et de quoi traite-t-il ?",
			content:
				"Coachini Podcast est une série d'épisodes sous forme de vidéos qui traitent de multitudes de thèmes de coaching : développement personnel et professionnel, entrepreneuriat, bien-être mental, et bien plus encore.",
		},
	},
	{
		en: {
			title:
				"How can Coachini Podcast benefit listeners interested in coaching?",
			content:
				"Coachini Podcast offers listeners practical advice, inspiring stories, and interviews with experts in various fields, which can help them improve their professional and personal lives.",
		},
		fr: {
			title:
				"Comment le Coachini Podcast peut-il bénéficier aux auditeurs intéressés par le coaching ?",
			content:
				"Le Coachini Podcast offre aux auditeurs des conseils pratiques, des histoires inspirantes et des interviews avec des experts dans divers domaines, ce qui peut les aider à améliorer leur vie professionnelle et personnelle.",
		},
	},
	{
		en: {
			title:
				"How can listeners access and listen to episodes of Coachini Podcast?",
			content:
				"Listeners can access various episodes of Coachini Podcast on our website and watch the full videos on our YouTube channel: Coachini App.",
		},
		fr: {
			title:
				"Comment les auditeurs peuvent-ils accéder et écouter les épisodes du Coachini Podcast ?",
			content:
				"Les auditeurs peuvent accéder aux différents épisodes du Coachini Podcast sur notre site web et visionner les vidéos complètes sur notre chaîne YouTube : Coachini App.",
		},
	},
	{
		en: {
			title:
				"Who are the guests and what are their areas of expertise in Coachini Podcast?",
			content:
				"The guests on Coachini Podcast are experts in various fields such as coaching, personal and professional development, entrepreneurship, mental health. They share their knowledge and experiences to inspire our listeners, and some coached individuals may also share their experiences to offer practical and inspiring perspectives, enriching our content and providing a mosaic of themes for professional and personal growth.",
		},
		fr: {
			title:
				"Qui sont les invités et quels sont leurs domaines d'expertise dans Coachini Podcast ?",
			content:
				"Les invités du Coachini Podcast sont des experts dans divers domaines tels que le coaching, le développement personnel et professionnel, l'entrepreneuriat, la santé mentale. Ils partagent leurs connaissances et leurs expériences pour inspirer nos auditeurs, et certains coachés peuvent également partager leurs expériences pour offrir une perspective pratique et inspirante, enrichissant ainsi notre contenu et proposant une mosaïque de thèmes pour l'épanouissement professionnel et personnel.",
		},
	},
	{
		en: {
			title:
				"What is a Video Resume and how does it differ from a traditional Resume?",
			content:
				"A video CV is an audiovisual presentation of your professional background that allows you to showcase your personality and skills dynamically. It differs from a traditional CV by its visual and interactive aspects, which can help you stand out during a job search or interview.",
		},
		fr: {
			title:
				"Qu’est-ce que le CV vidéo et en quoi diffère-t-il d'un CV traditionnel ?",
			content:
				"Le CV vidéo est une présentation audiovisuelle de votre parcours professionnel, qui permet de montrer votre personnalité et vos compétences de manière dynamique. Il se différencie d'un CV traditionnel par son aspect visuel et interactif, ce qui peut aider à se démarquer lors d'une recherche d'emploi ou d'un entretien.",
		},
	},
	{
		en: {
			title:
				"How does the process of creating a video CV with Coachini Studio work?",
			content:
				"With Coachini Studio, you follow a simple process: we start by working together on the script, then move on to filming and editing to produce your video CV, helping you stand out to recruiters.",
		},
		fr: {
			title:
				"Comment fonctionne le processus de création d'un CV vidéo avec Coachini Studio ?",
			content:
				"Avec Coachini Studio, vous suivez un processus simple : nous commençons par travailler ensemble sur le script, puis nous passons au tournage et au montage pour obtenir votre CV vidéo, vous permettant ainsi de vous démarquer auprès des recruteurs.",
		},
	},
	{
		en: {
			title:
				"What is a video pitch and how is it different from a traditional presentation?",
			content:
				"A video pitch is a short presentation of your company or project that captivates an audience in a few minutes. It differs from a traditional presentation by its professional video format and more dynamic approach, which can help startups present their ideas more effectively.",
		},
		fr: {
			title:
				"Qu’est-ce que le Pitch vidéo et en quoi est-il différent d'une présentation traditionnelle ?",
			content:
				"Le Pitch vidéo est une courte présentation de votre entreprise ou projet, qui permet de captiver un public en quelques minutes. Il se différencie d'une présentation traditionnelle par son format vidéo professionnelle et son approche plus dynamique, ce qui peut aider les startups à présenter leur idée de manière plus percutante.",
		},
	},
	{
		en: {
			title:
				"How can Coachini Studio help structure and create an effective video pitch?",
			content:
				"With Coachini Studio, you follow a simple process: we start by working together on the script for your pitch, then move on to filming and editing to produce your video pitch, which you can test with actors from our ecosystem, helping you present your company or project convincingly to clients and investors.",
		},
		fr: {
			title:
				"Comment Coachini Studio peut-il aider à structurer et à créer un Pitch vidéo efficace ?",
			content:
				"Avec Coachini Studio, vous suivez un processus simple : nous commençons par travailler ensemble sur le script de votre pitch, puis nous passons au tournage et au montage pour obtenir votre pitch vidéo, que vous pouvez tester avec des acteurs de notre écosystème, vous permettant ainsi de présenter votre entreprise ou votre projet de manière convaincante aux clients et investisseurs.",
		},
	},
];
