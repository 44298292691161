import type { ModelCardProps } from "@components/views/(app)/solutions/ModelSection/ModelSection.types.ts";
import { cn } from "@core/utils";
import Text from "@components/elements/Text";
import Image from "@components/elements/Image";

const ModelCard: React.FC<ModelCardProps> = ({
	className,
	iconBGColor,
	bulletColor,
	data,
}) => {
	return (
		<div
			className={cn(
				"relative z-[0] flex min-h-[130px] w-full items-center rounded-[10px] bg-logoWhite shadow-base2 _tablet_sm:w-[420px] _laptop:min-h-[170px] _laptop:w-[550px]",
				className
			)}
		>
			<svg
				className="absolute left-0 z-0 h-full"
				viewBox="0 0 110 179"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					className={cn("fill-primary-400", iconBGColor)}
					d="M94 179H10C4.47715 179 0 174.523 0 169V10C0 4.47716 4.47715 0 10 0H91.7242C91.7242 0 85.2429 35.5345 85.2429 89.059C85.2429 141.706 94 179 94 179Z"
				/>
			</svg>
			{data.icon && (
				<Image
					className="absolute left-0 z-10 ml-[2px] h-[40px] w-[60px] _tablet:ml-[3px] _tablet:h-[45px] _laptop:ml-[10px] _laptop:h-[55px]"
					src={`/svg/models/${data.icon}.svg`}
					alt={data.title}
					width={40}
					height={40}
				/>
			)}
			<div className="flex h-full flex-col gap-[7px] p-[20px] pl-[100px] _tablet:pl-[100px] _laptop:pl-[130px] ">
				<Text
					tag="h3"
					text={data.title}
					className={cn(
						"titleLarge text-left font-medium",
						"_tablet:order-initial order-first flex flex-col space-y-[7px]"
					)}
				/>
				<ul className={cn("ml-6 list-disc", bulletColor)}>
					{data.features.map((feature, index) => (
						<li key={index} className="bodyText text-text-700">
							{feature}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default ModelCard;
