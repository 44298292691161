import type { FunctionComponent } from "types/types.ts";
import { useTranslation } from "react-i18next";
import NavigationWrapper from "@components/layout/common/NavigationWrapper.tsx";
import HeaderSection from "@components/views/(app)/coaches/HeaderSection";
import ValueSection from "@components/views/(app)/common/ValueSection";
import CoachCtaSection from "@components/views/(app)/common/CoachCtaSection";
import { ExpertReviewSection } from "@components/views/(app)/common/ExpertReviewSection";
import { coachReviewData } from "@api/database/constant/reviews.data.ts";
import StatisticSection from "@components/views/(app)/common/StatisticSection";
import NewsletterSection from "@components/views/(app)/common/NewsletterSection";
import SponsorListSection from "@components/views/(app)/home/SponsorListSection";

export const Coaches = (): FunctionComponent => {
	const { t } = useTranslation("coaches-page");

	return (
		<NavigationWrapper>
			<main>
				<HeaderSection />
				<ValueSection className="margin-divider-small" />
				<CoachCtaSection showSecondaryButton={false} />
				<ExpertReviewSection
					titleColor="secondary"
					titleText={t("expert-review-section.title")}
					data={coachReviewData}
				/>
				<SponsorListSection />
				<StatisticSection isDivider />
				<NewsletterSection />
			</main>
		</NavigationWrapper>
	);
};
