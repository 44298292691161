import React from "react";
import { cn } from "@core/utils";
import Image from "@components/elements/Image";
import Text from "@components/elements/Text";
import Button from "@components/elements/Buttons/Button.tsx";
import { useTranslation } from "react-i18next";
import {
	FaPhoneAlt,
	FaEnvelope,
	FaFacebookF,
	FaTwitter,
	FaLinkedinIn,
} from "react-icons/fa";
import { Instagram } from "@mui/icons-material";

function FooterSection() {
	const { t } = useTranslation("startgreen-page");

	return (
		<div
			className={cn(
				"bg-startgreen_primary-100 px-7 py-[30px] rounded-md",
				"flex flex-col lg:flex-row items-center justify-center",
				"gap-[30px] drop-shadow-md"
			)}
		>
			<div className={"flex flex-col items-center justify-center text-white"}>
				<Image
					className="rounded-lg aspect-auto"
					width={68}
					height={68}
					src={"images/startgreen/tr-sg.png"}
					alt={"Startgreen"}
					aria-label="StartGreen"
				/>
				{/*<h3 className={"text-[14px] font-bold"}>StartGreen</h3>*/}
			</div>
			<hr
				className={cn(
					"border lg:hidden border-startgreen_secondary-100/60 w-[20%]"
				)}
			/>
			<svg
				width="2"
				height="70"
				viewBox="0 0 2 89"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={"opacity-50 hidden  lg:block"}
			>
				<line x1="1" y1="0.5" x2="0.999996" y2="88.5" stroke="#87C443" />
			</svg>

			{/*--------------------------------------------------------------------*/}
			<div className={"flex flex-col gap-[14px] items-center  lg:items-start"}>
				<div
					className={
						"flex flex-col text-[20px] gap-[6px] text-white items-center lg:items-start"
					}
				>
					<div className="flex items-center gap-2">
						<FaEnvelope className="text-[20px] text-white" />
						<h2>contact@coachini.net</h2>
					</div>
					<div className="flex items-center gap-2">
						<FaPhoneAlt className="text-[20px] text-white" />
						<h2>(+216) 28 28 52 52</h2>
					</div>
				</div>
			</div>
			<hr
				className={cn(
					"block lg:hidden border-startgreen_secondary-100/60 w-[20%]"
				)}
			/>
			{/*--------------------------------------------------------------------*/}

			<svg
				width="2"
				height="70"
				viewBox="0 0 2 89"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={"opacity-50 hidden lg:block"}
			>
				<line x1="1" y1="0.5" x2="0.999996" y2="88.5" stroke="#87C443" />
			</svg>

			{/*--------------------------------------------------------------------*/}

			<div className={"flex flex-row lg:flex-col gap-[11px]"}>
				<a
					href="https://www.facebook.com/profile.php?id=61566739157426"
					target="_blank"
					rel="noopener noreferrer"
				>
					<FaFacebookF className="text-white text-[30px] lg:text-[20px]" />
				</a>
				<a
					href="https://www.instagram.com/startgreenbyiptic/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<Instagram className="text-white text-[30px] lg:text-[20px]" />
				</a>
				<a
					href="https://www.linkedin.com/company/startgreen-sousse/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<FaLinkedinIn className="text-white text-[30px] lg:text-[20px]" />
				</a>
			</div>
		</div>
	);
}

export default FooterSection;
