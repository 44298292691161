import { useTranslation } from "react-i18next";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { cn } from "@core/utils";
import TargetSlider from "@components/views/(app)/home/TargetSection/TargetSlider.tsx";
import { targetsListData } from "@api/database/constant/home.data.ts";
import Text from "@components/elements/Text";

function TargetSection() {
	const { t } = useTranslation("home-page");
	return (
		<SectionWrapper
			className={cn(
				"relative  flex h-full w-full flex-col gap-[50px]  _large:mb-0 ",
				"margin-divider-medium"
			)}
		>
			<Text
				tag="h2"
				className={cn("displayLarge text-center")}
				highlightStyle="font-semibold text-secondary-900"
				text={t("target-section.title")}
			/>
			<TargetSlider data={targetsListData} />
		</SectionWrapper>
	);
}

export default TargetSection;
