import type { FunctionComponent } from "types/types.ts";
import { useTranslation } from "react-i18next";
import NavigationWrapper from "@components/layout/common/NavigationWrapper.tsx";
import ValueSection from "@components/views/(app)/common/ValueSection";
import NewsletterSection from "@components/views/(app)/common/NewsletterSection";
import HeaderSection from "@components/views/(app)/about/HeaderSection";
import VisionSection from "@components/views/(app)/about/VisionSection";
import FaqAccordionSection from "@components/views/(app)/common/FaqAccordionSection";

export const About = (): FunctionComponent => {
	const { t } = useTranslation("coaches-page");

	return (
		<NavigationWrapper>
			<main>
				<HeaderSection />
				<VisionSection />
				<ValueSection className="margin-divider-xsm" />
				<FaqAccordionSection />
				<NewsletterSection />
			</main>
		</NavigationWrapper>
	);
};
