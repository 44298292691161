import React from "react";
import { cn } from "@core/utils";

export enum QuestionType {
	TEXT = "text",
	EMAIL = "email",
	MULTILINE = "multiline",
	CHECKBOX = "checkbox",
	NUMBER = "number",
	RADIO = "radio",
	SELECTION = "selection",
	DROPDOWN_SINGLE = "dropdown-single",
	DROPDOWN_MULTIPLE = "dropdown-multiple",
}

export const isAnswerValue = (candidate, questionName, possibleValues) => {
	// Ensure possibleValues is an array for uniform processing
	const valuesArray = Array.isArray(possibleValues)
		? possibleValues
		: [possibleValues];

	return candidate.answers.some((answer) => {
		const { question, value } = answer;

		// If the question matches the given questionName
		if (question.questionName === questionName) {
			// console.log("valuesArray", valuesArray);
			// Check if the answer's value matches any of the possible values
			if (Array.isArray(value)) {
				return value.some((val) => valuesArray.includes(val));
			} else {
				return valuesArray.includes(value);
			}
		}

		return false;
	});
};
export const isAnswerInConditionalFields = (candidate, questionNames) => {
	// Ensure questionNames is an array for uniform processing
	const questionNamesArray = Array.isArray(questionNames)
		? questionNames
		: [questionNames];

	// Recursive helper function to traverse conditional fields
	const checkConditionalFields = (conditionalFields) => {
		if (!conditionalFields) return false;

		// Ensure conditionalFields is an array for uniform processing
		const conditionalFieldsArray = Array.isArray(conditionalFields)
			? conditionalFields
			: [conditionalFields];

		for (const condField of conditionalFieldsArray) {
			// If the current conditionalField's questionName is in the list, return true
			if (questionNamesArray.includes(condField.questionName)) {
				return true;
			}

			// If the current conditionalField has its own conditionalFields, recurse
			if (condField.conditionalField) {
				const nestedMatch = checkConditionalFields(condField.conditionalField);
				if (nestedMatch) {
					return true;
				}
			}
		}

		// If no matches found in this branch, return false
		return false;
	};

	// Iterate through all answers to check their conditional fields recursively
	return candidate.answers.some((answer) => {
		const { conditionalField } = answer.question;

		// Use the recursive helper to check all levels of conditional fields
		return checkConditionalFields(conditionalField);
	});
};

export const shouldDisableAnswer = (
	candidate,
	questionNames,
	possibleValues
) => {
	// Ensure questionNames is an array for uniform processing
	const questionNamesArray = Array.isArray(questionNames)
		? questionNames
		: [questionNames];

	// Ensure possibleValues is a boolean (either true or false)
	const isDisableValue =
		typeof possibleValues === "boolean" ? possibleValues : false;

	// Check if the answer value for any of the questionNames matches the possible boolean value
	return questionNamesArray.every((questionName) => {
		return isAnswerValue(candidate, questionName, isDisableValue);
	});
};

export const extractAnswer = (candidate, questionName, type?) => {
	const answer = candidate.answers.find(
		(ans) => ans.question.questionName === questionName
	);

	if (!answer.value) return "-";

	// If the answer is an array, render it as a list
	if (answer.question.questionType === QuestionType.TEXT) {
		return <>{answer.label}</>;
	}
	if (Array.isArray(answer.label)) {
		return (
			<ul className="list-disc pl-5">
				{answer.label.map((item, index) => (
					<li key={index}>{item}</li>
				))}
			</ul>
		);
	}

	return answer.label;
};

const QuestionListComponent = ({ candidate }) => {
	// Sort questions by step and order
	const sortedQuestions = [...candidate.answers].sort((a, b) => {
		if (a.question.step === b.question.step) {
			return a.question.order - b.question.order;
		}
		return a.question.step - b.question.step;
	});

	const findConditionalField = (questionName) => {
		const parentAnswer = candidate.answers.find((ans) => {
			// Check if conditionalField exists and it's an array
			const conditionalField = ans.question.conditionalField;
			return conditionalField?.questionName === questionName;
		});

		return parentAnswer !== undefined;
	};

	const findConditionalByParent = (questionName, parentName) => {
		const parentAnswer = candidate.answers.find((ans) => {
			// Check if the conditionalField exists and it has a `questionName`
			const conditionalField = ans.question.conditionalField;
			// console.log("CONDITIONAL FIELD", conditionalField.questionName);
			return (
				conditionalField?.questionName === questionName &&
				ans.question.questionName === parentName
			);
		});

		return parentAnswer !== undefined;
	};

	const shouldDisplayConditionalField = (conditionalField) => {
		if (
			!conditionalField ||
			!conditionalField.dependsOn ||
			!conditionalField.condition
		) {
			return false;
		}
		const parentAnswer = candidate.answers.find((ans) => {
			if (!ans.question.conditionalField) return false;
			ans.question.questionName === conditionalField.dependsOn;
		});

		// console.log("parentAnswer" + parentAnswer.value);
		return parentAnswer && parentAnswer.value === conditionalField.condition;
	};

	return (
		<div className="question-list space-y-4">
			{sortedQuestions.map((answer) => {
				const isReferenced = isAnswerInConditionalFields(
					candidate,
					answer.question.questionName
				);
				const developmentStageValue1 = isAnswerValue(
					candidate,
					"development_stage",
					["no_project_idea"]
				);
				const developmentStageValue2 = isAnswerValue(
					candidate,
					"development_stage",
					["project_idea_not_started"]
				);
				const developmentStageValue3 = isAnswerValue(
					candidate,
					"development_stage",
					["no_startup_act_project", "yes_startup_act_project"]
				);
				const lockDevelopmentStageAnswers1 =
					(answer.question.questionName === "project_activity" ||
						answer.question.questionName === "project_activity_other" ||
						answer.question.questionName === "employees_number" ||
						answer.question.questionName === "sales_revenue" ||
						answer.question.questionName === "support_needs" ||
						answer.question.questionName === "support_needs_other") &&
					developmentStageValue1;

				const lockDevelopmentStageAnswers2 =
					// (answer.question.questionName === "project_activity" ||
					// 	answer.question.questionName === "project_activity_other" ||
					(answer.question.questionName === "employees_number" ||
						answer.question.questionName === "sales_revenue" ||
						answer.question.questionName === "support_needs" ||
						answer.question.questionName === "support_needs_other") &&
					developmentStageValue2;

				const shouldLock =
					lockDevelopmentStageAnswers1 || lockDevelopmentStageAnswers2;

				if (isReferenced)
					return (
						<div
							className={cn(
								"conditional-field  border-2 border-startgreen_primary-100/20 p-4 rounded",
								extractAnswer(candidate, answer.question.questionName) !==
									"-" && !shouldLock
									? "bg-startgreen_secondary-100/10"
									: "bg-gray-100/10"
							)}
						>
							<p className="question-text">
								<strong>{answer.question.questionText} :</strong>
							</p>
							<p className="answer-text">
								{extractAnswer(candidate, answer.question.questionName)}
							</p>
						</div>
					);
				return (
					<div key={answer.id} className="question-item bg-white p-4 rounded">
						<p className="question-text">
							<strong>{answer.question.questionText} :</strong>
						</p>
						<p className="answer-text">
							{extractAnswer(candidate, answer.question.questionName)}
						</p>
					</div>
				);
			})}
		</div>
	);
};

export default QuestionListComponent;
