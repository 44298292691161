import type { ModelDataProps } from "@components/views/(app)/solutions/ModelSection/ModelSection.types.ts";

export const enterpriseModelCards: ModelDataProps[] = [
	{
		icon: "phase-1",
		en: {
			title: " Preliminary phase",
			features: ["Situation diagnosis", "Coaching needs assessment"],
		},
		fr: {
			title: "Phase préalable",
			features: [
				"Diagnostic de la situation",
				"Evaluation du besoin en coaching",
			],
		},
	},
	{
		icon: "execution",
		en: {
			title: "Pre-execution phase",
			features: ["Tasting preview", "Mission details"],
		},
		fr: {
			title: "Phase pré-exécution",
			features: ["Dégustation", "Détails de la mission"],
		},
	},
	{
		icon: "implementation",
		iconSize: "200",
		en: {
			title: "Implementation phase",
			features: [
				"Group coaching",
				"Real-time evaluation",
				"One to one coaching ",
				"Focus group",
			],
		},
		fr: {
			title: "Phase de mise en oeuvre",
			features: [
				"Coaching collectif",
				"Evaluation à chaud",
				"Coaching one to one",
				"Focus groupe",
			],
		},
	},
	{
		icon: "evaluation",
		en: {
			title: " Evaluation phase",
			features: ["Reporting", "Metrics (Return on investment)", "Whitepaper"],
		},
		fr: {
			title: "Phase d’évaluation",
			features: [
				"Reporting",
				"Mesures (Retour sur investissement)",
				"Livre Blanc",
			],
		},
	},
];
