import { useEffect, useState } from "react";
import { Link } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import type { NavBarMenuType } from "@components/layout/Navbar/Navbar.types.ts";
import { cn } from "@core/utils";

interface SideMenuProps {
	isOpen: boolean;
	menus: NavBarMenuType[];
	toggleSideMenu: () => void;
	activeSubmenu: number | null;
	locationName: string;
	handleSubmenuLinkClick: () => void;
	activeChildLink: string | null;
}

const SideMenu: React.FC<SideMenuProps> = ({
	isOpen,
	menus,
	toggleSideMenu,
	activeSubmenu,
	locationName,
	handleSubmenuLinkClick,
	activeChildLink,
}) => {
	const [openSubmenuIndex, setOpenSubmenuIndex] = useState<number | null>(null);

	const toggleSubmenu = (index: number) => {
		setOpenSubmenuIndex((currentValue) =>
			currentValue === index ? null : index
		);
	};

	useEffect(() => {
		if (isOpen) {
			const activeAccordionIndex = menus.findIndex((menu) =>
				menu.child?.some((subItem) => subItem.childlink === activeChildLink)
			);
			setOpenSubmenuIndex(
				activeAccordionIndex === -1 ? null : activeAccordionIndex
			);
		}
	}, [isOpen, menus, activeChildLink]);

	const handleSubLinkClick = () => {
		toggleSideMenu();
		handleSubmenuLinkClick();
	};

	const handleNormalLinkClick = () => {
		const isCurrentLinkActive = menus.some((menu, index) =>
			menu.child?.some(
				(subItem) =>
					subItem.childlink === activeChildLink && index === openSubmenuIndex
			)
		);
		if (!isCurrentLinkActive) {
			setOpenSubmenuIndex(null);
		}
		toggleSideMenu();
		handleSubmenuLinkClick();
	};

	return (
		<div
			className={cn(
				"sidemenu fixed left-0 top-[62px] w-full transform bg-BGWhite shadow-base2 transition-transform duration-200 ease-in-out _phone_lg:w-[350px] _laptop:hidden",
				isOpen ? "translate-x-0" : "-translate-x-full"
			)}
		>
			<div className="p-0">
				<ul>
					{menus.map((item, index) => (
						<MenuItem
							key={index}
							item={item}
							index={index}
							isOpen={isOpen}
							activeSubmenu={activeSubmenu}
							openSubmenuIndex={openSubmenuIndex}
							locationName={locationName}
							activeChildLink={activeChildLink}
							toggleSubmenu={toggleSubmenu}
							handleSubLinkClick={handleSubLinkClick}
							handleNormalLinkClick={handleNormalLinkClick}
						/>
					))}
				</ul>
			</div>
		</div>
	);
};

interface MenuItemProps {
	item: NavBarMenuType;
	index: number;
	isOpen: boolean;
	activeSubmenu: number | null;
	openSubmenuIndex: number | null;
	locationName: string;
	activeChildLink: string | null;
	toggleSubmenu: (index: number) => void;
	handleSubLinkClick: () => void;
	handleNormalLinkClick: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
	item,
	index,
	activeSubmenu,
	openSubmenuIndex,
	locationName,
	activeChildLink,
	toggleSubmenu,
	handleSubLinkClick,
	handleNormalLinkClick,
}) => {
	const { t } = useTranslation("layout");

	return (
		<li
			style={{ listStyleType: "none" }}
			className={cn(
				{ "bg-primary-80": activeSubmenu === index },
				{ "bg-primary-50/50": openSubmenuIndex === index },
				{
					"menu-item-active bg-primary-50 text-text-400":
						locationName === item.link,
				}
			)}
		>
			{item.child ? (
				<div
					className={cn(
						"menu-link cursor-pointer py-[18px] pl-[33px] pr-[25px] _tablet:pl-[88px]",
						openSubmenuIndex === index ? "not-collapsed" : "collapsed"
					)}
					onClick={() => {
						toggleSubmenu(index);
					}}
				>
					<div className="hover:text-gray-500/75 flex flex-1 items-start">
						<span className="menu-icon"></span>
						<div className="relative flex w-full items-center justify-between gap-1">
							<div className="buttonMedium">{t(item.title as any)}</div>
							<svg
								className={cn(
									"transition-all duration-100",
									openSubmenuIndex === index
										? "rotate-180 transform"
										: "rotate-0"
								)}
								width="10"
								height="5"
								viewBox="0 0 10 5"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M0 0L5 5L10 0L0 0Z" fill="#334155" />
							</svg>
						</div>
					</div>
				</div>
			) : (
				<Link
					className={cn("hover:text-gray-500/75 relative")}
					to={`/${item.link}`}
					onClick={handleNormalLinkClick}
				>
					<div
						className={cn(
							"buttonMedium py-[18px] pl-[33px] pr-[25px] _tablet:pl-[88px]"
						)}
					>
						{t(item.title as any)}
					</div>
				</Link>
			)}
			{openSubmenuIndex === index && item.child && (
				<ul>
					{item.child.map((subItem, subIndex) => (
						<SubMenuItem
							key={subIndex}
							subItem={subItem}
							activeChildLink={activeChildLink}
							handleSubLinkClick={handleSubLinkClick}
						/>
					))}
				</ul>
			)}
		</li>
	);
};

interface SubMenuItemProps {
	subItem: any;
	activeChildLink: string | null;
	handleSubLinkClick: () => void;
}

const SubMenuItem: React.FC<SubMenuItemProps> = ({
	subItem,
	activeChildLink,
	handleSubLinkClick,
}) => {
	const { t } = useTranslation("layout");

	return (
		<li
			className={cn("bg-[#E5E8EB] py-[12px]", {
				"bg-[#E9EDF1]": subItem.childlink === activeChildLink,
			})}
			onClick={handleSubLinkClick}
		>
			<Link to={`/${subItem.childlink}`}>
				<div
					className={cn(
						"buttonMedium pl-[33px] pr-[25px] hover:opacity-70 _tablet:pl-[88px]",
						{ "text-text-400": subItem.childlink === activeChildLink }
					)}
				>
					{t(subItem.childtitle)}
				</div>
			</Link>
		</li>
	);
};

export default SideMenu;
