import type React from "react";
import { useEffect, useRef } from "react";
import { cn } from "@core/utils";
import type { StatisticSectionProps } from "@components/views/(app)/common/StatisticSection/StatisticSection.types.ts";
import { useTranslation } from "react-i18next";
import { useDynamicGridLayout } from "@components/views/(app)/common/StatisticSection/useDynamicGridLayout.ts";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { statisticData } from "@api/database/constant/misc.data.ts";
import Text from "@components/elements/Text";
import { StatisticItem } from "@components/views/(app)/common/StatisticSection/StatisticItem.tsx";

const StatisticSection: React.FC<StatisticSectionProps> = ({
	className,
	showTitle = true,
	isDivider,
	dividerColor = "bg-primary-50",
	gridStyle,
}) => {
	const { t } = useTranslation("common");
	const gridRef = useRef<HTMLDivElement>();
	const itemsPerRow = useDynamicGridLayout();

	useEffect(() => {
		const adjustItemsPerRow = () => {
			const container = gridRef.current;
			if (!container) return;

			const items = [...container.children] as HTMLElement[];
			const gap = 10;
			items.forEach((item, index) => {
				item.style.flexBasis =
					itemsPerRow < 5 ? `${50 / itemsPerRow}%` : "unset";
				if (index % itemsPerRow !== 0) {
					item.style.marginLeft = `${gap / 2}px`;
				}
				if (index % itemsPerRow !== itemsPerRow - 1) {
					item.style.marginRight = `${gap / 2}px`;
				}
			});
		};
		adjustItemsPerRow();
	}, [itemsPerRow]);

	return (
		<div className={cn(isDivider && dividerColor)}>
			<SectionWrapper
				className={cn(
					"flex flex-col items-center gap-[50px] py-[80px]",
					"margin-divider-medium",
					className
				)}
			>
				{showTitle && (
					<Text
						highlightStyle="font-semibold text-secondary-900"
						className={cn(
							"displayLarge text-left text-text-black",
							"text-center"
						)}
						text={t("statistic-section.title")}
					/>
				)}
				<div
					ref={gridRef as React.RefObject<HTMLDivElement>}
					className={cn(
						"flex flex-wrap justify-center gap-x-[40px] gap-y-[40px] text-center",
						"_tablet:text-left _laptop_md:gap-[80px] _laptop:gap-[10px] _laptop_lg:gap-[28px] _large:gap-[70px]",
						gridStyle
					)}
				>
					{statisticData.map((statistic, index) => (
						<StatisticItem key={index} item={statistic} />
					))}
				</div>
			</SectionWrapper>
		</div>
	);
};

export default StatisticSection;
