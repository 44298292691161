import React, { useState } from "react";
import { FaQrcode, FaSpinner } from "react-icons/fa";
import QrScannerCam from "@pages/(startgreen)/views/(settings)/QrScannerCam.tsx";
import { useMutation } from "@tanstack/react-query";
import { decryptQrBadge } from "@api/service/startgreen.services.ts";
import toast, { Toaster } from "react-hot-toast";
import { useDebounce } from "ahooks";

const QRScanner: React.FC = () => {
	const [isScannerActive, setScannerActive] = useState(false);
	const [scannedData, setScannedData] = useState<string | null>(null);
	const [isLoadingCustom, setIsLoadingCustom] = useState(false); // Custom loading state

	const debouncedScannedData = useDebounce(scannedData, { wait: 1000 });

	const { mutate, isError, isSuccess, error, data } = useMutation({
		mutationFn: decryptQrBadge,
		onSuccess: (response) => {
			toast.success(response.message, { duration: 3000 });
			setIsLoadingCustom(false);
		},
		onError: (error) => {
			toast.error("Erreur lors de la décryption du QR code.", {
				duration: 3000,
			});
			setIsLoadingCustom(false);
			setScannerActive(false);
		},
	});

	const handleScan = (data: string | null) => {
		if (data && data !== scannedData) {
			setScannedData(data);
			setIsLoadingCustom(true);
		}
	};

	const handleError = (error: any) => {
		console.error("Erreur lors du scan du QR code :", error);
		toast.error("Erreur lors du scan du QR code.");
		setIsLoadingCustom(false); // Hide the spinner on scan error
	};

	const toggleScanner = () => {
		setScannerActive((prevState) => !prevState);
		setScannedData(null);
		setIsLoadingCustom(false); // Reset loading state when closing scanner
	};

	// Trigger the mutation when debounced scanned data changes
	React.useEffect(() => {
		if (debouncedScannedData) {
			mutate(debouncedScannedData);
		}
	}, [debouncedScannedData, mutate]);

	return (
		<div className="flex flex-col items-center">
			<Toaster position="bottom-center" reverseOrder={false} />
			{!isScannerActive ? (
				<button
					onClick={toggleScanner}
					className="bg-startgreen_secondary-100 text-white py-2 px-4 rounded-md flex items-center justify-center"
				>
					<FaQrcode className="mr-2" />
					QR Code
				</button>
			) : (
				<div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
					<div className="bg-white p-6 rounded-md shadow-lg max-w-lg w-full relative z-50">
						<h3 className="text-xl mb-4">Scanner le QR Code</h3>
						{/* QR Scanner Component */}

						{/* Show the custom spinner while loading */}
						{isLoadingCustom ? (
							<div className="flex justify-center mt-4">
								<FaSpinner
									className="animate-spin text-startgreen-500"
									size={40}
								/>
							</div>
						) : (
							<QrScannerCam onScan={handleScan} onError={handleError} />
						)}

						{/* Close button */}
						<div className="mt-4 flex justify-end">
							<button
								onClick={toggleScanner}
								className="bg-red-500 text-white py-2 px-4 rounded-md"
							>
								Fermer le scanner
							</button>
						</div>
					</div>
					<div className="absolute w-full h-full inset-0 bg-black-900 opacity-85"></div>
				</div>
			)}
		</div>
	);
};

export default QRScanner;
