import type React from "react";
import { useTranslation } from "react-i18next";
import { advantagesData } from "@api/database/constant/advantages.data.ts";
import { cn } from "@core/utils";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { GridSection } from "@components/views/(app)/common/ValueSection/GridSection.tsx";
import Text from "@components/elements/Text";
import i18next from "i18next";

interface FeaturesSectionProps {
	isDivider?: boolean;
	dividerColor?: string;
}

const AdvantageSection: React.FC<FeaturesSectionProps> = ({
	isDivider = true,
	dividerColor = "bg-primary-50/50",
}) => {
	const { t } = useTranslation("solutions-page");
	const currentLanguageData = advantagesData.map((item) => {
		return {
			...item,
			// @ts-ignore
			content: item[i18next.resolvedLanguage],
		};
	});
	return (
		<div className={cn(isDivider && dividerColor)}>
			<SectionWrapper
				className={cn(
					"flex flex-col items-center py-c _tablet:gap-[60px] _large:gap-[100px]",
					"margin-divider-medium"
				)}
			>
				<Text
					className={cn(
						"displayLarge",
						"mb-0 max-w-[350px] text-center  _tablet:max-w-[600px] _laptop:max-w-[850px]"
					)}
					highlightStyle="font-semibold text-primary-400"
					text={t("advantage-section.title")}
				/>

				<GridSection
					titleStyle="text-text-900 text-HeadingLarge font-normal"
					descriptionStyle="text-bodyLarge text-text-700 tracking-tighter !max-w-[320px]"
					className={cn(
						"mx-auto grid  justify-center gap-x-[0px] gap-y-[20px] _tablet:grid-cols-2 _laptop:grid-cols-3 _large:gap-y-[80px] ",
						"max-w-[700px] !gap-2 text-titleLarge font-normal _laptop:max-w-full _laptop:grid-cols-4"
					)}
					itemStyle="min-w-full _tablet:min-w-full max-w-[350px]"
					items={currentLanguageData}
					iconPath="advantages"
				/>
			</SectionWrapper>
		</div>
	);
};

export default AdvantageSection;
