import { cn } from "@core/utils";

export const StateCard = ({
	title,
	paragraphText,
	bgColor,
	className,
}: {
	title: string;
	paragraphText: string;
	bgColor: string;
	className?: string;
}) => {
	const cardClassName = cn(
		"shadow-2xl md:shadow-xl _tablet_lg:min-h-[280px] hover:shadow-2xl transform rounded p-[12px] text-center transition duration-300",
		bgColor,
		className
	);

	return (
		<div className={cn(cardClassName)}>
			{/*<Title*/}
			{/*  tagName='h2'*/}
			{/*  wrapperClassName='sm:max-w-7xl py-[15px] px-[15px] _tablet:px-[15px] mb-[20px] _tablet_lg:mb-[10px]'*/}
			{/*  titleStyle='font-normal text-start text-text-black text-titleMedium _tablet_sm:text-titleLarge  _tablet:text-headlineSmall _tablet_md:text-headlineMedium _tablet_lg:text-headlineMedium _laptop:text-headlineLarge_fluid'*/}
			{/*  paragraphStyle='text-start text-text-700 text-bodySmall _tablet:text-bodyMedium _laptop:text-bodyLarge'*/}
			{/*  textBeforeHighlight={title}*/}
			{/*  paragraphText={paragraphText}*/}
			{/*/>*/}
			<div
				className={cn(
					"flex flex-col gap-[22px]",
					"mb-[20px] px-[15px] py-[15px] text-left _tablet:px-[15px] sm:max-w-7xl _tablet_lg:mb-[10px]"
				)}
			>
				<h2
					className={cn(
						"text-start text-titleMedium font-normal text-text-black ",
						" _tablet_sm:text-titleLarge  _tablet:text-headlineSmall _tablet_md:text-headlineMedium _tablet_lg:text-headlineMedium _laptop:text-headlineLarge_fluid"
					)}
				>
					{title}
				</h2>
				<p
					className={cn(
						"text-start text-bodySmall text-text-700 _tablet:text-bodyMedium _laptop:text-bodyLarge"
					)}
				>
					{paragraphText}
				</p>
			</div>
		</div>
	);
};
