import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "@tanstack/react-router";
import { fetchSubmissionById } from "@api/service/startgreen.services.ts";
import { useState } from "react";
import axios from "axios";
import { appConfig } from "@config/appConfig.ts";
import QuestionListComponent, {
	extractAnswer,
} from "@pages/(startgreen)/views/(participants)/QuestionListComponent.tsx";
import cn from "classnames";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
} from "@mui/material";
import TooltipCard from "@pages/(startgreen)/views/(dashboard)/TooltipCard.tsx";

const CandidateDetail = () => {
	const { cid } = useParams({ strict: false });

	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [decision, setDecision] = useState("");
	const [isProcessingAccept, setIsProcessingAccept] = useState(false);
	const [isProcessingDecline, setIsProcessingDecline] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [dialogMessage, setDialogMessage] = useState("");

	const {
		data: candidate,
		isLoading,
		isError,
	} = useQuery({
		queryKey: ["candidate", cid],
		queryFn: () => fetchSubmissionById(cid),
	});

	const validateMutation = useMutation({
		mutationFn: ({ status, setProcessing }) => {
			setProcessing(true);
			return axios.post(`${appConfig.startGreenUrl}/submissions/validate/`, {
				id: cid,
				status,
			});
		},
		onSuccess: (_, { setProcessing }) => {
			queryClient.invalidateQueries();
			setProcessing(false);
			setDialogMessage("Candidat accepté.");
			setOpenDialog(true);
		},
		onError: (_, { setProcessing }) => {
			setProcessing(false);
			setDialogMessage(
				"Échec de la mise à jour du statut du candidat. Veuillez réessayer."
			);
			setOpenDialog(true);
		},
	});

	const declineMutation = useMutation({
		mutationFn: ({ setProcessing }) => {
			setProcessing(true);
			return axios.post(`${appConfig.startGreenUrl}/submissions/decline/`, {
				id: cid,
			});
		},
		onSuccess: (_, { setProcessing }) => {
			queryClient.invalidateQueries();
			setProcessing(false);
			setDialogMessage("Candidat refusé.");
			setOpenDialog(true);
		},
		onError: (_, { setProcessing }) => {
			setProcessing(false);
			setDialogMessage("Échec du refus du candidat. Veuillez réessayer.");
			setOpenDialog(true);
		},
	});

	if (isLoading) return <p>Chargement...</p>;
	if (isError) return <p>Erreur de chargement des détails du candidat.</p>;

	const isDisabled =
		candidate.status === "DECLINED" ||
		candidate.status === "ACCEPTED" ||
		isProcessingAccept ||
		isProcessingDecline;

	return (
		<div className="flex flex-col justify-center items-center min-h-screen bg-gray-100/10 p-6">
			<div className="flex flex-col gap-3 w-full max-w-3xl rounded-lg shadow-md p-8">
				<TooltipCard
					title="Aperçu détaillé des choix du candidat"
					description="Ici, nous pouvons voir tous les choix sélectionnés par le candidat et avons la possibilité d'accepter ou de refuser leur soumission."
					points={[
						"Toute carte colorée signifie qu'il s'agit d'un choix qui se débloque en sélectionnant un autre choix.",
						"Les cartes de couleur grise signifient qu'elles sont désactivées ou vides.",
						"Les cartes de couleur verte sont activées et contiennent des données.",
					]}
				/>
				{/*--------------------------------------------------------*/}
				<div
					className={cn("text-center text-xl font-bold rounded", {
						"bg-startgreen_secondary-100 text-startgreen_primary-100":
							candidate.status === "ACCEPTED",
						"bg-red-200 text-startgreen_primary-100":
							candidate.status === "DECLINED",
						"bg-startgreen_tertiary-100/20 text-startgreen_tertiary-100":
							candidate.status === "PENDING",
					})}
				>
					{candidate.status === "ACCEPTED"
						? "ACCEPTÉ"
						: candidate.status === "DECLINED"
							? "DREFUSÉ"
							: "EN ATTENTE"}
				</div>
				{/*--------------------------------------------------------*/}

				<div
					className={cn("text-center text-lg font-bold p-4 rounded", {
						"bg-startgreen_primary-100/30 text-startgreen_primary-100":
							candidate.type === "USER",
						"bg-startgreen_darkblue-100/30 text-startgreen_darkblue-100":
							candidate.type === "VISITOR",
					})}
				>
					{candidate.type === "USER" ? "CANDIDAT" : "VISITEUR"}
				</div>

				{/*--------------------------------------------------------*/}

				<div className={"p-4 bg-white rounded"}>
					<h1 className="text-2xl font-semibold text-center mt-4 mb-6">
						Détails du candidat pour {candidate.fullName}
					</h1>

					<div className="space-y-4">
						<p className="text-gray-700">
							<span className="font-medium">Email :</span> {candidate.email}
						</p>
						<p className="text-gray-700">
							<span className="font-medium">Région :</span>{" "}
							{extractAnswer(candidate, "region")}
						</p>
						<p className="text-gray-700">
							<span className="font-medium">Niveau de développement :</span>{" "}
							{Array.isArray(extractAnswer(candidate, "development_stage")) ? (
								<ul className="list-disc ml-6">
									{extractAnswer(candidate, "development_stage").map(
										(item, index) => (
											<li key={index}>{item}</li>
										)
									)}
								</ul>
							) : (
								extractAnswer(candidate, "development_stage")
							)}
						</p>
						<p className="text-gray-700">
							<span className="font-medium">Soutien reçu :</span>{" "}
							{extractAnswer(candidate, "previous_support")}
						</p>
					</div>
				</div>

				{/*--------------------------------------------------------*/}

				<div className="mt-8">
					<QuestionListComponent candidate={candidate} />
				</div>

				<div className="flex justify-center mt-8 space-x-4">
					<button
						onClick={() =>
							validateMutation.mutate({
								status: "accepted",
								setProcessing: setIsProcessingAccept,
							})
						}
						className={cn(
							"px-6 py-2 rounded font-medium text-white transition duration-200",
							{
								"bg-startgreen_primary-100 hover:bg-opacity-80": !isDisabled,
								"bg-startgreen_primary-100/30 cursor-not-allowed": isDisabled,
							}
						)}
						disabled={isDisabled}
					>
						{isProcessingAccept ? "Validation..." : "Valider"}
					</button>
					<button
						onClick={() =>
							declineMutation.mutate({ setProcessing: setIsProcessingDecline })
						}
						className={cn(
							"px-6 py-2 rounded font-medium text-white transition duration-200",
							{
								"bg-startgreen_tertiary-100 hover:bg-opacity-80": !isDisabled,
								"bg-startgreen_tertiary-100/30 cursor-not-allowed": isDisabled,
							}
						)}
						disabled={isDisabled}
					>
						{isProcessingDecline ? "Refus en cours..." : "Refuser"}
					</button>
				</div>

				{/* Dialog for Success or Error Messages */}
				<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
					<DialogTitle>Mise à jour du statut</DialogTitle>
					<DialogContent>
						<DialogContentText>{dialogMessage}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpenDialog(false)} color="primary">
							OK
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
};

export default CandidateDetail;
