import type React from "react";
import { cn } from "@core/utils";
import Image from "@components/elements/Image";

export interface Image {
	shape: string;
	name: string;
	width: number | string;
}

interface DynamicImageProps {
	className?: string;
	images: Image[];
	shape: string;
	currentIndex: number;
}

export const DynamicImage: React.FC<DynamicImageProps> = ({
	className,
	images,
	shape,
	currentIndex,
}) => {
	const getNextLogo = (shape: string, currentIndex: number): Image => {
		const logos = images.filter((img) => img.shape === shape);
		return logos[currentIndex % logos.length];
	};

	const logo = getNextLogo(shape, currentIndex);
	const logoSource = `/images/sponsors/${logo.name}.webp`;

	return (
		<div
			className={cn(
				"pointer-events-none relative flex select-none items-center justify-center p-[10px]",
				className
			)}
		>
			<Image
				className={cn("h-auto", logo.width ? `w-[${logo.width}]` : "w-[70%]")}
				width={100}
				height={100}
				src={logoSource}
				alt={logo.name}
				aria-label="sponsor logo"
				loading="lazy"
			/>
		</div>
	);
};
