import type { ExpertReviewProps } from "@components/views/(app)/common/ExpertReviewSection/ExpertReviewSection.types.ts";

export const coachReviewData: ExpertReviewProps[] = [
	{
		id: 1,
		expertName: "MYRIAM LILIA BESBES",
		expertProfession: "Coach et Superviseur de Coachs",
		content:
			'Sur l\'invitation de "Coachini" pour offrir une conférence "Langage et Emotions", j\'ai pu constater les moyens donnés aux jeunes pour leur permettre de dépasser leurs limites, de surmonter leurs obstacles et de réaliser leur plein potentiel. C\'est une initiation à prendre les commandes de leur propre vie, à modeler eux-mêmes leur destin que leur permet Sawsen Dhifallah avec ce projet "Coachini". Bravo !',
		cardColor: "primary-1",
		alt: "img",
	},
	{
		id: 2,
		expertName: "SANA ELLARBI",
		expertProfession: "Life Coach et formatrice en Soft Skills",
		content:
			"«Je trouve le nouveau projet de Coachini très intéressant car il met l'accent sur les thèmes phare du développement personnel. J'ai apprécié le professionnalisme de toute l'équipe. Tout au long du podcast sur la relation parents-enfants, la bienveillance et le professionnalisme de l’équipe m'ont permis de me sentir en aisance et d'exprimer mes convictions avec liberté ». ",
		cardColor: "primary-2",
		alt: "img",
	},
	{
		id: 3,
		expertName: "AYOUB KALLEL",
		expertProfession: "Coach ICF, enseignant PNL et Hypnose.",
		content:
			"« Échanger, partager, avancer et faire bouger les choses : ça fait partie des plaisirs du métier. C'était agréable d'être l'invité de Sawsen dans son podcast, son professionnalisme et courtoisie donnent lieu à des discussions profondes. Je sais que je serais à l'écoute, et vous ?» ",
		cardColor: "tertiary",
		alt: "img",
	},
	{
		id: 4,
		expertName: "RYM KAMOUN",
		expertProfession: "Coach ICF, Psychologue",
		content:
			"On cherche à travers la plate-forme de toucher des thèmes variés originaux. Personnellement, j'ai parlé de la reconversion professionnelle ; un thème pointu peu évoqué et en même temps souhaité par beaucoup d'entre nous. Dans le podcast, nous avons échangé autour du sujet dans une ambiance conviviale, agréable et surtout bienveillante. ",
		cardColor: "secondary",
		alt: "img",
	},
	// {
	//   id: 4,
	//   expertName: "ANIS TRIGUI",
	//   expertProfession: "CEO InspireTech",
	//   content:
	//     "Le partenariat avec COACHINI pour des sessions de préparation au pitch a été un vrai succès. Les participants ont bénéficié de conseils précieux et d’un environnement de confiance. Les start-ups incubés chez MakerLab ont acquis des compétences essentielles pour communiquer efficacement leur vision. Nous recommandons vivement leurs services pour leur excellence et leur impact. ",
	//   wrapperClassName: "bg-primary-100",
	//   alt: "img",
	// },
	{
		id: 4,
		expertName: "HEGER CHTOUI",
		expertProfession:
			"Maitre Praticienne en PNL, Praticienne en hypnothérapie.",
		content:
			"L’idée des podcasts à travers lesquels chaque coach puisse se présenter et expliquer le champ de son intervention est particulièrement magnifique. J’ai personnellement adoré l’expérience, le cadre professionnel et l’ambiance conviviale lors du tournage, au point de recommander cette prestation à mes confrères et consœurs coachs. J’ai hâte de suivre le podcast de différents intervenants et savourer les diverses socialités !  ",
		cardColor: "primary-2",
		alt: "img",
	},
	// {
	//   id: 4,
	//   expertName: "MALEK JABR",
	//   expertProfession:
	//     "Responsable Développement Commercial à Junior Entreprise Eniso",
	//   content:
	//     "Pour JEENISo, le soutien de la startup Coachini lors de notre événement START IT UP 2.0 était très précieux. Les participants ont pu appliquer immédiatement ce qu'ils apprennent à leurs projets et en plus nous partageons avec la startup la même vision : cultiver l'esprit entrepreneurial et aider les jeunes entrepreneurs à commencer leur chemin entrepreneurial. ",
	//   wrapperClassName: "bg-secondary-300",
	//   alt: "img",
	// },
	// {
	//   id: 4,
	//   expertName: "ZAHI FAROUK",
	//   expertProfession: "Fondateur MAISON DES ARTS LAB et DIDON",
	//   content:
	//     "Votre accompagnement de haute qualité, votre flexibilité et votre capacité d'adaptation aux besoins spécifiques de notre cible ont été essentiels pour le succès de nos bootcamps Carrière QuickStart et J'Ose Entreprendre, ce qui a permis d'apporter des solutions sur mesure. Je suis enthousiaste à l'idée de continuer à travailler avec l’équipe Coachini pour réaliser nos objectifs communs.  ",
	//   wrapperClassName: "bg-tertiary-300",
	//   alt: "img",
	// },
];

export const companyReviewData = [
	{
		title: "Title 2",
		content:
			"Le partenariat avec COACHINI pour des sessions de préparation au pitch a été un vrai succès. Les participants ont bénéficié de conseils précieux et d’un environnement de confiance. Les start-ups incubés chez MakerLab ont acquis des compétences essentielles pour communiquer efficacement leur vision. Nous recommandons vivement leurs services pour leur excellence et leur impact.",
		companyName: "ANIS TRIGUI",
		writer: "CEO InspireTech",
		companyLogo: "inspire-tech",
	},
	{
		title: "Title 2",
		content:
			"Notre collaboration avec Coachini au profit des entrepreneurs d'ELIFE a été remarquable. La formatrice a captivé l'audience et transmis ses connaissances avec clarté et professionnalisme. Nous sommes vivement partons pour toute future intervention.",
		companyName: "Asma Chebbi",
		companyLogo: "elife",
		// companyAlt: 'Pépinière Sousse',
		writer: "Program Manager à ELIFE, Fondation Tunisie Pour le Développement.",
		// date: "Date 2",
	},
	{
		title: "Title 2",
		content:
			"Pour JEENISo, le soutien de la startup Coachini lors de notre événement START IT UP 2.0 était très précieux. Les participants ont pu appliquer immédiatement ce qu'ils apprennent à leurs projets et en plus nous partageons avec la startup la même vision : cultiver l'esprit entrepreneurial et aider les jeunes entrepreneurs à commencer leur chemin entrepreneurial. ",
		companyName: "MALEK JABR",
		companyLogo: "jeeniso",
		writer: "Responsable Développement Commercial à Junior Entreprise Eniso ",
		// date: "Date 2",
	},
	{
		title: "Title 2",
		content:
			"Votre accompagnement de haute qualité, votre flexibilité et votre capacité d'adaptation aux besoins spécifiques de notre cible ont été essentiels pour le succès de nos bootcamps Carrière QuickStart et J'Ose Entreprendre, ce qui a permis d'apporter des solutions sur mesure. Je suis enthousiaste à l'idée de continuer à travailler avec l’équipe Coachini pour réaliser nos objectifs communs.  ",
		companyName: "Farouk Zahi",
		companyAlt: "Farouk Zahi",
		companyLogo: "didon",
		writer: "Fondateur MAISON DES ARTS LAB et DIDON",
		// date: "Date 2",
	},
	{
		title: "Title 2",
		content:
			"Je remercie chaleureusement Coachini pour la formation sur « le profil entrepreneur » et « les techniques de communication ». Leur approche interactive a été cruciale pour préparer nos participants à la création d'entreprise. Leur professionnalisme et dévouement ont été exemplaires. Hâte de futures collaborations !",
		companyName: "Samia Noureddine",
		// companyAlt: 'Pépinière Sousse',
		companyLogo: "apii",
		writer: " Responsable pépinière d'entreprises Sousse Tec",
		// date: "Date 2",
	},
];
