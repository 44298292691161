import { useTranslation } from "react-i18next";
import { Link } from "@tanstack/react-router";
import Button from "@components/elements/Buttons/Button.tsx";
import NavigationWrapper from "@components/layout/common/NavigationWrapper.tsx";

function NotFound() {
	const { t } = useTranslation("misc");

	return (
		<NavigationWrapper>
			<main className="flex h-[_calc(100vh_-_70px)] items-center p-16 dark:bg-gray-50 dark:text-gray-800">
				<div className="container mx-auto my-8 flex flex-col items-center justify-center px-5">
					<div className="max-w-md text-center">
						<h2 className="mb-4 text-9xl font-extrabold text-text-600">
							<span className="sr-only">Error</span>404
						</h2>
						<p className="displaySmall font-semibold">{t("404.subTitle")}</p>
						<p className="bodyText mb-8 mt-4 text-text-600">
							{t("404.description")}
						</p>
						<Link className="relative hover:text-gray-500/75" to={"/"}>
							<Button>{t("404.buttonLabel")}</Button>
						</Link>
					</div>
				</div>
			</main>
		</NavigationWrapper>
	);
}
export default NotFound;
