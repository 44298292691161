import React, { useEffect, useState } from "react";
import axios from "axios";
import { appConfig } from "@config/appConfig.ts";
import * as Yup from "yup";
import { useFormik } from "formik";
import Sidebar from "@pages/(startgreen)/views/(dashboard)/Sidebar";
import Navbar from "@pages/(startgreen)/views/(dashboard)/Navbar";
import TooltipCard from "@pages/(startgreen)/views/(dashboard)/TooltipCard.tsx";

interface Judge {
	id?: string;
	email: string;
	password?: string;
}

const JudgeManagement: React.FC = ({ title = "Gestion des Juges" }) => {
	const [judges, setJudges] = useState<Judge[]>([]);
	const [editingPassword, setEditingPassword] = useState<string | null>(null);
	const [passwordToUpdate, setPasswordToUpdate] = useState<string>("");
	const [message, setMessage] = useState<{ type: string; text: string } | null>(
		null
	);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		fetchJudges();
	}, []);

	useEffect(() => {
		if (message) {
			const timer = setTimeout(() => setMessage(null), 3000);
			return () => clearTimeout(timer);
		}
	}, [message]);

	const fetchJudges = async () => {
		try {
			const response = await axios.get(
				`${appConfig.startGreenUrl}/platform/judges/all`
			);
			setJudges(response.data);
		} catch (error) {
			setMessage({ type: "error", text: "Échec du chargement des juges." });
		}
	};

	const handleCreateJudge = async (values: Judge) => {
		try {
			const response = await axios.post(
				`${appConfig.startGreenUrl}/platform/judges`,
				values
			);
			setJudges([...judges, response.data]);
			formik.resetForm();
			setMessage({ type: "success", text: "Juge créé avec succès." });
		} catch (error) {
			formik.setErrors({ email: "Échec de la création du juge." });
			setMessage({ type: "error", text: "Échec de la création du juge." });
		}
	};

	const handleUpdateJudgePassword = async (id: string, newPassword: string) => {
		try {
			await axios.patch(`${appConfig.startGreenUrl}/platform/judges/${id}`, {
				password: newPassword,
			});
			setEditingPassword(null);
			setMessage({
				type: "success",
				text: "Mot de passe mis à jour avec succès.",
			});
		} catch (error) {
			setMessage({
				type: "error",
				text: "Échec de la mise à jour du mot de passe.",
			});
		}
	};

	const handleDeleteJudge = async (id: string) => {
		try {
			await axios.delete(`${appConfig.startGreenUrl}/platform/judges/${id}`);
			setJudges(judges.filter((judge) => judge.id !== id));
			setMessage({ type: "success", text: "Juge supprimé avec succès." });
		} catch (error) {
			setMessage({ type: "error", text: "Échec de la suppression du juge." });
		}
	};

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email("Adresse email invalide")
				.required("Obligatoire"),
			password: Yup.string()
				.min(8, "Le mot de passe doit contenir au moins 8 caractères")
				.required("Obligatoire"),
		}),
		onSubmit: (values) => {
			handleCreateJudge(values);
		},
	});

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};
	const tooltipPoints = [
		"Les juges sont des comptes avec un accès limité uniquement à la liste des candidats et des visiteurs et leurs fonctionnalités respectives.",
		"Ils ne peuvent pas gérer les mails, les autres comptes, les phases et la configuration de la plateforme.",
	];

	return (
		<div className="flex">
			<Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
			<div className="flex-grow">
				<Navbar title={title} toggleSidebar={toggleSidebar} />
				<main className="p-6 space-y-6">
					<TooltipCard
						title="Gestion des comptes de juges"
						description="Ajoutez et gérez les comptes des juges."
						points={tooltipPoints}
					/>
					{message && (
						<div
							className={`mb-4 p-4 rounded ${
								message.type === "error"
									? "bg-red-100 text-red-800"
									: "bg-green-100 text-green-800"
							}`}
						>
							{message.text}
						</div>
					)}
					<form
						className="bg-white p-6 rounded shadow-md space-y-4 drop-shadow-md border border-gray-100/30"
						onSubmit={formik.handleSubmit}
					>
						<div>
							<input
								className="w-full p-3 border border-gray-100/30 rounded focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm"
								type="email"
								name="email"
								placeholder="Entrez l'email du juge"
								value={formik.values.email}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
							{formik.touched.email && formik.errors.email && (
								<div className="text-red-500 text-sm mt-1">
									{formik.errors.email}
								</div>
							)}
						</div>

						<div>
							<input
								className="w-full p-3 border border-gray-100/30 rounded focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm"
								type="password"
								name="password"
								placeholder="Entrez le mot de passe du juge"
								value={formik.values.password}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
							{formik.touched.password && formik.errors.password && (
								<div className="text-red-500 text-sm mt-1">
									{formik.errors.password}
								</div>
							)}
						</div>

						<div className="flex gap-4">
							<button
								type="submit"
								className="bg-startgreen_primary-100 text-white px-4 py-2 rounded hover:bg-opacity-80 shadow-md"
							>
								Créer Juge
							</button>
							<button
								type="button"
								className="bg-startgreen_tertiary-100 text-white px-4 py-2 rounded hover:bg-opacity-80 shadow-md"
								onClick={formik.resetForm}
							>
								Réinitialiser
							</button>
						</div>
					</form>
					<hr className="my-4 border-t-1 border-gray-100/30" />

					<h2 className="text-xl font-bold mb-4">Liste des Juges</h2>
					<div className="bg-white p-6 rounded shadow-md space-y-4 drop-shadow-md border border-gray-100/30">
						{judges.map((judge) => (
							<div
								key={judge.id}
								className="sbg-white rounded shadow-md flex justify-between items-center"
							>
								<div className={"flex flex-col gap-4"}>
									<span className="font-medium">{judge.email}</span>
									{editingPassword === judge.id ? (
										<div className="flex items-center gap-2">
											<input
												className="p-2 border border-gray-100/30 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
												type="password"
												placeholder="Entrez le nouveau mot de passe"
												value={passwordToUpdate}
												onChange={(e) => setPasswordToUpdate(e.target.value)}
											/>
											<button
												className="bg-startgreen_primary-100 text-white px-4 py-2 rounded hover:bg-opacity-80"
												onClick={() =>
													handleUpdateJudgePassword(judge.id!, passwordToUpdate)
												}
											>
												Enregistrer
											</button>
											<button
												className="bg-startgreen_tertiary-100 text-white px-4 py-2 rounded hover:bg-opacity-80"
												onClick={() => setEditingPassword(null)}
											>
												Annuler
											</button>
										</div>
									) : (
										<button
											className="bg-startgreen_primary-100 text-white px-4 py-2 rounded hover:bg-opacity-80 shadow-md"
											onClick={() => {
												setEditingPassword(judge.id!);
												setPasswordToUpdate("");
											}}
										>
											Mise à jour du mot de passe
										</button>
									)}
								</div>

								<button
									className="bg-startgreen_tertiary-100 text-white px-4 py-2 rounded hover:bg-opacity-80"
									onClick={() => handleDeleteJudge(judge.id!)}
								>
									Supprimer
								</button>
							</div>
						))}
					</div>
				</main>
			</div>
		</div>
	);
};

export default JudgeManagement;
