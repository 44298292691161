export const sponsorImages = [
	{
		name: "alliance_coaching",
		alt: "Alliance Coaching",
		shape: "rectangle",
		width: "70%",
	},
	{
		name: "arsii",
		alt: "CRMN",
		shape: "square",
		width: "70%",
	},
	{
		name: "arts_lab",
		alt: "Arts Lab",
		shape: "square",
		width: "70%",
	},
	{
		name: "didon",
		alt: "Didon",
		shape: "square",
		width: "70%",
	},
	{
		name: "elife",
		alt: "Elife",
		shape: "square",
		width: "90%",
	},
	{
		name: "estudiant",
		alt: "Estudiant",
		shape: "rectangle",
		width: "70%",
	},
	{
		name: "go_my_code",
		alt: "Gomycode",
		shape: "rectangle",
		width: "70%",
	},
	{
		name: "hiil",
		alt: "Hiil",
		shape: "circle",
		width: "70%",
	},
	{
		name: "innovation_city",
		alt: "Innovation City",
		shape: "square",
		width: "80%",
	},
	{
		name: "iot_challenge",
		alt: "IoT Challenge",
		shape: "rectangle",
		width: "50%",
	},
	{
		name: "jeeniso",
		alt: "Jeeniso",
		shape: "rectangle",
		width: "50%",
	},
	{
		name: "maker_lab",
		alt: "Maker Lab",
		shape: "square",
		width: "90%",
	},

	{
		name: "pristini",
		alt: "Pristini",
		shape: "circle",
		width: "80%",
	},
	{
		name: "smart_capital",
		alt: "Smart Capital",
		shape: "rectangle",
		width: "80%",
	},

	{
		name: "tcc",
		alt: "TCC",
		shape: "square",
		width: "80%",
	},
	{
		name: "the_dot",
		alt: "The Dot",
		shape: "circle",
		width: "90%",
	},
	{
		name: "upsilon",
		alt: "Upsilon",
		shape: "circle",
		width: "90%",
	},
];
