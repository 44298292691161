import { TextField } from "@mui/material";
import { useController } from "react-hook-form";

import { cn } from "@core/utils";
import { useTranslation } from "react-i18next";

const FloatingInput = ({
	name,
	control,
	label,
	type = "text",
	variant = "filled",
	color = "primary",
	className,
	error,
	multiline = false,
	rows = 1,
}: {
	name: string;
	control: any;
	label: string;
	type?: string;
	variant?: "filled" | "outlined" | "standard";
	color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
	className?: string;
	error?: any;
	multiline?: boolean;
	rows?: number;
}) => {
	const {
		field,
		fieldState: { invalid },
	} = useController({
		name,
		control,
	});

	const { t: tMisc } = useTranslation("misc");
	return (
		<div className="space-y-[8px]">
			<TextField
				{...field}
				label={label}
				type={type}
				variant={variant}
				color={color}
				multiline={multiline}
				rows={rows}
				error={invalid}
				// helperText={invalid ? tMisc(`responses.${error?.message}`) : ' '} // Ensure this line correctly accesses the error message
				className={cn(
					"disabled:bg-gray-200 bg-primary-50/50 hover:bg-text-200 focus:bg-text-800",
					className
				)}
				fullWidth
				sx={{
					"&": {
						// backgroundColor: '#E6EDF6',
						borderRadius: "10px 10px 0 0",
						color: "#000",
					},
					"&:hover": {
						borderWidth: "10px",
					},
					"&.Mui-focused": {
						backgroundColor: "#e0e0e0",
					},
					"&.Mui-error": {
						backgroundColor: "#ffcccc",
					},
					"&.Mui-disabled": {
						backgroundColor: "#e83f3f",
					},
				}}
			/>
			{error && (
				<p className={cn("text-xs text-red-600 dark:text-red-400")}>
					{tMisc(`responses.${error?.message}`)}
				</p>
			)}
		</div>
	);
};

export default FloatingInput;
