import React from "react";
import { FiInfo } from "react-icons/fi"; // Importing an icon from react-icons
import { cn } from "@core/utils";

const TooltipCard = ({ title, description, points = [] }) => {
	return (
		<div
			role="tooltip"
			className={cn(
				"relative bg-white drop-shadow-md p-4 text-sm text-gray-700 border border-gray-200 rounded w-full"
			)}
		>
			<div className="flex items-center mb-2">
				<h4 className="text-base font-semibold mr-2">{title}</h4>
				<FiInfo className="text-gray-500" /> {/* Tooltip icon */}
			</div>
			<p>{description}</p>
			{points.length > 0 && (
				<ul className="mt-2 list-disc list-inside text-gray-600">
					{points.map((point, index) => (
						<li key={index}>{point}</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default TooltipCard;
