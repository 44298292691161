import type { ExpertItemsProps } from "@components/views/(app)/home/ExpertSection/ExpertSection.types.ts";
import type { TargetData } from "@components/views/(app)/home/TargetSection/TargetSection.types.ts";
import type { Feature } from "@components/views/(app)/home/FeaturesSection/FeaturesSection.types.ts";
import type { BannerSliderItem } from "@components/views/(app)/home/BannerSection/BannerSection.types.ts";

export const bannerSlidesData: BannerSliderItem[] = [
	{
		id: 1,
		url: "team-md",
		thumbnail: "Team",
	},
	{
		id: 2,
		url: "solo",
		thumbnail: "test",
	},
	{
		id: 3,
		url: "consult",
		thumbnail: "test",
	},
];

export const expertList: ExpertItemsProps[] = [
	{
		children: [
			{
				name: "Coach Sawsen",
				profession: { en: "ICF Coach", fr: "Coach ICF" },
				image: "c1",
			},
			{
				name: "Coach Maroua",
				profession: { en: "ICF Coach", fr: "Coach ICF" },
				image: "c6",
			},
		],
	},
	{
		children: [
			{
				name: "Coach Emira",
				profession: { en: "Holistic Coach", fr: "Coach holistique" },
				image: "c2",
			},
			{
				name: "Coach Ayoub",
				profession: { en: "ICF Coach", fr: "Coach ICF" },
				image: "c4",
			},
		],
	},
	{
		children: [
			{
				name: "Coach Ghalia",
				profession: { en: "ICF Coach", fr: "Coach ICF" },
				image: "c3",
			},
			{
				name: "Coach Sana",
				profession: {
					en: "Personal Development Coach",
					fr: "Coach Développement Personnel",
				},
				image: "c5",
			},
		],
	},
	{
		children: [
			{
				name: "Coach Myriam",
				profession: { en: "Coach Supervisor", fr: "Superviseure de coachs" },
				image: "c7",
			},
			{
				name: "Coach Rym",
				profession: { en: "ICF Coach", fr: "Coach ICF" },
				image: "c8",
			},
		],
	},
];

export const targetsListData: TargetData[] = [
	{
		wrapperClassName: " bg-primary-100 hover:bg-primary-200",
		buttonClassName: "bg-primary-100 group-hover:bg-primary-200",
		highlightStyle: "text-primary-900",
		alt: "img",
		link: "/solutions/enterprise",
		en: {
			title: {
				highlightText: "Business",
				textAfterHighlight: "Coaching",
			},
			content:
				"Every business being unique, our solutions are tailor-made to fit your specific needs. Discover how our personalized approach can address the unique challenges you encounter.",
		},
		fr: {
			title: {
				textBeforeHighlight: "Coaching",
				highlightText: "Entreprise",
			},
			content:
				"Chaque entreprise étant unique, nos solutions sont conçues sur mesure pour s'adapter à vos besoins spécifiques. Découvrez comment notre approche personnalisée peut répondre aux défis uniques que vous rencontrez.",
		},
	},
	{
		wrapperClassName: "bg-primary-200 hover:bg-primary-300",
		buttonClassName: "bg-primary-200 group-hover:bg-primary-300",
		highlightStyle: "text-primary-900",
		alt: "img",
		link: "/solutions/startup",

		en: {
			title: {
				highlightText: "Startup",
				textAfterHighlight: "Coaching",
			},
			content:
				"We understand the challenges faced by rapidly growing startups. Discover our agile approach and expert support to turn your ideas into tangible successes.",
		},
		fr: {
			title: {
				textBeforeHighlight: "Coaching",
				highlightText: "Startup",
			},
			content:
				"Nous comprenons les défis auxquels sont confrontées les startups en pleine croissance. Découvrez notre approche agile et notre soutien expert pour transformer vos idées en réussites tangibles.",
		},
	},
	{
		wrapperClassName: "bg-tertiary-400 hover:bg-tertiary-500",
		buttonClassName: "bg-tertiary-400 group-hover:bg-tertiary-500",
		highlightStyle: "text-primary-900",
		alt: "img",
		link: "/solutions/employability",
		en: {
			title: {
				highlightText: "Employability",
				textAfterHighlight: "Coaching",
			},
			content:
				"Every recent graduate aspires to an enriching journey where they can develop their skills and explore new career opportunities. Discover our approach that helps kickstart your journey on the right path.",
		},
		fr: {
			title: {
				textBeforeHighlight: "Coaching",
				highlightText: "Employabilité",
			},
			content:
				"Chaque jeune diplômé aspire à un parcours enrichissant où il peut développer ses compétences et explorer de nouvelles opportunités dans sa carrière. Découvrez notre approche qui aide à démarrer sur la bonne voie.",
		},
	},
	{
		wrapperClassName: "bg-secondary-300 hover:bg-secondary-400",
		buttonClassName: "bg-secondary-300 group-hover:bg-secondary-400",
		highlightStyle: "text-primary-900",
		alt: "img",
		link: "/solutions/researcher",
		en: {
			title: {
				highlightText: "Researcher",
				textAfterHighlight: "Coaching",
			},
			content:
				"You are a researcher-entrepreneur, you have an innovative idea, and you want to bring it to life outside of your laboratory? Explore our services to transform your research into thriving businesses.",
		},
		fr: {
			title: {
				textBeforeHighlight: "Coaching",
				highlightText: "Chercheur",
			},
			content:
				" Vous êtes un chercheur - entrepreneur, vous avez une idée novatrice et vous voulez qu’elle voit le jour en dehors de votre laboratoire ? Explorez nos services pour transformer vos recherches en entreprises prospères.",
		},
	},
];

export const featuresData: Feature[] = [
	{
		icon: "messenger",
		en: {
			title: "Messaging",
			description:
				"Open communication between coaches and coachees for smooth and quick exchanges.",
		},
		fr: {
			title: "Messagerie",
			description: "Une communication ouverte entre les coach et les coachs.",
		},
	},
	{
		icon: "acc-manager",
		en: {
			title: "Account Managment",
			description:
				"For HR managers looking to easily and comprehensively track the progress of their employees.",
		},
		fr: {
			title: "Gestion du compte",
			description:
				"Pour les gestionnaires RH qui souhaitent simplement et efficacement suivre le progres des leurs employés.",
		},
	},
	{
		icon: "calendar",
		en: {
			title: "Calendar & Notifications",
			description:
				"An integrated scheduling system and personalized reminders to stay up-to-date and not miss anything.",
		},
		fr: {
			title: "Calendrier & notification",
			description:
				"Un système de rendez-vous et des rappels personnalisés pour rester à jour et ne pas manquer de quelque chose.",
		},
	},
	{
		icon: "one-to-many",
		en: {
			title: "One To Many Video",
			description:
				"An enriching experience participating in collective sessions, conducive to exchange and mutual growth.",
		},
		fr: {
			title: "VisioCall en Groupe",
			description:
				"Une expeience enrichissée participe à des sessions collectives, convaincante de l'échange et de la croissance mutuelle.",
		},
	},
	{
		icon: "one-to-one",
		en: {
			title: "One To One Video",
			description:
				"Tailored support adapted to the specific needs of each individual for guaranteed impact.",
		},
		fr: {
			title: "VisioCall Individuel",
			description:
				"Un accompagnement adapté à la besoins spécif de chaque individu pour un impact garantie.",
		},
	},

	//
	// {
	//   title: "Calendrier & notification",
	//   description:
	//     "Un système de planification intégrée et des rappels personnalisés pour rester à jour avec ne rien manquer.",
	//   icon: "1",
	// },
	// {
	//   title: "Messagerie",
	//   description:
	//     "Une communication ouverte entre coachs et coachés pour des échanges fluides et rapides.",
	//   icon: "2",
	// },
	// {
	//   title: "VisioCall",
	//   description:
	//     "Virtual face-to-face sessions for immersive and impactful coaching.",
	//   icon: "3",
	// },
	// {
	//   title: "Gestion de Compte",
	//   description:
	//     "For HR managers looking to easily and comprehensively track the progress of their employees.",
	//   icon: "4",
	// },
	// {
	//   title: "One To One",
	//   description:
	//     "Tailored support adapted to the specific needs of each individual for guaranteed impact.",
	//   icon: "5",
	// },
	// {
	//   title: "One To Many",
	//   description:
	//     "An enriching experience participating in collective sessions, conducive to exchange and mutual growth.",
	//   icon: "6",
	// },
];
