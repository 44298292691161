import type React from "react";
import { useState } from "react";
import type {
	FAQTabsSectionProps,
	LanguageType,
} from "@components/views/(app)/faq/FaqTabSection/FaqTabSection.types.ts";
import { useTranslation } from "react-i18next";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { cn } from "@core/utils";
import { TabNavItem } from "@components/views/(app)/faq/FaqTabSection/TabNavItem.tsx";
import AccordionItem from "@components/views/(app)/common/FaqAccordionSection/AccordionItem.tsx";
import i18next from "i18next";

export const FAQTabsSection: React.FC<FAQTabsSectionProps> = ({
	dataModel,
}) => {
	const [activeIndex, setActiveIndex] = useState<number | null>(null);

	const toggleAccordion = (index: number) => {
		setActiveIndex((previousIndex) => (previousIndex === index ? null : index));
	};
	const { t } = useTranslation("faq-page");
	const [activeTab, setActiveTab] = useState<number>(dataModel[0].id);

	// const layout = determineLayout(dataModel[activeTab].questions.length);
	// const columns =
	// 	layout === "second"
	// 		? "grid-cols-1 text-left max-w-[750px]"
	// 		: layout === "third"
	// 			? "grid-cols-1 _tablet:grid-cols-2 gap-x-[100px] text-left"
	// 			: "grid-cols-1 _tablet:grid-cols-2 _laptop:grid-cols-3 gap-x-[30px] text-left _laptop:text-center";

	const questionsToRender = dataModel[activeTab].questions.map((question) => {
		const questionToRender = question as unknown as LanguageType;
		return {
			...question,
			title: questionToRender[i18next.resolvedLanguage as "en" | "fr"].title,
			content:
				questionToRender[i18next.resolvedLanguage as "en" | "fr"].content,
		};
	});
	return (
		<SectionWrapper
			className={cn(
				"flex flex-col items-center justify-center gap-[70px]",
				"margin-divider-xsm"
			)}
		>
			<ul className="relative flex  flex-wrap justify-between gap-x-[30px] gap-y-[20px] ">
				<div className="absolute bottom-0 left-0 h-[2px] w-full rounded bg-primary-200" />
				{/* This is the grey underline*/}
				{dataModel.map((tab) => (
					<TabNavItem
						key={tab.id}
						id={tab.id}
						title={tab.title}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						t={t}
					/>
				))}
			</ul>
			<div className="flex flex-col items-center justify-center">
				<div className="max-w-[100%] space-y-[32px] p-4 _laptop:max-w-[90%]">
					{questionsToRender.map((question, index) => (
						<AccordionItem
							key={index}
							item={question}
							index={index}
							activeIndex={activeIndex}
							toggleAccordion={toggleAccordion}
						/>
					))}
				</div>
			</div>
		</SectionWrapper>
	);
};
