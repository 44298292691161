import React from "react";
import { cn } from "@core/utils";
import Image from "@components/elements/Image";
import Marquee from "react-fast-marquee";

function SponsorSection() {
	return (
		<section
			className={cn(
				"fixed left-0 bottom-0 min-h-20 max-h-24 p-2 flex w-full",
				"flex items-center justify-around h-full",
				"bg-white drop-shadow-lg border-t border-startgreen_primary-100/20"
			)}
		>
			{/*<Image*/}
			{/*	className="rounded-lg aspect-auto w-full h-full object-contain md:object-fill"*/}
			{/*	width={68}*/}
			{/*	height={68}*/}
			{/*	src={"images/startgreen/sponsor-list.jpg"}*/}
			{/*	alt={"Startgreen"}*/}
			{/*	aria-label="StartGreen"*/}
			{/*/>*/}

			<Marquee
				className="relative marquee flex md:!hidden h-full gap-20"
				pauseOnHover={false}
				pauseOnClick={false}
				speed={80}
			>
				<div
					className={
						"flex flex-row items-center justify-between gap-[45px] mx-[15px] "
					}
				>
					<Image
						className={"aspect-auto"}
						loading="lazy"
						width={170}
						height={170}
						src={`/images/startgreen/sponsors/oit.png`}
					/>
					<Image
						className={"h-auto"}
						loading="lazy"
						width={110}
						height={110}
						src={`/images/startgreen/sponsors/koica.png`}
					/>
					<Image
						className={"h-auto"}
						loading="lazy"
						width={70}
						height={70}
						src={`/images/startgreen/sponsors/itihad.png`}
					/>
					<Image
						className={"h-auto"}
						loading="lazy"
						width={60}
						height={60}
						src={`/images/startgreen/sponsors/utica.png`}
					/>
					<Image
						className={"h-auto"}
						loading="lazy"
						width={85}
						height={85}
						src={`/images/startgreen/sponsors/takwin.png`}
					/>
					<Image
						className={"h-auto"}
						loading="lazy"
						width={85}
						height={85}
						src={`/images/startgreen/sponsors/tn-flag.png`}
					/>
				</div>

				{/*-------------------------------------------*/}
			</Marquee>

			{/*----------------------------------------------------------------*/}
			<div
				className={cn(
					"w-full flex-row items-center justify-around hidden md:flex ",
					"_laptop:max-w-screen-_laptop_md "
				)}
			>
				<Image
					className={"aspect-auto"}
					loading="lazy"
					width={160}
					height={160}
					src={`/images/startgreen/sponsors/oit.png`}
				/>
				<Image
					className={"h-auto"}
					loading="lazy"
					width={110}
					height={110}
					src={`/images/startgreen/sponsors/koica.png`}
				/>
				<Image
					className={"h-auto"}
					loading="lazy"
					width={70}
					height={70}
					src={`/images/startgreen/sponsors/itihad.png`}
				/>
				<Image
					className={"h-auto"}
					loading="lazy"
					width={60}
					height={60}
					src={`/images/startgreen/sponsors/utica.png`}
				/>
				<Image
					className={"h-auto"}
					loading="lazy"
					width={85}
					height={85}
					src={`/images/startgreen/sponsors/takwin.png`}
				/>
				<Image
					className={"h-auto"}
					loading="lazy"
					width={85}
					height={85}
					src={`/images/startgreen/sponsors/tn-flag.png`}
				/>
				{/*-------------------------------------------*/}
			</div>
		</section>
	);
}

export default SponsorSection;
