import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {
	constructTranslations,
	importFiles,
	resolveTranslations,
} from "@i18n/i18n.utils.ts";

const enFiles = importFiles("en");
const frFiles = importFiles("fr");

const enResolved = resolveTranslations(enFiles);
const frResolved = resolveTranslations(frFiles);

const translations = {
	...constructTranslations({ en: enResolved }),
	...constructTranslations({ fr: frResolved }),
};
// console.log(translations);

try {
	void i18n
		.use(LanguageDetector)
		.use(initReactI18next)
		.init({
			debug: false,
			fallbackLng: "fr",
			interpolation: {
				escapeValue: false,
			},
			resources: translations,
			lng: localStorage.getItem("i18nextLng") || "fr",
			returnNull: false,
		});
} catch (error) {
	console.error("i18next failed to initialize:", error);
}

i18n.on("languageChanged", (lng) => {
	localStorage.setItem("i18nextLng", lng);
});

export { default } from "i18next";
