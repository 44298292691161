import type React from "react";
import type { TabNavItemProps } from "@components/views/(app)/faq/FaqTabSection/FaqTabSection.types.ts";
import { cn } from "@core/utils";

export const TabNavItem: React.FC<TabNavItemProps> = ({
	id,
	title,
	activeTab,
	setActiveTab,
	t,
}) => {
	const handleClick = () => {
		setActiveTab(id);
	};

	return (
		<button
			onClick={handleClick}
			className={cn(
				"titleLarge relative pb-2 text-primary-400 _tablet:px-[20px]",
				activeTab === id ? "font-medium text-text-black" : ""
			)}
		>
			{t(title)}
			{activeTab === id && (
				<div className="absolute bottom-[0] left-0 h-1 w-full rounded bg-secondary-900" />
			)}
		</button>
	);
};
