import type { FunctionComponent } from "types/types.ts";
import { useTranslation } from "react-i18next";
import HeaderSection from "@pages/(startgreen)/views/(landing)/HeaderSection";
import { cn } from "@core/utils";
import PhasesSection from "@pages/(startgreen)/views/(landing)/PhasesSection";
import FAQSection from "@pages/(startgreen)/views/(landing)/FAQSection";
import CTASection from "@pages/(startgreen)/views/(landing)/CTASection";
import FooterSection from "@pages/(startgreen)/views/(landing)/FooterSection";
import Navbar from "@pages/(startgreen)/views/(landing)/Navbar.tsx";
import SponsorSection from "@pages/(startgreen)/views/(landing)/SponsorSection";

export const StartgreenLanding = (): FunctionComponent => {
	const { t } = useTranslation("coaches-page");

	return (
		// <NavigationWrapper>
		<main
			className={cn(
				"flex flex-col h-full w-full",
				"pt-[40px] pb-[120px] px-[20px] md:px-[163px] mx-auto max-w-screen-_middle",
				"gap-8"
			)}
		>
			<Navbar />
			<div className={"flex flex-col gap-16"}>
				<HeaderSection />
				<PhasesSection />
				<FAQSection />
				<CTASection />
				<FooterSection />
				<SponsorSection />
			</div>
		</main>
		// </NavigationWrapper>
	);
};
