import type { ReactNode } from "react";
import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleDarkMode, handleRtl } from "@store/reducers/layoutReducer";
import type { RootState } from "@core/store";
import { useTranslation } from "react-i18next";

interface ThemeContextType {
	currentTheme: string;
	setCurrentTheme: (theme: string) => void;
	toggleDarkMode: () => void;
	isRTL: boolean;
	toggleRTL: () => void;
}

export const ThemeContext = createContext<ThemeContextType | undefined>(
	undefined
);

export function ThemeContextProvider({ children }: { children: ReactNode }) {
	const dispatch = useDispatch();
	const { darkMode, isRTL } = useSelector((state: RootState) => state.layout);
	const [currentTheme, setTheme] = useState(darkMode ? "dark" : "light");
	const { i18n } = useTranslation();

	useEffect(() => {
		const root = window.document.documentElement;

		const removeOldTheme = currentTheme === "light" ? "dark" : "light";
		root.classList.remove(removeOldTheme);
		root.classList.add(currentTheme);

		root.setAttribute("lang", i18n.language);
	}, [currentTheme, i18n.language]);

	useEffect(() => {
		document.documentElement.dir = isRTL ? "rtl" : "ltr";
	}, [isRTL]);

	const setCurrentTheme = (theme: string) => {
		setTheme(theme);
		dispatch(handleDarkMode(theme === "dark"));
	};

	const toggleDarkMode = () => {
		setCurrentTheme(currentTheme === "light" ? "dark" : "light");
	};

	const toggleRTL = () => {
		dispatch(handleRtl(!isRTL));
	};

	return (
		<ThemeContext.Provider
			value={{
				currentTheme,
				setCurrentTheme,
				toggleDarkMode,
				isRTL,
				toggleRTL,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
}
