import { useState, useEffect, useCallback } from "react";
import { Options } from "./useFilter.types.ts";

const useFilter = (
	data,
	initialSearchProps,
	initialFiltersProps,
	options?: Options
) => {
	const {
		itemsPerPage = 10,
		strict = false,
		caseSensitive: globalCaseSensitive = false,
		paginate = true,
	} = options || {};

	const [filteredData, setFilteredData] = useState([]);
	const [filteredDataCount, setFilteredDataCount] = useState(0); // New state for total filtered data count
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [totalDataLines, setTotalDataLines] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const applySearchFilters = useCallback(
		(item) => {
			const {
				query,
				fields,
				caseSensitive: searchCaseSensitive = globalCaseSensitive,
			} = initialSearchProps;

			return fields.some((field) => {
				const fieldPath = field.split("."); // Split the field by "."
				let value;

				// Traverse the nested structure
				if (fieldPath[0] === "answers") {
					if (Array.isArray(item.answers)) {
						value = item.answers.find((answer) => {
							// Check if the field path matches "answers.question.questionName"
							return (
								answer.question &&
								answer.question.questionName === fieldPath[2] &&
								answer.value
							);
						});
						if (value) {
							value = value.value; // Get the answer value if match found
						}
					}
				} else {
					value = item[fieldPath[0]];
				}

				// Check if the value matches the search query
				if (value && typeof value === "string") {
					const processedValue = searchCaseSensitive
						? value
						: value.toLowerCase();
					const processedQuery = searchCaseSensitive
						? query
						: query.toLowerCase();
					return processedValue.includes(processedQuery);
				}

				return false;
			});
		},
		[initialSearchProps, globalCaseSensitive]
	);

	const applyAdditionalFilters = useCallback(
		(item) => {
			for (const type in initialFiltersProps) {
				if (initialFiltersProps.hasOwnProperty(type)) {
					const filterValues = initialFiltersProps[type];
					const { caseSensitive = globalCaseSensitive } = filterValues;

					// Handle date range filter for createdAt
					if (type === "createdAt") {
						const { min, max } = filterValues;

						if (item.createdAt) {
							const createdAtValue = new Date(item.createdAt);
							const minDate = min ? new Date(min) : null;
							const maxDate = max ? new Date(max) : null;

							// Set minDate to the start of the day
							if (minDate) {
								minDate.setHours(0, 0, 0, 0); // Start of the day
							}

							// Set maxDate to the end of the day
							if (maxDate) {
								maxDate.setHours(23, 59, 59, 999); // End of the day
							}

							// Check the date range
							if (
								(minDate && createdAtValue < minDate) || // Exclude if before min
								(maxDate && createdAtValue > maxDate) // Exclude if after max
							) {
								return false; // Exclude items outside the date range
							}
						}
						continue; // Skip the rest for the date filter
					}

					// Handle age range filter
					if (type === "answers.question.age") {
						const { min, max } = filterValues;
						if (item.answers) {
							const ageAnswer = item.answers.find(
								(answer) =>
									answer.question && answer.question.questionName === "age"
							);
							if (ageAnswer && ageAnswer.value) {
								const ageValue = Number(ageAnswer.value);
								if (ageValue < min || ageValue > max) {
									return false; // Exclude items not within the range
								}
							}
						}
						continue; // Skip the rest for the age filter
					}

					// Other filters logic...

					const fieldPath = type.split(".");
					let itemValue;

					if (fieldPath.length === 1) {
						itemValue = item[fieldPath[0]];
					} else if (fieldPath[0] === "answers") {
						if (Array.isArray(item.answers)) {
							const answerMatch = item.answers.find(
								(answer) =>
									answer.question &&
									answer.question.questionName === fieldPath[2]
							);
							if (answerMatch) {
								itemValue = answerMatch.value;
							}
						}
					}

					// Apply other multi-value filters (arrays)
					if (Array.isArray(filterValues.values)) {
						const { values } = filterValues;

						if (values.length > 0) {
							if (!caseSensitive && typeof itemValue === "string") {
								itemValue = itemValue.toLowerCase();
							}

							const processedValues = caseSensitive
								? values
								: values.map((value) =>
										typeof value === "string" ? value.toLowerCase() : value
									);

							if (Array.isArray(itemValue)) {
								const hasAnyValue = processedValues.some((value) =>
									itemValue.includes(value)
								);
								if (!hasAnyValue) {
									return false;
								}
							} else {
								const hasValue = processedValues.includes(itemValue);
								if (!hasValue) {
									return false;
								}
							}
						}
					}
				}
			}
			return true;
		},
		[initialFiltersProps, globalCaseSensitive]
	);

	useEffect(() => {
		setIsLoading(true);
		let filtered = data;
		// Apply search and additional filters to the entire data set
		if (initialFiltersProps && initialSearchProps) {
			filtered = data.filter(
				(item) => applySearchFilters(item) && applyAdditionalFilters(item)
			);
		}

		setFilteredDataCount(filtered.length); // Update total filtered count

		// If itemsPerPage is -1, display all filtered data
		if (itemsPerPage === -1 || !paginate) {
			setFilteredData(filtered);
			setTotalDataLines(filtered.length);
			setTotalPages(1);
		} else {
			// Handle pagination normally if itemsPerPage is not -1
			const startIndex = (currentPage - 1) * itemsPerPage;
			const paginatedData = filtered.slice(
				startIndex,
				startIndex + itemsPerPage
			);
			if (JSON.stringify(filteredData) !== JSON.stringify(paginatedData)) {
				setFilteredData(paginatedData);
			}
			setTotalDataLines(filtered.length);
			setTotalPages(Math.ceil(filtered.length / itemsPerPage));
		}

		setIsLoading(false);
	}, [
		data,
		currentPage,
		itemsPerPage,
		paginate,
		applySearchFilters,
		applyAdditionalFilters,
	]);

	const setPage = useCallback(
		(page) => {
			if (page > 0 && page <= totalPages) {
				setCurrentPage(page);
			}
		},
		[totalPages]
	);

	const loadMoreData = useCallback(() => {
		if (currentPage < totalPages) {
			setCurrentPage((prevPage) => prevPage + 1);
		}
	}, [currentPage, totalPages]);

	return {
		filteredData,
		filteredDataCount, // Return the total filtered data count
		currentPage,
		totalPages,
		totalDataLines,
		isLoading,
		setPage,
		loadMoreData,
	};
};

export default useFilter;
