import { useTranslation } from "react-i18next";
import { featuresData } from "@api/database/constant/home.data.ts";
import { cn } from "@core/utils";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { GridSection } from "@components/views/(app)/common/ValueSection/GridSection.tsx";
import Button from "@components/elements/Buttons/Button.tsx";
import Text from "@components/elements/Text";
import i18next from "i18next";

const FeaturesSection = ({
	isDivider = true,
	dividerColor = "bg-primary-50/50",
}: {
	isDivider?: boolean;
	dividerColor?: string;
}) => {
	const { t } = useTranslation("home-page");
	const { t: buttons } = useTranslation("misc");

	const currentLanguageData = featuresData.map((item) => {
		return {
			...item,
			// @ts-ignore
			content: item[i18next.resolvedLanguage],
		};
	});
	return (
		<div className={cn(isDivider && dividerColor)}>
			<SectionWrapper
				className={cn(
					"flex flex-col items-center justify-center ",
					"gap-[60px] py-[80px] _large:gap-[60px]",
					"margin-divider-medium"
				)}
			>
				<div className="max-w-[400px] _tablet:max-w-[500px] _laptop:max-w-[860px]">
					<Text
						tag="h2"
						className={cn("displayLarge text-center")}
						highlightStyle="text-primary-900 font-semibold"
						text={t("features-section.title")}
					/>
				</div>
				<GridSection
					// descriptionStyle='tracking-tight !max-w-[220px] _tablet:!max-w-[550px]'
					// titleStyle={"bg-red-300"}
					items={currentLanguageData}
					// gridStyle={"_tablet_lg:w-[90%] _laptop:w-full"}
					itemStyle="min-w-[50px] _tablet:min-w-[400px]"
					className={cn(
						"!flex !flex-wrap justify-center gap-x-[20px] gap-y-[20px]",
						" _tablet:grid-cols-2 _laptop:grid-cols-3 _large:gap-x-[30px] _large:gap-y-[30px]"
					)}
					iconStyle="h-[50px] w-[50px]"
					iconPath="features"
				/>
				<Button variant="primary" link={`/contact`}>
					{buttons("buttons.book-demo")}
				</Button>
			</SectionWrapper>
		</div>
	);
};
export default FeaturesSection;
