import { cn } from "@core/utils";

function MenuButton({
	isSideMenuOpen,
	toggleSideMenu,
}: {
	isSideMenuOpen: boolean;
	toggleSideMenu: () => void;
}) {
	return (
		<button
			onClick={toggleSideMenu}
			className={cn(
				isSideMenuOpen ? "hidden" : "block",
				"menutoggle text-gray-600 hover:text-gray-600/75 block rounded p-2.5 transition _laptop_md:hidden"
			)}
		>
			<span className="sr-only">Toggle menu</span>
			{isSideMenuOpen ? (
				<svg
					className="h-5 w-5"
					viewBox="0 0 24 13"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M2.72773 0.483396C2.23962 -0.00480998 1.44817 -0.00489259 0.959959 0.483212C0.471753 0.971316 0.47167 1.76277 0.959774 2.25098L7.22759 8.52011L0.968121 14.7796C0.479966 15.2677 0.479966 16.0592 0.968121 16.5473C1.45628 17.0355 2.24773 17.0355 2.73589 16.5473L8.99518 10.2881L15.2618 16.556C15.7499 17.0442 16.5413 17.0443 17.0296 16.5562C17.5178 16.0681 17.5178 15.2766 17.0297 14.7884L10.7629 8.52029L17.026 2.25726C17.5141 1.76911 17.5141 0.97765 17.026 0.489495C16.5378 0.00133944 15.7464 0.00133944 15.2582 0.489495L8.99536 6.75234L2.72773 0.483396Z"
						fill="#094BA4"
					/>
				</svg>
			) : (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-5 w-5 stroke-primary-900"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					strokeWidth="2"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M4 6h16M4 12h16M4 18h16"
					/>
				</svg>
			)}
		</button>
	);
}

export default MenuButton;
