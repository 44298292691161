import type { Affirmation } from "types/types.ts";

export const affirmations: Affirmation[] = [
	{
		id: 11,
		title: "Affirmation du jour",
		description: {
			en: "Today I have completed a small step towards an important goal.",
			fr: "Aujourd’hui, j’accomplis un pas, même minuscule, vers un but qui me tient à cœur.",
		},
		date_time: "2023-12-02 09:00:00",
		created_at: "2021-11-17T10:00:53.000000Z",
		updated_at: "2023-11-09T09:20:37.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
	{
		id: 12,
		title: "Affirmation du jour",
		description: {
			en: "Today I spend time with a close friend or a cheerful, joyous person.",
			fr: "Aujourd’hui je passe un moment avec un proche ou un ami enjoué, joyeux.",
		},
		date_time: "2023-12-03 09:00:00",
		created_at: "2021-11-17T10:02:42.000000Z",
		updated_at: "2023-11-09T09:20:48.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
	{
		id: 13,
		title: "Affirmation du jour",
		description: {
			en: "Today I create a moment in the day where I am joyful and optimistic.",
			fr: "Aujourd’hui, je crée un moment dans la journée où je suis joyeux, optimiste.",
		},
		date_time: "2023-12-04 09:00:00",
		created_at: "2021-11-17T10:03:36.000000Z",
		updated_at: "2023-11-09T09:20:58.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
	{
		id: 14,
		title: "Affirmation du jour",
		description: {
			en: "Today I dedicate time to myself.",
			fr: "Aujourd’hui, je consacre du temps à moi-même.",
		},
		date_time: "2023-12-05 09:00:00",
		created_at: "2021-11-17T10:04:35.000000Z",
		updated_at: "2023-11-09T09:21:07.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
	{
		id: 15,
		title: "Affirmation du jour",
		description: {
			en: "Today I create a moment in the day where I am smiling.",
			fr: "Aujourd’hui, je crée un moment dans la journée où je suis souriant.",
		},
		date_time: "2023-12-06 09:00:00",
		created_at: "2021-11-17T10:05:44.000000Z",
		updated_at: "2023-11-09T09:21:17.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
	{
		id: 16,
		title: "Affirmation du jour",
		description: {
			en: "Today I take 5 minutes to close my eyes, listen to the sounds around me, and follow my breathing.",
			fr: "Aujourd’hui je prends 5min pour fermer les yeux, écouter les bruits autour, suivre ma respiration.",
		},
		date_time: "2023-12-07 09:00:00",
		created_at: "2021-11-17T10:07:18.000000Z",
		updated_at: "2023-11-09T09:21:27.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
	{
		id: 17,
		title: "Affirmation du jour",
		description: {
			en: "Today I treat myself to a small personal pleasure.",
			fr: "Aujourd’hui, je m’offre un petit plaisir personnel.",
		},
		date_time: "2023-12-08 09:00:00",
		created_at: "2021-11-17T10:08:14.000000Z",
		updated_at: "2023-11-09T09:21:38.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
	{
		id: 18,
		title: "Affirmation du jour",
		description: {
			en: "Today I tackle a subject or task I have been postponing for a long time.",
			fr: "Aujourd’hui, je traite un sujet, un dossier que je reporte depuis longtemps.",
		},
		date_time: "2023-12-09 09:00:00",
		created_at: "2021-11-17T10:09:12.000000Z",
		updated_at: "2023-11-09T09:21:48.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
	{
		id: 19,
		title: "Affirmation du jour",
		description: {
			en: "Today I will let my heart speak more than my head.",
			fr: "Aujourd’hui, je vais laisser parler mon cœur plus que ma tête.",
		},
		date_time: "2023-12-10 09:00:00",
		created_at: "2021-11-17T10:10:59.000000Z",
		updated_at: "2023-11-09T09:21:56.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
	{
		id: 20,
		title: "Affirmation du jour",
		description: {
			en: "Today I buy a flower, a gift, or a dessert for someone I love, which can be myself.",
			fr: "Aujourd’hui, j’achète une fleur, un présent, un dessert… à quelqu’un que j’aime, qui peut être moi.",
		},
		date_time: "2023-12-11 09:00:00",
		created_at: "2021-11-17T10:13:40.000000Z",
		updated_at: "2023-11-09T09:22:07.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
	{
		id: 21,
		title: "Affirmation du jour",
		description: {
			en: "Today I smile spontaneously at as many people as I meet.",
			fr: "Aujourd’hui, je souris spontanément au maximum de personnes que je rencontre.",
		},
		date_time: "2023-12-12 09:00:00",
		created_at: "2021-11-17T10:15:09.000000Z",
		updated_at: "2023-11-09T09:24:12.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
	{
		id: 22,
		title: "Affirmation du jour",
		description: {
			en: "Today I call a friend I have not seen for a long time.",
			fr: "Aujourd’hui, j’appelle un ami perdu de vue depuis longtemps.",
		},
		date_time: "2023-12-13 09:00:00",
		created_at: "2021-11-17T10:16:15.000000Z",
		updated_at: "2023-11-09T09:24:23.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
	{
		id: 23,
		title: "Affirmation du jour",
		description: {
			en: "Today I accept a situation that usually annoys me.",
			fr: "Aujourd’hui, j’accepte une situation qui d’habitude m’insupporte.",
		},
		date_time: "2023-12-14 09:00:00",
		created_at: "2021-11-17T10:25:37.000000Z",
		updated_at: "2023-11-09T09:24:00.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
	{
		id: 24,
		title: "Affirmation du jour",
		description: {
			en: "Today I listen to others and do not finish their sentences.",
			fr: "Aujourd’hui, j’écoute l’autre et je ne finis pas la phrase à sa place.",
		},
		date_time: "2023-12-15 09:00:00",
		created_at: "2021-11-17T10:27:27.000000Z",
		updated_at: "2023-11-09T09:23:46.000000Z",
		category_image_url: "https://coachini.net/img/no-image.jpg",
	},
];
