import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import React from "react";
import { StatsCard } from "@pages/(startgreen)/views/(dashboard)/StatsCards";

const data = [{ category: "Accepted Users", male: 100, female: 80 }];

const CustomLabel = ({ x, y, width, height, value, data, index, gender }) => {
	const candidateCount =
		gender === "male"
			? data?.breakdown[index]?.male
			: data?.breakdown[index]?.female;
	const total = data?.breakdown[index]?.total;

	const ratio2 = (candidateCount / total) * 100;

	if (!candidateCount || !total || total === 0) {
		return null;
	}

	return (
		<text
			x={x + width / 2}
			y={y + height / 2}
			textAnchor="middle"
			dominantBaseline="middle"
			fill="white"
			fontSize={14}
		>
			<tspan x={x + width / 2} dy="0">
				Total: {candidateCount}
			</tspan>
			<tspan x={x + width / 2} dy="1.2em">
				Ratio: {ratio2.toFixed(0)}%
			</tspan>
		</text>
	);
};

const AcceptedUsersChart = ({ data }) => {
	return (
		<ResponsiveContainer width="100%" height={400}>
			<BarChart data={data?.breakdown}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="category" />
				<YAxis allowDecimals={false} />
				<Tooltip
					content={({ payload }) => {
						if (payload && payload.length > 0) {
							const { male, female, total } = payload[0].payload;
							return (
								<div className={"bg-white p-4 rounded-md"}>
									<p className={"text-[#0088FE]"}>Homme: {male}</p>
									<p className={"text-[#FF8042]"}>Femme: {female}</p>
									<p>Total acceptés: {total}</p>
								</div>
							);
						}
						return null;
					}}
				/>
				<Legend />

				{/* Male Bar */}
				<Bar dataKey="male" name="Homme" stackId="a" fill="#0088FE">
					<LabelList content={<CustomLabel data={data} gender={"male"} />} />
				</Bar>

				{/* Female Bar */}
				<Bar dataKey="female" name="Femme" stackId="a" fill="#FF8042">
					<LabelList content={<CustomLabel data={data} gender={"female"} />} />
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};

export default AcceptedUsersChart;
