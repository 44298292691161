import { useTranslation } from "react-i18next";
import { cn } from "@core/utils";
import Button from "@components/elements/Buttons/Button.tsx";
import Text from "@components/elements/Text";

function HeaderSection({
	solutionType = "enterprise",
}: {
	solutionType?: "enterprise" | "startup" | "employability" | "researcher";
}) {
	const { t } = useTranslation("solutions-page");
	const { t: buttons } = useTranslation("misc");

	let titleColor;

	switch (solutionType) {
		case "enterprise": {
			titleColor = "text-primary-400";
			break;
		}
		case "startup": {
			titleColor = "text-primary-400";
			break;
		}
		case "employability": {
			titleColor = "text-tertiary-900";
			break;
		}
		case "researcher": {
			titleColor = "text-secondary-900";
			break;
		}
	}
	return (
		<section
			className={cn(
				"relative min-h-[calc(70vh-64px)] w-full _tablet:h-[calc(45vh-64px)] _tablet:min-h-full _laptop:h-[calc(75vh-64px)]",
				"flex flex-col items-center justify-center gap-[32px]",
				"overflow-clip rounded-md bg-primary-50/50"
			)}
		>
			<div
				className={cn(
					"flex flex-col items-center justify-center",
					"gap-[22px] px-[40px] ",
					"w-full max-w-[760px]"
				)}
			>
				<Text
					text={t(`hero-section.${solutionType}.title`)}
					className={cn("displayLarge text-center text-text-black")}
					highlightStyle={cn("font-semibold", titleColor)}
				/>
				<Text
					tag="p"
					text={t(`hero-section.${solutionType}.paragraph`)}
					className="titleLarge max-w-[360px] text-center text-text-700 _tablet:max-w-[480px] _laptop:max-w-[680px]"
				/>
			</div>
			<Button variant="primary" link={`/contact`}>
				{buttons("buttons.book-demo")}
			</Button>
		</section>
	);
}

export default HeaderSection;
