import React from "react";
import { useNavigate } from "@tanstack/react-router";
import useAuth from "@core/guards/auth.util.ts";

interface RoleGuardProps {
	requiredRole: string;
	children: React.ReactNode;
}

const RoleGuard: React.FC<RoleGuardProps> = ({ requiredRole, children }) => {
	const { isAuthenticated, role, loading } = useAuth();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (!loading) {
			if (!isAuthenticated || role !== requiredRole) {
				navigate({
					to: "/startgreen-by-iptic/dashboard",
				});
			}
		}
	}, [loading, isAuthenticated, role, requiredRole, navigate]);

	return isAuthenticated && role === requiredRole ? <>{children}</> : null;
};

export default RoleGuard;
