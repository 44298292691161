import FormField from "@pages/(startgreen)/views/(form)/FormField.tsx";
import React from "react";

export const CheckboxGroup = ({
	title,
	name,
	options,
	conditionalField,
}: {
	title: string;
	name: string;
	options: any[];
	conditionalField?: any;
}) => (
	<div className="bg-white rounded-md p-4">
		<h3>{title}</h3>
		<div className="p-2 text-[16px] text-text-black">
			<FormField name={name} type="checkbox" options={options} />
			{conditionalField?.condition && (
				<FormField
					name={conditionalField.name}
					label={conditionalField.label}
					type="text"
					placeholder={conditionalField.placeholder}
				/>
			)}
		</div>
	</div>
);

export const RadioGroup = ({
	title,
	name,
	options,
	conditionalField,
}: {
	title: string;
	name: string;
	options: any[];
	conditionalField?: any;
}) => (
	<div className="bg-white rounded-md p-4">
		<h3>{title}</h3>
		<div className="p-2 text-[16px] text-text-black">
			<FormField name={name} type="radio" options={options} />
			{conditionalField?.condition && (
				<FormField
					name={conditionalField.name}
					label={conditionalField.label}
					type="text"
					placeholder={conditionalField.placeholder}
				/>
			)}
		</div>
	</div>
);
