import type React from "react";
import { useEffect, useState } from "react";
import type {
	FooterLinkProps,
	FooterLinksProps,
} from "@components/layout/Footer/Footer.types.ts";
import { Link, useLocation } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { cn } from "@core/utils";
import FooterAccordion from "@components/layout/Footer/FooterAccordion.tsx";

const FooterLink: React.FC<FooterLinkProps> = ({
	text,
	to,
	isActive,
	isActiveStyle = "text-primary-700",
}) => {
	const activeClass = isActive ? isActiveStyle : "text-primary-700";
	return (
		<li>
			<Link to={to} className={`text-primary-700 ${activeClass} transition`}>
				{text}
			</Link>
		</li>
	);
};

export const FooterLinks: React.FC<FooterLinksProps> = ({ links }) => {
	const { t } = useTranslation("layout");

	const router = useLocation().pathname;
	const pathname = router.replace(/^\/(en|fr)(\/|$)/, "/");
	const [activeLink, setActiveLink] = useState<string | null | undefined>(null);

	useEffect(() => {
		// Determine the active link based on the current pathname
		const activeLink = links.find(
			(link) =>
				pathname === link.link ||
				(link.children &&
					link.children.some((child) => pathname === child.link))
		);
		setActiveLink(activeLink ? activeLink.title : null);
	}, [pathname, links]);

	return (
		<div className="flex h-full flex-col items-center gap-[20px] _tablet:flex-row _tablet:items-start _tablet:gap-10">
			<div className="flex max-w-max flex-col gap-[5px] text-center _tablet:gap-[1px] _tablet:text-left">
				{links.map((link, index) => {
					if (!link.children) {
						return (
							<ul key={index} className="buttonMedium _tablet:max-w-max">
								<FooterLink
									text={t(link.title + "")}
									// @ts-ignore
									to={link.link}
									isActive={pathname === link.link}
								/>
								{pathname === link.link && (
									<div className=" h-1 w-full self-end rounded bg-secondary-900" />
								)}
							</ul>
						);
					}
				})}
			</div>
			<FooterAccordion footerLinks={links} isActiveLink={activeLink} />
			<div className="hidden _tablet:flex _tablet:gap-10">
				{links.map((link, index) => {
					const isActive = activeLink === link.title;
					// console.log('link.title', link.title);
					// console.log('activeLink', activeLink);
					if (link.children) {
						return (
							<div
								key={index}
								className=" max-w-max flex-col gap-[5px] border-l-2 border-text-300 pl-10 text-center  _tablet:text-left"
							>
								<p
									className={cn("labelLarge max-w-max text-text-700", isActive)}
								>
									{t(link.title + "")}
									{isActive && (
										<span className=" h-1 w-full self-end rounded bg-secondary-900" />
									)}
								</p>

								{link.children.map((child, index) => (
									<ul key={index} className="buttonMedium space-y-[5px]">
										<FooterLink
											text={t(child.title + "")}
											// @ts-ignore
											to={child.link}
											isActive={pathname === child.link}
											isActiveStyle="font-bold "
										/>
									</ul>
								))}
							</div>
						);
					}
				})}
			</div>
		</div>
	);
};
