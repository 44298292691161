import React, { useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Grid,
	IconButton,
	CircularProgress,
} from "@mui/material";
import {
	fetchVisitors,
	createVisitors,
} from "@api/service/startgreen.services.ts"; // Adjust import path
import VisitorsTable from "./VisitorsTable"; // Adjust path as needed
import CloseIcon from "@mui/icons-material/Close";
import useGenericQuery from "@hooks/useGenericQuery.ts";
import useInvalidateOnVisibility from "@hooks/useInvalidateOnVisibility.ts";
import Sidebar from "@pages/(startgreen)/views/(dashboard)/Sidebar";
import Navbar from "@pages/(startgreen)/views/(dashboard)/Navbar";
import { cn } from "@core/utils";
import { toast, Toaster } from "react-hot-toast"; // Import toast
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { Tooltip } from "@pages/(startgreen)/views/(dashboard)/Tooltip.tsx";
import useFilter from "@hooks/useFilter/useFilter.ts";
import TooltipCard from "@pages/(startgreen)/views/(dashboard)/TooltipCard.tsx";

const VisitorsManagement = () => {
	const {
		data: visitors,
		isLoading,
		refetch,
	} = useGenericQuery({
		queryKey: ["visitors"],
		queryFn: fetchVisitors,
	});
	const queryKeys = ["visitors"];
	const { refetchQueries } = useInvalidateOnVisibility(queryKeys);
	const [isModalOpen, setModalOpen] = useState(false);
	const [emails, setEmails] = useState([""]);
	const [file, setFile] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
	const [isLoadingUpload, setIsLoadingUpload] = useState(false);

	// Pagination and filtering setup with useFilter
	const initialSearchProps = {
		query: "",
		fields: ["email"],
	};

	const initialFiltersProps = {};

	const [itemsPerPage, setItemsPerPage] = useState(50);
	const [currentPageIndex, setCurrentPageIndex] = useState(1);

	const options = {
		itemsPerPage,
		strict: true,
		caseSensitive: false,
		paginate: true,
	};

	const { filteredData, currentPage, totalPages, setPage, loadMoreData } =
		useFilter(visitors || [], initialSearchProps, initialFiltersProps, options);

	const handleAddEmail = () => {
		setEmails((prev) => [...prev, ""]);
	};

	const handleEmailChange = (index, value) => {
		const updatedEmails = [...emails];
		updatedEmails[index] = value;
		setEmails(updatedEmails);
	};

	const handleRemoveEmail = (index) => {
		setEmails((prev) => prev.filter((_, i) => i !== index));
	};

	const handleSubmit = async () => {
		if (emails.filter(Boolean).length === 0) {
			toast.error(
				"Il doit y avoir au moins une adresse email avant de confirmer."
			);
			return;
		}
		setIsLoadingSubmit(true);
		try {
			await createVisitors({ emails: emails.filter(Boolean) }).then(() => {
				setIsLoadingSubmit(false);
				setModalOpen(false);
				setEmails([""]); // Reset email states
				toast.success("Invitations envoyées avec succès !");
			});
			refetch();
		} finally {
			setIsLoadingSubmit(false);
			setModalOpen(false);
			setEmails([""]); // Reset email states
		}
	};

	const handleFileUpload = async () => {
		if (file) {
			setIsLoadingUpload(true);
			try {
				const fileExtension = file.name.split(".").pop().toLowerCase();
				let uploadedEmails = [];

				if (fileExtension === "csv") {
					// Parse CSV file
					const parsedData = await new Promise((resolve, reject) => {
						Papa.parse(file, {
							header: true,
							skipEmptyLines: true,
							complete: (results) => resolve(results.data),
							error: (err) => reject(err),
						});
					});

					// Extract emails from parsed data
					uploadedEmails = parsedData
						.map((row) => row.email)
						.filter((email) => email && email.includes("@"));
				} else if (["xlsx", "xls"].includes(fileExtension)) {
					// Parse Excel file
					const data = await file.arrayBuffer();
					const workbook = XLSX.read(data, { type: "array" });
					const firstSheetName = workbook.SheetNames[0];
					const worksheet = workbook.Sheets[firstSheetName];
					const sheetData = XLSX.utils.sheet_to_json(worksheet, {
						header: 1,
						defval: "",
					});

					// Extract emails from sheet data
					const headers = sheetData[0].map((header) =>
						header.toString().trim().toLowerCase()
					);
					const emailColumnIndex = headers.findIndex((header) =>
						["mail", "email", "e-mail"].includes(header)
					);

					if (emailColumnIndex !== -1) {
						uploadedEmails = sheetData
							.slice(1)
							.map((row) => row[emailColumnIndex])
							.filter((email) => email && email.includes("@"));
					}
				} else {
					toast.error("Seuls les fichiers CSV et Excel sont acceptés.");
					return;
				}

				// Remove duplicates and update state
				const uniqueEmails = [...new Set([...emails, ...uploadedEmails])];
				setEmails(uniqueEmails);
				toast.success("Fichier téléchargé avec succès !");
			} catch (error) {
				toast.error("Erreur lors du téléchargement du fichier.");
			} finally {
				setIsLoadingUpload(false);
				setFile(null); // Reset file input
			}
		}
	};

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};
	const tooltipPoints = [
		"Télécharger un fichier Excel ou CSV",
		"Saisir des adresses email manuellement",
		"Vérifier la liste avant de soumettre",
	];
	return (
		<div className="flex">
			<Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
			<div className="flex-grow">
				<Navbar toggleSidebar={toggleSidebar} title="Gestion des Visiteurs" />
				<main className="space-y-6 p-6">
					<TooltipCard
						title="Détails sur l'invitation"
						description="Voici quelques détails supplémentaires sur la façon d'inviter des personnes."
						points={tooltipPoints}
					/>

					<Toaster position="bottom-center" reverseOrder={false} />
					<div className={"flex flex-col gap-2 w-full"}>
						<div className="flex flex-col w-full gap-2">
							<div className={"flex w-full gap-2"}>
								<input
									type="file"
									accept=".csv, .xlsx"
									className="border w-full rounded px-2 py-1"
									onChange={(e) => setFile(e.target.files[0])}
									disabled={isLoadingSubmit || isLoadingUpload}
								/>
								<Tooltip
									title="Téléchargement de fichier"
									description="Téléchargez un fichier CSV ou Excel pour importer des adresses email."
								/>
							</div>

							<Button
								variant="contained"
								className={cn(
									"!bg-startgreen_primary-100 !disabled:bg-startgreen_primary-100/20 disabled:!cursor-not-allowed",
									!file || isLoadingUpload
										? "cursor-not-allowed !bg-startgreen_primary-100/30"
										: ""
								)}
								onClick={handleFileUpload}
								disabled={!file || isLoadingUpload || isLoadingSubmit}
							>
								{isLoadingUpload ? (
									<CircularProgress size={24} />
								) : (
									"Importer les Emails"
								)}
							</Button>
						</div>
					</div>

					{/* Scrollable Email List */}
					<div className="border rounded p-4 max-h-80 overflow-y-auto">
						<h3 className="font-bold mb-2">Liste des Emails a inviter</h3>
						{emails.map((email, index) => (
							<div key={index} className="flex items-center space-x-2 mt-2">
								<TextField
									fullWidth
									label="Email"
									type="email"
									value={email}
									onChange={(e) => handleEmailChange(index, e.target.value)}
									disabled={isLoadingSubmit}
								/>
								<IconButton
									color="error"
									onClick={() => handleRemoveEmail(index)}
									disabled={isLoadingSubmit}
								>
									<CloseIcon />
								</IconButton>
							</div>
						))}
					</div>
					<div className={"flex gap-2"}>
						<button
							className={cn(
								"border border-startgreen_primary-100/30 px-4 py-2 rounded !bg-startgreen_darkblue-100 !disabled:bg-startgreen_primary-100 text-white hover:!bg-opacity-75 disabled:!cursor-not-allowed"
							)}
							onClick={handleAddEmail}
							disabled={isLoadingSubmit}
						>
							Ajouter un Email
						</button>

						<button
							className="border border-startgreen_primary-100/30 px-4 py-2 rounded !bg-startgreen_primary-100 !disabled:bg-startgreen_primary-100 text-white hover:!bg-opacity-75"
							onClick={handleSubmit}
							disabled={isLoadingSubmit}
						>
							{isLoadingSubmit ? <CircularProgress size={24} /> : "Inviter"}
						</button>
					</div>

					<div className="flex justify-between w-full items-center mt-4">
						<span>
							Page {currentPage} de {totalPages}
						</span>
						<div>
							<label htmlFor="itemsPerPage" className="mr-2">
								Éléments par page :
							</label>
							<select
								id="itemsPerPage"
								value={itemsPerPage}
								onChange={(e) => {
									setItemsPerPage(Number(e.target.value));
									setPage(1); // Reset to first page when changing items per page
								}}
								className="border border-gray-300 rounded px-2 py-1"
							>
								<option value={5}>5</option>
								<option value={10}>10</option>
								<option value={25}>25</option>
								<option value={50}>50</option>
								<option value={100}>100</option>
								<option value={150}>150</option>
								<option value={200}>200</option>
							</select>
						</div>
					</div>

					{/* Visitors Table with Pagination */}
					<VisitorsTable candidates={filteredData} isLoading={isLoading} />

					{/* Pagination Controls */}

					{/* Pagination Buttons */}
					<div className={"flex justify-between w-full"}>
						<button
							onClick={() => setPage(currentPage - 1)}
							disabled={currentPage === 1}
							className="bg-startgreen_primary-100/60 hover:bg-startgreen_primary-100/40  cursor-pointer text-white font-bold py-2 px-4 rounded"
						>
							Précédent
						</button>

						<div className={"flex gap-2"}>
							{/* Boutons Numérotés pour Chaque Page */}
							{Array.from({ length: totalPages }, (_, index) => (
								<button
									key={index}
									onClick={() => setPage(index + 1)}
									className={`${
										currentPage === index + 1
											? "bg-startgreen_darkblue-100/20 text-white"
											: "bg-startgreen_darkblue-100 text-white"
									} hover:bg-opacity-80 font-bold py-2 px-4 rounded`}
								>
									{index + 1}
								</button>
							))}
						</div>

						<button
							onClick={() => setPage(currentPage + 1)}
							disabled={currentPage === totalPages}
							className="bg-startgreen_primary-100 hover:bg-opacity-80 cursor-pointer text-white font-bold py-2 px-4 rounded"
						>
							Suivant
						</button>
					</div>
				</main>
			</div>
		</div>
	);
};

export default VisitorsManagement;
