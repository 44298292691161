import React, { createContext, useEffect, useState } from "react";
import { appConfig } from "@config/appConfig.ts";
import { api } from "@api/axios/axios.client.ts";
import {
	decryptToken,
	deleteCookie,
	encryptToken,
	getCookie,
	setCookie,
} from "@api/utils/sessionUtils.ts";
import { getPlatformStatus } from "@api/service/startgreen.services.ts";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const [isAuthenticated, setAuthenticated] = useState(false);
	const [isAuthorized, setAuthorized] = useState(false);
	const [loading, setLoading] = useState(true);
	const [role, setRole] = useState(null);

	useEffect(() => {
		const checkAuthentication = async () => {
			try {
				const cookieToken = getCookie(appConfig.authTokenCookieKey);
				if (cookieToken) {
					const decryptedData = decryptToken(cookieToken);
					if (decryptedData) {
						setAuthenticated(true);
						setRole(decryptedData.role);
					} else {
						setAuthenticated(false);
					}
				} else {
					setAuthenticated(false);
				}

				// Check authorization separately
				await checkAuthorization();
			} catch (error) {
				console.error("Error checking authentication:", error);
				setAuthenticated(false);
				setAuthorized(false);
			} finally {
				setLoading(false);
			}
		};

		checkAuthentication();
	}, []);

	const checkAuthorization = async () => {
		try {
			const authCookie = getCookie(appConfig.authorizationCookieKey);
			const decryptedData = decryptToken(authCookie);
			const statusData = await getPlatformStatus();
			const { openStatus, passcode } = statusData;
			if (openStatus || (authCookie && decryptedData?.token === passcode)) {
				setAuthorized(true);
			} else {
				setAuthorized(false);
			}
		} catch (error) {
			console.error("Error checking authorization:", error);
			setAuthorized(false);
		}
	};

	const handleLogin = async (credentials) => {
		try {
			const response = await api.post(
				`${appConfig.startGreenUrl}/auth/login`,
				credentials
			);
			if (response.data.access_token && response.data.role) {
				const encryptedData = encryptToken(
					JSON.stringify({
						token: response.data.token,
						role: response.data.role,
					})
				);
				setCookie(appConfig.authTokenCookieKey, encryptedData, {
					secure: true,
				});
				setAuthenticated(true);
				setRole(response.data.role);
				return true;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	};

	const handleAuthorization = async (password) => {
		try {
			const statusData = await getPlatformStatus();
			const { openStatus, passcode } = statusData;

			if (password === passcode) {
				const encryptedAuth = encryptToken(
					JSON.stringify({
						token: passcode,
					})
				);
				setCookie(appConfig.authorizationCookieKey, encryptedAuth, {
					secure: true,
				});
				setAuthorized(true);
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.error("Error fetching platform status:", error);
			return false;
		}
	};

	const handleLogout = () => {
		deleteCookie(appConfig.authTokenCookieKey);
		setAuthenticated(false);
		setRole(null);
	};

	const handleDeAuthorization = () => {
		deleteCookie(appConfig.authorizationCookieKey);
		setAuthorized(false);
	};

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				isAuthorized,
				isAuthLoading: loading,
				role,
				handleLogin,
				handleAuthorization,
				handleLogout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
