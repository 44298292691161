import type { FunctionComponent, MutableRefObject } from "react";
import { useEffect, useRef, useState } from "react";
import type { ExpertReviewCardProps } from "@components/views/(app)/common/ExpertReviewSection/ExpertReviewSection.types.ts";
import useMediaQuery from "@hooks/useMediaQuery.ts";
import { cn } from "@core/utils";

const ExpertReviewCard: FunctionComponent<ExpertReviewCardProps> = ({
	content,
	className,
	cardColor = "primary-1",
	expertName,
	expertProfession,
}) => {
	const isLargeScreen = useMediaQuery("(min-width: 1024px)");
	const isMobile = useMediaQuery("(max-width: 620px)");

	const contentRef = useRef<HTMLParagraphElement>();
	const [isContentTruncated, setIsContentTruncated] = useState(false);
	// const [modal, toggleModal] = useModali({
	//     closeButton: false,
	//     animated: true,
	// });
	// type ExpertChildItem = {
	// 	id?: number;
	// 	name: string;
	// 	profession: {
	// 		en: string;
	// 		fr: string;
	// 	};
	// 	image?: string;
	// 	teaserURL?: string;
	// 	description?: string;
	// };

	// const item: ExpertChildItem = {
	// 	name: expertName || "",
	// 	profession: { en: expertProfession || "", fr: expertProfession || "" },
	// 	description: content,
	// };
	const lineClamp = isMobile ? 11 : isLargeScreen ? 7 : 8;

	useEffect(() => {
		if (contentRef.current) {
			setIsContentTruncated(
				contentRef.current?.scrollHeight > contentRef.current?.clientHeight
			);
		}
	}, []);

	const selectedCardColor =
		cardColor === "primary-1"
			? "bg-primary-100"
			: cardColor === "primary-2"
				? "bg-primary-200"
				: cardColor === "secondary"
					? "bg-secondary-300"
					: cardColor === "tertiary"
						? "bg-tertiary-400"
						: "bg-primary-100";
	return (
		<div
			// onClick={toggleModal}
			className={cn(
				"shadow-md bg-blue-400 ",
				"min-h-[510px] min-w-64",
				"group relative flex cursor-pointer rounded-lg p-[50px] transition",
				"_tablet:min-h-[500px]",
				selectedCardColor,
				className,
				`hover:bg-opacity-75`
			)}
		>
			<div className="flex flex-col space-y-4 sm:max-w-7xl">
				<svg
					width="41"
					height="26"
					viewBox="0 0 41 26"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M10.7111 23.7001C10.4819 24.5816 9.68618 25.197 8.77537 25.197H2.01732C0.585606 25.197 -0.382396 23.7366 0.175266 22.418L9.13964 1.22099C9.45262 0.480905 10.1781 0 10.9817 0H14.2847C15.5936 0 16.5497 1.23636 16.2204 2.50311L10.7111 23.7001Z"
						fill="#334155"
						fillOpacity="0.3"
					/>
					<path
						d="M30.3292 1.49689C30.5583 0.615379 31.3541 0 32.2649 0H38.9119C40.3436 0 41.3116 1.46038 40.754 2.77902L31.7896 23.976C31.4766 24.7161 30.7511 25.197 29.9476 25.197H26.7555C25.4467 25.197 24.4906 23.9606 24.8198 22.6939L30.3292 1.49689Z"
						fill="#334155"
						fillOpacity="0.3"
					/>
				</svg>
				<div
					className={cn(
						"flex h-full flex-col gap-[12px]",
						"justify-between _laptop_sm:justify-start _laptop_sm:gap-[40px]"
						// isMobile ? 'justify-between' : '',
					)}
				>
					<div className="flex flex-col">
						<p
							ref={contentRef as MutableRefObject<any>}
							className={cn(
								"font titleLarge truncate-text text-left font-normal text-text-700"
							)}
							style={{
								//     display: -webkit-box;
								//   -webkit-line-clamp: 12; /* number of lines to show */
								// -webkit-box-orient: vertical;
								// overflow: hidden;

								WebkitBoxOrient: "vertical",
								WebkitLineClamp: lineClamp,
								overflow: "hidden",
								display: "-webkit-box",
							}}
						>
							{content}
						</p>
						{isContentTruncated && (
							<button
								// onClick={toggleModal}
								className="text-blue-500 mt-[5px] hover:underline"
							>
								<svg
									width="25"
									height="25"
									viewBox="0 0 55 40"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M14.168 8.33342L25.8346 20.0001L14.168 31.6667"
										stroke="#094BA4"
										className="stroke-black-600"
										strokeWidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M29.168 8.33342L40.8346 20.0001L29.168 31.6667"
										stroke="#094BA4"
										className="stroke-black-600"
										strokeWidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</button>
						)}
					</div>

					<div className="text-[14px] text-text-black _tablet:text-[19.5px]">
						<p className={cn("font text-right  font-medium")}>{expertName}</p>
						<p className={cn("font text-right font-medium")}>
							{expertProfession}
						</p>
					</div>
				</div>
			</div>

			{/*<ReviewModal item={item} toggle={toggleModal} modal={modal} />*/}
		</div>
	);
};

export default ExpertReviewCard;
