import { type createRouter, RouterProvider } from "@tanstack/react-router";
import type { FunctionComponent } from "types/types.ts";
import AppProviderWrapper from "@components/layout/common/AppProviderWrapper.tsx";

type AppProps = { router: ReturnType<typeof createRouter> };

const App = ({ router }: AppProps): FunctionComponent => {
	return (
		<AppProviderWrapper>
			<RouterProvider router={router} />
		</AppProviderWrapper>
	);
};

export default App;
