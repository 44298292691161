import React from "react";
import Image from "@components/elements/Image";
import LanguageToggle from "@components/layout/Navbar/components/LanguageToggle.tsx";
import Button from "@components/elements/Buttons/Button.tsx";
import { useTranslation } from "react-i18next";

function Navbar() {
	const { t } = useTranslation("startgreen-page");

	return (
		<div className={"flex items-center flex-row justify-center"}>
			<Image
				className="rounded-lg aspect-auto"
				width={450}
				height={450}
				src={"images/startgreen/iptic-logo.png"}
				alt={"Startgreen"}
				aria-label="StartGreen"
			/>
			{/*<div className={"flex flex-row gap-[20px]"}>*/}
			{/*	<Button*/}
			{/*		variant="startgreen"*/}
			{/*		size={"base"}*/}
			{/*		link={"/startgreen/apply"}*/}
			{/*		className={"px-12"}*/}
			{/*	>*/}
			{/*		Login*/}
			{/*	</Button>*/}

			{/*	<LanguageToggle />*/}
			{/*</div>*/}
		</div>
	);
}

export default Navbar;
