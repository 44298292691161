import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { Studio } from "@pages/(app)/Studio.tsx";
import { useTranslation } from "react-i18next";

const Page = () => {
	const { t: tMeta } = useTranslation("metadata");

	return (
		<>
			<Meta
				title={tMeta("coachini-studio.title")}
				template={"%s | Coachini"}
				description={tMeta("coachini-studio.description")}
				keywords={[
					"Coachini",
					"Studio",
					"Visual CV",
					"Podcast",
					"Coachini Podcast",
					"CV Video",
					"CV Parlant",
					"Video Pitch",
					"Video Resume",
					"Video Portfolio",
					"Video CV",
					"Video Cover Letter",
				]}
				robots="index,follow"
				ogTitle={tMeta("coachini-studio.title")}
				ogDescription={tMeta("coachini-studio.description")}
			/>
			<Studio />
		</>
	);
};

export const Route = createFileRoute("/(main)/studio/")({
	component: Page,
});
