import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { StartgreenLanding } from "@pages/(startgreen)/Landing.tsx";
import Application from "@pages/(startgreen)/views/(form)/Application.tsx";

const getStartGreen = async () => {
	const response = await axios.get("http://localhost:4400/v1");
	return response.data;
};
const Page = () => {
	const { t: tMeta } = useTranslation("metadata");
	// const {
	// 	data: startGreenData,
	// 	isLoading,
	// 	error,
	// } = useQuery({
	// 	queryKey: ["startGreen"],
	// 	queryFn: getStartGreen,
	// });
	//
	// if (isLoading) return <div>Loading...</div>;
	// if (error) return <div>Error: {error.message}</div>;
	return (
		<>
			<Meta
				title={"StartGreen by IPTIC"}
				template={"%s | Coachini"}
				description={tMeta("about-page.description")}
				keywords={["startgreen", "start-green", "STARTGREEN", "#STARTGREEN"]}
				robots="index,follow"
				ogTitle={tMeta("about-page.title")}
				ogDescription={tMeta("about-page.description")}
				className={"bg-[#F3F3F3]"}
			/>

			<Application />
		</>
	);
};

export const Route = createFileRoute("/(main)/startgreen-by-iptic/apply/")({
	component: Page,
});
