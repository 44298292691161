import Marquee from "react-fast-marquee";
import { cn } from "@core/utils";
import Image from "@components/elements/Image";

interface Image {
	name: string;
}

interface MarqueProps {
	images: Image[];
}

export default function RollingMarque({ images }: MarqueProps) {
	return (
		<div className="flex w-full _tablet_sm:hidden">
			<Marquee
				className="marquee flex gap-20 "
				pauseOnHover={false}
				pauseOnClick={false}
				speed={200}
			>
				{images.map((logo, index) => (
					<div
						key={index}
						className={cn(
							"flex items-center justify-center",
							"bg-[#EFEFEF] p-[30px]",
							" mx-[20px] aspect-[16/9] h-[180px] max-w-[230px] rounded-lg"
						)}
					>
						<Image
							loading="lazy"
							className={cn(
								"aspect-square h-full w-full rounded-none object-scale-down"
							)}
							width={200}
							height={200}
							src={`/images/sponsors/${logo.name}.webp`}
							alt="Coachini Associated Sponsors"
							aria-label="Coachini Associated Sponsors"
						/>
					</div>
				))}
			</Marquee>
		</div>
	);
}
