import type React from "react";
import { useTranslation } from "react-i18next";
import type { PackPlanProps } from "@components/views/(app)/solutions/PackSection/PackSection.types.ts";
import type { LanguageFields } from "@components/views/(app)/solutions/ModelSection/ModelSection.types.ts";
import { cn } from "@core/utils";
import i18next from "i18next";

const PackPlan: React.FC<PackPlanProps> = ({
	primaryCardColor = "bg-primary-200",
	secondaryCardColor = "bg-primary-200",
	secondaryPopularCardColor = "bg-primary-200",
	primaryPopularCardColor = "bg-primary-200",
	plan,
	solutionType,
}) => {
	const popularCardStyles: { [key: string]: string } = {
		"bg-primary-200": "hover:bg-opacity-30 hover:bg-primary-200",
		"bg-tertiary-400": "hover:bg-opacity-30 hover:bg-tertiary-400",
		"bg-secondary-300": "hover:bg-opacity-30 hover:bg-secondary-300",
	};
	const cardHoverStyles = popularCardStyles[primaryPopularCardColor];

	const { t } = useTranslation("solutions-page");
	const languageKey = i18next.resolvedLanguage as keyof LanguageFields;
	const currentLanguageData = plan[languageKey];
	return (
		<div className="flex w-full max-w-[420px] flex-col items-center gap-[7px] _tablet:max-w-full _laptop:place-self-start">
			<div
				className={cn(
					`flex h-full w-full transform cursor-pointer flex-col items-center justify-between gap-[40px] px-2  py-[35px] transition-colors  duration-300 hover:bg-opacity-75 ${primaryCardColor} ${plan.isPopular ? primaryPopularCardColor + " hover:bg-opacity-60" : cardHoverStyles}  ${plan.discount ? "rounded-t-lg" : "rounded-lg"} ${plan.isGolden ? "pb-[65px]" : ""}`
				)}
			>
				<div className="">
					<p className="text-center text-titleMedium font-semibold uppercase text-text-black _tablet:text-titleLarge _laptop:text-[19px] _laptop_xl:text-headlineSmall">
						{currentLanguageData?.title}
					</p>
					<div className="flex flex-col items-center px-2">
						<h4 className="mt-2 text-[25px] font-bold leading-[32px] text-text-black _tablet:text-[35px] _tablet:leading-[50px] _laptop:text-[50px] _laptop:leading-[64px]">
							{currentLanguageData?.sessions}
						</h4>
						<p className="labelLarge font-medium uppercase text-text-700">
							{t(`pack-section.${solutionType}.pack-info.theme`, {
								s: Number(currentLanguageData?.sessions) > 1 ? "s" : "",
							})}
						</p>
					</div>
				</div>
			</div>
			{plan.discount && (
				<div className="flex w-full gap-[7px] rounded-b-lg text-text-black">
					<div
						className={`headlineLarge flex place-items-center rounded-bl-lg px-4 py-2 font-bold ${plan.isGolden ? secondaryCardColor : secondaryPopularCardColor} `}
					>
						{plan.discount.toString() + "%"}
					</div>
					<div
						className={`flex w-full items-center rounded-br-lg px-[20px] py-[7px] ${plan.isGolden ? secondaryCardColor : secondaryPopularCardColor} ${plan.isGolden ? "!py-[13px]" : ""}`}
					>
						<p className="titleMedium max-w-[70%] font-medium">
							{t(`pack-section.${solutionType}.pack-info.discount`)}
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default PackPlan;
