import { useState } from "react";

import AccordionItem from "./AccordionItem.tsx";
import { useTranslation } from "react-i18next";
import { accordionData } from "@api/database/constant/about.data.ts";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { cn } from "@core/utils";
import Button from "@components/elements/Buttons/Button.tsx";
import Text from "@components/elements/Text";
import i18next from "i18next";

function AccordionSection({
	isDivider = true,
	dividerColor = "bg-primary-50/50",
}: {
	isDivider?: boolean;
	dividerColor?: string;
}) {
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const { t } = useTranslation("about-page");
	const { t: buttons } = useTranslation("misc");

	const toggleAccordion = (index: number) => {
		setActiveIndex((previousIndex) => (previousIndex === index ? null : index));
	};

	const accordionsToRender = accordionData.map((accordion) => ({
		...accordion,
		title: accordion[i18next.resolvedLanguage as "en" | "fr"].title,
		content: accordion[i18next.resolvedLanguage as "en" | "fr"].content,
	}));

	return (
		<SectionWrapper
			className={cn(
				"mx-auto flex flex-col place-items-center gap-[60px] py-[124px]",
				"margin-divider-small",
				isDivider && dividerColor
			)}
		>
			<Text
				styled
				tag="h2"
				highlightStyle="font-semibold text-secondary-900"
				text={t("faq-section.title")}
			/>
			<div className="w-full max-w-[500px] space-y-[30px] _tablet:max-w-[920px]">
				{accordionsToRender.map((item, index) => (
					<AccordionItem
						key={index}
						item={item}
						index={index}
						activeIndex={activeIndex}
						toggleAccordion={toggleAccordion}
					/>
				))}
			</div>
			<Button link={`/faq`}>{buttons("buttons.see-faq")}</Button>
		</SectionWrapper>
	);
}

export default AccordionSection;
