import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { About } from "@pages/(app)/About.tsx";
import { useTranslation } from "react-i18next";

const Page = () => {
	const { t: tMeta } = useTranslation("metadata");

	return (
		<>
			<Meta
				title={tMeta("about-page.title")}
				template={"%s | Coachini"}
				description={tMeta("about-page.description")}
				keywords={[
					"about us",
					"about coachini",
					"know more",
					"coaching",
					"coachini",
				]}
				robots="index,follow"
				ogTitle={tMeta("about-page.title")}
				ogDescription={tMeta("about-page.description")}
			/>
			<About />
		</>
	);
};

export const Route = createFileRoute("/(main)/about/")({
	component: Page,
});
