import { useTranslation } from "react-i18next";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { cn } from "@core/utils";
import ExpertScroll from "@components/views/(app)/home/ExpertSection/ExpertScroll.tsx";

function ExpertSection() {
	const { t } = useTranslation("home-page");
	return (
		<SectionWrapper
			className={cn("flex flex-col gap-6", "margin-divider-medium")}
		>
			<div className="max-w-[450px] _tablet:max-w-[550px] _tablet:self-end _laptop:max-w-[800px] ">
				<h2 className="displayLarge text-center text-text-black _tablet:text-end">
					{t("expert-section.title.text-1")}{" "}
					<span className="font-medium">
						{t("expert-section.title.text-2")}
					</span>{" "}
					{t("expert-section.title.text-3")}
					<span className="font-semibold text-secondary-900">
						{" "}
						{t("expert-section.title.text-4")}
					</span>
				</h2>
			</div>
			<ExpertScroll />
		</SectionWrapper>
	);
}

export default ExpertSection;
