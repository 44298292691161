import type { Affirmation } from "types/types.ts";

export const pickRandomField = (
	data: Affirmation[] | null
): Affirmation | null => {
	if (!data || data.length === 0) {
		return null;
	}
	const currentDate = new Date();
	const dateString = currentDate.toISOString().slice(0, 10);
	const hash = hashCode(dateString);
	const randomIndex = Math.abs(hash) % data.length;
	if (randomIndex < 0 || randomIndex >= data.length) {
		return null;
	}
	return data[randomIndex];
};

function hashCode(string_: string): number {
	let hash = 0;
	for (let index = 0; index < string_.length; index++) {
		const char = string_.codePointAt(index);
		hash = (hash << 5) - hash + (char ?? 0);
		hash = Math.trunc(hash);
	}
	return hash;
}
