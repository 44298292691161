import { Link } from "@tanstack/react-router";

const TableauCandidats = ({
	refetch,
	candidates,
	extractAnswer,
	toggleCandidateSelection,
	selectedCandidates,
}) => (
	<div className="overflow-hidden rounded-lg drop-shadow-md border border-gray-100/20 bg-gray-50">
		<table className="min-w-full bg-white">
			<thead className="bg-startgreen_primary-100/20">
				<tr>
					<th className="py-3 px-5 border-b">
						<div className="flex items-center justify-center">
							<div className="relative">
								<input
									type="checkbox"
									onChange={(e) => {
										const { checked } = e.target;
										if (checked) {
											// Sélectionner tous les candidats
											candidates.forEach((candidate) =>
												toggleCandidateSelection(candidate.id)
											);
										} else {
											// Désélectionner tous les candidats
											candidates.forEach((candidate) =>
												toggleCandidateSelection(candidate.id)
											);
										}
									}}
									checked={
										selectedCandidates.length === candidates.length &&
										candidates.length > 0
									}
									className="appearance-none h-6 w-6 border-2 border-gray-300 rounded-md bg-white checked:bg-startgreen_primary-100 checked:border-bg-startgreen_primary-100/30 focus:outline-none focus:ring-2 focus:ring-green-400 transition duration-200 ease-in-out cursor-pointer"
								/>
								<svg
									className="w-4 h-4 text-white absolute top-1 left-1 pointer-events-none"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									style={{
										display:
											selectedCandidates.length === candidates.length &&
											candidates.length > 0
												? "block"
												: "none",
									}}
								>
									<path
										fillRule="evenodd"
										d="M16.707 5.293a1 1 0 00-1.414 0L7 13.586 4.707 11.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l9-9a1 1 0 000-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</div>
						</div>
					</th>
					<th className="py-3 px-5 border-b text-left font-medium text-gray-700">
						Nom Complet
					</th>
					<th className="py-3 px-5 border-b text-left font-medium text-gray-700">
						Email
					</th>
					<th className="py-3 px-5 border-b text-left font-medium text-gray-700">
						Région
					</th>
					<th className="py-3 px-5 border-b text-left font-medium text-gray-700">
						Situation Professionnelle
					</th>
				</tr>
			</thead>
			<tbody>
				{candidates.map((candidate, index) => (
					<tr
						key={candidate.id}
						className={
							index % 2 === 0 ? "bg-white" : "bg-startgreen_darkblue-100/10"
						}
					>
						<td className="py-3 px-5 border-b">
							<div className="flex items-center justify-center">
								<div className="relative">
									<input
										type="checkbox"
										checked={selectedCandidates.includes(candidate.id)}
										onChange={() => toggleCandidateSelection(candidate.id)}
										className="appearance-none h-6 w-6 border-2 border-gray-300 rounded-md bg-white checked:bg-startgreen_primary-100 checked:border-bg-startgreen_primary-100/30 focus:outline-none focus:ring-2 focus:ring-green-400 transition duration-200 ease-in-out cursor-pointer"
									/>
									<svg
										className="w-4 h-4 text-white absolute top-1 left-1 pointer-events-none"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										style={{
											display: selectedCandidates.includes(candidate.id)
												? "block"
												: "none",
										}}
									>
										<path
											fillRule="evenodd"
											d="M16.707 5.293a1 1 0 00-1.414 0L7 13.586 4.707 11.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l9-9a1 1 0 000-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
							</div>
						</td>
						<td className="py-3 px-5 border-b">
							<Link
								target="_blank"
								rel="noopener noreferrer"
								to={`/startgreen-by-iptic/dashboard/participants/${candidate.id}`}
								className="text-blue-600 hover:text-blue-800 font-medium"
							>
								{extractAnswer(candidate, "first_name")}{" "}
								{extractAnswer(candidate, "last_name")}
							</Link>
						</td>
						<td className="py-3 px-5 border-b text-gray-700">
							{extractAnswer(candidate, "email")}
						</td>
						<td className="py-3 px-5 border-b text-gray-700">
							{extractAnswer(candidate, "region")}
						</td>
						<td className="py-3 px-5 border-b text-gray-700">
							{extractAnswer(candidate, "professional_situation")}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
);

export default TableauCandidats;
