import React from "react";
import { cn } from "@core/utils";
import type {
	TextPart,
	TextProps,
} from "@components/elements/Text/Text.types.ts";

const parseTitleText = (text: string): { type: string; text: string }[] => {
	const combinedRegex = /<highlight>(.*?)<\/highlight>/gs;
	let match;
	let lastIndex = 0;
	const parts: TextPart[] = [];
	while ((match = combinedRegex.exec(text)) !== null) {
		parts.push({
			type: "text",
			text: text.slice(lastIndex, match.index),
		});
		if (match[1]) {
			parts.push({ type: "highlight", text: match[1] });
		}
		lastIndex = combinedRegex.lastIndex;
	}
	parts.push({ type: "text", text: text.slice(lastIndex) });
	return parts;
};

const renderTextWithLineBreaks = (part: string | TextPart) => {
	const text = typeof part === "string" ? part : part.text;
	const parts = text.split(/\r?\n|\r|\n/g);
	return parts.map((part, index) => (
		<React.Fragment key={index}>
			{index > 0 && <br />}
			{part}
		</React.Fragment>
	));
};

const Text: React.FC<TextProps> = ({
	className,
	highlightStyle,
	tag = "h1",
	text,
	styled = true,
}) => {
	const parsedText = text ? parseTitleText(text) : [];

	const Tag = tag;
	return (
		<Tag
			className={cn(
				"text-center text-base text-text-900",
				//#region  //*=========== Tag Variant ===========
				[
					tag === "p" && [
						styled &&
							cn(
								"text-titleSmall text-text-500 _tablet:text-titleMedium _laptop:text-titleLarge",
								"text-text-500"
							),
					],
					tag === "h1" && [
						styled &&
							cn(
								"text-headlineSmall _tablet:text-displaySmall _laptop:text-displayLarge",
								"text-text-900"
							),
					],
					tag === "h2" && [
						styled &&
							cn(
								"text-headlineSmall _tablet:text-displaySmall _laptop:text-displayLarge",
								"text-text-900"
							),
					],
					// tag === 'h3' ||
					// tag === 'h4' ||
					// tag === 'h5' ||
					// (tag === 'h6' && ['text-text-900', 'displayLarge']),
				],
				//#endregion  //*======== Tag Variant ===========
				className
			)}
		>
			{text &&
				parsedText.map((part, index) => {
					if (part.type === "text") {
						return renderTextWithLineBreaks(part);
					}
					if (part.type === "highlight") {
						return (
							<span key={index} className={cn(highlightStyle)}>
								{renderTextWithLineBreaks(part)}
							</span>
						);
					}
				})}
		</Tag>
	);
};
export default Text;
