import React from "react";
import { cn } from "@core/utils";

interface ProgressBarProps {
	step: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ step }) => {
	return (
		<div className="w-full max-w-[100%] mx-auto">
			<div className="w-full ">
				<div className="flex">
					<div className="w-1/2">
						<div className="relative mb-2">
							<div className="w-10 h-10 mx-auto bg-iptic_primary-100 rounded-full text-lg text-white flex items-center">
								<span className="w-10 h-10 mx-auto  rounded-full text-[20px] text-white flex items-center">
									<span className="text-center w-full">1</span>
								</span>
							</div>
						</div>
					</div>
					{/*-----------------------------*/}
					<div className="w-1/2">
						<div className="relative mb-2">
							<div
								className="absolute flex align-center items-center align-middle content-center"
								style={{
									width: "calc(100% - 2.5rem - 1rem)",
									top: "50%",
									transform: "translate(-50%, -50%)",
								}}
							>
								<div className="w-full bg-white rounded items-center align-middle align-center flex-1">
									<div
										className="w-0 bg-iptic_primary-100 py-1 rounded"
										style={{ width: step === 1 ? "50%" : "100%" }}
									></div>
								</div>
							</div>

							<div
								className={cn(
									"w-10 h-10 mx-auto  rounded-full text-[20px] text-text-black flex items-center",
									step > 1 ? "bg-iptic_primary-100 text-white" : "bg-white"
								)}
							>
								<span className="text-center w-full">2</span>
							</div>
						</div>
					</div>
					{/*	-------------------------------*/}
					{/*<div className="w-1/3">*/}
					{/*	<div className="relative mb-2">*/}
					{/*		<div*/}
					{/*			className="absolute flex align-center items-center align-middle content-center"*/}
					{/*			style={{*/}
					{/*				width: "calc(100% - 2.5rem - 1rem)",*/}
					{/*				top: "50%",*/}
					{/*				transform: "translate(-50%, -50%)",*/}
					{/*			}}*/}
					{/*		>*/}
					{/*			<div className="w-full bg-white rounded items-center align-middle align-center flex-1">*/}
					{/*				<div*/}
					{/*					className="w-0 bg-startgreen_primary-100 py-1 rounded"*/}
					{/*					style={{*/}
					{/*						width: step < 2 ? "0%" : step === 2 ? "50%" : "100%",*/}
					{/*					}}*/}
					{/*				></div>*/}
					{/*			</div>*/}
					{/*		</div>*/}

					{/*		<div*/}
					{/*			className={cn(*/}
					{/*				"w-10 h-10 mx-auto  rounded-full text-[20px] text-text-black flex items-center",*/}
					{/*				step > 2 ? "bg-startgreen_primary-100 text-white" : "bg-white"*/}
					{/*			)}*/}
					{/*		>*/}
					{/*			<span className="text-center w-full">3</span>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}
					{/*-----------------------------*/}
				</div>
			</div>
		</div>
	);
};

export default ProgressBar;
