import { useCountUp } from "react-countup";
import { useTranslation } from "react-i18next";
import type { RSESectionProps } from "@components/views/(app)/home/RSESection/RSESection.types.ts";
import { cn } from "@core/utils";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import Button from "@components/elements/Buttons/Button.tsx";
import Text from "@components/elements/Text";
import i18next from "i18next";
import React from "react";

const RSESection = ({
	isDivider,
	dividerColor = "bg-primary-50/50",
}: RSESectionProps) => {
	const { t } = useTranslation("home-page");
	const { t: tButtons } = useTranslation("misc");
	const countUpRef = React.useRef(null);
	const { start, pauseResume, reset, update } = useCountUp({
		ref: countUpRef,
		start: 0,
		end: Number(66),
		suffix: "%",
		duration: 1.75,
		scrollSpyOnce: true,
		enableScrollSpy: true,
	});
	return (
		<section className={cn(isDivider && dividerColor)}>
			<SectionWrapper
				className={cn(
					"flex flex-col items-center justify-center gap-[80px] py-c",
					"margin-divider-medium",
					"_laptop:flex-row _laptop:gap-[180px]"
				)}
			>
				<div className=" flex max-w-[380px] flex-col gap-[25px]">
					<span className="flex flex-col items-center justify-center">
						<span
							className={cn(
								"racing-sans-one text-[90px] !font-normal text-secondary-900",
								"leading-[0.91] _tablet:text-[150px] _laptop:text-[204px]"
							)}
						>
							<div ref={countUpRef}></div>
						</span>
						<Text
							className={cn(
								"text-center font-bold text-text-700",
								"items-center justify-center",
								"!leading-[0.91] ",
								i18next.resolvedLanguage === "en"
									? "text-[30px] _tablet:!text-[45px] _laptop:!text-[60px]"
									: " text-[20px] _tablet:!text-[35px] _laptop:!text-[44px]"
							)}
							highlightStyle={cn(
								"text-secondary-900 racing-sans-one !font-normal text-[90px]",
								"_tablet:text-[150px] _laptop:text-[204px] leading-[0.91]"
							)}
							text={t("rse-section.argument")}
						/>
					</span>

					<Text
						className={cn(
							"w-full items-center justify-center text-center text-titleMedium font-semibold !text-text-700 ",
							"_tablet_sm:text-titleMedium _tablet:text-titleLarge _tablet:!leading-[30px] _laptop:text-titleLarge "
						)}
						highlightStyle={cn(
							"text-bold text-[30px] _tablet:text-[41px] leading-[45px]"
						)}
						text={t("rse-section.description-1")}
					/>
				</div>
				<div className="flex max-w-[600px] flex-col items-center gap-[20px] _laptop:items-start _laptop:self-end">
					<p
						className={cn(
							"headlineSmall",
							"max-w-[600px] text-center text-text-black _laptop:text-left"
						)}
					>
						{t("rse-section.description-2")}
					</p>
					<Button className="max-w-max" variant="primary" link={`/contact`}>
						{tButtons("buttons.contact")}
					</Button>
				</div>
			</SectionWrapper>
		</section>
	);
};

export default RSESection;
