import type { FunctionComponent } from "types/types.ts";
import { useTranslation } from "react-i18next";
import Sidebar from "@pages/(startgreen)/views/(dashboard)/Sidebar";
import Navbar from "@pages/(startgreen)/views/(dashboard)/Navbar";
import StatsCards from "@pages/(startgreen)/views/(dashboard)/StatsCards";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
	fetchCurrentPhase,
	fetchStatistics,
	getPhases,
} from "@api/service/startgreen.services.ts";
import { useDebounceFn, useDocumentVisibility, useUpdateEffect } from "ahooks";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	ReferenceArea,
} from "recharts";
import CandidateParticipationChart from "@pages/(startgreen)/views/(dashboard)/CandidateParticipationChart.tsx";
import TopRegionChart from "@pages/(startgreen)/views/(dashboard)/TopRegionChart.tsx";
import GenderDistributionChart from "@pages/(startgreen)/views/(dashboard)/GenderDistributionChart.tsx";
import AcceptedUsersChart from "@pages/(startgreen)/views/(dashboard)/AcceptedUsersChart.tsx";
import TooltipCard from "@pages/(startgreen)/views/(dashboard)/TooltipCard.tsx";

export const StartGreenDashboard = (): FunctionComponent => {
	const { t } = useTranslation("coaches-page");
	const queryClient = useQueryClient();

	// Fetching submission statistics
	const {
		data: statistics,
		isLoading: isStatisticsLoading,
		isError: isStatisticsError,
	} = useQuery({
		queryKey: ["submissionStatistics"],
		queryFn: fetchStatistics,
		refetchOnWindowFocus: true,
		refetchInterval: 1000 * 60,
		staleTime: 1000 * 60,
	});

	// Fetching current phase
	const {
		data: allPhases,
		isLoading: isPhaseLoading,
		isError: isPhaseError,
	} = useQuery({
		queryKey: ["allPhases"],
		queryFn: getPhases,
		refetchOnWindowFocus: true,
		staleTime: 1000 * 60,
	});
	// const {
	// 	data: currentPhase,
	// 	isLoading: isPhaseLoading,
	// 	isError: isPhaseError,
	// } = useQuery({
	// 	queryKey: ["currentPhase"],
	// 	queryFn: fetchCurrentPhase,
	// 	refetchOnWindowFocus: true,
	// 	staleTime: 1000 * 60,
	// });

	const navItems = [
		{ label: "Dashboard", path: "/startgreen-by-iptic/dashboard" },
		{
			label: "Participants",
			path: "/startgreen-by-iptic/dashboard/participants",
		},
		{ label: "Phases", path: "/startgreen-by-iptic/dashboard/phases" },
		{ label: "Emails", path: "/startgreen-by-iptic/dashboard/emails" },
		{ label: "Settings", path: "/startgreen-by-iptic/dashboard/settings" },
	];
	const [isOpen, setIsOpen] = useState(false);

	// Use document visibility to determine when to refetch
	const documentVisibility = useDocumentVisibility();

	// Debounce function to invalidate queries when document becomes visible
	const { run: debouncedInvalidateQueries } = useDebounceFn(
		() => {
			queryClient.invalidateQueries(["submissionStatistics"]);
			queryClient.invalidateQueries(["currentPhase"]);
		},
		{ wait: 1000 }
	);

	useUpdateEffect(() => {
		if (documentVisibility === "visible") {
			debouncedInvalidateQueries();
		}
	}, [documentVisibility]);

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	// -----------------------------------

	// Helper function to convert date string to timestamp (for comparison)
	const dateToTimestamp = (date) => new Date(date).getTime();
	const { userSignIns } = statistics || {};

	const tooltipPoints = [
		"Vous pouvez filtrer par date et utiliser la date de la phase comme base pour votre aide.",
		"Vous pouvez ajuster les données affichées en fonction de la période (tout le temps, les 7 derniers jours, les 30 derniers jours, etc.)",
		"Survolez le graphique pour voir le nombre total de participants de ce jour-là, ainsi que quelques détails supplémentaires",
	];

	return (
		<div className="flex bg-red-[#F3F3F3]">
			<Sidebar
				isOpen={isOpen}
				toggleSidebar={toggleSidebar}
				navItems={navItems}
			/>
			<div className="flex-grow">
				<Navbar toggleSidebar={toggleSidebar} />
				<main className="p-5 space-y-6">
					{/* Pass the fetched statistics and current phase to the StatsCards component */}
					{/*<StatsCards*/}
					{/*	statistics={statistics}*/}
					{/*	isLoading={isStatisticsLoading || isPhaseLoading}*/}
					{/*	isError={isStatisticsError || isPhaseError}*/}
					{/*	currentPhase={currentPhase}*/}
					{/*/>*/}
					<GenderDistributionChart
						data={statistics?.totalUsers}
						acceptedCandidates={statistics?.acceptedUsers}
						visitorData={statistics?.visitorCount}
						attendanceCount={statistics?.attendanceCount}
					/>

					<hr className="my-8 border-t-2 border-gray-100/10" />
					<TooltipCard
						title="Graphique de participation des candidats"
						description="Cette section affiche le graphique de participation des candidats sur une période donnée."
						points={tooltipPoints}
					/>
					<CandidateParticipationChart
						userSignIns={statistics?.userSignIns}
						phases={allPhases}
					/>
					<hr className="my-8 border-t-2 border-gray-100/10" />

					<TooltipCard
						title="Top 5 des régions"
						description="Cette section présente les 5 régions ayant le plus grand nombre de participants, ainsi que le nombre de candidats pour chacune."
					/>

					<TopRegionChart data={statistics?.topRegions?.topRegions} />

					<hr className="my-8 border-t-2 border-gray-100/10" />
					<TooltipCard
						title="Graphique des candidats acceptés"
						description="Cette section affiche les détails sur les candidats acceptés (hommes et femmes)."
					/>
					<AcceptedUsersChart data={statistics?.acceptedUsers} />

					{/*<ActionButtons />*/}
				</main>
			</div>
		</div>
	);
};

export default StartGreenDashboard;
