import type { FunctionComponent } from "types/types.ts";
import { useTranslation } from "react-i18next";
import Sidebar from "@pages/(startgreen)/views/(dashboard)/Sidebar";
import Navbar from "@pages/(startgreen)/views/(dashboard)/Navbar";
import { useState } from "react";
import { PhaseList } from "@pages/(startgreen)/views/phases/PhasesList.tsx";
import TooltipCard from "@pages/(startgreen)/views/(dashboard)/TooltipCard.tsx";

export const PhasesScreen = (): FunctionComponent => {
	const { t } = useTranslation("coaches-page");
	const navItems = [
		{ label: "Dashboard", path: "/startgreen-by-iptic/dashboard" },
		{
			label: "Participants",
			path: "/startgreen-by-iptic/dashboard/participants",
		},
		{ label: "Phases", path: "/startgreen-by-iptic/dashboard/phases" },
		{ label: "Emails", path: "/startgreen-by-iptic/dashboard/emails" },
		{ label: "Settings", path: "/startgreen-by-iptic/dashboard/settings" },
	];
	const [isOpen, setIsOpen] = useState(false);

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};
	const tooltipPoints = [
		"Les phases ont des dates, elles peuvent donc être utilisées comme un critère de filtrage dans la liste des candidats.",
		"Veuillez vérifier les modifications avant de les appliquer, car les changements de titres et de descriptions des phases seront visibles publiquement sur la page d'accueil pour tout le monde.",
	];
	return (
		<div className="flex bg-red-[#F3F3F3]">
			<Sidebar
				isOpen={isOpen}
				toggleSidebar={toggleSidebar}
				navItems={navItems}
			/>
			<div className="flex-grow">
				<Navbar title={"Phases"} toggleSidebar={toggleSidebar} />
				<main className="p-5 space-y-6">
					<TooltipCard
						title="Gestion des phases"
						description="Les phases sont les différentes étapes de l'événement et apparaissent sur la page d'accueil afin que les participants puissent les lire et comprendre."
						points={tooltipPoints}
					/>
					{/*<Phases />*/}
					<PhaseList />
					{/*<PlatformStatus />*/}
					{/*<StatsCards />*/}
					{/*<GlobalMessage />*/}
					<hr className="my-8 border-t-2 border-gray-100/10" />
					{/*<ActionButtons />*/}
				</main>
			</div>
		</div>
	);
};

export default PhasesScreen;
