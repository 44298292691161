import type React from "react";
import { cn } from "@core/utils";
import type { GridSectionProps } from "@components/views/(app)/common/ValueSection/ValueSection.types.ts";
import type { GridItemComponent } from "@components/views/(app)/common/ValueSection/ValueSection.types.ts";
import Image from "@components/elements/Image";

const GridItem: React.FC<GridItemComponent> = ({
	className,
	titleStyle,
	descriptionStyle,
	iconStyle,
	iconPath = "",
	hashSign,
	showHashSign,
	item,
}) => {
	return (
		<div
			className={cn(
				"flex min-w-[400px] max-w-[200px] flex-col items-center p-4",
				className
			)}
		>
			{item.icon && iconPath && (
				<Image
					className={cn("pointer-events-none select-none", iconStyle)}
					src={`/svg/${iconPath}/${item.icon}.svg`}
					alt="Coaches Banner"
					width={50}
					height={50}
					loading="lazy"
				/>
			)}

			<h3
				className={cn(
					"my-3 text-center text-titleMedium text-text-black _tablet:text-headlineMedium _laptop:text-_fluid_28_30",
					titleStyle
				)}
			>
				{showHashSign && (
					<span className="font-semibold text-secondary-900">{hashSign}</span>
				)}
				{item.content?.title}
			</h3>
			<p
				className={cn(
					"bodyText max-w-[90%] space-y-1 text-center tracking-tight text-text-600",
					descriptionStyle
				)}
			>
				{item.content?.description}
			</p>
		</div>
	);
};

export const GridSection: React.FC<GridSectionProps> = ({
	className,
	itemStyle = "",
	titleStyle = "font-normal",
	descriptionStyle = "",
	iconStyle = "",
	iconPath = "",
	items,
	hashSign = "#",
	showHashSign = false,
}) => {
	return (
		<section
			className={cn(
				"mx-auto flex flex-wrap  justify-center gap-x-[0px] gap-y-[20px] _tablet:grid-cols-2 _laptop:grid-cols-3 _large:gap-y-[80px] ",
				className
			)}
		>
			{items.map((item, index) => {
				return (
					<GridItem
						key={index}
						item={item}
						titleStyle={titleStyle}
						descriptionStyle={descriptionStyle}
						iconStyle={iconStyle}
						hashSign={hashSign}
						showHashSign={showHashSign}
						className={itemStyle}
						iconPath={iconPath}
					/>
				);
			})}
		</section>
	);
};
