import type React from "react";
import type { FooterSocialsProps } from "@components/layout/Footer/Footer.types.ts";
import { useTranslation } from "react-i18next";
import { Link } from "@tanstack/react-router";
import { FooterSocials } from "@components/layout/Footer/FooterSocials.tsx";
import Image from "@components/elements/Image";

export const FooterLogo: React.FC<FooterSocialsProps> = ({ socials }) => {
	const { t } = useTranslation("layout");
	return (
		<div className="_tablet:gap-mb-[20px]  flex flex-col items-center gap-[20px] _laptop:items-start">
			<div className="flex flex-col gap-[20px] _laptop:gap-[10px]">
				<div className="text-teal-600 flex justify-center _laptop:justify-start">
					<Link href="/">
						<Image
							className="h-full w-28"
							width={112}
							height={112}
							src="/images/misc/logo/logo-coachini-big.png"
							alt="Coachini Logo"
							aria-label="Coachini logo"
						/>
					</Link>
					{/*<Logo />*/}
				</div>

				<p className="bodyMedium  max-w-[270px] text-center leading-relaxed text-text-600 _tablet:max-w-[270px] _laptop:text-left">
					{t("footer.slogan")}
				</p>
			</div>

			<FooterSocials socials={socials} />
		</div>
	);
};
