import { createFileRoute, Navigate, redirect } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { useLocation } from "@tanstack/react-router";

export const Route = createFileRoute("/(main)/startgreen/$")({
	component: () => {
		const location = useLocation();
		const newPath = location.pathname.replace(
			"/startgreen",
			"/startgreen-by-iptic"
		);
		return <Navigate to={newPath} />;
	},
	// redirect: {
	// 	to: "/startgreen-by-iptic",
	// },
	// loader: () => {
	// 	redirect({
	// 		to: "/startgreen-by-iptic",
	// 		throw: true,
	// 	});
	// },
});
