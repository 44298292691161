import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Button from "@components/elements/Buttons/Button.tsx";
import Logo from "@components/layout/Navbar/components/Logo.tsx";
import { Link, useLocation } from "@tanstack/react-router";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { cn } from "@core/utils";
import LanguageToggle from "@components/layout/Navbar/components/LanguageToggle.tsx";
import { navBarItems } from "@config/routes.ts";
import MenuItems from "@components/layout/Navbar/components/MenuItems.tsx";
import MenuButton from "@components/layout/Navbar/components/MenuButton.tsx";
import SideMenu from "@components/layout/Navbar/components/SideMenu.tsx";

export const Navbar = () => {
	const { t: tButtons } = useTranslation("misc");

	const [activeSubmenu, setActiveSubmenu] = useState<number | null>(null);
	const [openSubmenuIndex, setOpenSubmenuIndex] = useState<number | null>(null);
	const [activeChildLink, setActiveChildLink] = useState<string | null>(null);
	const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

	const toggleSideMenu = () => {
		setIsSideMenuOpen(!isSideMenuOpen);
	};

	const toggleSubmenu = (index: number) => {
		if (openSubmenuIndex === index) {
			setOpenSubmenuIndex(null);
		} else {
			setOpenSubmenuIndex(index);
		}
	};

	const handleSubmenuLinkClick = () => {
		setOpenSubmenuIndex(null);
	};
	const location = useLocation().pathname;
	const locationName = location.replace(
		location.slice(0, Math.max(0, location.indexOf("/"))),
		""
	);
	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			const isClickInsideSubmenu =
				(event.target as HTMLElement).closest(".submenu") !== null;

			const isClickInsideToggle = (event.target as HTMLElement).closest(
				".menutoggle"
			);
			const isClickInsideSideMenu = (event.target as HTMLElement).closest(
				".sidemenu"
			);
			const isClickInsideMenuLink =
				(event.target as HTMLElement).closest(".menu-link") !== null;
			if (
				!isClickInsideSubmenu &&
				!isClickInsideMenuLink &&
				!isClickInsideToggle &&
				!isClickInsideSideMenu
			) {
				setOpenSubmenuIndex(null);
				setIsSideMenuOpen(false);
			}
		};
		document.body.addEventListener("click", handleOutsideClick);
		return () => {
			document.body.removeEventListener("click", handleOutsideClick);
		};
	}, []);

	useEffect(() => {
		let submenuIndex: null | number = null;
		navBarItems.forEach((item, index) => {
			if (item.link === locationName) {
				submenuIndex = index;
				setActiveChildLink(null);
				//@ts-ignore
			} else if (item?.child) {
				//@ts-ignore
				const ciIndex = item?.child.findIndex((ci) =>
					locationName.includes(ci.childlink)
				);
				if (ciIndex !== -1) {
					submenuIndex = index;
					setActiveChildLink(locationName);
				}
			}
		});
		setActiveSubmenu(submenuIndex);
		setOpenSubmenuIndex(null);
	}, [locationName]);

	return (
		<>
			<div aria-hidden="true" className="h-[64px] w-full bg-BGPage" />
			<nav
				aria-label="Main Navigation"
				className="fixed top-0 z-50 flex w-full bg-BGWhite shadow-small "
			>
				<SectionWrapper
					className={cn(
						"flex flex-1 items-center justify-end gap-[15px]",
						"pl-[23px] pr-[23px]",
						"_phone:pl-[23px] _phone:pr-[23px]",
						"_tablet:pl-[75px] _tablet:pr-[75px]",
						"_laptop_md:pl-[50px] _laptop_md:pr-[50px]",
						"_laptop:justify-between _laptop:pl-[70px] _laptop:pr-[70px] ",
						"_laptop_lg:pl-[130px] _laptop_lg:pr-[130px]",
						"_large:pl-[180px] _large:pr-[180px]"
					)}
				>
					<MenuButton
						isSideMenuOpen={isSideMenuOpen}
						toggleSideMenu={toggleSideMenu}
					/>
					<div className="flex h-16 w-full items-center justify-between gap-8">
						<Link to={`/`}>
							<Logo />
						</Link>
						<div className="flex flex-1 items-center justify-end _laptop_md:justify-between">
							<nav aria-label="Global" className="hidden _laptop_md:block">
								<MenuItems
									menus={navBarItems}
									activeSubmenu={activeSubmenu}
									locationName={locationName}
									toggleSubmenu={toggleSubmenu}
									openSubmenuIndex={openSubmenuIndex}
									handleSubmenuLinkClick={handleSubmenuLinkClick}
									activeChildLink={activeChildLink}
								/>
							</nav>

							<div className="flex items-center gap-4">
								<div className="flex gap-4">
									<Button
										variant="primary"
										link={`/contact`}
										className="hidden _phone_sm:block"
									>
										{tButtons("buttons.contact")}
									</Button>
								</div>
								<SideMenu
									menus={navBarItems}
									activeSubmenu={activeSubmenu}
									locationName={locationName}
									toggleSideMenu={toggleSideMenu}
									handleSubmenuLinkClick={handleSubmenuLinkClick}
									activeChildLink={activeChildLink}
									isOpen={isSideMenuOpen}
								/>
							</div>
						</div>
					</div>
					<LanguageToggle />
				</SectionWrapper>
			</nav>
		</>
	);
};
