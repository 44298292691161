import type { KeenSliderPlugin } from "keen-slider/react";

export const WheelControls: KeenSliderPlugin = (slider) => {
	let touchTimeout: ReturnType<typeof setTimeout>;
	let position: {
		x: number;
		y: number;
	};
	let wheelActive: boolean;

	function dispatch(event_: WheelEvent, name: string) {
		position.x -= event_.deltaX;
		position.y -= event_.deltaY;
		slider.container.dispatchEvent(
			new CustomEvent(name, {
				detail: {
					x: position.x,
					y: position.y,
				},
			})
		);
	}

	function wheelStart(event_: WheelEvent) {
		position = {
			x: event_.pageX,
			y: event_.pageY,
		};
		dispatch(event_, "ksDragStart");
	}

	function wheel(event_: WheelEvent) {
		dispatch(event_, "ksDrag");
	}

	function wheelEnd(event_: WheelEvent) {
		dispatch(event_, "ksDragEnd");
	}

	function eventWheel(event_: WheelEvent) {
		const isHorizontalScroll =
			Math.abs(event_.deltaX) > Math.abs(event_.deltaY);

		if (isHorizontalScroll) {
			event_.preventDefault();
		}

		if (!wheelActive) {
			wheelStart(event_);
			wheelActive = true;
		}

		wheel(event_);
		clearTimeout(touchTimeout);
		touchTimeout = setTimeout(() => {
			wheelActive = false;
			wheelEnd(event_);
		}, 50);
	}

	slider.on("created", () => {
		slider.container.addEventListener("wheel", eventWheel, {
			passive: false,
		});
	});
	// slider.on('dragStarted', () => {
	//   slider.options.rubberband = true;
	// });
};
