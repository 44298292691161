import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { SolutionsEnterprise } from "@pages/(app)/solutions/SolutionsEnterprise.tsx";
import { useTranslation } from "react-i18next";

const Page = () => {
	const { t: tMeta } = useTranslation("metadata");

	return (
		<>
			<Meta
				title={tMeta("solutions-page.enterprise.title")}
				template={"%s | Coachini"}
				description={tMeta("solutions-page.enterprise.description")}
				keywords={[
					"enterprise",
					"solutions",
					"business",
					"business themes",
					"company coaching",
					"business coaching",
				]}
				robots="index,follow"
				ogTitle={tMeta("solutions-page.enterprise.title")}
				ogDescription={tMeta("solutions-page.enterprise.description")}
			/>
			<SolutionsEnterprise />
		</>
	);
};
export const Route = createFileRoute("/(main)/solutions/enterprise/")({
	component: Page,
});
