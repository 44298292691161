import type { FunctionComponent } from "types/types.ts";
import NavigationWrapper from "@components/layout/common/NavigationWrapper.tsx";
import Text from "@components/elements/Text";
import {
	CoachiniSolutionsFAQ,
	CoachiniStudioFAQ,
} from "@api/database/constant/faq.data.ts";
import { useTranslation } from "react-i18next";
import { FAQTabsSection } from "@components/views/(app)/faq/FaqTabSection";
import NewsLetterSection from "@components/views/(app)/common/NewsletterSection";
import { cn } from "@core/utils";

export const FAQ = (): FunctionComponent => {
	const { t } = useTranslation("faq-page");

	const dataModel = [
		{
			id: 0,
			title: "faq-section.tabs.general.title",
			questions: CoachiniSolutionsFAQ,
		},
		{
			id: 1,
			title: "faq-section.tabs.studio.title",
			questions: CoachiniStudioFAQ,
		},
	];
	return (
		<NavigationWrapper>
			<main>
				<section
					className={cn(
						"flex flex-col items-center justify-center gap-[30px] px-[23px]",
						"h-[_calc(70vh_-_70px)] _tablet_sm:h-[_calc(45vh_-_70px)] _laptop:h-[_calc(60vh_-_70px)]",
						"bg-primary-50/50",
						" _tablet:px-[75px] _laptop:px-[130px] _large:px-[180px]"
					)}
				>
					<Text
						className="displayLarge"
						highlightStyle="font-semibold text-secondary-900"
						text={t("hero-section.title")}
					/>
				</section>
				<FAQTabsSection dataModel={dataModel} />

				<NewsLetterSection />
			</main>
		</NavigationWrapper>
	);
};
