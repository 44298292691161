// src/validationSchemas/step1ValidationSchema.ts
import * as Yup from "yup";

export const step1ValidationSchema = Yup.object({
	// -------- //
	first_name: Yup.string().required("Le nom est requis"), //added
	last_name: Yup.string().required("La prénom est requis"), //added
	// full_name: Yup.string().required("Le nom complet est requis"), DEL
	email: Yup.string().email("Email invalide").required("L'email est requis"),
	phone_number: Yup.string().required("Le numéro de téléphone est requis"),
	region: Yup.string().required("La région est requise"),
	age: Yup.number()
		.min(18, "Vous devez avoir au moins 18 ans")
		.required("L'âge est requis"),
	// -------- //
	gender: Yup.string().required("Le genre est requis"),
	// -------- //
	professional_situation: Yup.string().required(
		"La situation professionnelle est requise"
	),
	// -------- //
	education_level: Yup.string().required("Le niveau d'éducation est requis"),
	education_level_other: Yup.string().when(
		"education_level",
		([education_level], schema) => {
			if (education_level === "education_level_other")
				return Yup.string().required(
					"Veuillez préciser votre niveau d'éducation"
				);
			return schema;
		}
	),

	// ------------------------------------ //
	development_stage: Yup.string().required(
		"Au moins une étape de développement est requise"
	),

	project_activity: Yup.array().when(
		["development_stage"],
		([development_stage], schema) => {
			if (development_stage === "project_idea_not_started")
				return Yup.array().min(1, "Au moins une idée de projet est requise");
			if (
				development_stage === "no_startup_act_project" ||
				development_stage === "yes_startup_act_project"
			)
				return Yup.array().min(
					1,
					"Au moins une activité de projet est requise"
				);
			return schema;
		}
	),

	project_activity_other: Yup.string().when(
		["project_activity", "development_stage"],
		([project_activity, development_stage], schema) => {
			if (
				(development_stage === "no_startup_act_project" ||
					development_stage === "yes_startup_act_project") &&
				project_activity.includes("project_activity_other")
			)
				return Yup.string().required(
					"Veuillez préciser un autre activité de projet"
				);
			else {
				if (project_activity.includes("project_activity_other")) {
					return Yup.string().required(
						"Veuillez préciser un autre idée de projet"
					);
				}
			}
			return schema;
		}
	),

	// project_activity: Yup.array().min(
	// 	1,
	// 	"Au moins une activité de projet est requise"
	// ),

	employees_number: Yup.number().when(
		"development_stage",
		([development_stage], schema) => {
			if (
				development_stage === "no_startup_act_project" ||
				development_stage === "yes_startup_act_project"
			)
				return (
					Yup.number()
						.min(0, "Le nombre d'employés ne peut pas être négatif")
						// .positive("Le nombre d'employés doit être supérieur à 0")
						.required("Le nombre d'employés est requis")
				);
			return schema;
		}
	),

	sales_revenue: Yup.number().when(
		"development_stage",
		([development_stage], schema) => {
			if (
				development_stage === "no_startup_act_project" ||
				development_stage === "yes_startup_act_project"
			)
				return (
					Yup.number()
						.min(0, "Le chiffre d'affaire ne peut pas être négatif")
						// .positive("Le chiffre d'affaire doit être supérieur à 0")
						.required("Le chiffre d'affaire est requis")
				);
			return schema;
		}
	),

	// employees_number: Yup.number()
	// 	.min(0, "Le nombre d'employés ne peut pas être négatif")
	// 	// .positive("Le nombre d'employés doit être supérieur à 0")
	// 	.required("Le nombre d'employés est requis"),

	// sales_revenue: Yup.number()
	// 	.min(0, "Le chiffre d'affaire ne peut pas être négatif")
	// 	// .positive("Le chiffre d'affaire doit être supérieur à 0")
	// 	.required("Le chiffre d'affaire est requis"),

	// ------------------------------------ //

	support_needs: Yup.string().when(
		"development_stage",
		([development_stage], schema) => {
			if (
				development_stage === "no_startup_act_project" ||
				development_stage === "yes_startup_act_project"
			)
				return Yup.string().required(
					"Au moins un besoin de soutien est requis"
				);
			return schema;
		}
	),

	// support_needs: Yup.string().required(
	// 	"Au moins un besoin de soutien est requis"
	// ),
	support_needs_other: Yup.string().when(
		"support_needs",
		([support_needs], schema) => {
			if (support_needs === "support_needs_other")
				return Yup.string().required(
					"Veuillez préciser votre besoin de soutien"
				);
			return schema;
		}
	),
	// ------------------------------------ //
	support_themes: Yup.array().min(
		1,
		"Veuillez sélectionner au moins un thème de soutien"
	),
	support_themes_other: Yup.string().when(
		"support_themes",
		([support_themes], schema) => {
			if (support_themes.includes("support_themes_other"))
				return Yup.string().required(
					"Veuillez préciser un autre thème de soutien"
				);
			return schema;
		}
	),
	// ------------------------------------ //
	hackathon_participation: Yup.string().required(
		"La participation au hackathon est requise"
	),
	previous_support: Yup.string().required(
		"Veuillez préciser si vous avez déjà reçu un soutien"
	),
	previous_support_details: Yup.string().when(
		"previous_support",
		([previous_support], schema) => {
			if (previous_support === "yes_received_support")
				return Yup.string().required(
					"Veuillez préciser les détails du soutien reçu"
				);
			return schema;
		}
	),
	// ------------------------------------ //
	discovery_source: Yup.array().min(
		1,
		"Au moins une source de découverte est requise"
	),
	discovery_source_other: Yup.string().when(
		"discovery_source",
		([discovery_source], schema) => {
			if (discovery_source.includes("discovery_source_other"))
				return Yup.string().required(
					"Veuillez préciser la source de découverte"
				);
			return schema;
		}
	),

	// Optional fields
});

// ------------------------------- //

export const step2ValidationSchema = Yup.object({
	fullName: Yup.string().required("Le nom complet est requis"),
	email: Yup.string().email("Email invalide").required("L'email est requis"),
	phoneNumber: Yup.string().required("Le numéro de téléphone est requis"),
	region: Yup.string().required("La région est requise"),
	age: Yup.number()
		.min(18, "Vous devez avoir au moins 18 ans")
		.required("L'âge est requis"),
	gender: Yup.string().required("Le genre est requis"),
	professional_situation: Yup.string().required(
		"La situation professionnelle est requise"
	),
	education_level: Yup.string().required("Le niveau d'éducation est requis"),
});
