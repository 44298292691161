import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import type React from "react";
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import Text from "@components/elements/Text";
import { cn } from "@core/utils";
import type {
	Cluster,
	GridItemProps,
	SectionExtended,
	ThemeSectionProps,
} from "@components/views/(app)/solutions/ThemeSection/ThemeSection.types.ts";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@hooks/useMediaQuery.ts";
import useScrollTrigger from "@components/views/(app)/common/StatisticSection/useScrollTrigger.ts";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import Button from "@components/elements/Buttons/Button.tsx";
import Image from "@components/elements/Image";
import i18next from "i18next";

const ThemeSection: React.FC<ThemeSectionProps> = ({
	solutionType = "enterprise",
	data,
}) => {
	let titleColor;

	switch (solutionType) {
		case "enterprise": {
			titleColor = "text-primary-400";
			break;
		}
		case "startup": {
			titleColor = "text-primary-400";
			break;
		}
		case "employability": {
			titleColor = "text-tertiary-900";
			break;
		}
		case "researcher": {
			titleColor = "text-secondary-900";
			break;
		}
	}

	const { t } = useTranslation("solutions-page");
	const { t: buttons } = useTranslation("misc");
	const instanceRef = useRef<HTMLDivElement | null>(null);
	const animationRef = useRef(null);

	// @ts-ignore
	const { events } = useDraggable(instanceRef, {
		applyRubberBandEffect: true,
		safeDisplacement: true,
	});
	// instanceRef?.current?.scrollTo({
	//   left: 10000,
	//   behavior: 'smooth',
	// });
	const isMobile = useMediaQuery("(max-width: 560px)");

	gsap.registerPlugin(ScrollToPlugin);
	const scrollToPosition = (percentage: number) => {
		if (instanceRef.current) {
			const container = instanceRef.current;
			const targetPosition =
				(container?.scrollWidth - container?.offsetWidth) * (percentage / 100);
			// @ts-ignore
			animationRef.current = gsap.to(instanceRef.current, {
				duration: isMobile ? 30 : 15,
				scrollTo: { x: targetPosition, autoKill: true },
				ease: "none",
			});
		}
	};

	const handleMouseEnter = () => {
		if (animationRef.current) {
			// @ts-ignore
			animationRef.current.pause();
		}
	};

	const handleMouseLeave = () => {
		if (animationRef.current) {
			// @ts-ignore
			animationRef.current.resume();
		}
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { scrollData, triggerPosition } = useScrollTrigger(instanceRef, {
		offset: 100,
		onStart: () => {
			scrollToPosition(100);
		},
		once: true,
	});

	return (
		<>
			<SectionWrapper
				className={cn(
					"relative flex flex-col overflow-clip",
					"_phone:pr-[0] _tablet:pr-[0] _laptop:pr-[0px] _large:pr-[0]",
					"margin-divider-xsm"
				)}
			>
				<div className="mb-[40px] flex w-full flex-col items-center justify-between gap-[20px] _phone:pr-[23px] _tablet:flex-row  _tablet:pr-[75px] _laptop:gap-0 _laptop:pr-[130px] _large:pr-[180px]">
					<Text
						tag="h2"
						text={t(`theme-section.${solutionType}.title`)}
						className={cn(
							"displayLarge text-center _tablet:text-start",
							"_phone_lg:max-w-[400px] _tablet:max-w-[350px] _laptop_sm:max-w-[450px] _laptop:max-w-[650px]"
						)}
						highlightStyle={cn("font-semibold text-primary-900", titleColor)}
					/>
					<div className="flex flex-col items-center gap-[15px] _tablet:items-end">
						<p className="bodyText max-w-[230px] text-center text-text-700 _tablet:text-end _laptop:max-w-[280px]">
							{t("theme-section.subSection.paragraph")}
						</p>
						<Button variant="primary" link={`/contact`}>
							{buttons("buttons.contact")}
						</Button>
					</div>
				</div>
				<div
					className={cn(
						"scrollbar-hide scroll flex w-full gap-[30px] overflow-x-scroll",
						" _phone:pr-[23px] _tablet:pr-[75px] _laptop:pr-[130px] _large:pr-[180px]"
					)}
					{...events}
					ref={instanceRef}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					{data.map((cluster) => (
						<div
							key={cluster.id}
							className="flex flex-none select-none items-center justify-center"
						>
							<GridLayout cluster={cluster} />
						</div>
					))}
				</div>
			</SectionWrapper>
		</>
	);
};

export default ThemeSection;

const GridLayout: React.FC<{
	cluster: Cluster;
	className?: string;
}> = ({ cluster, className }) => {
	const isMobile = useMediaQuery("(max-width: 560px)");
	const gridTemplateRows = `repeat(${isMobile ? 2 : cluster.rows}, minmax(0, 250px))`;
	const gridTemplateColumns = `repeat(${isMobile && cluster.items.length > 2 ? cluster.cols + 1 : cluster.cols}, minmax(0, 300px))`;
	// const { t, i18n } = useTranslation('solutions');
	const cardsToRender = cluster.items.map((card) => {
		const cardToRender = card as SectionExtended;
		return {
			...card,
			title: cardToRender[i18next.resolvedLanguage as "en" | "fr"]?.title,
			content: cardToRender[i18next.resolvedLanguage as "en" | "fr"]?.content,
		};
	});
	return (
		<div
			className={cn(
				"grid h-full w-full grid-flow-col gap-[30px] _tablet_sm:grid-flow-row",
				className
			)}
			style={{
				gridTemplateRows: gridTemplateRows,
				gridTemplateColumns: gridTemplateColumns,
				// gridAutoFlow: "row dense", // Adjust grid auto flow
			}}
		>
			{cardsToRender.map((item, index) => (
				<GridItem
					key={index}
					title={item.title}
					paragraphText={item.content}
					picture={item.picture}
					className={item.className}
					spanX={isMobile ? item.spanX.mobile : item.spanX.laptop}
					spanY={isMobile ? item.spanY.mobile : item.spanY.laptop}
				/>
			))}
		</div>
	);
};

const GridItem: React.FC<GridItemProps & { spanX: number; spanY: number }> = ({
	title,
	paragraphText,
	picture,
	className,
	spanX,
	spanY,
}) => {
	return (
		<div
			className={cn(
				picture ? "p-[0px] " : "p-[12px] ",
				"relative   flex-none rounded-md",
				className
			)}
			style={{
				gridColumn: `span ${spanX}`,
				gridRow: `span ${spanY}`,
			}}
		>
			{picture && (
				<Image
					className={cn(
						"pointer-events-none absolute h-full w-full select-none object-cover",
						className
					)}
					src={`/images/themes/${picture.subPath}/${picture.src}.webp`}
					alt={String(picture.alt)}
					width={1080}
					height={720}
				/>
			)}
			{title && (
				<div
					className={cn(
						"mb-[20px] px-[15px] py-[15px] _tablet:px-[15px] sm:max-w-7xl _tablet_lg:mb-[10px]"
					)}
				>
					<h3
						className={cn(
							"text-start text-titleMedium font-medium text-text-black ",
							"_tablet:text-titleMedium _laptop:text-titleLarge"
						)}
					>
						{title}
					</h3>
					<p
						className={cn(
							"mt-[12px] text-start text-bodySmall text-text-700 _tablet:text-bodyMedium _laptop:text-bodyLarge"
						)}
					>
						{paragraphText}
					</p>
				</div>
			)}
		</div>
	);
};
