import React, { useEffect, useState } from "react";
import {
	Brush,
	CartesianGrid,
	Legend,
	Line,
	LineChart,
	ReferenceArea,
	ReferenceLine,
	ResponsiveContainer,
	Text,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

const formatDate = (dateString) => {
	const date = new Date(dateString);
	return date.toLocaleDateString();
};

const dateToTimestamp = (date) => new Date(date).getTime();

// Custom tooltip to display phase information
const CustomTooltip = ({ phases, active, payload, label }) => {
	if (active && payload && payload.length > 0) {
		const signInData = payload[0].payload;
		const phaseData = phases?.find(
			(phase) =>
				dateToTimestamp(phase.startDate) <= dateToTimestamp(signInData.date) &&
				dateToTimestamp(phase.endDate) >= dateToTimestamp(signInData.date)
		);

		return (
			<div className="custom-tooltip bg-white p-4 rounded-md">
				<p>{`Date: ${label}`}</p>
				<p>{`Inscriptions: ${signInData.signIns}`}</p>
				{phaseData && <p>{`Phase: ${phaseData.name}`}</p>}
			</div>
		);
	}

	return null;
};

const CandidateParticipationChart = ({ userSignIns, phases }) => {
	const [dateRange, setDateRange] = useState(userSignIns);
	const [selectedRange, setSelectedRange] = useState("Tous les temps");

	useEffect(() => {
		filterData("Tous les temps");
	}, [userSignIns]);

	const filterData = (range) => {
		const now = new Date();
		let startDate;

		switch (range) {
			case "Le mois dernier": {
				startDate = new Date(now.setMonth(now.getMonth() - 1));
				break;
			}
			case "La semaine dernière": {
				startDate = new Date(now.setDate(now.getDate() - 7));
				break;
			}
			case "Les 3 derniers jours": {
				startDate = new Date(now.setDate(now.getDate() - 3));
				break;
			}
			case "Aujourd'hui": {
				startDate = new Date(now.setDate(now.getDate() - 1));
				break;
			}
			default: {
				startDate = new Date("2024-01-01");
			}
		}

		const filteredData =
			range === "Aujourd'hui"
				? userSignIns?.filter((data) => {
						const dataDate = new Date(data.date);
						return dataDate.toDateString() === new Date().toDateString();
					})
				: userSignIns?.filter((data) => new Date(data.date) >= startDate);

		setSelectedRange(range);
		setDateRange(filteredData);
	};

	return (
		<div
			className={"w-full bg-startgreen_primary-100/10 p-2 space-y-2 rounded"}
		>
			{/* Date range filter controls */}
			<div>
				<select
					onChange={(e) => {
						filterData(e.target.value);
					}}
					value={selectedRange}
				>
					<option value="Tous les temps">Tous les temps</option>
					<option value="Le mois dernier">Le mois dernier</option>
					<option value="La semaine dernière">La semaine dernière</option>
					<option value="Les 3 derniers jours">Les 3 derniers jours</option>
					<option value="Aujourd'hui">Aujourd'hui</option>
				</select>
			</div>

			<ResponsiveContainer width="100%" height={400}>
				<LineChart data={dateRange}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="date"
						label={{ value: "Date", position: "insideBottom", offset: 5 }}
					/>
					<YAxis
						label={{
							value: "Inscriptions",
							angle: -90,
							position: "insideLeft",
						}}
						allowDecimals={false}
					/>
					<Tooltip
						content={<CustomTooltip phases={phases} />}
						formatter={(value) => [value, "Connexions"]}
						labelFormatter={(label) => `Date : ${label}`}
					/>
					<Legend formatter={() => "Charte d'inscriptions"} />

					{/* Draw the phases as highlighted areas with distinct colors */}
					{/*{phases?.map((phase, index) => (*/}
					{/*	<ReferenceArea*/}
					{/*		key={index}*/}
					{/*		x1={dateToTimestamp(phase.startDate)}*/}
					{/*		x2={dateToTimestamp(phase.endDate)}*/}
					{/*		fill={phase.color}*/}
					{/*		fillOpacity={0.2}*/}
					{/*		stroke="none"*/}
					{/*	>*/}
					{/*		<Text*/}
					{/*			x={*/}
					{/*				(dateToTimestamp(phase.startDate) +*/}
					{/*					dateToTimestamp(phase.endDate)) /*/}
					{/*				2*/}
					{/*			}*/}
					{/*			y={100}*/}
					{/*			textAnchor="middle"*/}
					{/*			fill="#fff"*/}
					{/*			fontSize={14}*/}
					{/*		>*/}
					{/*			{phase.name}*/}
					{/*		</Text>*/}
					{/*	</ReferenceArea>*/}
					{/*))}*/}

					{/* Line for user sign-ins */}
					<Line type="monotone" dataKey="signIns" stroke="#8884d8" />

					{/* Draw lines to mark phase boundaries */}
					{phases?.map((phase, index) => (
						<ReferenceLine
							key={index}
							x={dateToTimestamp(phase.startDate)}
							stroke="red"
							strokeDasharray="3 3"
							label={{ value: phase.name, position: "top", fill: "red" }}
						/>
					))}

					{/* Brush for scrolling */}
					<Brush
						dataKey="date"
						height={30}
						stroke="#8884d8"
						travellerWidth={10}
						formatter={(date) => formatDate(date)}
					/>
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
};

export default CandidateParticipationChart;
