// EmailTypeSelection.jsx
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { cn } from "@core/utils";
import { Tooltip } from "@pages/(startgreen)/views/(dashboard)/Tooltip.tsx";

export const EmailTypeSelection = ({ emailType, onChange }) => {
	return (
		<div className="flex items-center space-x-4">
			<label className="flex items-center space-x-2">
				<input
					type="radio"
					name="emailType"
					value="template"
					checked={emailType === "template"}
					onChange={() => onChange("template")}
					className="form-radio h-5 w-5 text-blue-600"
				/>
				<span>Utiliser un modèle</span>
				<Tooltip
					title="Utiliser un modèle"
					description="Sélectionnez cette option pour utiliser un modèle prédéfini."
				/>
			</label>
			<label className="flex items-center space-x-2">
				<input
					type="radio"
					name="emailType"
					value="custom"
					checked={emailType === "custom"}
					onChange={() => onChange("custom")}
					className="form-radio h-5 w-5 text-blue-600"
				/>
				<span>Écrire votre propre email</span>
				<Tooltip
					title="Écrire votre propre email"
					description="Sélectionnez cette option pour rédiger un email personnalisé."
				/>
			</label>
		</div>
	);
};

export const TemplateSelection = ({
	selectedTemplateId,
	templates,
	onChange,
	dynamicFields,
	onDynamicFieldChange,
}) => {
	return (
		<div>
			<div className="flex items-center space-x-2">
				<select
					value={selectedTemplateId}
					onChange={(e) => onChange(e.target.value)}
					className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
				>
					<option value="">Sélectionnez le modèle</option>
					{templates.map((template) => (
						<option key={template.id} value={template.id}>
							{template.name}
						</option>
					))}
				</select>
				<Tooltip
					title="Sélection de modèle"
					description="Choisissez un modèle d'email prédéfini pour faciliter la rédaction."
				/>
			</div>

			{/* Dynamic Fields */}
			{dynamicFields.length > 0 && (
				<div className="dynamic-fields space-y-4 mt-4">
					<h3 className="text-xl font-semibold">Champs dynamiques</h3>
					{dynamicFields.map((field, index) => (
						<div key={index} className="flex flex-col space-y-2">
							<label className="flex items-center space-x-2">
								{field.dynamicField}
								<Tooltip
									title={field.dynamicField}
									description={`Entrez la valeur pour ${field.dynamicField}.`}
								/>
							</label>
							<input
								type="text"
								value={field.value}
								onChange={(e) => onDynamicFieldChange(index, e.target.value)}
								placeholder={`Valeur pour ${field.dynamicField}`}
								className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
							/>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export const CustomEmailBody = ({ emailBody, onChange }) => {
	return (
		<div className="flex flex-col space-y-2">
			<label
				htmlFor="emailBody"
				className="font-semibold flex items-center space-x-2"
			>
				Contenu de l'email (Markdown pris en charge)
				<Tooltip
					title="Contenu de l'email"
					description="Rédigez le contenu de votre email. Vous pouvez utiliser le Markdown pour la mise en forme."
				/>
			</label>
			<textarea
				id="emailBody"
				value={emailBody}
				onChange={onChange}
				placeholder="Écrivez votre email ici..."
				rows={6}
				className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
			></textarea>
		</div>
	);
};

export const FileUpload = ({
	file,
	onFileChange,
	onReplace,
	onAdd,
	isLoadingReplace,
	isLoadingAdd,
}) => {
	return (
		<div className="flex flex-col items-start bg-white border border-gray-200 rounded p-4 gap-[20px] ">
			<input
				type="file"
				accept=".csv, .xlsx, .xls"
				onChange={onFileChange}
				className="border rounded px-2 py-1 w-full "
			/>
			{/* "Remplacer la liste" Button */}
			<div className={"flex space-x-2"}>
				<button
					onClick={onReplace}
					disabled={!file || isLoadingReplace}
					className={cn(
						"text-white px-4 py-2 rounded flex items-center justify-center",
						{
							"bg-startgreen_primary-100 hover:bg-opacity-75":
								!isLoadingReplace && file,
							"bg-startgreen_primary-100/20 cursor-not-allowed":
								!file || isLoadingReplace,
						}
					)}
				>
					{isLoadingReplace ? (
						<>
							{/* Spinner SVG */}
							<svg
								className="animate-spin h-5 w-5 mr-2 text-white"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							>
								<circle
									className="opacity-25"
									cx="12"
									cy="12"
									r="10"
									stroke="currentColor"
									strokeWidth="4"
								></circle>
								<path
									className="opacity-75"
									fill="currentColor"
									d="M4 12a8 8 0 018-8v8H4z"
								></path>
							</svg>
							Remplacer la liste...
						</>
					) : (
						"Remplacer la liste"
					)}
				</button>
				{/* "Ajouter à la liste" Button */}
				<button
					onClick={onAdd}
					disabled={!file || isLoadingAdd}
					className={cn(
						"text-white px-4 py-2 rounded flex items-center justify-center",
						{
							"bg-startgreen_darkblue-100 hover:bg-opacity-75":
								!isLoadingAdd && file,
							"bg-startgreen_darkblue-100/20 cursor-not-allowed":
								!file || isLoadingAdd,
						}
					)}
				>
					{isLoadingAdd ? (
						<>
							{/* Spinner SVG */}
							<svg
								className="animate-spin h-5 w-5 mr-2 text-white"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							>
								<circle
									className="opacity-25"
									cx="12"
									cy="12"
									r="10"
									stroke="currentColor"
									strokeWidth="4"
								></circle>
								<path
									className="opacity-75"
									fill="currentColor"
									d="M4 12a8 8 0 018-8v8H4z"
								></path>
							</svg>
							Ajout en cours...
						</>
					) : (
						"Ajouter à la liste"
					)}
				</button>
			</div>

			<Tooltip
				title="Téléchargement de fichier"
				description="Téléchargez un fichier CSV ou Excel pour importer des adresses email."
			/>
		</div>
	);
};

export const RecipientEmailsList = ({
	recipientEmails,
	onEmailChange,
	onRemoveEmail,
}) => {
	return (
		<div>
			{recipientEmails.map((email, index) => (
				<div key={index} className="flex items-center space-x-2 mt-2">
					<input
						type="email"
						value={email}
						onChange={(e) => onEmailChange(index, e.target.value)}
						placeholder="Email du destinataire"
						className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
					/>
					{recipientEmails.length > 1 && (
						<button
							type="button"
							onClick={() => onRemoveEmail(index)}
							className="text-red-500 hover:text-red-700"
						>
							Supprimer
						</button>
					)}
				</div>
			))}
		</div>
	);
};

export const SubjectField = ({ subject, onChange }) => {
	return (
		<div className="flex flex-col space-y-2">
			<label
				htmlFor="subject"
				className="font-semibold flex items-center space-x-2"
			>
				Sujet
				<Tooltip
					title="Sujet de l'email"
					description="Entrez le sujet de votre email qui sera visible par les destinataires."
				/>
			</label>
			<input
				type="text"
				id="subject"
				value={subject}
				onChange={onChange}
				placeholder="Sujet"
				className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
			/>
		</div>
	);
};

export const SchedulingOption = ({
	isScheduled,
	onScheduledChange,
	scheduleDate,
	onScheduleDateChange,
}) => {
	return (
		<div>
			<label className="flex items-center space-x-2">
				<input
					type="checkbox"
					checked={isScheduled}
					onChange={onScheduledChange}
					className="form-checkbox h-5 w-5 text-blue-600"
				/>
				<span>Planifier cet email ?</span>
				<Tooltip
					title="Planification d'email"
					description="Cochez cette option pour planifier l'envoi de l'email à une date et heure ultérieures."
				/>
			</label>
			{isScheduled && (
				<input
					type="datetime-local"
					value={scheduleDate}
					onChange={onScheduleDateChange}
					className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 mt-2"
				/>
			)}
		</div>
	);
};

export const SendButton = ({ onClick, loading }) => {
	return (
		<div className="flex items-center space-x-2">
			<button
				onClick={onClick}
				disabled={loading}
				className={cn(
					"text-white px-4 py-2 rounded flex items-center justify-center",
					{
						"bg-green-500 hover:bg-green-600": !loading,
						"bg-gray-400 cursor-not-allowed": loading,
					}
				)}
			>
				{loading ? (
					<>
						{/* Spinner SVG */}
						<svg
							className="animate-spin h-5 w-5 mr-2 text-white"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<circle
								className="opacity-25"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								strokeWidth="4"
							></circle>
							<path
								className="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8v8H4z"
							></path>
						</svg>
						Envoi en cours...
					</>
				) : (
					"Envoyer l'email"
				)}
			</button>
		</div>
	);
};

export const SendMailForm = ({ templates, onSendMail }) => {
	const [emailType, setEmailType] = useState("template"); // 'template' or 'custom'
	const [selectedTemplateId, setSelectedTemplateId] = useState("");
	const [recipientEmails, setRecipientEmails] = useState([""]);
	const [subject, setSubject] = useState("");
	const [emailBody, setEmailBody] = useState(""); // For custom email
	const [isScheduled, setIsScheduled] = useState(false);
	const [scheduleDate, setScheduleDate] = useState("");
	const [dynamicFields, setDynamicFields] = useState([]);
	const [loading, setLoading] = useState(false); // For sending emails
	const [file, setFile] = useState(null); // For storing the uploaded file
	const [isFileLoadingReplace, setIsFileLoadingReplace] = useState(false); // For "Remplacer la liste" loading state
	const [isFileLoadingAdd, setIsFileLoadingAdd] = useState(false); // For "Ajouter à la liste" loading state

	// Handler to add a new recipient email field
	const handleAddEmail = () => {
		setRecipientEmails([...recipientEmails, ""]);
	};

	// Handler to update recipient emails
	const handleEmailChange = (index, value) => {
		const newEmails = [...recipientEmails];
		newEmails[index] = value;
		setRecipientEmails(newEmails);
	};

	// Handler for removing an email field
	const handleRemoveEmail = (index) => {
		setRecipientEmails((prev) => prev.filter((_, i) => i !== index));
	};

	// Handler for changing email type (template or custom)
	const handleEmailTypeChange = (type) => {
		setEmailType(type);
		if (type === "template") {
			// Reset custom email body when switching to template
			setEmailBody("");
		} else {
			// Reset template selection when switching to custom
			setSelectedTemplateId("");
			setDynamicFields([]);
		}
	};

	// Handler for selecting a template
	const handleTemplateChange = (templateId) => {
		setSelectedTemplateId(templateId);
		const selectedTemplate = templates.find(
			(template) => template.id === templateId
		);
		if (selectedTemplate && selectedTemplate.dynamicFields) {
			setDynamicFields(
				selectedTemplate.dynamicFields.map((field) => ({
					dynamicField: field,
					value: "",
				}))
			);
		} else {
			setDynamicFields([]);
		}
	};

	// Handler for dynamic field changes
	const handleDynamicFieldChange = (index, value) => {
		const newFields = [...dynamicFields];
		newFields[index].value = value;
		setDynamicFields(newFields);
	};

	// Handler for file input change
	const handleFileChange = (e) => {
		const selectedFile = e.target.files[0];
		if (selectedFile) {
			setFile(selectedFile);
		}
	};

	// Utility function to validate email format
	const isValidEmail = (email) => {
		// Simple email regex validation
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	// Handler for replacing the recipient list with new emails from the file
	const handleFileUploadReplace = async () => {
		if (!file) {
			toast.error("Veuillez sélectionner un fichier CSV ou Excel.");
			return;
		}

		setIsFileLoadingReplace(true);

		try {
			const fileExtension = file.name.split(".").pop().toLowerCase();
			let emails = [];

			if (fileExtension === "csv") {
				// Parse CSV file
				const parsedData = await new Promise((resolve, reject) => {
					Papa.parse(file, {
						header: true,
						skipEmptyLines: true,
						complete: (results) => resolve(results.data),
						error: (err) => reject(err),
					});
				});

				if (parsedData.length === 0) {
					toast.error("Le fichier CSV est vide.");
					return;
				}

				// Identify the email column (trimmed and case-insensitive)
				const emailColumn = Object.keys(parsedData[0]).find((header) =>
					["mail", "email", "e-mail"].includes(header.trim().toLowerCase())
				);

				if (!emailColumn) {
					toast.error(
						"Aucune colonne d'email trouvée. Assurez-vous que la colonne est nommée 'mail', 'email' ou 'e-mail'."
					);
					return;
				}

				// Extract and validate emails
				emails = parsedData
					.map((row) => row[emailColumn])
					.filter((email) => email && isValidEmail(email.trim()))
					.map((email) => email.trim());

				if (emails.length === 0) {
					toast.error("Aucun email valide trouvé dans le fichier CSV.");
					return;
				}

				toast.success("Fichier CSV téléchargé et analysé avec succès !");
			} else if (["xlsx", "xls"].includes(fileExtension)) {
				// Parse Excel file
				const data = await file.arrayBuffer();
				const workbook = XLSX.read(data, { type: "array" });
				const firstSheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[firstSheetName];
				const sheetData = XLSX.utils.sheet_to_json(worksheet, {
					header: 1,
					defval: "",
				});

				if (sheetData.length === 0) {
					toast.error("Le fichier Excel est vide.");
					return;
				}

				// Identify the email column by headers (trimmed and case-insensitive)
				const headers = sheetData[0].map((header) =>
					header.toString().trim().toLowerCase()
				);
				const emailColumnIndex = headers.findIndex((header) =>
					["mail", "email", "e-mail"].includes(header)
				);

				if (emailColumnIndex === -1) {
					toast.error(
						"Aucune colonne d'email trouvée. Assurez-vous que la colonne est nommée 'mail', 'email' ou 'e-mail'."
					);
					return;
				}

				// Extract and validate emails
				emails = sheetData
					.slice(1)
					.map((row) => row[emailColumnIndex])
					.filter((email) => {
						const trimmedEmail = email.toString().trim();
						return trimmedEmail && isValidEmail(trimmedEmail);
					})
					.map((email) => email.toString().trim());

				if (emails.length === 0) {
					toast.error("Aucun email valide trouvé dans le fichier Excel.");
					return;
				}

				toast.success("Fichier Excel téléchargé et analysé avec succès !");
			} else {
				toast.error("Seuls les fichiers CSV et Excel sont acceptés.");
				return;
			}

			// Remove duplicate emails
			emails = [...new Set(emails)];

			setRecipientEmails(emails);
			setFile(null); // Reset the file input
		} catch (error) {
			console.error(error);
			toast.error("Erreur lors du téléchargement ou de l'analyse du fichier.");
		} finally {
			setIsFileLoadingReplace(false);
		}
	};

	// Handler for adding new emails to the existing list
	const handleFileUploadAdd = async () => {
		if (!file) {
			toast.error("Veuillez sélectionner un fichier CSV ou Excel.");
			return;
		}

		setIsFileLoadingAdd(true);

		try {
			const fileExtension = file.name.split(".").pop().toLowerCase();
			let emails = [];

			if (fileExtension === "csv") {
				// Parse CSV file
				const parsedData = await new Promise((resolve, reject) => {
					Papa.parse(file, {
						header: true,
						skipEmptyLines: true,
						complete: (results) => resolve(results.data),
						error: (err) => reject(err),
					});
				});

				if (parsedData.length === 0) {
					toast.error("Le fichier CSV est vide.");
					return;
				}

				// Identify the email column (trimmed and case-insensitive)
				const emailColumn = Object.keys(parsedData[0]).find((header) =>
					["mail", "email", "e-mail"].includes(header.trim().toLowerCase())
				);

				if (!emailColumn) {
					toast.error(
						"Aucune colonne d'email trouvée. Assurez-vous que la colonne est nommée 'mail', 'email' ou 'e-mail'."
					);
					return;
				}

				// Extract and validate emails
				emails = parsedData
					.map((row) => row[emailColumn])
					.filter((email) => email && isValidEmail(email.trim()))
					.map((email) => email.trim());

				if (emails.length === 0) {
					toast.error("Aucun email valide trouvé dans le fichier CSV.");
					return;
				}

				toast.success("Fichier CSV téléchargé et analysé avec succès !");
			} else if (["xlsx", "xls"].includes(fileExtension)) {
				// Parse Excel file
				const data = await file.arrayBuffer();
				const workbook = XLSX.read(data, { type: "array" });
				const firstSheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[firstSheetName];
				const sheetData = XLSX.utils.sheet_to_json(worksheet, {
					header: 1,
					defval: "",
				});

				if (sheetData.length === 0) {
					toast.error("Le fichier Excel est vide.");
					return;
				}

				// Identify the email column by headers (trimmed and case-insensitive)
				const headers = sheetData[0].map((header) =>
					header.toString().trim().toLowerCase()
				);
				const emailColumnIndex = headers.findIndex((header) =>
					["mail", "email", "e-mail"].includes(header)
				);

				if (emailColumnIndex === -1) {
					toast.error(
						"Aucune colonne d'email trouvée. Assurez-vous que la colonne est nommée 'mail', 'email' ou 'e-mail'."
					);
					return;
				}

				// Extract and validate emails
				emails = sheetData
					.slice(1)
					.map((row) => row[emailColumnIndex])
					.filter((email) => {
						const trimmedEmail = email.toString().trim();
						return trimmedEmail && isValidEmail(trimmedEmail);
					})
					.map((email) => email.toString().trim());

				if (emails.length === 0) {
					toast.error("Aucun email valide trouvé dans le fichier Excel.");
					return;
				}

				toast.success("Fichier Excel téléchargé et analysé avec succès !");
			} else {
				toast.error("Seuls les fichiers CSV et Excel sont acceptés.");
				return;
			}

			// Remove duplicate emails from the new list
			emails = [...new Set(emails)];

			// Combine with existing emails and remove duplicates
			const combinedEmails = [...recipientEmails, ...emails];
			const uniqueEmails = [...new Set(combinedEmails)];

			setRecipientEmails(uniqueEmails);
			setFile(null); // Reset the file input
		} catch (error) {
			console.error(error);
			toast.error("Erreur lors du téléchargement ou de l'analyse du fichier.");
		} finally {
			setIsFileLoadingAdd(false);
		}
	};

	// Handler for sending the email
	const handleSend = async () => {
		// Basic validation
		if (
			recipientEmails.length === 0 ||
			recipientEmails.some((email) => email.trim() === "")
		) {
			toast.error("Veuillez entrer au moins un email de destinataire.", {
				duration: 5000,
			});
			return;
		}

		// if (subject.trim() === "") {
		// 	toast.error("Veuillez entrer un sujet pour l'email.", {
		// 		duration: 5000,
		// 	});
		// 	return;
		// }

		if (emailType === "template" && selectedTemplateId === "") {
			toast.error("Veuillez sélectionner un modèle d'email.", {
				duration: 5000,
			});
			return;
		}

		if (emailType === "custom" && emailBody.trim() === "") {
			toast.error("Veuillez entrer le contenu de l'email.", {
				duration: 5000,
			});
			return;
		}

		// Additional validation: Ensure all recipient emails are valid
		const invalidEmails = recipientEmails.filter(
			(email) => !isValidEmail(email)
		);
		if (invalidEmails.length > 0) {
			toast.error("Certains emails de destinataires ne sont pas valides.");
			return;
		}

		setLoading(true);
		const context = dynamicFields.reduce((acc, field) => {
			acc[field.dynamicField] = field.value;
			return acc;
		}, {});

		// Construct the payload based on email type
		const payload = {
			recipients: recipientEmails,
			subject,
			templateId: selectedTemplateId,
			schedule: isScheduled,
			sendAt: scheduleDate,
			body: emailBody,
			context,
		};

		if (emailType === "template") {
			payload.templateId = selectedTemplateId;
			payload.context = context;
		} else {
			payload.body = emailBody;
		}

		try {
			await onSendMail(payload);

			// Reset the form upon successful send
			setSelectedTemplateId("");
			setRecipientEmails([""]);
			setSubject("");
			setEmailBody("");
			setIsScheduled(false);
			setScheduleDate("");
			setDynamicFields([]);
			setEmailType("template");
			setFile(null); // Reset file upload after sending
			// toast.success("Email envoyé avec succès !");
		} catch (error) {
			console.error(error);
			toast.error(
				error?.message ||
					"Une erreur s'est produite lors de l'envoi de l'email.",
				{ duration: 5000 }
			);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="send-mail-form space-y-4">
			{/* Toaster for notifications */}
			<Toaster position="bottom-center" reverseOrder={false} />

			{/* Email Type Selection */}
			<EmailTypeSelection
				emailType={emailType}
				onChange={handleEmailTypeChange}
			/>

			{/* Conditional Rendering Based on Email Type */}
			{emailType === "template" ? (
				<TemplateSelection
					selectedTemplateId={selectedTemplateId}
					templates={templates}
					onChange={handleTemplateChange}
					dynamicFields={dynamicFields}
					onDynamicFieldChange={handleDynamicFieldChange}
				/>
			) : (
				<CustomEmailBody
					emailBody={emailBody}
					onChange={(e) => setEmailBody(e.target.value)}
				/>
			)}

			{/* File Upload for CSV or Excel */}
			<FileUpload
				file={file}
				onFileChange={handleFileChange}
				onReplace={handleFileUploadReplace}
				onAdd={handleFileUploadAdd}
				isLoadingReplace={isFileLoadingReplace}
				isLoadingAdd={isFileLoadingAdd}
			/>

			{/* Recipient Emails */}
			<RecipientEmailsList
				recipientEmails={recipientEmails}
				onEmailChange={handleEmailChange}
				onRemoveEmail={handleRemoveEmail}
			/>

			{/* Add More Emails Button */}
			<button
				type="button"
				className="bg-startgreen_darkblue-100 text-white px-4 py-2 rounded hover:bg-blue-600"
				onClick={handleAddEmail}
			>
				Ajouter plus d'emails
			</button>

			{/* Subject Field */}
			<SubjectField
				subject={subject}
				onChange={(e) => setSubject(e.target.value)}
			/>

			{/* Scheduling Option */}
			<SchedulingOption
				isScheduled={isScheduled}
				onScheduledChange={(e) => setIsScheduled(e.target.checked)}
				scheduleDate={scheduleDate}
				onScheduleDateChange={(e) => setScheduleDate(e.target.value)}
			/>

			{/* Send Button */}
			<SendButton onClick={handleSend} loading={loading} />
		</div>
	);
};
