import { useContext } from "react";
import { AuthContext } from "@core/guards/AuthProvider.tsx";

const useAuth = () => {
	const {
		isAuthenticated,
		isAuthLoading,
		isAuthorized,
		role,
		handleLogin,
		handleAuthorization,
		handleLogout,
	} = useContext(AuthContext);

	return {
		isAuthenticated,
		isAuthLoading,
		isAuthorized,
		role,
		handleLogin,
		handleAuthorization,
		handleLogout,
	};
};

export default useAuth;
