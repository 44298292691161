import { createFileRoute, Outlet } from "@tanstack/react-router";
import AuthGuard from "@core/guards/auth.guard.tsx";
import RoleGuard from "@core/guards/role.guard.tsx";

export const Route = createFileRoute("/(main)/startgreen-by-iptic/dashboard")({
	component: () => (
		<div>
			<AuthGuard>
				{/*<RoleGuard requiredRole={"admin"}>*/}
				<Outlet />
				{/*</RoleGuard>*/}
			</AuthGuard>
		</div>
	),
});
