import type React from "react";
import { useEffect, useRef, useState } from "react";
import type { ImageProps } from "@components/elements/Image/Image.types.ts";
import { cn, getAssetURL } from "@core/utils";

const Image: React.FC<ImageProps> = ({
	src,
	alt = "",
	width = 50,
	height = 50,
	className = "",
	loading = "lazy" as "lazy" | "eager",
	blurOnLoad = false,
	...ariaAttributes
}) => {
	const imgRef = useRef<HTMLImageElement>(null);
	const imageUrl = getAssetURL(src);
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		const img = imgRef.current;
		if (img && loading === "eager") {
			const handleLoad = () => {
				setIsLoading(false);
			};
			img.src = imageUrl;
			img.addEventListener("load", handleLoad);
			return () => {
				img.removeEventListener("load", handleLoad);
			};
		}
	}, [imageUrl, loading, src]);
	return (
		<img
			ref={imgRef}
			src={imageUrl}
			alt={alt}
			width={width}
			height={height}
			className={cn(
				"select-none pointer-events-none",
				className,
				isLoading && blurOnLoad && "transition animate-pulse blur-[3px]"
			)}
			{...ariaAttributes}
			loading={loading}
		/>
	);
};

export default Image;
