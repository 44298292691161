import { cn } from "@core/utils";
import { StateCard } from "@components/views/(app)/solutions/StateSection/StateCard.tsx";
import Text from "@components/elements/Text";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { useTranslation } from "react-i18next";

function StateSection() {
	const { t } = useTranslation("solutions-page");

	return (
		<SectionWrapper className={" "}>
			<div
				className={cn(
					"relative flex w-full flex-col items-center",
					"margin-divider-xsm"
				)}
			>
				<Text
					tag="h2"
					className={cn(
						"text-headlineSmall _tablet_sm:text-displaySmall _tablet:text-displayMedium _laptop:text-displayLarge",
						"sm:max-w-title _tablet:order-initial order-first mb-[40px] flex max-w-[360px] flex-col items-center justify-center",
						" tracking-tight _phone_lg:max-w-[600px] _tablet_lg:col-span-2 _laptop_md:hidden"
					)}
					text={t("state-section.title")}
					highlightStyle="text-primary-400 font-semibold"
				/>
				<div className="relative grid gap-5 _tablet_sm:grid-cols-2 _laptop_md:grid-cols-3">
					<StateCard
						className="_tablet_lg:order-first"
						title={t("state-section.cards.state-1.title")}
						paragraphText={t("state-section.cards.state-1.paragraph")}
						bgColor="bg-secondary-200"
					/>
					<Text
						tag="h2"
						className={cn(
							"text-left text-headlineSmall tracking-tight _tablet_sm:text-displaySmall _tablet:text-displayMedium _laptop:text-displayLarge",
							"_tablet:order-initial order-first ml-[20px] hidden flex-col items-center justify-center",
							"_tablet_lg:col-span-2 lg:items-start _laptop_md:flex"
						)}
						text={t("state-section.title")}
						highlightStyle="text-primary-400 font-semibold"
					/>
					<StateCard
						title={t("state-section.cards.state-2.title")}
						paragraphText={t("state-section.cards.state-2.paragraph")}
						bgColor="bg-tertiary-100"
					/>
					<StateCard
						title={t("state-section.cards.state-3.title")}
						paragraphText={t("state-section.cards.state-3.paragraph")}
						bgColor="bg-primary-80"
					/>
					<StateCard
						title={t("state-section.cards.state-4.title")}
						paragraphText={t("state-section.cards.state-4.paragraph")}
						bgColor="bg-primary-50"
					/>
				</div>
			</div>
		</SectionWrapper>
	);
}

export default StateSection;
