const themeConfig = {
	app: {
		name: "Coachini",
	},
	layout: {
		isRTL: false,
		darkMode: false,
		lang: "en",
		semiDarkMode: false,
		skin: "default",
		contentWidth: "full",
		type: "vertical",
		navBarType: "sticky",
		footerType: "static",
		isMonochrome: false,
		menu: {
			isCollapsed: false,
			isHidden: false,
		},
		mobileMenu: false,
		customizer: false,
	},
};

export default themeConfig;
