import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { About } from "@pages/(app)/About.tsx";
import { useTranslation } from "react-i18next";

const Page = () => {
	const { t: tMeta } = useTranslation("metadata");

	return (
		<>
			<Meta
				title={tMeta("tos.title")}
				template={"%s | Coachini"}
				description={tMeta("tos.description")}
				keywords={["terms of use", "tos"]}
				robots="index,follow"
				ogTitle={tMeta("about-page.title")}
				ogDescription={tMeta("about-page.description")}
			/>
			TERMS OF USE FOR COACHINI.NET Article 1: Definitions In this document, the
			following terms shall have the following meanings: - The Site:
			https://coachini.net - The Publisher: Coachini - The User: Any person who
			uses the Site and its services Article 2: Purpose These Terms of Use aim
			to define the conditions of access and use of the Site by the User.
			Article 3: Acceptance of Terms of Use Access to the Site implies full
			acceptance of these Terms of Use by the User. Article 4: Use of the Site
			The Site is accessible from anywhere by all users having internet access.
			All costs incurred by the User to access the service (computer equipment,
			software, internet connection, etc.) are at their expense. Article 5:
			Intellectual Property All content on the Site, including texts, images,
			sounds, logos, icons, and graphic elements, as well as their layout, are
			the exclusive property of Coachini or its partners, and are protected by
			French and international laws relating to intellectual property. Article
			6: Liability Coachini cannot be held responsible in case of failure,
			breakdown, difficulty, or interruption of operation preventing access to
			the Site or one of its features. Article 7: Hyperlinks Creating links to
			the Site is authorized without prior request to Coachini. However,
			Coachini reserves the right to request the removal of a link it deems
			non-compliant with the Site's image and/or values it defends. Article 8:
			Modification of Terms of Use These Terms of Use may be modified at any
			time by Coachini. Modifications will be published on the Site and shall be
			considered accepted by the User when using the Site after modification.
			Article 10: Contact For any questions regarding these Terms of Use of the
			Site, please contact us at [your email address]. Last updated: October 9,
			2024
		</>
	);
};

export const Route = createFileRoute("/(main)/cgu/")({
	component: Page,
});
