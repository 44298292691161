import type { FunctionComponent, Key } from "react";
import type { NavBarMenuType } from "@components/layout/Navbar/Navbar.types.ts";
import { cn } from "@core/utils";
import { useTranslation } from "react-i18next";
import { Link } from "@tanstack/react-router";

type SubmenuProps = {
	item: NavBarMenuType;
	activeChildLink: string | null;
	handleSubmenuLinkClick: () => void;
	openSubmenuIndex: number | null;
	i: Key | null | undefined;
};

const Submenu: FunctionComponent<SubmenuProps> = ({
	item,
	activeChildLink,
	handleSubmenuLinkClick,
	openSubmenuIndex,
	i,
}) => {
	const { t } = useTranslation("layout");
	return (
		<div
			className={`menu-arrow absolute top-[110%] transform cursor-default rounded-md bg-white  p-5 ring-4 ring-secondary-300 ring-opacity-20 transition-all duration-300 ${
				openSubmenuIndex === i
					? "visible translate-y-0 opacity-100"
					: "hidden -translate-y-9 opacity-0"
			}`}
		>
			<ul>
				{item.child?.map((item: any, index: React.Key | null | undefined) => (
					<li key={index} className="mb-1">
						<Link
							to={`/${item.childlink}`}
							onClick={handleSubmenuLinkClick}
							className={cn(
								"hover:text-gray-500/75 text-text-700",
								item.childlink === activeChildLink ? "text-secondary-900" : ""
							)}
						>
							{t(item.childtitle)}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Submenu;
