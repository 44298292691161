import React, { useState } from "react";
import { cn } from "@core/utils";

const FilterCheckbox = ({ option, isChecked, onChange }) => {
	return (
		<div className="flex items-center">
			<input
				type="checkbox"
				id={option}
				className="mr-2"
				checked={isChecked}
				onChange={onChange}
			/>
			<label htmlFor={option}>{option}</label>
		</div>
	);
};

export default FilterCheckbox;

export const Accordion = ({
	title,
	options,
	selectedOptions,
	onFilterChange,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div
			className={cn(
				"border border-gray-300 rounded-md mb-4",
				selectedOptions.length > 0
					? "bg-startgreen_primary-100/10 border-startgreen_primary-100/50"
					: ""
			)}
		>
			<div className={" px-2 flex items-center"}>
				{/* add + icon when its closed and - when its open*/}
				<h2>{isOpen ? "-" : "+"}</h2>
				<button
					className="w-full bg-gray-200 text-left p-3 rounded-md"
					onClick={() => setIsOpen(!isOpen)}
				>
					{title}
				</button>
			</div>

			{isOpen && (
				<div className="bg-white p-3">
					{options.map((option) => (
						<>
							<FilterCheckbox
								key={option.value}
								option={option.label}
								isChecked={selectedOptions.includes(option.value)}
								onChange={() => {
									onFilterChange(option.value);
								}}
							/>
							<hr
								className={cn(
									"border border-1 border-black-300/50 my-2 w-[90%] self-center justify-self-center"
								)}
							/>
						</>
					))}
				</div>
			)}
		</div>
	);
};
