import { TEditorConfiguration } from "../../documents/editor/core";

const RESERVATION_REMINDER: TEditorConfiguration = {
	root: {
		type: "EmailLayout",
		data: {
			backdropColor: "#FFFFFF",
			canvasColor: "#FFFFFF",
			textColor: "#03124A",
			fontFamily: "GEOMETRIC_SANS",
			childrenIds: [
				"block_DDiR1Jz1iXY5aR1PMCzRAC",
				"block_J98nQiyYeX1vJTu44ZSuua",
				"block_M7imqmjHsmhd61LcdHJz5m",
				"block_Fec2JmwhjNYJS7xWDyUqWu",
				"block_E8jr6ieqZ4fwaHxj9C1uBF",
				"block_H1hMtvKJ9b1eQ2aiEDjbTL",
				"block_JJtd5TsPSjEMNhtKeMPh82",
				"block_HRswcB2Ww7m3H4SijapqPq",
				"block_kq85AQYHuSL8fXqqcZP7D",
				"block_Sa3vmMr4AUT4W55Mx5EFxr",
				"block_YF6R8BeMFE3XuiSP4cUex2",
				"block_Qh1QiwAbDq1yxuzqBMAxbJ",
				"block_FUvBg3iUzvmUcTjmjvo5Wd",
				"block_UncM7Be9AkE197F3KYjpa5",
				"block_8hNnkxhS84scJ18FCwRr4q",
				"block_JihqAfW5u5wR8oQUJFP5kE",
			],
		},
	},
	block_DDiR1Jz1iXY5aR1PMCzRAC: {
		type: "Image",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 24,
					bottom: 24,
					left: 24,
					right: 24,
				},
				textAlign: "left",
			},
			props: {
				height: 32,
				url: "https://d1iiu589g39o6c.cloudfront.net/live/platforms/platform_A9wwKSL6EV6orh6f/images/wptemplateimage_RAFATLGe3CN1wDsb/birdhouse.png",
				contentAlignment: "middle",
			},
		},
	},
	block_J98nQiyYeX1vJTu44ZSuua: {
		type: "Heading",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "left",
				padding: {
					top: 16,
					bottom: 0,
					left: 24,
					right: 24,
				},
			},
			props: {
				level: "h2",
				text: "Elaina arrives Sunday, May 21.",
			},
		},
	},
	block_M7imqmjHsmhd61LcdHJz5m: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 16,
					bottom: 16,
					left: 24,
					right: 24,
				},
			},
			props: {
				text: "If you haven’t already, reach out to Elaina to send directions and coordinate check-in time.",
			},
		},
	},
	block_MEPSuGAUvE8WAg1AJWJvNU: {
		type: "Avatar",
		data: {
			style: {
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				size: 64,
				shape: "circle",
				imageUrl: "https://ui-avatars.com/api/?size=128&name=Elaina",
				alt: "Elaina",
			},
		},
	},
	block_7MDe4niz9sWkSR7Un5BvRo: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_MEPSuGAUvE8WAg1AJWJvNU"],
			},
		},
	},
	block_5jMPEu49PGBxyGcuBYxXK2: {
		type: "Heading",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				level: "h3",
				text: "Elaina",
			},
		},
	},
	block_NUv5obp6NnExg8DgonEyzm: {
		type: "Text",
		data: {
			style: {
				color: "#2e3c68",
				backgroundColor: null,
				fontSize: 14,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "United States\nMember since 2013",
			},
		},
	},
	block_NetzRCyQeJbPp2VWQwmxBP: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: [
					"block_5jMPEu49PGBxyGcuBYxXK2",
					"block_NUv5obp6NnExg8DgonEyzm",
				],
			},
		},
	},
	block_GH41bF6mW4wxDywjsxKrAv: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: [],
			},
		},
	},
	block_UWushKnW34XryX4BSzpotP: {
		type: "ColumnsContainer",
		data: {
			style: {
				backgroundColor: "#f8f8f8",
				padding: {
					top: 24,
					bottom: 24,
					left: 16,
					right: 16,
				},
			},
			props: {
				fixedWidths: [64, null, null],
				columnsCount: 2,
				columnsGap: 16,
				columns: [
					{
						childrenIds: ["block_7MDe4niz9sWkSR7Un5BvRo"],
					},
					{
						childrenIds: ["block_NetzRCyQeJbPp2VWQwmxBP"],
					},
					{
						childrenIds: ["block_GH41bF6mW4wxDywjsxKrAv"],
					},
				],
			},
		},
	},
	block_Fec2JmwhjNYJS7xWDyUqWu: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 8,
					bottom: 8,
					left: 24,
					right: 24,
				},
			},
			props: {
				childrenIds: ["block_UWushKnW34XryX4BSzpotP"],
			},
		},
	},
	block_E8jr6ieqZ4fwaHxj9C1uBF: {
		type: "Button",
		data: {
			style: {
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "left",
				padding: {
					top: 16,
					bottom: 16,
					left: 24,
					right: 24,
				},
			},
			props: {
				buttonBackgroundColor: "#03124A",
				buttonStyle: "rounded",
				buttonTextColor: "#FFFFFF",
				fullWidth: false,
				size: "large",
				text: "Send Elaina a message",
				url: "https://www.coachini.net",
			},
		},
	},
	block_H1hMtvKJ9b1eQ2aiEDjbTL: {
		type: "Image",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 24,
					bottom: 24,
					left: 24,
					right: 24,
				},
				textAlign: "left",
			},
			props: {
				url: "https://d1iiu589g39o6c.cloudfront.net/live/platforms/platform_A9wwKSL6EV6orh6f/images/wptemplateimage_QWeWcucRbjPqoewY/L1030293.jpg",
				alt: "Photo",
				linkHref: "https://example.coachini.net/listings/r029348209842",
				contentAlignment: "middle",
			},
		},
	},
	block_JJtd5TsPSjEMNhtKeMPh82: {
		type: "Heading",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 24,
					right: 24,
				},
			},
			props: {
				level: "h2",
				text: "Private Hideaway in the North Shore",
			},
		},
	},
	block_HRswcB2Ww7m3H4SijapqPq: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 16,
					left: 24,
					right: 24,
				},
			},
			props: {
				text: "Entire home/apartment",
			},
		},
	},
	block_kq85AQYHuSL8fXqqcZP7D: {
		type: "Divider",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 16,
					bottom: 16,
					left: 24,
					right: 24,
				},
			},
			props: {
				lineHeight: 1,
				lineColor: "#EEEEEE",
			},
		},
	},
	block_AvnqsSCo9CTXYXHfMCQvUN: {
		type: "Heading",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 8,
					left: 0,
					right: 0,
				},
			},
			props: {
				level: "h3",
				text: "Check in",
			},
		},
	},
	block_BsbDbmYTuyisEMXQY8dwW6: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "Sunday, May 21 2023.\nCheck-in anytime after 3:00 PM",
			},
		},
	},
	block_6G89B9zYn7ELqnioW62cLA: {
		type: "Heading",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "left",
				padding: {
					top: 16,
					bottom: 8,
					left: 0,
					right: 0,
				},
			},
			props: {
				level: "h3",
				text: "Check out",
			},
		},
	},
	block_DhZSQJBrQCXG17HSSMAfiq: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "Tuesday, May 23 2023.\nCheckout by 11:00 AM",
			},
		},
	},
	block_NAzWgDcqGdpeHWrspZ8fz7: {
		type: "Divider",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 16,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				lineHeight: 1,
				lineColor: "#EEEEEE",
			},
		},
	},
	block_Sa3vmMr4AUT4W55Mx5EFxr: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 8,
					bottom: 8,
					left: 24,
					right: 24,
				},
			},
			props: {
				childrenIds: [
					"block_AvnqsSCo9CTXYXHfMCQvUN",
					"block_BsbDbmYTuyisEMXQY8dwW6",
					"block_6G89B9zYn7ELqnioW62cLA",
					"block_DhZSQJBrQCXG17HSSMAfiq",
					"block_NAzWgDcqGdpeHWrspZ8fz7",
				],
			},
		},
	},
	block_MxedV7vMjjU4ZsikW1Fadv: {
		type: "Heading",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 8,
					left: 0,
					right: 0,
				},
			},
			props: {
				level: "h3",
				text: "Guests",
			},
		},
	},
	block_8htLTdnN2CcAX67kwJFo2V: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "Let them know upfront if your listing is suitable for children by updating your House Rules.",
			},
		},
	},
	block_UR2m2tSekeEBy6s76D9Vju: {
		type: "Divider",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 16,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				lineHeight: 1,
				lineColor: "#EEEEEE",
			},
		},
	},
	block_YF6R8BeMFE3XuiSP4cUex2: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 8,
					bottom: 8,
					left: 24,
					right: 24,
				},
			},
			props: {
				childrenIds: [
					"block_MxedV7vMjjU4ZsikW1Fadv",
					"block_8htLTdnN2CcAX67kwJFo2V",
					"block_UR2m2tSekeEBy6s76D9Vju",
				],
			},
		},
	},
	block_BouZQPSLfEfWvVp9qrHaW1: {
		type: "Heading",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 8,
					left: 0,
					right: 0,
				},
			},
			props: {
				level: "h3",
				text: "Confirmation code",
			},
		},
	},
	block_Si5vw3ga7PZppiYv6g7GV7: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "HMFN35C4MH",
			},
		},
	},
	block_S6ARLFYsniJdJdkkie4UNa: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_Si5vw3ga7PZppiYv6g7GV7"],
			},
		},
	},
	block_MawqUvY8CdWpQ84dy4eRsa: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "right",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "View itinerary",
			},
		},
	},
	block_KjrdgHLJcyAcsjKStPktPU: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_MawqUvY8CdWpQ84dy4eRsa"],
			},
		},
	},
	block_DK7wXQDdPd8rzFxMeyxDzs: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: [],
			},
		},
	},
	block_LHBuzMB27wJAjyi6bwLKeN: {
		type: "ColumnsContainer",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 8,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				columnsCount: 2,
				columns: [
					{
						childrenIds: ["block_S6ARLFYsniJdJdkkie4UNa"],
					},
					{
						childrenIds: ["block_KjrdgHLJcyAcsjKStPktPU"],
					},
					{
						childrenIds: ["block_DK7wXQDdPd8rzFxMeyxDzs"],
					},
				],
			},
		},
	},
	block_CWgMBgMBgpLMjqd1biz1mz: {
		type: "Divider",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 16,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				lineHeight: 1,
				lineColor: "#EEEEEE",
			},
		},
	},
	block_Qh1QiwAbDq1yxuzqBMAxbJ: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 8,
					bottom: 8,
					left: 24,
					right: 24,
				},
			},
			props: {
				childrenIds: [
					"block_BouZQPSLfEfWvVp9qrHaW1",
					"block_LHBuzMB27wJAjyi6bwLKeN",
					"block_CWgMBgMBgpLMjqd1biz1mz",
				],
			},
		},
	},
	block_SnYxmj5ia2K4dqVEaZDLst: {
		type: "Heading",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				level: "h3",
				text: "Guest paid",
			},
		},
	},
	block_DBsnXnwmLBhETFknwe3qJt: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "$99.00 x 2 nights",
			},
		},
	},
	block_4DvvyfwDXkgXXsjswWE9DE: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_DBsnXnwmLBhETFknwe3qJt"],
			},
		},
	},
	block_L6NUphHkWkpcXZgzBbW9dM: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "right",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "$198.00",
			},
		},
	},
	block_DE363EYUo29c6qTiQLFzHv: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_L6NUphHkWkpcXZgzBbW9dM"],
			},
		},
	},
	block_Hs2DNRHwYGFvzMGVrZWUat: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: [],
			},
		},
	},
	block_8rxqcogAeoRMFJJwHDLBvY: {
		type: "ColumnsContainer",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 8,
					bottom: 8,
					left: 0,
					right: 0,
				},
			},
			props: {
				columnsCount: 2,
				columns: [
					{
						childrenIds: ["block_4DvvyfwDXkgXXsjswWE9DE"],
					},
					{
						childrenIds: ["block_DE363EYUo29c6qTiQLFzHv"],
					},
					{
						childrenIds: ["block_Hs2DNRHwYGFvzMGVrZWUat"],
					},
				],
			},
		},
	},
	block_KbFcTU27iLRQK6eVKkJ5As: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "Cleaning fee",
			},
		},
	},
	block_McUGzTsrZTZGi8rXAs85KR: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_KbFcTU27iLRQK6eVKkJ5As"],
			},
		},
	},
	block_VhFNCUhgsbekxdnzvddmSH: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "right",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "$50.00",
			},
		},
	},
	block_N5gv3mrQEm485SpKfBFHzi: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_VhFNCUhgsbekxdnzvddmSH"],
			},
		},
	},
	block_T3dLYTNNPBosywgZPEvuyn: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: [],
			},
		},
	},
	block_RUef6VdysRRfcaDSdPPPRL: {
		type: "ColumnsContainer",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 8,
					bottom: 8,
					left: 0,
					right: 0,
				},
			},
			props: {
				columnsCount: 2,
				columns: [
					{
						childrenIds: ["block_McUGzTsrZTZGi8rXAs85KR"],
					},
					{
						childrenIds: ["block_N5gv3mrQEm485SpKfBFHzi"],
					},
					{
						childrenIds: ["block_T3dLYTNNPBosywgZPEvuyn"],
					},
				],
			},
		},
	},
	block_4HKuXRyhnMPYfpEre1Y4EP: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "Guest service fee",
			},
		},
	},
	block_C9rqNoPW6sR7FJ94dCW48c: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_4HKuXRyhnMPYfpEre1Y4EP"],
			},
		},
	},
	block_BGnqpBpBPAJZMqiSB2tFhs: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "right",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "$35.01",
			},
		},
	},
	block_8EUayHcU4ze8PPYG4uVyqQ: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_BGnqpBpBPAJZMqiSB2tFhs"],
			},
		},
	},
	block_NAdj8o21Qtuz5U1Ptsp8QS: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: [],
			},
		},
	},
	block_FDZhF8wpD8Am5TBAxqXYRn: {
		type: "ColumnsContainer",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 8,
					bottom: 8,
					left: 0,
					right: 0,
				},
			},
			props: {
				columnsCount: 2,
				columns: [
					{
						childrenIds: ["block_C9rqNoPW6sR7FJ94dCW48c"],
					},
					{
						childrenIds: ["block_8EUayHcU4ze8PPYG4uVyqQ"],
					},
					{
						childrenIds: ["block_NAdj8o21Qtuz5U1Ptsp8QS"],
					},
				],
			},
		},
	},
	block_Fnft7v54tmFaNiTJ3Qn5sg: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "Occupancy taxes",
			},
		},
	},
	block_Qvmy5aUBDfQXJyGjUyTZa3: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_Fnft7v54tmFaNiTJ3Qn5sg"],
			},
		},
	},
	block_Jnd8bMVCEZPckFeotT1H6F: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "right",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "$46.02",
			},
		},
	},
	block_9LZ7J716qiGogPFAufpYdi: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_Jnd8bMVCEZPckFeotT1H6F"],
			},
		},
	},
	block_RU3D4pVFkQDvAyNS7P5rRR: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: [],
			},
		},
	},
	block_9Fj1tXV6otFnGdgJevbFPG: {
		type: "ColumnsContainer",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 8,
					bottom: 8,
					left: 0,
					right: 0,
				},
			},
			props: {
				columnsCount: 2,
				columns: [
					{
						childrenIds: ["block_Qvmy5aUBDfQXJyGjUyTZa3"],
					},
					{
						childrenIds: ["block_9LZ7J716qiGogPFAufpYdi"],
					},
					{
						childrenIds: ["block_RU3D4pVFkQDvAyNS7P5rRR"],
					},
				],
			},
		},
	},
	block_JgZwrNfGi5BfW8KWeZT4Gq: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "Total (USD)",
			},
		},
	},
	block_7eXpDRpkNETHdMN8Bkg9m1: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_JgZwrNfGi5BfW8KWeZT4Gq"],
			},
		},
	},
	block_8Xioqh7pENRgRhzFpVqh8w: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "right",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "$329.03",
			},
		},
	},
	block_DCRQd2eFKSbUGvmQkxDdHs: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_8Xioqh7pENRgRhzFpVqh8w"],
			},
		},
	},
	block_65qW362ex9LdhQXx1dLSuq: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: [],
			},
		},
	},
	block_7FUnUJ6umTXZz3Mn54KLCY: {
		type: "ColumnsContainer",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 24,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				columnsCount: 2,
				columns: [
					{
						childrenIds: ["block_7eXpDRpkNETHdMN8Bkg9m1"],
					},
					{
						childrenIds: ["block_DCRQd2eFKSbUGvmQkxDdHs"],
					},
					{
						childrenIds: ["block_65qW362ex9LdhQXx1dLSuq"],
					},
				],
			},
		},
	},
	block_7RKLxAoSQFEyUSMx9fdkw3: {
		type: "Divider",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 16,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				lineHeight: 1,
				lineColor: "#EEEEEE",
			},
		},
	},
	block_FUvBg3iUzvmUcTjmjvo5Wd: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 8,
					bottom: 8,
					left: 24,
					right: 24,
				},
			},
			props: {
				childrenIds: [
					"block_SnYxmj5ia2K4dqVEaZDLst",
					"block_8rxqcogAeoRMFJJwHDLBvY",
					"block_RUef6VdysRRfcaDSdPPPRL",
					"block_FDZhF8wpD8Am5TBAxqXYRn",
					"block_9Fj1tXV6otFnGdgJevbFPG",
					"block_7FUnUJ6umTXZz3Mn54KLCY",
					"block_7RKLxAoSQFEyUSMx9fdkw3",
				],
			},
		},
	},
	block_WQ5J7hXLdDXn8Vq4bVkAie: {
		type: "Heading",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				level: "h3",
				text: "Host payout",
			},
		},
	},
	block_9ztgHz93w9GvUBGTrDUCHE: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "$99.00 x 2 nights",
			},
		},
	},
	block_CbV491KJbEv4crKswx5HHD: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_9ztgHz93w9GvUBGTrDUCHE"],
			},
		},
	},
	block_GJqkQ13ELZmPRexPJirGiF: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "right",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "$198.00",
			},
		},
	},
	block_BPtTenyy93Su76mzF1cpqd: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_GJqkQ13ELZmPRexPJirGiF"],
			},
		},
	},
	block_94kXBLAgQPLF79YgFvAefs: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: [],
			},
		},
	},
	block_StE5sGCYCoZTGAgs5KDKVX: {
		type: "ColumnsContainer",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 8,
					bottom: 8,
					left: 0,
					right: 0,
				},
			},
			props: {
				columnsCount: 2,
				columns: [
					{
						childrenIds: ["block_CbV491KJbEv4crKswx5HHD"],
					},
					{
						childrenIds: ["block_BPtTenyy93Su76mzF1cpqd"],
					},
					{
						childrenIds: ["block_94kXBLAgQPLF79YgFvAefs"],
					},
				],
			},
		},
	},
	block_NAtKDFR77MRHULTzQJy3M4: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "Cleaning fee",
			},
		},
	},
	block_GG9WkMFMLq5ySDKBScvVnF: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_NAtKDFR77MRHULTzQJy3M4"],
			},
		},
	},
	block_ScETZ7SFzGKLaxS5NqDh96: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "right",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "$50.00",
			},
		},
	},
	block_NAzfAuBqmG9wA2j2qykgF7: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_ScETZ7SFzGKLaxS5NqDh96"],
			},
		},
	},
	block_QAXFjyCSg9Mwog7ukkRzcX: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: [],
			},
		},
	},
	block_RYUv7gd391axaL7itsyKqD: {
		type: "ColumnsContainer",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 8,
					bottom: 8,
					left: 0,
					right: 0,
				},
			},
			props: {
				columnsCount: 2,
				columns: [
					{
						childrenIds: ["block_GG9WkMFMLq5ySDKBScvVnF"],
					},
					{
						childrenIds: ["block_NAzfAuBqmG9wA2j2qykgF7"],
					},
					{
						childrenIds: ["block_QAXFjyCSg9Mwog7ukkRzcX"],
					},
				],
			},
		},
	},
	block_LLCNdKMpQ7YzhfmVU5GhCR: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "Host service fee (3%)",
			},
		},
	},
	block_Y5UCxoKxDgggVgnrgPakvt: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_LLCNdKMpQ7YzhfmVU5GhCR"],
			},
		},
	},
	block_G9S6kAvgE1VkeoytcpG7dg: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "right",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "$7.44",
			},
		},
	},
	block_QrX6x2NPgGGScZPn4mSe1a: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_G9S6kAvgE1VkeoytcpG7dg"],
			},
		},
	},
	block_QYx54o6C4Tvkw58N3nMt9t: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: [],
			},
		},
	},
	block_LSBTeahYDd47Fxt3ymwBZj: {
		type: "ColumnsContainer",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 8,
					bottom: 8,
					left: 0,
					right: 0,
				},
			},
			props: {
				columnsCount: 2,
				columns: [
					{
						childrenIds: ["block_Y5UCxoKxDgggVgnrgPakvt"],
					},
					{
						childrenIds: ["block_QrX6x2NPgGGScZPn4mSe1a"],
					},
					{
						childrenIds: ["block_QYx54o6C4Tvkw58N3nMt9t"],
					},
				],
			},
		},
	},
	block_JRj1iySUsD8LjG1r9R1qCP: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "Total (USD)",
			},
		},
	},
	block_FANLGemZZEXcrKPfe1CNw: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_JRj1iySUsD8LjG1r9R1qCP"],
			},
		},
	},
	block_LFBMTsnpzjENRjTbjecCr9: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "right",
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "$240.56",
			},
		},
	},
	block_Eb9iDCjkJQBET2qNsaL74L: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: ["block_LFBMTsnpzjENRjTbjecCr9"],
			},
		},
	},
	block_UPrvRbjeyXXT57HiVpHxuH: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
			},
			props: {
				childrenIds: [],
			},
		},
	},
	block_V7XtD9k81GjEmSt2HHCrQH: {
		type: "ColumnsContainer",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 24,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				columnsCount: 2,
				columns: [
					{
						childrenIds: ["block_FANLGemZZEXcrKPfe1CNw"],
					},
					{
						childrenIds: ["block_Eb9iDCjkJQBET2qNsaL74L"],
					},
					{
						childrenIds: ["block_UPrvRbjeyXXT57HiVpHxuH"],
					},
				],
			},
		},
	},
	block_9tD2RA16EdVQfPXKNSXeru: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 16,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "The money you earn hosting will be sent to you 24 hours after your guest arrives. You can check your upcoming payments in your transaction history.",
			},
		},
	},
	block_A9ub75sjdu8XvuCnwSA9AC: {
		type: "Divider",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 16,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				lineHeight: 1,
				lineColor: "#EEEEEE",
			},
		},
	},
	block_UncM7Be9AkE197F3KYjpa5: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 8,
					bottom: 8,
					left: 24,
					right: 24,
				},
			},
			props: {
				childrenIds: [
					"block_WQ5J7hXLdDXn8Vq4bVkAie",
					"block_StE5sGCYCoZTGAgs5KDKVX",
					"block_RYUv7gd391axaL7itsyKqD",
					"block_LSBTeahYDd47Fxt3ymwBZj",
					"block_V7XtD9k81GjEmSt2HHCrQH",
					"block_9tD2RA16EdVQfPXKNSXeru",
					"block_A9ub75sjdu8XvuCnwSA9AC",
				],
			},
		},
	},
	block_3vaa8gDUB8D7gyYzTpKGzN: {
		type: "Heading",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontFamily: null,
				fontWeight: "bold",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 8,
					left: 0,
					right: 0,
				},
			},
			props: {
				level: "h3",
				text: "Cancellations",
			},
		},
	},
	block_AqF4QDsGrVadYYB9yJywmL: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 16,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 0,
					bottom: 24,
					left: 0,
					right: 0,
				},
			},
			props: {
				text: "Your cancellation policy for guests is Moderate.\n\nThe penalties for canceling this reservation include getting a public review that shows you canceled, paying a cancellation fee, and having the canceled nights blocked on your calendar.",
			},
		},
	},
	block_F9xbhy5omsUCjE9MuDfxJ5: {
		type: "Divider",
		data: {
			style: {
				backgroundColor: null,
				padding: {
					top: 16,
					bottom: 16,
					left: 0,
					right: 0,
				},
			},
			props: {
				lineHeight: 1,
				lineColor: "#EEEEEE",
			},
		},
	},
	block_8hNnkxhS84scJ18FCwRr4q: {
		type: "Container",
		data: {
			style: {
				backgroundColor: null,
				borderColor: null,
				borderRadius: null,
				padding: {
					top: 8,
					bottom: 0,
					left: 24,
					right: 24,
				},
			},
			props: {
				childrenIds: [
					"block_3vaa8gDUB8D7gyYzTpKGzN",
					"block_AqF4QDsGrVadYYB9yJywmL",
					"block_F9xbhy5omsUCjE9MuDfxJ5",
				],
			},
		},
	},
	block_JihqAfW5u5wR8oQUJFP5kE: {
		type: "Text",
		data: {
			style: {
				color: null,
				backgroundColor: null,
				fontSize: 14,
				fontFamily: null,
				fontWeight: "normal",
				textAlign: "left",
				padding: {
					top: 16,
					bottom: 16,
					left: 24,
					right: 24,
				},
			},
			props: {
				text: "Need help? Just reply to this email.",
			},
		},
	},
};

export default RESERVATION_REMINDER;
