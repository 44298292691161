import { useKeenSlider } from "keen-slider/react";
import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { WheelControls } from "@utils/scrollwheel-plugin/ScrollWheelPlugin.ts";
import { ThumbnailPlugin } from "@components/views/(app)/solutions/CompanyReviewSection/ThumbnailPlugin.ts";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { cn } from "@core/utils";
import CarouselButtons from "@components/views/(app)/solutions/CompanyReviewSection/CarouselButtons.tsx";
import CarouselPrevButton from "@components/views/(app)/solutions/CompanyReviewSection/CarouselPreviousButton.tsx";
import CompanyReviewCard from "@components/views/(app)/solutions/CompanyReviewSection/CompanyReviewCard.tsx";
import CarouselNextButton from "@components/views/(app)/solutions/CompanyReviewSection/CarouselNextButton.tsx";
import Text from "@components/elements/Text";
import Image from "@components/elements/Image";

interface SliderItem {
	title: string;
	content: string;
	companyName: string;
	companyLogo?: string;
	companyAlt?: string;
	writer: string;
	date?: string;
}

interface KeenSliderProps {
	data: SliderItem[];
	solutionType?: "enterprise" | "startup" | "employability" | "researcher";
}

const CompanyReviewSection: React.FC<KeenSliderProps> = ({
	data,
	solutionType = "enterprise",
}) => {
	let titleColor;

	switch (solutionType) {
		case "enterprise": {
			titleColor = "text-primary-400";
			break;
		}
		case "startup": {
			titleColor = "text-primary-400";
			break;
		}
		case "employability": {
			titleColor = "text-tertiary-900";
			break;
		}
		case "researcher": {
			titleColor = "text-secondary-900";
			break;
		}
	}

	const { t } = useTranslation("solutions-page");
	const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
		{
			initial: 0,
			loop: false,
			slides: { perView: 1, spacing: 30 },
		},
		[WheelControls]
	);
	const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
		{
			initial: 0,
			loop: true,
			slides: {
				perView: 1.5,
				spacing: 10,
				origin: "center",
			},
			breakpoints: {
				"(min-width: 418px)": {
					slides: { perView: 2, spacing: 10, origin: "center" },
				},
				"(min-width: 518px)": {
					slides: { perView: 2.5, spacing: 10, origin: "center" },
				},
				"(min-width: 720px)": {
					slides: { perView: 3.5, spacing: 10, origin: "center" },
				},
				"(min-width: 1280px)": {
					slides: { perView: 4.5, spacing: 10, origin: "center" },
				},
			},
		},
		[WheelControls, ThumbnailPlugin(instanceRef)]
	);

	const [activeIndex, setActiveIndex] = useState<number | undefined>(0);

	useEffect(() => {
		if (instanceRef.current) {
			instanceRef.current?.on("slideChanged", () => {
				setActiveIndex(instanceRef.current?.track.details.abs);
			});
		}
	}, [instanceRef]);

	const handlePrevious = () => {
		if (instanceRef) instanceRef.current?.prev();
	};

	const handleNext = () => {
		if (instanceRef) instanceRef.current?.next();
	};
	return (
		<div className="flex flex-col gap-5">
			<SectionWrapper
				className={cn(
					"flex w-full flex-col gap-[30px]",
					"margin-divider-medium"
				)}
			>
				<Text
					className="displaySmall text-center"
					highlightStyle={cn("text-primary-400 font-semibold", titleColor)}
					text={t("company-review-section.title")}
				/>
				<div className="flex w-full flex-col items-center _tablet_lg:flex-row">
					<CarouselButtons
						wrapperStyle="mb-[0]  flex max-w-[700px]"
						disablePrevButton={activeIndex === 0}
						disableNextButton={activeIndex === data.length - 1}
						handleNextButtonClick={handleNext}
						handlePrevButtonClick={handlePrevious}
					/>
					<CarouselPrevButton
						wrapperStyle="hidden _tablet_lg:flex"
						handlePrevButtonClick={handlePrevious}
						disablePrevButton={activeIndex === 0}
					/>
					<div
						ref={sliderRef}
						className="keen-slider mx-20 flex items-start justify-start p-2 px-[20px] pl-0 pr-0 _tablet:items-center _tablet:justify-start"
					>
						{data.map((item, index) => (
							<div
								key={index}
								className="keen-slider__slide flex items-center  justify-center py-5 "
							>
								<CompanyReviewCard {...item} />
							</div>
						))}
					</div>
					<CarouselNextButton
						wrapperStyle="hidden _tablet_lg:flex"
						handleNextButtonClick={handleNext}
						disableNextButton={activeIndex === data.length - 1}
					/>
				</div>
			</SectionWrapper>

			<div ref={thumbnailRef} className="keen-slider thumbnail">
				{data.map((item, index) => (
					<div
						key={index}
						className="keen-slider__slide relative flex flex-col gap-[20px]"
					>
						<div className="flex gap-[10px]">
							<div className=" h-full w-[2px]  rounded bg-primary-50" />
							<div className="relative flex h-[111px] w-full items-center justify-center rounded-[10px] bg-primary-100">
								{item.companyLogo ? (
									<Image
										className="h-[70%] w-full object-contain"
										src={`/images/reviews/${item.companyLogo}.webp`}
										alt={item.companyLogo}
										width={200}
										height={200}
									/>
								) : (
									<h2 className="labelLarge font-bold text-text-500">
										{item.companyAlt}
									</h2>
								)}
							</div>
						</div>
						{activeIndex === index && (
							<div className=" h-1 w-[_calc(100%_-_10px)] self-end rounded bg-secondary-900" />
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default CompanyReviewSection;
