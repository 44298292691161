// Tooltip.jsx
import React, { useState, useRef, useEffect } from "react";
import { cn } from "@core/utils"; // Ensure this utility is available or replace with classnames package

export const Tooltip = ({ title, description }) => {
	const [visible, setVisible] = useState(false);
	const tooltipRef = useRef(null);
	const buttonRef = useRef(null);
	const [position, setPosition] = useState("top"); // Possible values: 'top', 'bottom', 'left', 'right'

	// Function to determine the best position for the tooltip
	const determinePosition = () => {
		const tooltip = tooltipRef.current as HTMLDivElement;
		const button = buttonRef.current as HTMLButtonElement;

		if (!tooltip || !button) return;

		const tooltipRect = tooltip.getBoundingClientRect();
		const buttonRect = button.getBoundingClientRect();

		const spaceAbove = buttonRect.top;
		const spaceBelow = window.innerHeight - buttonRect.bottom;
		const spaceLeft = buttonRect.left;
		const spaceRight = window.innerWidth - buttonRect.right;

		// Default position is 'top'
		let newPosition = "top";

		if (
			spaceAbove < tooltipRect.height + 8 &&
			spaceBelow > tooltipRect.height + 8
		) {
			newPosition = "bottom";
		}

		if (
			spaceRight < tooltipRect.width + 8 &&
			spaceLeft > tooltipRect.width + 8
		) {
			newPosition = "left";
		} else if (
			spaceLeft < tooltipRect.width + 8 &&
			spaceRight > tooltipRect.width + 8
		) {
			newPosition = "right";
		}

		setPosition(newPosition);
	};

	useEffect(() => {
		if (visible) {
			determinePosition();
			window.addEventListener("resize", determinePosition);
			window.addEventListener("scroll", determinePosition, true);
		} else {
			window.removeEventListener("resize", determinePosition);
			window.removeEventListener("scroll", determinePosition, true);
		}

		return () => {
			window.removeEventListener("resize", determinePosition);
			window.removeEventListener("scroll", determinePosition, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible]);

	return (
		<div className="relative flex items-center">
			{/* Tooltip Icon */}
			<button
				type="button"
				ref={buttonRef}
				className="text-gray-500 hover:text-gray-700 focus:outline-none"
				onMouseEnter={() => {
					setVisible(true);
				}}
				onMouseLeave={() => {
					setVisible(false);
				}}
				onFocus={() => {
					setVisible(true);
				}}
				onBlur={() => {
					setVisible(false);
				}}
				aria-describedby="tooltip-content"
			>
				{/* Information Icon (SVG) */}
				<svg
					className="h-5 w-5"
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					viewBox="0 0 20 20"
				>
					<path
						fillRule="evenodd"
						d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-9-3a1 1 0 112 0v4a1 1 0 11-2 0V7zm1 8a1 1 0 100-2 1 1 0 000 2z"
						clipRule="evenodd"
					/>
				</svg>
			</button>

			{/* Tooltip Card */}
			{visible && (
				<div
					ref={tooltipRef}
					id="tooltip-content"
					role="tooltip"
					className={cn(
						"absolute z-50 w-64 p-4 text-sm text-gray-700 bg-white border border-gray-200 rounded shadow-lg transition-opacity duration-200  pointer-events-none",
						"opacity-0",
						visible && "opacity-100",
						{
							"bottom-full mb-2 left-1/2 transform -translate-x-1/2":
								position === "top",
							"top-full mt-2 left-1/2 transform -translate-x-1/2":
								position === "bottom",
							"right-0 mr-2 top-1/2 transform -translate-y-1/2":
								position === "left",
							"left-0 ml-2 top-1/2 transform -translate-y-1/2":
								position === "right",
						}
					)}
				>
					<h4 className="mb-2 text-base font-semibold">{title}</h4>
					<p>{description}</p>
				</div>
			)}
		</div>
	);
};
