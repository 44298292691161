import type React from "react";
import { cn } from "@core/utils";

function CarouselButtons({
	prevButtonRef,
	nextButtonRef,
	handlePrevButtonClick,
	handleNextButtonClick,
	disablePrevButton,
	disableNextButton,
	wrapperStyle,
}: {
	prevButtonRef?: React.RefObject<HTMLButtonElement>;
	nextButtonRef?: React.RefObject<HTMLButtonElement>;
	handlePrevButtonClick?: () => void;
	handleNextButtonClick?: () => void;
	disablePrevButton?: boolean;
	disableNextButton?: boolean;
	wrapperStyle?: string;
}) {
	return (
		<div
			className={cn(
				"mb-4 flex w-full flex-row  justify-between _tablet_lg:hidden",
				wrapperStyle
			)}
		>
			<button
				disabled={disablePrevButton}
				className="group grid h-10 w-10 rotate-180 place-items-center"
				ref={prevButtonRef}
				onClick={handlePrevButtonClick}
			>
				<svg
					className={cn(
						disablePrevButton
							? "stroke-primary-80"
							: "stroke-primary-200 transition group-hover:stroke-primary-300"
					)}
					width="16"
					height="25"
					viewBox="0 0 16 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M2 2.00004L13.6667 12.7278L2 23.4556"
						strokeWidth="3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
			<button
				disabled={disableNextButton}
				className="group grid h-10 w-10 place-items-center"
				ref={nextButtonRef}
				onClick={handleNextButtonClick}
			>
				<svg
					className={cn(
						disableNextButton
							? "stroke-primary-80"
							: "stroke-primary-200 transition group-hover:stroke-primary-300"
					)}
					width="16"
					height="25"
					viewBox="0 0 16 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M2 2.00004L13.6667 12.7278L2 23.4556"
						strokeWidth="3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
		</div>
	);
}

export default CarouselButtons;
