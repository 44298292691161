import React from "react";
import { cn } from "@core/utils";
import { Tooltip } from "@pages/(startgreen)/views/(dashboard)/Tooltip.tsx";

export const StatsCard = ({
	title,
	value,
	items = [],
	description,
	className = "",
	titleClassName = "",
	valueClassName = "",
	itemsClassName = "",
	itemLabelClassName = "",
	descriptionClassName = "",
	tooltipTitle = "",
	tooltipDescription = "",
}) => (
	<div
		className={`p-6 rounded-xl border border-startgreen_primary-100/20 shadow-lg transform hover:scale-[1.01] transition duration-300 ease-in-out ${className}`}
	>
		{(tooltipTitle || tooltipDescription) && (
			<Tooltip title={tooltipTitle} description={tooltipDescription} />
		)}

		<h2 className={cn("text-xl font-semibold text-green-800", titleClassName)}>
			{title}
		</h2>
		<p
			className={cn(
				"text-4xl font-extrabold text-green-900 mt-2",
				valueClassName
			)}
		>
			{value}
		</p>
		{items.length > 0 && (
			<ul className="mt-4 space-y-1">
				{items.map((item, index) => (
					<li
						key={index}
						className={cn(
							"flex flex-col text-xl font-semibold text-green-700",
							itemLabelClassName
						)}
					>
						{item.label}
						<span
							className={cn(
								"text-4xl font-bold text-green-900",
								itemsClassName
							)}
						>
							{item.value}
						</span>
					</li>
				))}
			</ul>
		)}
		{description && (
			<div className={cn("text-xl text-gray-600 mt-4", descriptionClassName)}>
				{description}
			</div>
		)}
	</div>
);

const StatsCards = ({ statistics, isLoading, isError, currentPhase }) => {
	if (isLoading) {
		return (
			<p className="text-center text-gray-500">
				Chargement des statistiques...
			</p>
		);
	}

	if (isError) {
		return (
			<p className="text-center text-red-500">
				Erreur lors du chargement des statistiques. Veuillez réessayer plus
				tard.
			</p>
		);
	}

	return (
		<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
			<StatsCard
				title="Statistiques des candidats"
				items={[
					{
						label: "Total des candidats",
						value: statistics?.totalUsers,
					},
					{
						label: "Candidats acceptés",
						value: statistics?.acceptedUsers,
					},
				]}
				className="bg-startgreen_primary-100/20"
			/>
			<StatsCard
				title="Statistiques des visiteurs"
				items={[
					{
						label: "Total des visiteurs",
						value: statistics?.totalVisitors,
					},
					// {
					// 	label: "Visiteurs acceptés",
					// 	value: statistics?.acceptedVisitors,
					// },
				]}
				className="bg-startgreen_primary-100/20"
			/>
			<hr className="my-4 w-[80%] self-center justify-self-center col-span-full border-gray-100/30" />

			<StatsCard
				title="Total des candidates féminines"
				value={statistics?.totalFemaleCandidates || 0}
				className="bg-startgreen_tertiary-100/10 border-startgreen_tertiary-100/30"
				titleClassName={"text-startgreen_tertiary-100"}
				valueClassName={"text-startgreen_tertiary-100"}
			/>
			<StatsCard
				title="Candidates féminines acceptées"
				value={statistics?.acceptedFemaleCandidates}
				className="bg-startgreen_tertiary-100/10 border-startgreen_tertiary-100/30"
				titleClassName={"text-startgreen_tertiary-100"}
				valueClassName={"text-startgreen_tertiary-100"}
			/>

			<StatsCard
				className="bg-startgreen_tertiary-100/10 border-startgreen_tertiary-100/30"
				titleClassName={"text-startgreen_tertiary-100"}
				valueClassName={"text-startgreen_tertiary-100"}
				itemsClassName={"text-startgreen_tertiary-100"}
				itemLabelClassName={"text-black-500/80"}
				title="Statistiques de participation féminine"
				items={[
					{
						label: "Tous les candidats",
						value: `${(statistics?.femaleParticipationRatio || 0).toFixed(2)}%`,
					},
				]}
			/>

			<StatsCard
				className="bg-startgreen_tertiary-100/10 border-startgreen_tertiary-100/30"
				titleClassName={"text-startgreen_tertiary-100"}
				valueClassName={"text-startgreen_tertiary-100"}
				itemsClassName={"text-startgreen_tertiary-100"}
				itemLabelClassName={"text-black-500/80"}
				title="Statistiques d'acceptation féminine"
				items={[
					{
						label: "Tous les candidats",
						value: `${(statistics?.globalFemaleAcceptanceRatio || 0).toFixed(2)}%`,
					},
					{
						label: "Candidates féminines",
						value: `${(statistics?.femaleAcceptanceRatio || 0).toFixed(2)}%`,
					},
				]}
			/>

			<hr className="my-4 w-[80%] self-center justify-self-center col-span-full border-gray-100/30" />

			<StatsCard
				title="Régions uniques"
				value={statistics?.uniqueRegions || 0}
				className="bg-startgreen_primary-100/20"
			/>

			<StatsCard
				title="Phase actuelle"
				value={currentPhase?.name || "N/A"}
				description={
					<>
						<p>
							<span className={"font-bold"}>Date de début : </span>
							{currentPhase?.startDate
								? new Date(currentPhase.startDate).toLocaleDateString()
								: "N/A"}
						</p>
						<p>
							<span className={"font-bold"}>Date de fin : </span>
							{currentPhase?.endDate
								? new Date(currentPhase.endDate).toLocaleDateString()
								: "N/A"}
						</p>
					</>
				}
				className="bg-startgreen_primary-100/20"
			/>
		</div>
	);
};

export default StatsCards;
