import type { TFunction } from "i18next";
import type React from "react";
import { cn } from "@core/utils";
import { useTranslation } from "react-i18next";
import Text from "@components/elements/Text";

export interface OnlineCardProps {
	t: TFunction<string, undefined>;
	title?: string;
	description?: string;
}

export interface PresentialCardProps {
	t: TFunction<string, undefined>;
	title?: string;
	description?: string;
}

export const OnlineCard: React.FC<OnlineCardProps> = ({ t }) => (
	<div className="relative flex min-h-[280px]  w-[100%] flex-col items-start  self-stretch rounded-md bg-primary-100 p-[45px] _tablet_lg:max-w-[420px] _laptop:max-w-[450px] _large:h-[530px] _large:max-w-[550px]">
		<div
			className={cn(
				"flex flex-col gap-[25px]",
				"mb-[120px] px-[1.7vw] py-[5px] sm:max-w-7xl _tablet_lg:mb-[10px] _tablet_lg:px-0"
			)}
		>
			<Text
				tag="h2"
				styled={false}
				className={cn(
					"headlineLarge text-start font-semibold text-text-black",
					"_tablet:px-[1.7vw] sm:max-w-7xl"
				)}
				text={t("flexibility-section.online-card.title")}
			/>

			<Text
				tag="p"
				className={cn(
					"bodyText text-start text-text-700",
					"_tablet:px-[1.7vw] sm:max-w-7xl"
				)}
				text={t("flexibility-section.online-card.paragraph")}
			/>
		</div>
	</div>
);

export const ClassicCard: React.FC<PresentialCardProps> = ({ t }) => (
	<div className="relative flex  w-full flex-col gap-0 self-start">
		<span
			className={cn(
				"mb-[60px] hidden pl-[55px] pt-10 sm:max-w-7xl _tablet_lg:block"
			)}
		>
			<Text
				tag="h2"
				className={cn("displayLarge", "pl-[1.7vw] text-center md:text-left")}
				highlightStyle="font-semibold text-secondary-900"
				text={t("flexibility-section.title")}
			/>
		</span>
		<div className="relative mb-[-60px] flex  min-h-[230px]  w-[100%]  flex-col items-start rounded-md bg-secondary-200 p-[55px] _tablet:min-h-[290px]  _tablet_lg:min-w-[380px] ">
			<div className="flex flex-col gap-[25px]">
				<Text
					tag="h2"
					styled={false}
					className={cn(
						"headlineLarge text-start font-semibold text-text-black",
						"_tablet:px-[1.7vw] sm:max-w-7xl"
					)}
					text={t("flexibility-section.classic-card.title")}
				/>

				<Text
					tag="p"
					className={cn(
						"bodyText text-start text-text-700",
						"_tablet:px-[1.7vw] sm:max-w-7xl"
					)}
					text={t("flexibility-section.classic-card.paragraph")}
				/>
			</div>
			<svg
				className="absolute left-[22%] top-[-162px] rotate-90 _phone:left-[25%] _tablet_lg:left-[-138px] _tablet_lg:top-2  _tablet_lg:rotate-0"
				width="148"
				height="195"
				viewBox="0 0 148 195"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M83.5 176.5C37.3842 176.5 0 139.116 0 93C0 46.8842 37.3842 9.49997 83.5 9.49997C90.7182 9.49997 99.2113 10.7755 104.403 12.1378C109.594 13.5 116.975 12.1378 117 0C117 0 116.697 140.013 116.839 182.227C117.188 185.577 117.134 189.472 117 193.5C116.92 195.897 116.869 191.342 116.839 182.227C116.018 174.352 112.972 169.492 103.5 173C96 175.5 92 176.5 83.5 176.5Z"
					fill="#FCFBF7"
				/>
				<circle cx="84" cy="94" r="64" fill="#F3EDDB" />
				<path
					d="M64.6055 49.3938H131.515C138.302 47.9392 139.272 41.1514 139.272 41.1514V152.667C139.272 152.667 140.727 142.97 118.424 142.97H65.0903L64.6055 49.3938Z"
					fill="#F3EDDB"
				/>
			</svg>
		</div>
	</div>
);

function FlexibilityCards() {
	const { t } = useTranslation("home-page");
	return (
		<div className="flex w-full flex-col gap-6 _tablet_lg:flex-row">
			<OnlineCard t={t} />
			<ClassicCard t={t} />
		</div>
	);
}

export default FlexibilityCards;
