import { useDebounceFn, useDocumentVisibility, useUpdateEffect } from "ahooks";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

export const useInvalidateOnVisibility = (queryKeys = []) => {
	const queryClient = useQueryClient();
	const documentVisibility = useDocumentVisibility();

	const { run: debouncedInvalidateQueries } = useDebounceFn(
		() => {
			queryKeys.forEach((key) => queryClient.invalidateQueries(key));
		},
		{ wait: 1000 }
	);

	useUpdateEffect(() => {
		if (documentVisibility === "visible") {
			debouncedInvalidateQueries();
		}
	}, [documentVisibility, debouncedInvalidateQueries]);

	// Function to manually refetch specific queries or all provided queries
	const refetchQueries = (key = null) => {
		if (key) {
			void queryClient.refetchQueries(key);
		} else {
			queryKeys.forEach((key) => queryClient.refetchQueries(key));
		}
	};

	return { refetchQueries };
};

export default useInvalidateOnVisibility;
