import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { useTranslation } from "react-i18next";
import axios from "axios";
import LoginPage from "@pages/(startgreen)/views/(dashboard)/LoginPage";

const getStartGreen = async () => {
	const response = await axios.get("http://localhost:4400/v1");
	return response.data;
};
const Page = () => {
	const { t: tMeta } = useTranslation("metadata");
	return (
		<>
			<Meta
				title={"StartGreen by IPTIC | Login"}
				template={"%s | Coachini"}
				description={tMeta("about-page.description")}
				keywords={["startgreen", "start-green", "STARTGREEN", "#STARTGREEN"]}
				robots="index,follow"
				ogTitle={tMeta("about-page.title")}
				ogDescription={tMeta("about-page.description")}
				className={"bg-[#F3F3F3]"}
			/>

			<LoginPage />
		</>
	);
};

export const Route = createFileRoute("/(main)/startgreen-by-iptic/login/")({
	component: Page,
});
