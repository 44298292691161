import type { PricingPlanProps } from "@components/views/(app)/solutions/PackSection/PackSection.types.ts";

export const startupPlans: PricingPlanProps[] = [
	{
		en: {
			title: "Discovery Package",
			sessions: "1",
			description: "Sans réduction",
			price: "$19 / Month",
			features: [
				{
					// featureHighlight: "5",
					featureTitle: "One To One",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "One To Many (Max 10 People)",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "Supervisor dashboard access",
				},
			],
			buttonText: "Choose plan",
		},
		fr: {
			title: "Formule Découverte",
			sessions: "1",
			description: "-",
			price: "$19 / Month",
			features: [
				{
					// featureHighlight: "5",
					featureTitle: "One To One",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "One To Many ( Max 10 Personnes)",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "Accès dashboard superviseur",
				},
			],
			buttonText: "Choose plan",
		},
	},

	{
		en: {
			title: "Standard Package",
			sessions: "3",
			description: "-",
			price: "$19 / Month",
			features: [
				{
					// featureHighlight: "5",
					featureTitle: "One To One",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "One To Many (Max 10 People)",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "Supervisor dashboard access ",
				},
			],
			buttonText: "Choose plan",
		},
		fr: {
			title: "Formule Standard",
			sessions: "3",
			description: "-",
			price: "$19 / Month",
			features: [
				{
					// featureHighlight: "5",
					featureTitle: "One To One",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "One To Many ( Max 10 Personnes)",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "Accès dashboard superviseur",
				},
			],
			buttonText: "Choose plan",
		},
	},

	{
		isPopular: true,
		discount: 5,
		en: {
			title: "Advanced Package",
			sessions: "5",
			description: "Sans réduction",
			price: "$19 / Month",
			features: [
				{
					// featureHighlight: "5",
					featureTitle: "One To One",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "One To Many (Max 10 People)",
				},
				{
					featureHighlight: "illimité",
					featureTitle: "Supervisor dashboard access",
				},
			],
			buttonText: "Choose plan",
		},
		fr: {
			title: "Formule Avancée",
			sessions: "5",
			description: "Sans réduction",
			price: "$19 / Month",
			features: [
				{
					// featureHighlight: "5",
					featureTitle: "One To One",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "One To Many ( Max 10 Personnes)",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "Accès dashboard superviseur",
				},
			],
			buttonText: "Choose plan",
		},
	},

	{
		discount: 7,
		isGolden: true,
		en: {
			title: "Supreme Package",
			sessions: "+5",
			description: "Sans réduction",
			price: "$19 / Month",
			features: [
				{
					// featureHighlight: "5",
					featureTitle: "One To One",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "One To Many (Max 10 People)",
				},
				{
					featureHighlight: "illimité",
					featureTitle: "Supervisor dashboard access",
				},
			],
			buttonText: "Choose plan",
		},
		fr: {
			title: "Formule Suprême",
			sessions: "+5",
			description: "Sans réduction",
			price: "$19 / Month",
			features: [
				{
					// featureHighlight: "5",
					featureTitle: "One To One",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "One To Many ( Max 10 Personnes)",
				},
				{
					// featureHighlight: "illimité",
					featureTitle: "Accès dashboard superviseur",
				},
			],
			buttonText: "Choose plan",
		},
	},
];
