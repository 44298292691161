import React from "react";
import { cn } from "@core/utils";
import Image from "@components/elements/Image";
import Text from "@components/elements/Text";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

function HeaderSection() {
	const { t } = useTranslation("startgreen-page");
	const sponsorImages = [
		{
			name: "coachini-logo@2x",
			width: "70%",
		},
		{
			name: "iptic@2x",
			width: "70%",
		},
		{
			name: "koica-logo@2x",
			width: "70%",
		},
		{
			name: "koica-logo@2x",
			width: "70%",
		},
		{
			name: "oit-logo@2x",
			width: "70%",
		},
		{
			name: "takwin-logo@2x",
			width: "70%",
		},
	];

	return (
		<div
			className={cn(
				"flex flex-col rounded-md p-10 bg-white gap-[20px] text-justify"
			)}
		>
			<div className={"flex flex-col items-center"}>
				<Image
					className="rounded-md aspect-auto flex-shrink-0 "
					width={300}
					height={300}
					src="/images/startgreen/iptic-logo.png"
					alt="IPTIC"
					aria-label="IPTIC"
				/>

				{/*<Text*/}
				{/*	text={t("common.form-title")}*/}
				{/*	className={cn(*/}
				{/*		"text-headlineSmall _tablet:text-headlineMedium _laptop:text-headlineMedium",*/}
				{/*		"font-bold"*/}
				{/*	)}*/}
				{/*	highlightStyle={"text-startgreen_primary-100"}*/}
				{/*/>*/}
			</div>
			<div className={"flex flex-col gap-[20px]"}>
				<h2 className={"font-bold text-[13px] text-[#6F6C90]"}>
					StartGreen by IPTIC est un programme destiné aux jeunes (&lt; 35 ans)
					et aux femmes (de tout âge) futurs entrepreneurs du gouvernorat de
					Sousse qui souhaitent développer un projet dans le domaine de la
					GreenTech.
				</h2>

				{/*-----------------*/}
				<div className={"space-y-2"}>
					<h2 className={"font-regular text-[13px] text-[#6F6C90]"}>
						Ce programme propose :
					</h2>
					<ol className="list-disc pl-[20px] font-regular text-[13px] text-[#6F6C90] space-y-2">
						<li>
							3 jours de sensibilisation pour renforcer vos connaissances sur la
							GreenTech, les statuts des entreprises, le label Start-up Act…
							animée par des coachs qualifiés et spécialisés dans la GreenTech
							et le domaine des startups.
						</li>
						<li>
							Un hackathon en présence des institutions financières, incubateurs
							et professionnels du secteur.
						</li>
						<li>
							Une incubation de 6 mois incluant hébergement, formations et mise
							en contact avec les financeurs pour les 25 gagnants au hackathon.
						</li>
					</ol>
				</div>
				{/*-----------------*/}
				<h2
					className={
						"font-bold text-[13px] text-[#6F6C90] flex flex-col gap-[7px]"
					}
				>
					Pour en savoir plus:{" "}
					<a
						className={"text-[#005ee0]"}
						href={"https://www.coachini.net/startgreen-by-iptic"}
					>
						www.coachini.net/startgreen-by-iptic
					</a>
					<a
						className={"text-[#005ee0]"}
						href={"https://www.facebook.com/IPTICTunisie"}
					>
						https://www.facebook.com/IPTICTunisie
					</a>
				</h2>

				{/*<Image*/}
				{/*	className="rounded-lg aspect-auto w-full h-full object-contain hidden md:block"*/}
				{/*	width={68}*/}
				{/*	height={68}*/}
				{/*	src={"images/startgreen/sponsor-list.jpg"}*/}
				{/*	alt={"Startgreen"}*/}
				{/*	aria-label="StartGreen"*/}
				{/*/>*/}

				{/*<Marquee*/}
				{/*	className="marquee flex md:!hidden"*/}
				{/*	pauseOnHover={false}*/}
				{/*	pauseOnClick={false}*/}
				{/*	speed={80}*/}
				{/*>*/}
				{/*	<div*/}
				{/*		className={cn(*/}
				{/*			"flex flex-col items-center justify-center",*/}
				{/*			"bg-startgreen_primary-100/5 p-[20px]",*/}
				{/*			" mx-[20px] h-[180px] min-w-[230px] max-w-max rounded-lg"*/}
				{/*		)}*/}
				{/*	>*/}
				{/*		<h2 className={"text-black"}>Projet:</h2>*/}

				{/*		<Image*/}
				{/*			loading="lazy"*/}
				{/*			className={cn("aspect-auto rounded-none object-scale-down")}*/}
				{/*			width={110}*/}
				{/*			height={110}*/}
				{/*			src={`/images/startgreen/sponsors/iptic@2x.png`}*/}
				{/*		/>*/}
				{/*	</div>*/}
				{/*	/!*-------------------------------------------*!/*/}
				{/*	<div*/}
				{/*		className={cn(*/}
				{/*			"flex flex-col items-center justify-center",*/}
				{/*			"bg-startgreen_primary-100/5 p-[20px]",*/}
				{/*			"mx-[20px] h-[180px] min-w-[230px] rounded-lg"*/}
				{/*		)}*/}
				{/*	>*/}
				{/*		<h2 className={"text-black"}>Appuyé par:</h2>*/}

				{/*		<div className={"flex flex-row  items-center justify-center gap-4"}>*/}
				{/*			<Image*/}
				{/*				loading="lazy"*/}
				{/*				className={cn("aspect-square rounded-none object-scale-down")}*/}
				{/*				width={110}*/}
				{/*				height={110}*/}
				{/*				src={`/images/startgreen/sponsors/takwin-logo@2x.png`}*/}
				{/*			/>*/}
				{/*			<Image*/}
				{/*				loading="lazy"*/}
				{/*				className={cn("aspect-square rounded-none object-scale-down")}*/}
				{/*				width={110}*/}
				{/*				height={110}*/}
				{/*				src={`/images/startgreen/sponsors/tn-flag@2x.png`}*/}
				{/*			/>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*	/!*-------------------------------------------*!/*/}

				{/*	<div*/}
				{/*		className={cn(*/}
				{/*			"flex flex-col items-center justify-center",*/}
				{/*			"bg-startgreen_primary-100/5 p-[20px]",*/}
				{/*			"mx-[20px] h-[180px] min-w-[230px] rounded-lg"*/}
				{/*		)}*/}
				{/*	>*/}
				{/*		<h2 className={"text-black"}>Mise en œuvre par:</h2>*/}

				{/*		<div className={"flex flex-row  items-center justify-center gap-4"}>*/}
				{/*			<Image*/}
				{/*				loading="lazy"*/}
				{/*				className={cn("aspect-square rounded-none object-scale-down")}*/}
				{/*				width={110}*/}
				{/*				height={110}*/}
				{/*				src={`/images/startgreen/sponsors/oit-logo@2x.png`}*/}
				{/*			/>*/}
				{/*			<Image*/}
				{/*				loading="lazy"*/}
				{/*				className={cn("aspect-square rounded-none object-scale-down")}*/}
				{/*				width={110}*/}
				{/*				height={110}*/}
				{/*				src={`/images/startgreen/sponsors/coachini-logo@2x.png`}*/}
				{/*			/>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*	/!*-------------------------------------------*!/*/}

				{/*	<div*/}
				{/*		className={cn(*/}
				{/*			"flex flex-col items-center justify-center",*/}
				{/*			"bg-startgreen_primary-100/5 p-[20px]",*/}
				{/*			" mx-[20px] h-[180px] min-w-[230px] rounded-lg"*/}
				{/*		)}*/}
				{/*	>*/}
				{/*		<h2 className={"text-black"}>Financé par:</h2>*/}

				{/*		<Image*/}
				{/*			loading="lazy"*/}
				{/*			className={cn("aspect-square rounded-none object-scale-down")}*/}
				{/*			width={120}*/}
				{/*			height={120}*/}
				{/*			src={`/images/startgreen/sponsors/koica-logo@2x.png`}*/}
				{/*		/>*/}
				{/*	</div>*/}
				{/*	/!*-------------------------------------------*!/*/}
				{/*	/!*{sponsorImages.map((logo, index) => (*!/*/}
				{/*	/!*	<div*!/*/}
				{/*	/!*		key={index}*!/*/}
				{/*	/!*		className={cn(*!/*/}
				{/*	/!*			"flex flex-col items-center justify-center",*!/*/}
				{/*	/!*			"bg-[#EFEFEF] p-[20px]",*!/*/}
				{/*	/!*			" mx-[20px] aspect-[16/9] h-[120px] max-w-[180px] rounded-lg"*!/*/}
				{/*	/!*		)}*!/*/}
				{/*	/!*	>*!/*/}
				{/*	/!*		<h2>Projet:</h2>*!/*/}
				{/*	/!*		<Image*!/*/}
				{/*	/!*			loading="lazy"*!/*/}
				{/*	/!*			className={cn(*!/*/}
				{/*	/!*				"aspect-square h-full w-full rounded-none object-scale-down"*!/*/}
				{/*	/!*			)}*!/*/}
				{/*	/!*			width={120}*!/*/}
				{/*	/!*			height={120}*!/*/}
				{/*	/!*			src={`/images/startgreen/sponsors/${logo.name}.png`}*!/*/}
				{/*	/!*			alt={logo.name}*!/*/}
				{/*	/!*			aria-label={logo.name}*!/*/}
				{/*	/!*		/>*!/*/}
				{/*	/!*	</div>*!/*/}
				{/*	/!*))}*!/*/}
				{/*</Marquee>*/}

				<h2 className={"font-bold text-[13px] text-[#6F6C90]"}>
					Ce projet est mis en œuvre par l’OIT (Organisation Internationale du
					Travail) à travers le projet IPTIC (Impact des politiques et création
					d'emplois dans les TIC en Tunisie) et financée par KOICA TUNISIA
					OFFICE.
				</h2>
			</div>
		</div>
	);
}

export default HeaderSection;
