import React from "react";
import {
	Box,
	Drawer,
	Tab,
	Tabs,
	TextField,
	Button,
	Stack,
	Typography,
} from "@mui/material";
import {
	useInspectorDrawerOpen,
	useSelectedSidebarTab,
	setSidebarTab,
} from "../../documents/editor/EditorContext";
import ConfigurationPanel from "./ConfigurationPanel";
import StylesPanel from "./StylesPanel";

export const INSPECTOR_DRAWER_WIDTH = 320;

export default function InspectorDrawer({ dynamicValues, setDynamicValues }) {
	const selectedSidebarTab = useSelectedSidebarTab();
	const inspectorDrawerOpen = useInspectorDrawerOpen();

	const handleAddDynamicField = () => {
		setDynamicValues([...dynamicValues, { name: "", value: "" }]);
	};

	const handleDynamicFieldChange = (index, field, value) => {
		const updatedValues = dynamicValues.map((dynamicField, idx) =>
			idx === index ? { ...dynamicField, [field]: value } : dynamicField
		);
		setDynamicValues(updatedValues);
	};

	const renderCurrentSidebarPanel = () => {
		switch (selectedSidebarTab) {
			case "block-configuration":
				return (
					<ConfigurationPanel
						dynamicValues={dynamicValues}
						setDynamicValues={setDynamicValues}
					/>
				);
			case "styles":
				return <StylesPanel />;
			default:
				return null;
		}
	};

	return (
		<Drawer
			variant="persistent"
			anchor="right"
			open={inspectorDrawerOpen}
			sx={{
				width: inspectorDrawerOpen ? INSPECTOR_DRAWER_WIDTH : 0,
			}}
		>
			<Box
				sx={{
					width: INSPECTOR_DRAWER_WIDTH,
					height: 49,
					borderBottom: 1,
					borderColor: "divider",
				}}
			>
				<Box px={2}>
					<Tabs
						value={selectedSidebarTab}
						onChange={(_, v) => setSidebarTab(v)}
					>
						<Tab value="styles" label="Styles" />
						<Tab value="block-configuration" label="Inspect" />
					</Tabs>
				</Box>
			</Box>
			<Box
				sx={{
					width: INSPECTOR_DRAWER_WIDTH,
					height: "calc(100% - 49px)",
					overflow: "auto",
				}}
			>
				{renderCurrentSidebarPanel()}

				{/* Dynamic Fields Section */}
				<Box p={2}>
					<Typography variant="h6" gutterBottom>
						Dynamic Fields
					</Typography>
					<Stack spacing={2}>
						{dynamicValues.map((dynamicField, index) => (
							<Box key={index}>
								<TextField
									label="Field Name"
									value={dynamicField.name}
									onChange={(e) =>
										handleDynamicFieldChange(index, "name", e.target.value)
									}
									fullWidth
									margin="dense"
								/>
								<TextField
									label="Field Value"
									value={dynamicField.value}
									onChange={(e) =>
										handleDynamicFieldChange(index, "value", e.target.value)
									}
									fullWidth
									margin="dense"
								/>
							</Box>
						))}
						<Button
							variant="contained"
							color="primary"
							onClick={handleAddDynamicField}
						>
							Add Dynamic Field
						</Button>
					</Stack>
				</Box>
			</Box>
		</Drawer>
	);
}
