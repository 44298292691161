import CryptoJS from "crypto-js";
import { appConfig } from "@config/appConfig.ts";

const encryptToken = (data) => {
	return CryptoJS.AES.encrypt(
		data,
		appConfig.authTokenEncryptionKey
	).toString();
};

const decryptToken = (encryptedData) => {
	try {
		const decryptedBytes = CryptoJS.AES.decrypt(
			encryptedData,
			appConfig.authTokenEncryptionKey
		);
		const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
		return JSON.parse(decryptedString);
	} catch {
		// console.error("Error decrypting data:", error);
		return null;
	}
};

const setCookie = (name, value, options = {}) => {
	if (!name) return;
	let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
	if (options.expires instanceof Date) {
		cookieString += `; expires=${options.expires.toUTCString()}`;
	}
	cookieString += `; path=/`;
	if (options.domain) {
		cookieString += `; domain=${options.domain}`;
	}
	if (options.secure && window.location.protocol === "https:") {
		cookieString += "; secure";
	}
	document.cookie = cookieString;
};

const getCookie = (name) => {
	if (!name) return null;
	const cookies = document.cookie.split(";");
	for (const cookie of cookies) {
		const trimmed = cookie.trim();
		if (trimmed.startsWith(`${encodeURIComponent(name)}=`)) {
			return decodeURIComponent(trimmed.slice(name.length + 1));
		}
	}
	return null;
};

const deleteCookie = (name) => {
	setCookie(name, "", { expires: new Date(0), secure: true });
};

export { getCookie, deleteCookie, encryptToken, decryptToken, setCookie };
