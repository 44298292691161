import type React from "react";
import { useState } from "react";
import useMediaQuery from "@hooks/useMediaQuery.ts";
import type {
	ExpertItemProps,
	ExpertItemsProps,
} from "@components/views/(app)/home/ExpertSection/ExpertSection.types.ts";
import ExpertItem from "@components/views/(app)/home/ExpertSection/ExpertItem.tsx";

type ExpertListProps = {
	sliderRef: (node: HTMLElement | null) => void;
	expertList: ExpertItemsProps[];
};

const ExpertList: React.FC<ExpertListProps> = ({ sliderRef, expertList }) => {
	const isTablet = useMediaQuery("(min-width: 744px)");

	const [, setSelectedItem] = useState<ExpertItemProps | null>(null);
	const handleItemClick = (item: ExpertItemProps) => {
		if (item.teaserUrl) {
			setSelectedItem(item);
			//toggle modal
		}
	};
	return (
		<>
			{!isTablet && (
				<div ref={sliderRef} className="keen-slider">
					{expertList.map((parent) =>
						parent.children.map((expert, index) => (
							<div key={index} className="keen-slider__slide flex-col gap-5">
								<ExpertItem
									onClick={() => {
										handleItemClick(expert);
									}}
									name={expert.name}
									image={expert.image}
									profession={expert.profession}
									teaserUrl={expert.teaserUrl}
								/>
							</div>
						))
					)}
				</div>
			)}

			{isTablet && (
				<div ref={sliderRef} className="keen-slider">
					{expertList.map((parent, index) => (
						<div key={index} className="keen-slider__slide flex flex-col gap-5">
							{parent.children.map((expert, index) => (
								<button
									key={index}
									onClick={() => {
										handleItemClick(expert);
									}}
								>
									<ExpertItem
										onClick={() => {
											handleItemClick(expert);
										}}
										name={expert.name}
										image={expert.image}
										profession={expert.profession}
										teaserUrl={expert.teaserUrl}
									/>
								</button>
							))}
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default ExpertList;
