import { useEffect, useState } from "react";

export function useDynamicGridLayout() {
	const [itemsPerRow, setItemsPerRow] = useState(5);
	useEffect(() => {
		const handleResize = () => {
			const windowWidth = window.innerWidth;
			let newItemsPerRow = 5;
			if (windowWidth <= 600) {
				newItemsPerRow = 1;
			} else if (windowWidth <= 744) {
				newItemsPerRow = 2;
			} else if (windowWidth <= 1180) {
				newItemsPerRow = 2;
			} else if (windowWidth <= 1400) {
				newItemsPerRow = 5;
			} else {
				newItemsPerRow = 5;
			}
			setItemsPerRow(newItemsPerRow);
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return itemsPerRow;
}
