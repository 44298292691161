import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import FloatingInput from "@components/views/(app)/contact/ContactForm/FloatingInput.tsx";
import { useTranslation } from "react-i18next";
import Button from "@components/elements/Buttons/Button.tsx";
import toast, { Toaster } from "react-hot-toast";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import Spinner from "@components/elements/Spinner";
import { appConfig } from "@config/appConfig.ts";

emailjs.init(appConfig.emailJSPublicKey);

const defaultValues = {
	name: "",
	email: "",
	organization: "",
	phone: "",
	message: "",
};

const schema = yup.object().shape({
	name: yup.string().min(1, "required-field").required("required-field"),
	email: yup
		.string()
		.email("invalid-email")
		.min(1, "required-field")
		.required("required-field"),
	organization: yup
		.string()
		.min(1, "required-field")
		.required("required-field"),
	phone: yup.string().min(1, "required-field").required("required-field"),
	// message: yup.string().min(10, "min-length-10").required("required-field"),
});

const ContactForm = () => {
	const [loading, setLoading] = useState(false);
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues,
	});
	const { t } = useTranslation("contact-page");
	const { t: tMisc } = useTranslation("misc");

	const onSubmit = async (data: any) => {
		setLoading(true);
		try {
			await emailjs.send(
				appConfig.emailJSServiceId,
				appConfig.emailJSTemplateId,
				data,
				appConfig.emailJSPublicKey
			);
			toast.success(tMisc("responses.mail-sent"));
			reset(defaultValues);
		} catch {
			toast.error(tMisc("responses.mail-error"));
		} finally {
			setLoading(false);
		}
	};
	return (
		<>
			<Toaster position="bottom-right" reverseOrder={false} />
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="mx-auto flex w-full flex-col gap-[16px]"
			>
				<FloatingInput
					name="name"
					control={control}
					label={t("contact-form.fullName")}
					variant="filled"
					error={errors.name}
				/>
				<FloatingInput
					name="email"
					control={control}
					label={t("contact-form.email")}
					type="email"
					variant="filled"
					error={errors.email}
				/>
				<FloatingInput
					name="organization"
					control={control}
					label={t("contact-form.organisation")}
					variant="filled"
					error={errors.organization}
				/>
				<FloatingInput
					name="phone"
					type="tel"
					control={control}
					label={t("contact-form.phone")}
					variant="filled"
					error={errors.phone}
				/>
				<FloatingInput
					name="message"
					control={control}
					label={t("contact-form.message")}
					variant="filled"
					multiline={true}
					rows={4}
					// error={errors.message}
				/>
				<Button
					type="submit"
					className="w-full"
					variant="primary"
					disabled={loading}
				>
					{loading ? <Spinner /> : t("contact-form.button")}
				</Button>
			</form>
		</>
	);
};

export default ContactForm;
