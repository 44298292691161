import { useQuery } from "@tanstack/react-query";

export const useGenericQuery = ({
	queryKey,
	queryFn,
	refetchOnWindowFocus = true,
	staleTime = 1000 * 60,
}) => {
	return useQuery({
		queryKey,
		queryFn,
		refetchOnWindowFocus,
		staleTime,
	});
};

export default useGenericQuery;
