import {
	PieChart,
	Pie,
	Cell,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import React from "react";
import { StatsCard } from "@pages/(startgreen)/views/(dashboard)/StatsCards";

const data = [
	{ name: "Male", value: 150 },
	{ name: "Female", value: 120 },
];

const COLORS = {
	Male: "#0088FE",
	Female: "#FF8042",
};

const genderTranslations = {
	Male: "Homme",
	Female: "Femme",
};

const GenderDistributionChart = ({
	data,
	acceptedCandidates,
	visitorData,
	attendanceCount,
}) => {
	return (
		<div className={"flex flex-col _laptop:flex-row w-full"}>
			{/* Display the total number of candidates */}
			<StatsCard
				title="Statistiques des candidats"
				items={[
					{
						label: "Total des candidats",
						value: data?.total,
					},
					{
						label: "Candidats acceptés",
						value: acceptedCandidates?.total,
					},
				]}
				className="bg-startgreen_primary-100/20 w-full _laptop:max-w-max"
			/>
			<ResponsiveContainer
				className={"w-full _laptop:max-w-[70%]"}
				width="100%"
				height={400}
			>
				<PieChart>
					<Pie
						data={data?.breakdown}
						dataKey="value"
						nameKey="name"
						outerRadius={150}
						fill="#8884d8"
						label={({ name, value }) => {
							const percentage = ((value / data?.total) * 100).toFixed(2);
							return `${genderTranslations[name] || name}: ${value} (${percentage}%)`;
						}}
					>
						{data?.breakdown.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={COLORS[entry.name] || "#ccc"} />
						))}
					</Pie>
					<Tooltip
						formatter={(value, name) => [
							`${value}`,
							genderTranslations[name] || name,
						]}
					/>
					<Legend formatter={(value) => genderTranslations[value] || value} />
				</PieChart>
			</ResponsiveContainer>

			<div className={"flex w-full gap-2 _laptop:flex-col _laptop:max-w-max"}>
				<StatsCard
					title="Statistiques des visiteurs"
					items={[
						{
							label: "Total des visiteurs invités",
							value: visitorData,
						},
					]}
					className="bg-startgreen_primary-100/20 w-full _laptop:max-w-max"
				/>
				<Tooltip
					title="Fonction de recherche"
					description="Recherchez par (nom du candidat, région, domaine du projet, stade de développement, besoins du support, situation professionnelle et niveau d'éducation)"
				/>
				<StatsCard
					title="Statistiques d'attendance"
					items={[
						{
							label: "Total Scannés",
							value: attendanceCount,
						},
					]}
					className="bg-startgreen_primary-100/20 w-full _laptop:max-w-max"
					tooltipTitle={"Total des participants scannés"}
					tooltipDescription={
						"Fait référence aux candidats qui se présentent au Forum Technique / Ateliers et dont nous scannons les badges."
					}
				/>
			</div>
		</div>
	);
};

export default GenderDistributionChart;
