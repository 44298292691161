import type { FunctionComponent } from "types/types.ts";
import NavigationWrapper from "@components/layout/common/NavigationWrapper.tsx";
import StatisticSection from "@components/views/(app)/common/StatisticSection";
import NewsletterSection from "@components/views/(app)/common/NewsletterSection";
import SponsorListSection from "@components/views/(app)/home/SponsorListSection";
import ExpertSection from "@components/views/(app)/home/ExpertSection";
import FeaturesSection from "@components/views/(app)/home/FeaturesSection";
import CoachCtaSection from "@components/views/(app)/common/CoachCtaSection";
import RSESection from "@components/views/(app)/home/RSESection";
import TargetSection from "@components/views/(app)/home/TargetSection";
import FlexibilitySection from "@components/views/(app)/home/FlexibilitySection";
import BannerSection from "@components/views/(app)/home/BannerSection";

export const Home = (): FunctionComponent => {
	return (
		<NavigationWrapper>
			<main>
				<BannerSection />
				<FlexibilitySection />
				<TargetSection />
				<RSESection isDivider />
				<CoachCtaSection isDivider={false} />
				<FeaturesSection />
				<ExpertSection />
				<SponsorListSection />
				<StatisticSection isDivider />
				<NewsletterSection />
			</main>
		</NavigationWrapper>
	);
};
