import {
	Button,
	Dialog,
	DialogPanel,
	DialogTitle,
	Transition,
	TransitionChild,
} from "@headlessui/react";
import type React from "react";
import { Fragment } from "react";
import { cn } from "@core/utils";
import type { ExpertReviewProps } from "@components/views/(app)/common/ExpertReviewSection/ExpertReviewSection.types.ts";

interface ReviewDialogProps {
	isOpen: boolean;
	onClose: () => void;
	review: ExpertReviewProps | null;
}

const ReviewDialog: React.FC<ReviewDialogProps> = ({
	isOpen,
	onClose,
	review,
}) => {
	if (!review) return null;

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={onClose}>
				<div className="fixed inset-0 bg-black-500 bg-opacity-25 transition-opacity" />
				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<TransitionChild
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<DialogPanel className="shadow-xl w-full max-w-md transform overflow-hidden rounded-lg bg-BGPage p-6 text-left align-middle transition-all">
								<DialogTitle className="flex items-center justify-between">
									<div>
										<h3 className="text-gray-900 text-titleMedium font-medium _tablet:text-titleLarge">
											{review.expertName}
										</h3>
										<p className="text-titleSmall text-text-800 _tablet:text-titleMedium">
											{review.expertProfession}
										</p>
									</div>

									<Button
										type="button"
										className={cn(
											"inline-flex justify-center rounded-md border border-none focus:outline-none "
										)}
										onClick={onClose}
									>
										<svg
											width="49"
											height="49"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M29.3908 19.5898L19.6071 29.3735"
												stroke="#130F26"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M29.3939 29.3793L19.602 19.5854"
												stroke="#130F26"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M33.3496 5.61523H15.6504C9.48252 5.61523 5.6156 9.98236 5.6156 16.1625V32.8388C5.6156 39.0189 9.46414 43.3861 15.6504 43.3861H33.3476C39.5358 43.3861 43.3864 39.0189 43.3864 32.8388V16.1625C43.3864 9.98236 39.5358 5.61523 33.3496 5.61523Z"
												stroke="#130F26"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</Button>
								</DialogTitle>
								<div className="mt-[30px]">
									<p className="text-sm text-text-600">{review.content}</p>
								</div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ReviewDialog;
