import { useTranslation } from "react-i18next";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import SponsorGrid from "@components/views/(app)/home/SponsorListSection/SponsorGrid.tsx";
import { sponsorImages } from "@api/database/constant/sponsors.data.ts";
import Text from "@components/elements/Text";
import RollingMarque from "@components/views/(app)/home/SponsorListSection/RollingMarquee.tsx";

function SponsorSection() {
	const { t } = useTranslation("common");
	return (
		<SectionWrapper className="margin-divider-medium py-[60px]">
			<div className="relative flex w-full flex-col items-center justify-between gap-[100px] overflow-clip _tablet_sm:overflow-visible _middle:flex-row _middle:gap-2">
				<SponsorGrid images={sponsorImages} />
				<RollingMarque images={sponsorImages} />
				<div className="order-first max-w-[380px] items-center justify-end _middle:order-last">
					<Text
						tag="h2"
						className="displayLarge text-center _middle:text-end"
						highlightStyle="font-semibold text-primary-900"
						text={t("sponsor-section.title")}
					/>
					{/*<Title*/}
					{/*    wrapperClassName="mb-[10px] _tablet:mb-[100px] _middle:mb-0 max-w-[380px]"*/}
					{/*    titleStyle=" mb-0 text-center _middle:text-end"*/}
					{/*    highlightStyle="font-semibold text-primary-900"*/}
					{/*    paragraphStyle="max-w-[430px] _tablet:max-w-[500px] _middle:max-w-[330px] mt-[8px] text-center _middle:text-end"*/}
					{/*    titleText={t("sponsorSection.title.titleText")}*/}
					{/*/>*/}
				</div>
			</div>
		</SectionWrapper>
	);
}

export default SponsorSection;
