import React from "react";

import { Button } from "@mui/material";

import {
	resetDocument,
	setDocument,
} from "../../documents/editor/EditorContext";
import getConfiguration from "../../getConfiguration";
import validateJsonStringValue from "@/mail-builder/App/TemplatePanel/ImportJson/validateJsonStringValue.ts";
import EMPTY_EMAIL_MESSAGE from "@/mail-builder/getConfiguration/sample/empty-email-message.ts";

export default function SidebarButton({
	href = "#sample/welcome",
	config,
	children,
}: {
	href?: string;
	config?: any;
	children: JSX.Element | string;
}) {
	const handleClick = () => {
		if (config) {
			const { error, data } = validateJsonStringValue(config);
			if (!data) {
				return;
			}
			resetDocument(data);
			// resetDocument(JSON.parse(config));
		} else {
			resetDocument(EMPTY_EMAIL_MESSAGE);
		}
	};
	return (
		<Button size="small" href={href} onClick={handleClick}>
			{children}
		</Button>
	);
}
