import { useEffect } from "react";

const useResetFieldOnCondition = (
	condition: boolean,
	fieldToReset: string,
	setFieldValue: (field: string, value: any) => void,
	dependencies: any[] // Additional dependencies for the useEffect
) => {
	useEffect(() => {
		if (condition) {
			setFieldValue(fieldToReset, ""); // Reset the specified field
		}
	}, [condition, fieldToReset, setFieldValue, ...dependencies]);
};

export default useResetFieldOnCondition;
