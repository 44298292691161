import { useTranslation } from "react-i18next";
import { cn } from "@core/utils";
import Button from "@components/elements/Buttons/Button.tsx";
import Text from "@components/elements/Text";
import Image from "@components/elements/Image";

function HeaderSection() {
	const { t } = useTranslation("coaches-page");
	const { t: tButtons } = useTranslation("misc");
	return (
		<section
			className={cn(
				"relative w-full",
				"h-[calc(70vh-64px)] _tablet_sm:h-[calc(65vh-64px)] _laptop:min-h-[calc(80vh-64px)]",
				"flex flex-col items-center justify-center _tablet_lg:items-end",
				"overflow-clip rounded-md"
			)}
		>
			<Image
				className={cn(
					"pointer-events-none absolute z-[-1] h-full w-full select-none object-cover"
				)}
				src="/images/markup/coaches/bg-2.webp"
				alt="Coaches Banner"
				width={1920}
				height={1080}
			/>
			<div className="absolute top-0 h-full w-full bg-primary-50 mix-blend-multiply" />

			<div
				className={cn(
					"z-[3] flex flex-col items-center justify-center",
					"gap-[30px] rounded-md bg-logoWhite",
					"max-w-[75%] _tablet_sm:max-w-[480px] _laptop:max-w-[670px]",
					"mx-[30px] px-[30px] py-[30px] _tablet_sm:mx-[90px] _laptop:mx-[200px]"
				)}
			>
				<Text
					tag="h1"
					className={cn("displayLarge text-center")}
					highlightStyle="font-semibold text-secondary-900"
					text={t("header-section.title")}
				/>
				<Button variant="primary" link={`/contact`}>
					{tButtons("buttons.join-us")}
				</Button>
			</div>
		</section>
	);
}

export default HeaderSection;
