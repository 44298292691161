import type React from "react";

import SVGComponent from "./SVGComponent";

const SponsorCardWrapper = ({
	children,
	shape,
	className,
	svgPosition,
	svgShape,
}: {
	children?: React.ReactNode;
	shape: "rectangle" | "circle" | "square";
	size?: string;
	className?: string;
	svgPosition?: string;
	svgShape:
		| "circle-1"
		| "circle-2"
		| "rectangle-1"
		| "rectangle-2"
		| "square-1"
		| "square-2";
}) => {
	const baseStyle = `relative bg-BGPage shadow-small ${className}`;

	const renderShape = () => {
		switch (shape) {
			case "circle": {
				return (
					<div
						className={`${baseStyle} flex h-[110px] w-[110px] items-center justify-center rounded-full _laptop_sm:h-[130px] _laptop_sm:w-[130px]`}
					>
						<SVGComponent svgShape={svgShape} />
						{children}
					</div>
				);
			}
			case "square": {
				return (
					<div
						className={`${baseStyle} flex h-[110px] w-[110px] items-center justify-center _laptop_sm:h-[130px] _laptop_sm:w-[130px]`}
					>
						<SVGComponent svgShape={svgShape} />
						{children}
					</div>
				);
			}
			case "rectangle":
			default: {
				return (
					<div
						className={`${baseStyle} flex h-[80px] w-[200px] items-center justify-center _laptop_sm:h-[80px] _laptop_sm:w-[290px]`}
					>
						<SVGComponent svgShape={svgShape} />
						{children}
					</div>
				);
			}
		}
	};

	return <div className={`relative ${svgPosition}`}>{renderShape()}</div>;
};

export default SponsorCardWrapper;
