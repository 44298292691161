import type { AccordionData } from "@components/views/(app)/common/FaqAccordionSection/FaqAccordionSection.types.ts";

export const accordionData: AccordionData = [
	{
		en: {
			title:
				"Why is it important to opt for personalized support and how does it work?",
			content:
				"There is no standard path, it all depends on the objectives and situation of our client to determine the characteristics of a support mission: what, when, how, how much and who. Because the Coachini team is aware of this specificity, our process includes demo, diagnostic, tasting and monitoring/reframing sessions, which means that our solutions adapt to you, and not the other way around! In addition, our platform promotes individual support (one to one) for the coachee, more adapted to their objectives, preferences and requirements.",
		},
		fr: {
			title:
				"Pourquoi est-il important d'opter pour un accompagnement personnalisé et comment il se déroule ?",
			content:
				" Il n’y a pas de parcours standard, tout dépend des objectifs et de la situation de notre client pour déterminer les caractéristiques d’une mission d’accompagnement : quoi, quand, comment, combien et qui. Parce que l’équipe de Coachini est consciente de cette spécificité, notre processus comprend des séances de démo, de diagnostic, de dégustation et de suivi/recadrage, ce qui fait que nos solutions s’adaptent à vous, et non l’inverse ! De plus, notre plateforme favorise une prise en charge individuelle (one to one) du coaché, plus adaptée à ses objectifs, à ses préférences et à ses exigences.",
		},
	},
	{
		en: {
			title:
				"How is the coaching experience personalized and implemented according to the needs of my employees?",
			content:
				"Each employee is unique. We match each employee with a coach who best matches their specific needs and interests, ensuring a tailored virtual coaching experience. The employee’s personality, goals, learning preferences and career aspirations are considered in this matching process.",
		},
		fr: {
			title:
				"Comment l’expérience de coaching est-elle personnalisée et mise en place en fonction des besoins de mes employés ?",
			content:
				"Chaque employé est unique. Nous associons chaque employé à un coach qui correspond le mieux à ses besoins et intérêts spécifiques, garantissant ainsi une expérience de coaching virtuel sur mesure. La personnalité, les objectifs, les préférences d’apprentissage et les aspirations professionnelles de l’employé sont pris en compte dans ce processus de jumelage.",
		},
	},
	{
		en: {
			title:
				"Why is it important to opt for hybrid support and how does it work?",
			content:
				"By combining face-to-face support with an online component, we create a consolidated and effective learning experience. This approach achieves global and lasting impact by providing participants with the opportunity to enjoy the benefits of in-person interaction while also taking advantage of the opportunities offered by online tools.",
		},
		fr: {
			title:
				"Pourquoi est-il important d'opter pour un accompagnement hybride et comment il se déroule ?",
			content:
				"En combinant un accompagnement en présentiel avec une composante en ligne, nous créons une expérience d'apprentissage consolidée et efficace. Cette approche permet d'atteindre un impact global et durable en offrant aux participants la possibilité de bénéficier des avantages de l'interaction en personne tout en profitant des opportunités offertes par les outils en ligne.",
		},
	},
	{
		en: {
			title: "What are the advantages of the online support service?",
			content:
				"Transparency and accessibility: Coachini offers supervisors and coachees an intuitive user interface that allows them to easily access coaching sessions, track their progress as well as feedback from each team member and coaches.",
		},
		fr: {
			title: "Quels sont les avantages du service d’accompagnement en ligne ?",
			content:
				"Transparence et accessibilité : Coachini offre aux superviseurs et aux coachés une interface utilisateur intuitive qui leur permet d’accéder facilement aux sessions de coaching, de suivre leurs progrès ainsi que les feedbacks de chaque membre de l'équipe et des coachs.",
		},
	},
	{
		en: {
			title: "What is Coachini Studio?",
			content:
				"Coachini Studio is a service that allows you to create video CVs, video pitches and podcasts.",
		},
		fr: {
			title: "C'est quoi Coachini Studio ?",
			content:
				"Coachini Studio est un service qui permet de créer des CV vidéo, des Pitch vidéo et des Podcasts.",
		},
	},
];
