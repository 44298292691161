import { useTranslation } from "react-i18next";
import { cn } from "@core/utils";
import Text from "@components/elements/Text";
import Image from "@components/elements/Image";
import React from "react";
import Button from "@components/elements/Buttons/Button.tsx";

function HeaderSection() {
	const { t } = useTranslation("startgreen-page");
	const { t: tButtons } = useTranslation("misc");
	return (
		<section
			className={cn(
				"relative flex w-full bg-startgreen_lightcream-100 h-full",
				"flex flex-col items-center justify-center",
				"rounded-md p-5 shadow-xSm",
				"gap-5 lg:flex-row md:gap-5"
			)}
		>
			<div
				className={cn(
					"relative bg-startgreen_primary-100/30 w-full rounded-md "
				)}
			>
				<Image
					className="h-full w-full rounded-md aspect-square flex-shrink-0 "
					width={112}
					height={112}
					src="/images/startgreen/main.webp"
					alt="StartGreen"
					aria-label="StartGreen"
				/>
			</div>
			{/*--------------------------------------------*/}
			<div
				className={cn(
					"flex flex-col justify-start items-start w-full gap-[30px]",
					"px-0 md:px-0 lg:px-24"
				)}
			>
				<div className={"flex flex-col items-start gap-[10px]"}>
					<Text
						text={t("header-section.title")}
						className={cn(
							"text-displayMedium _tablet:text-displayExtra _laptop:text-displayExtra",
							"font-bold text-left"
						)}
						highlightStyle={"text-startgreen_primary-100"}
					/>
					<Text
						text={t("header-section.slogan")}
						className={cn(
							"text-start text-titleMedium _tablet:text-titleLarge _laptop:text-titleLarge",
							"font-medium"
						)}
						highlightStyle={"text-startgreen_primary-100"}
					/>
				</div>
				{/*---------------------*/}
				<div className={"flex flex-col items-start gap-[20px]"}>
					<h2
						className={cn(
							"text-[#363636] !leading-normal text-[16px] _tablet:text-[18px] _tablet:leading-normal _laptop:text-[22px]",
							"text-left font-normal"
						)}
					>
						Un programme dédié aux jeunes et aux femmes entrepreneurs de Sousse,
						porteurs de projets dans le domaine de la GreenTech.
					</h2>
					<Button
						variant="startgreen"
						size={"extra"}
						link={"/startgreen-by-iptic/apply"}
						className={"w-full lg:w-auto"}
					>
						{t("buttons.apply")}
					</Button>
				</div>
				{/*---------------------*/}
			</div>
			{/*--------------------------------------------*/}
		</section>
	);
}

export default HeaderSection;
