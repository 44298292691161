import type React from "react";
import { useTranslation } from "react-i18next";
import { cn } from "@core/utils";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import Button from "@components/elements/Buttons/Button.tsx";
import Text from "@components/elements/Text";

interface CoachCTAProps {
	showJoinButton?: boolean;
	showAboutButton?: boolean;
}

const MarqueEmployerSection: React.FC<CoachCTAProps> = ({
	showAboutButton = true,
	showJoinButton = true,
}) => {
	const { t } = useTranslation("solutions-page");
	const { t: buttons } = useTranslation("misc");
	return (
		<div className={cn("bg-primary-50/50")}>
			<SectionWrapper
				className={cn(
					" flex flex-col items-center gap-12 px-cXSm py-c",
					"margin-divider-medium"
				)}
			>
				<Text
					className="displayLarge text-center"
					highlightStyle="text-primary-400 font-semibold"
					text={t("marque-employer-section.title")}
				/>

				<div className="flex flex-col gap-4 sm:flex-row">
					{showAboutButton && (
						<Button variant="secondary" link={`/contact`}>
							{buttons("buttons.contact")}
						</Button>
					)}
					{showJoinButton && (
						<Button variant="primary" link={`/contact`}>
							{buttons("buttons.join-us")}
						</Button>
					)}
				</div>
			</SectionWrapper>
		</div>
	);
};
export default MarqueEmployerSection;
