import { cn } from "@core/utils";
import type React from "react";

interface AccordionHeaderProps {
	title: string;
	index: number;
	activeIndex: number | null;
	toggleAccordion: (index: number) => void;
}

const AccordionHeader: React.FC<AccordionHeaderProps> = ({
	title,
	index,
	activeIndex,
	toggleAccordion,
}) => {
	return (
		<div
			onClick={() => {
				toggleAccordion(index);
			}}
			className="accordion-header relative flex min-h-[140px] cursor-pointer items-center overflow-clip rounded-lg pr-[12px] shadow-base2 transition _tablet:min-h-[115px]  _tablet:pr-[22px] _laptop:min-h-[100px] "
		>
			<div className="relative flex w-[110px] place-items-center justify-center self-stretch bg-primary-100">
				<svg
					className={cn("transition", activeIndex === index ? "rotate-90" : "")}
					width="18"
					height="33"
					viewBox="0 0 18 33"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M2 2.98926L16 16.9893L2 30.9893"
						stroke="#094BA4"
						strokeWidth="4"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
				<svg
					className="absolute left-[95%] h-[100%] fill-green-300"
					// width="13"
					// height="92"
					viewBox="0 0 13 92"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						className="fill-primary-100"
						d="M13 92H0.5V0H10.7242C10.7242 0 4.24291 18.2635 4.24291 45.7733C4.24291 72.8322 13 92 13 92Z"
						fill="red"
					/>
				</svg>
			</div>

			<h3 className="titleLarge my-[10px] ml-[30px]  w-full p-2 font-medium tracking-tight _phone:p-3 _phone_md:ml-[20px] _tablet:ml-[40px]">
				{title}
			</h3>
		</div>
	);
};

export default AccordionHeader;
