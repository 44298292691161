import { useTranslation } from "react-i18next";
import { cn } from "@core/utils";
import Text from "@components/elements/Text";
import Image from "@components/elements/Image";

function VisionSection() {
	const { t } = useTranslation("about-page");
	return (
		<div className="relative grid  h-[100%] grid-cols-1 flex-row  _tablet:h-[70vh] _tablet:grid-cols-[0.8fr_1fr]  _laptop:h-[85vh] _large:h-[80vh]">
			<div className="_tablet:items-left relative flex h-full flex-col items-center justify-evenly gap-[80px] bg-primary-50_50  py-[80px] pl-[0px] _tablet:pl-[75px] _laptop:pl-[80px] _large:pl-[120px]">
				<div className="items-left flex max-w-[660px] flex-col gap-[25px]">
					<Text
						tag="h2"
						text={t("vision-section.mission.title")}
						className="displaySmall text-center _tablet:text-left"
						highlightStyle="text-secondary-900 font-semibold"
					/>
					<Text
						tag="p"
						text={t("vision-section.mission.paragraph")}
						className="titleLarge max-w-[250px] text-center text-text-700 _tablet:text-left _laptop:max-w-[420px]"
					/>
				</div>

				<div className="items-left flex max-w-[660px] flex-col gap-[25px]">
					<Text
						tag="h2"
						text={t("vision-section.vision.title")}
						className="displaySmall text-center _tablet:text-left"
						highlightStyle="text-secondary-900 font-semibold"
					/>
					<Text
						tag="p"
						text={t("vision-section.vision.paragraph")}
						className="titleLarge max-w-[250px] text-center text-text-700 _tablet:text-left _laptop:max-w-[420px]"
					/>
				</div>
			</div>
			<div
				className={cn(
					"relative h-[300px] w-full overflow-clip",
					"_tablet_sm:h-[400px] _tablet:h-full"
				)}
			>
				<Image
					className={cn(
						"pointer-events-none absolute object-cover",
						"w-ful h-full"
					)}
					width={1920}
					height={1080}
					src="/images/markup/about/gestion-de-carriere.webp"
					alt="Coachini Vision and Mission"
					aria-label="Coachini Vision and Mission"
					loading="lazy"
				/>
				<svg
					className="absolute top-[-5px] block  _tablet:hidden"
					viewBox="0 0 911 74"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						className="fill-primary-50_50"
						d="M0.499997 73.4997L0.5 10.5002C0.5 4.97736 4.97715 0.500205 10.5 0.500205L900.5 0.500244C906.023 0.500244 910.5 4.9774 910.5 10.5002L910.5 61.927C910.5 61.927 729.85 28.969 457.742 28.969C190.095 28.969 0.499997 73.4997 0.499997 73.4997Z"
					/>
				</svg>
				<svg
					className="absolute left-[-5px] hidden h-full _tablet:block"
					viewBox="0 0 73 910"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						className="fill-primary-50_50"
						d="M72.9995 910H10C4.47715 910 0 905.523 0 900L0 10C0 4.47715 4.47715 0 10 0H61.4268C61.4268 0 28.4688 180.65 28.4688 452.758C28.4688 720.405 72.9995 910 72.9995 910Z"
					/>
				</svg>
			</div>
		</div>
	);
}

export default VisionSection;
