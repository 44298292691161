// store/layoutReducer.ts

import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "@store/storage";
import themeConfig from "@config/themeConfig";

const initialState = {
	isRTL: themeConfig.layout.isRTL,
	darkMode: themeConfig.layout.darkMode,
	lang: themeConfig.layout.lang,
	// isCollapsed: themeConfig.layout.isCollapsed,
	customizer: themeConfig.layout.customizer,
	semiDarkMode: themeConfig.layout.semiDarkMode,
	skin: themeConfig.layout.skin,
	contentWidth: themeConfig.layout.contentWidth,
	type: themeConfig.layout.type,
	menuHidden: themeConfig.layout.menu.isHidden,
	navBarType: themeConfig.layout.navBarType,
	footerType: themeConfig.layout.footerType,
	mobileMenu: themeConfig.layout.mobileMenu,
	isMonochrome: themeConfig.layout.isMonochrome,
};

const persistConfig = {
	key: "layout",
	storage,
	whitelist: [
		"darkMode",
		"isCollapsed",
		"semiDarkMode",
		"isRTL",
		"skin",
		"type",
	],
};

const layoutSlice = createSlice({
	name: "layout",
	initialState,
	reducers: {
		handleDarkMode: (state, action) => {
			state.darkMode = action.payload;
		},

		handleLang: (state, action) => {
			state.lang = action.payload;
		},
		// handleSidebarCollapsed: (state, action) => {
		//     state.isCollapsed = action.payload;
		// },
		handleCustomizer: (state, action) => {
			state.customizer = action.payload;
		},
		handleSemiDarkMode: (state, action) => {
			state.semiDarkMode = action.payload;
		},
		handleRtl: (state, action) => {
			state.isRTL = action.payload;
		},
		handleSkin: (state, action) => {
			state.skin = action.payload;
		},
		handleContentWidth: (state, action) => {
			state.contentWidth = action.payload;
		},
		handleType: (state, action) => {
			state.type = action.payload;
		},
		handleMenuHidden: (state, action) => {
			state.menuHidden = action.payload;
		},
		handleNavBarType: (state, action) => {
			state.navBarType = action.payload;
		},
		handleFooterType: (state, action) => {
			state.footerType = action.payload;
		},
		handleMobileMenu: (state, action) => {
			state.mobileMenu = action.payload;
		},
		handleMonoChrome: (state, action) => {
			state.isMonochrome = action.payload;
		},
	},
});

export const {
	handleDarkMode,
	handleLang,
	// handleSidebarCollapsed,
	handleCustomizer,
	handleSemiDarkMode,
	handleRtl,
	handleSkin,
	handleContentWidth,
	handleType,
	handleMenuHidden,
	handleNavBarType,
	handleFooterType,
	handleMobileMenu,
	handleMonoChrome,
} = layoutSlice.actions;

const persistedReducer = persistReducer(persistConfig, layoutSlice.reducer);

export default persistedReducer;
