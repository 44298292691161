import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	LabelList,
} from "recharts";

const CustomLabel = ({ x, y, width, height, value, data, index }) => {
	const region = data[index]?.label;
	return (
		<text
			x={x + width / 2}
			y={y + height / 2}
			textAnchor="middle"
			dominantBaseline="middle"
			fill="white"
			fontSize={18}
			className={"text-[#0088FE]"}
		>
			<tspan x={x + width / 2} dy="0">
				{region}
			</tspan>
			<tspan x={x + width / 2} dy="1.2em">
				{`${value} candidat(s)`}
			</tspan>
		</text>
	);
};

const TopRegionsChart = ({ data }) => {
	return (
		<ResponsiveContainer width="100%" height={400}>
			<BarChart data={data}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="label" />
				<YAxis allowDecimals={false} />
				<Tooltip
					formatter={(value) => `${value}`}
					labelFormatter={(label) => `Région: ${label}`}
				/>
				<Legend
					payload={[
						{
							value: "Utilisateurs par région",
							type: "square",
							id: "candidates",
							color: "#006837",
						},
					]}
				/>
				<Bar dataKey="count" name="Candidats" fill="#006837">
					<LabelList content={<CustomLabel data={data} />} />
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};
export default TopRegionsChart;
