import * as React from "react";
import type { ScrollTriggerHookOptions } from "@components/views/(app)/common/StatisticSection/ScrollTypes.ts";
import useScroll from "@components/views/(app)/common/StatisticSection/useScroll.tsx";

export const ScrollTriggerPositions = {
	TOP: "top",
	CENTER: "center",
	BOTTOM: "bottom",
};

const useScrollTrigger = (
	triggerElement: React.RefObject<HTMLElement>,
	options: ScrollTriggerHookOptions = {}
) => {
	const scrollData = useScroll(options);
	const [triggerPosition, setTriggerPosition] = React.useState<string | null>(
		null
	);
	const [triggered, setTriggered] = React.useState(false);

	React.useEffect(() => {
		if (!triggerElement.current) return;

		const elementRect = triggerElement.current.getBoundingClientRect();
		const windowHeight = window.innerHeight;

		// Apply the offset
		const adjustedTop = elementRect.top + (options.offset || 0);
		const adjustedBottom = elementRect.bottom + (options.offset || 0);

		const topTrigger = adjustedTop <= windowHeight && adjustedBottom >= 0;
		const bottomTrigger = adjustedBottom <= windowHeight && adjustedTop >= 0;
		const centerTrigger =
			adjustedTop <= windowHeight / 2 && adjustedBottom >= windowHeight / 2;

		if (topTrigger) {
			setTriggerPosition(ScrollTriggerPositions.TOP);
		} else if (bottomTrigger) {
			setTriggerPosition(ScrollTriggerPositions.BOTTOM);
		} else if (centerTrigger) {
			setTriggerPosition(ScrollTriggerPositions.CENTER);
		} else {
			setTriggerPosition(null);
		}
	}, [scrollData, triggerElement, options.offset]); // Include options.offset in dependency array

	React.useEffect(() => {
		const { onStart, onLeave, once } = options;

		if (
			triggerPosition === ScrollTriggerPositions.TOP &&
			onStart &&
			!triggered
		) {
			onStart();
			if (once) {
				setTriggered(true);
			}
		} else if (
			triggerPosition === ScrollTriggerPositions.BOTTOM &&
			onLeave &&
			!triggered
		) {
			onLeave();
			if (once) {
				setTriggered(true);
			}
		}
	}, [triggerPosition, options, triggered]);

	return {
		scrollData,
		triggerPosition,
	};
};

export default useScrollTrigger;
