import QrScanner from "qr-scanner";
import { useEffect, useRef, useState } from "react";

interface QrScannerCamProps {
	onScan: (data: string | null) => void;
	onError: (error: any) => void;
}

const QrScannerCam: React.FC<QrScannerCamProps> = ({ onScan, onError }) => {
	const videoElementRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		const video: HTMLVideoElement | null = videoElementRef.current;

		if (!video) {
			return;
		}

		const qrScanner = new QrScanner(
			video,
			(result) => {
				onScan(result.data);
			},
			{
				returnDetailedScanResult: true,
				highlightScanRegion: true,
				highlightCodeOutline: true,
			}
		);

		void qrScanner.start();

		return () => {
			qrScanner.stop();
			qrScanner.destroy();
		};
	}, [onScan]);

	return (
		<div>
			<div className="videoWrapper">
				<video className="qrVideo" ref={videoElementRef} />
			</div>
		</div>
	);
};

export default QrScannerCam;
