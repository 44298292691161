import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGenericQuery } from "@hooks/useGenericQuery";
import {
	cancelAllScheduledMail,
	cancelScheduledMail,
	createTemplate,
	deleteTemplate,
	fetchScheduledMails,
	fetchTemplates,
	updateTemplate,
} from "@api/service/startgreen.services";
import Navbar from "@pages/(startgreen)/views/(dashboard)/Navbar";
import Sidebar from "@pages/(startgreen)/views/(dashboard)/Sidebar";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { appConfig } from "@config/appConfig.ts";
import { ScheduledMailList } from "@pages/(startgreen)/views/(emails)/ScheduledMailList.tsx";
import { SendMailForm } from "@pages/(startgreen)/views/(emails)/SendMailForm.tsx";
import { TemplateList } from "@pages/(startgreen)/views/(emails)/TemplateList.tsx";
import { TemplateEditor } from "@pages/(startgreen)/views/(emails)/TemplateEditor.tsx";
import { TemplateCreator } from "@pages/(startgreen)/views/(emails)/TemplateCreator.tsx";
import useInvalidateOnVisibility from "@hooks/useInvalidateOnVisibility.ts";
import Tabs from "./Tabs";
import toast from "react-hot-toast";
import TooltipCard from "@pages/(startgreen)/views/(dashboard)/TooltipCard.tsx";

const VIEWS = {
	TEMPLATES: "templates",
	TEMPLATE_EDITOR: "template-editor",
	TEMPLATE_CREATOR: "template-creator",
	SEND: "send",
	SCHEDULED: "scheduled",
};

const EmailManagement = () => {
	const { t } = useTranslation("coaches-page");
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [view, setView] = useState(VIEWS.TEMPLATES);
	const [message, setMessage] = useState<{ type: string; text: string } | null>(
		null
	);

	useEffect(() => {
		if (message) {
			const timer = setTimeout(() => setMessage(null), 3000);
			return () => clearTimeout(timer);
		}
	}, [message]);

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	const handleTemplateSelect = (template) => {
		setSelectedTemplate(template);
		setView(VIEWS.TEMPLATE_EDITOR);
	};

	const handleTabChange = (tab) => {
		setView(tab);
		if (tab === VIEWS.TEMPLATES) {
			setSelectedTemplate(null);
		}
	};

	const {
		data: templates,
		isLoading: isTemplatesLoading,
		refetch,
	} = useGenericQuery({
		queryKey: ["mailTemplates"],
		queryFn: fetchTemplates,
	});

	const { data: scheduledMails, isLoading: isScheduledLoading } =
		useGenericQuery({
			queryKey: ["scheduledMails"],
			queryFn: fetchScheduledMails,
		});

	const sendMailMutation = useMutation({
		mutationFn: (mailData) => {
			return axios.post(`${appConfig.startGreenUrl}/mail/send-bulk-exp`, {
				recipients: mailData.recipients,
				subject: mailData.subject,
				template: mailData.templateId,
				context: mailData.context || {},
				schedule: mailData.schedule || false,
				sendAt: mailData.sendAt || null,
				body: mailData.body || null,
			});
		},
		onSuccess: () => {
			setMessage({ type: "success", text: "Email envoyé avec succès!" });
			toast.success("Email envoyé avec succès!", { duration: 3000 });
		},
		onError: () => {
			setMessage({ type: "error", text: "Échec de l'envoi de l'email." });
			toast.error("Échec de l'envoi de l'email!", { duration: 3000 });
		},
	});

	const handleSendMail = (mailData) => {
		sendMailMutation.mutate(mailData);
	};

	const cancelMutation = useMutation({
		mutationFn: (id: string) => cancelScheduledMail(id),
		onSuccess: () => {
			setMessage({
				type: "success",
				text: "Scheduled email canceled successfully.",
			});
			refetch();
		},
		onError: () => {
			setMessage({ type: "error", text: "Failed to cancel scheduled email." });
		},
	});

	const cancelAllMutation = useMutation({
		mutationFn: cancelAllScheduledMail,
		onSuccess: () => {
			setMessage({
				type: "success",
				text: "All scheduled emails canceled successfully.",
			});
			refetch();
		},
		onError: () => {
			setMessage({
				type: "error",
				text: "Failed to cancel all scheduled emails.",
			});
		},
	});

	const createTemplateMutation = useMutation({
		mutationFn: createTemplate,
		onSuccess: () => {
			setMessage({ type: "success", text: "Template created successfully." });
			refetch();
			setView(VIEWS.TEMPLATE_CREATOR);
			setSelectedTemplate({ name: "", template: "", dynamicFields: [] });
		},
		onError: () => {
			setMessage({ type: "error", text: "Failed to create template." });
		},
	});

	const updateTemplateMutation = useMutation({
		mutationFn: updateTemplate,
		onSuccess: () => {
			setMessage({ type: "success", text: "Template updated successfully." });
			refetch();
		},
		onError: () => {
			setMessage({ type: "error", text: "Failed to update template." });
		},
	});

	const deleteTemplateMutation = useMutation({
		mutationFn: (id: string) => deleteTemplate(id),
		onSuccess: () => {
			setMessage({ type: "success", text: "Template deleted successfully." });
			refetch();
		},
		onError: () => {
			setMessage({ type: "error", text: "Failed to delete template." });
		},
	});

	const handleCancelAll = () => {
		cancelAllMutation.mutate();
	};

	const handleCreateTemplate = () => {
		setView(VIEWS.TEMPLATE_CREATOR);
	};

	const queryKeys = ["mailTemplates", "scheduledMails"];
	const { refetchQueries } = useInvalidateOnVisibility(queryKeys);

	const handleRefetchAll = () => {
		refetchQueries();
	};

	const handleRefetchTemplates = () => {
		refetchQueries("mailTemplates");
	};

	const tooltipPoints = [
		"Nous pouvons envoyer des mails en masse à des personnes depuis cette section.",
		"Nous pouvons programmer l'envoi des mails.",
		"Veuillez vérifier les administrateurs avant d'ajouter/supprimer des modèles de mails, car l'existence de certains modèles est cruciale pour la plateforme.",
		"Nous pouvons modifier les modèles en cliquant sur 'Constructeur'.",
	];

	return (
		<div className="flex bg-[#F3F3F3]">
			<Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
			<div className="flex-grow">
				<Navbar title={"Emails"} toggleSidebar={toggleSidebar} />
				<Tabs
					view={view}
					handleTabChange={handleTabChange}
					handleRefetchAll={handleRefetchAll}
					handleRefetchTemplates={handleRefetchTemplates}
					isTemplatesLoading={isTemplatesLoading}
				/>
				<main className="p-5 space-y-6">
					<TooltipCard
						title="Centre des Emails"
						description="Cette section permet aux administrateurs de la plateforme d'envoyer des mails aux candidats."
						points={tooltipPoints}
					/>
					{message && (
						<div
							className={`mb-4 p-4 rounded ${message.type === "error" ? "bg-red-100 text-red-800" : "bg-green-100 text-green-800"}`}
						>
							{message.text}
						</div>
					)}
					{view === VIEWS.TEMPLATES && (
						<TemplateList
							onTemplateSelect={handleTemplateSelect}
							templates={templates}
							isLoading={isTemplatesLoading}
							onCreateTemplate={handleCreateTemplate}
							onDeleteTemplate={(id) => deleteTemplateMutation.mutate(id)}
						/>
					)}
					{view === VIEWS.TEMPLATE_EDITOR && selectedTemplate && (
						<TemplateEditor
							selectedTemplate={selectedTemplate}
							onSave={(updatedTemplate) => {
								updateTemplateMutation.mutate(updatedTemplate);
							}}
						/>
					)}
					{view === VIEWS.TEMPLATE_CREATOR && (
						<TemplateCreator
							onSave={(newTemplate) => {
								createTemplateMutation.mutate(newTemplate);
							}}
						/>
					)}
					{view === VIEWS.SEND && (
						<SendMailForm templates={templates} onSendMail={handleSendMail} />
					)}
					{view === VIEWS.SCHEDULED && (
						<ScheduledMailList
							scheduledMails={scheduledMails}
							isLoading={isScheduledLoading}
							onCancel={(id) => cancelMutation.mutate(id)}
							onCancelAll={handleCancelAll}
						/>
					)}
				</main>
			</div>
		</div>
	);
};

export default EmailManagement;
