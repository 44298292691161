import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { FAQ } from "@pages/(app)/FAQ.tsx";
import { useTranslation } from "react-i18next";

const Page = () => {
	const { t: tMeta } = useTranslation("metadata");

	return (
		<>
			<Meta
				title={tMeta("faq-page.title")}
				template={"%s | Coachini"}
				description={tMeta("faq-page.description")}
				keywords={["contact", "contact", "contact"]}
				robots="index,follow"
				ogTitle={tMeta("faq-page.title")}
				ogDescription={tMeta("faq-page.description")}
			/>
			<FAQ />
		</>
	);
};

export const Route = createFileRoute("/(main)/faq/")({
	component: Page,
});
