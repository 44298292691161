export const importFiles = (lng) => {
	let files = {};
	if (lng === "en") {
		files = import.meta.glob("./languages/en/*/*.json", { eager: true });
	} else if (lng === "fr") {
		files = import.meta.glob("./languages/fr/*/*.json", { eager: true });
	}
	const organizedFiles = {};
	Object.keys(files).forEach((key) => {
		const filePathParts = key.split("/");
		const fileName = filePathParts.pop()?.split(".")[0];
		const directoryName = filePathParts.pop() as string;
		if (!organizedFiles[directoryName]) {
			organizedFiles[directoryName] = {};
		}
		organizedFiles[directoryName][fileName] = files[key];
	});
	return organizedFiles;
};

type ImportFunction = () => Promise<{ default: any }>;
type OrganizedFiles = Record<string, Record<string, ImportFunction>>;

export const resolveTranslations = (organizedFiles: OrganizedFiles) => {
	const resolved: Record<string, Record<string, any>> = {};

	for (const [directory, files] of Object.entries(organizedFiles)) {
		resolved[directory] = {};
		for (const [file, importFn] of Object.entries(files) as any) {
			const fileName = file.split(".")[0];
			resolved[directory][fileName] = importFn.default;
		}
	}

	return resolved;
};

export const constructTranslations = (resolvedFiles) => {
	const translations = {};
	for (const [lang, files] of Object.entries(resolvedFiles)) {
		translations[lang] = {};
		// @ts-ignore
		for (const [, directoryFiles] of Object.entries(files as any)) {
			for (const [file, content] of Object.entries(directoryFiles as any)) {
				if (!translations[lang][file]) {
					translations[lang][file] = {};
				}
				translations[lang][file] = content;
			}
		}
	}
	return translations;
};
