import type { Cluster } from "@components/views/(app)/solutions/ThemeSection/ThemeSection.types.ts";

export const enterpriseThemes: Cluster[] = [
	{
		id: 0,
		rows: 3,
		cols: 3,
		items: [
			{
				id: 1,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Effective communication",
					content: "The art of expressing oneself with confidence and kindness",
				},
				fr: {
					title: "Communication efficace",
					content: "L’art de s’exprimer avec affirmation et bienveillance",
				},
				className: "bg-secondary-200",
			},
			{
				id: 4,
				spanX: {
					mobile: 2,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "communication-efficace",
					ext: "webp",
					alt: "test",
					subPath: "business",
				},
				className: "w-full",
			},
			{
				id: 3,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Stress management",
					content:
						"And what if you decide not to just endure your life anymore !",
				},
				fr: {
					title: "Gestion de stress",
					content: "Et si vous décidez de ne plus subir votre vie !",
				},
				className: "bg-primary-80",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Team management / Cohesion",
					content:
						"« The best things that happen in the business world are not the result of one person's work. It's the work of an entire team. » Steve Jobs",
				},
				fr: {
					title: "Gestion / Cohésion d’équipe ",
					content:
						"« Les meilleures choses qui arrivent dans le monde de l’entreprise ne sont pas le résultat du travail d’un seul homme. C’est le travail de toute une équipe » Steve Jobs",
				},
				className: "bg-tertiary-100",
			},
			{
				id: 2,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Conflict management",
					content: "Serenity and zen at work: Yes, it's possible !",
				},
				fr: {
					title: "Gestion de conflits",
					content:
						"La sérénité et la zénitude au travail : Oui, c’est possible !",
				},
				className: "bg-secondary-200",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "emotion",
					alt: "test",
					ext: "webp",
					subPath: "business",
				},
				className: "w-full",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Emotional intelligence",
					content: "Stay in control and take the wheel of your emotions !",
				},
				fr: {
					title: "Intelligence émotionnelle",
					content: "Restez maître à bord et prenez le volant de vos émotions !",
				},
				className: "bg-tertiary-100",
			},
		],
	},
	{
		id: 1,
		rows: 3,
		cols: 1,
		items: [
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 2,
				},
				picture: {
					src: "team-synergy",
					ext: "webp",
					alt: "test",
					subPath: "business",
				},
				className: "w-full",
			},
			{
				id: 1,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Skills development",
					content: "Add some spice to your professional journey.",
				},
				fr: {
					title: "Développement des compétences",
					content: "L’art de s’exprimer avec affirmation et bienveillance.",
				},
				className: "bg-secondary-200",
			},
		],
	},
	{
		id: 2,
		rows: 3,
		cols: 3,
		items: [
			{
				id: 1,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Change management",
					content: "Are you bamboo or steel ?",
				},
				fr: {
					title: "Gestion du changement",
					content: "Vous êtes un bambou ou une barre d'acier ?",
				},
				className: "bg-secondary-200",
			},
			{
				id: 2,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Work life balance",
					content: "Every aspect of your life deserves to shine.",
				},
				fr: {
					title: "Equilibre vie pro / perso",
					content: "Chaque aspect de votre vie a le droit de briller.",
				},
				className: "bg-secondary-200",
			},
			{
				id: 3,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Profiling",
					content: "A touch of magic to understand yourself and others.",
				},
				fr: {
					title: "Profiling",
					content:
						"Une pincé de magie pour vous comprendre et comprendre les autres.",
				},
				className: "bg-primary-80",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Employer branding strategy",
					content: "A company culture is like a melody: choose to be a Mozart.",
				},
				fr: {
					title: "Stratégie marque employeur",
					content:
						"Une culture d'entreprise est comme une mélodie : choisissez d'être un Mozart.",
				},
				className: "bg-tertiary-100",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "business",
					ext: "webp",
					alt: "test",
					subPath: "business",
				},
				className: "w-full",
			},
			{
				id: 4,
				spanX: {
					mobile: 2,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "united",
					ext: "webp",
					alt: "test",
					subPath: "business",
				},
				className: "w-full",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Collaborator experience strategy",
					content:
						"« The way you treat your employees is the way they will treat your customers » Richard Branson.",
				},
				fr: {
					title: "Stratégie expérience collaborateur",
					content:
						"« La façon dont vous traitez vos employés est la façon dont ils traiteront vos clients » Richard Branson.",
				},
				className: "bg-tertiary-100",
			},
		],
	},
	{
		id: 3,
		rows: 3,
		cols: 3,
		items: [
			{
				id: 1,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Time management",
					content: "Time is gold, become rich by learning to manage it.",
				},
				fr: {
					title: "Gestion du temps",
					content: "Le temps est en or, devenez riche en apprenant à le gérer.",
				},
				className: "bg-secondary-200",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					// src: "work-life-balance",
					// ext: "JPEG",
					src: "time-management",
					ext: "webp",
					alt: "Time Managment",
					subPath: "business",
				},
				className: "w-full",
			},
			{
				id: 2,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Priority management",
					content: "No need to buy the sofa before building the house.",
				},
				fr: {
					title: "Gestion des priorités",
					content: "Inutile d’acheter le divan avant de construire la maison.",
				},
				className: "bg-secondary-200",
			},
			{
				id: 3,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Happiness at work",
					content: "Yes Yes, happiness isn't just in the meadow !",
				},
				fr: {
					title: "Le bonheur au travail",
					content: "Oui Oui, le bonheur n’est pas seulement dans le pré !",
				},
				className: "bg-primary-80",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Leadership",
					content: "Captain, we're ready to set sail !",
				},
				fr: {
					title: "Leadership",
					content: "Capitaine, nous sommes prêts à mettre les voiles !",
				},
				className: "bg-tertiary-100",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					// src: "work-life-balance",
					// ext: "JPEG",
					src: "leadership-2",
					ext: "webp",
					alt: "Leadership",
					subPath: "business",
				},
				className: "w-full",
			},
			{
				id: 4,
				spanX: {
					mobile: 2,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "team-building-1",
					ext: "webp",
					alt: "Team Building",
					subPath: "business",
				},
				className: "w-full",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Team building",
					content: "And be the actors in a giant board game.",
				},
				fr: {
					title: "Team building",
					content: "Et soyez les acteurs d’un jeu de société géant.",
				},
				className: "bg-tertiary-100",
			},
		],
	},
];

export const startupThemes: Cluster[] = [
	{
		id: 0,
		rows: 3,
		cols: 3,
		items: [
			{
				id: 1,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Startup mentoring",
					content: "Your Passion + Our Expertise = Energizing Cocktail.",
				},
				fr: {
					title: "Mentoring des startups",
					content: "Votre passion + Notre expertise = Cocktail énergisant.",
				},
				className: "bg-secondary-200",
			},
			{
				id: 2,
				spanX: {
					mobile: 1,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Ideation",
					content:
						"Once upon a time, there was a place where dreamers gathered, where curious minds sought inspiration, and where ideas took flight.",
				},
				fr: {
					title: "Idéation",
					content:
						"Il était une fois un endroit où les rêveurs se rassemblaient, où les esprits curieux venaient chercher l'inspiration et où les idées prenaient leur envol.",
				},
				className: "bg-secondary-200",
			},
			{
				id: 3,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Design thinking",
					content: "See the world through the eyes of your customers.",
				},
				fr: {
					title: "Design thinking",
					content: "Voyez le monde avec les yeux de vos clients.",
				},
				className: "bg-primary-80",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Pre-Label / Label",
					content: "Build a bond of trust with your ecosystem",
				},
				fr: {
					title: "Pré-Label / Label",
					content: "Tissez un lien de confiance avec votre écosystème.",
				},
				className: "bg-tertiary-100",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "ideation",
					ext: "webp",
					alt: "test",
					subPath: "startup",
				},
				className: "w-full",
			},
			{
				id: 4,
				spanX: {
					mobile: 2,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "design-thinking",
					ext: "webp",
					alt: "test",
					subPath: "startup",
				},
				className: "w-full",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Market study",
					content: "Mirror, Mirror, Reveal All the Secrets to Me !",
				},
				fr: {
					title: "Etude de marché",
					content: "Miroir, miroir, révèle-moi tous les secrets !",
				},
				className: "bg-tertiary-100",
			},
		],
	},
	{
		id: 1,
		rows: 3,
		cols: 1,
		items: [
			{
				id: 0,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Pitch / Pitch deck",
					content: "Every startup has its unique story !",
				},
				fr: {
					title: "Pitch / Pitch deck",
					content: "Chaque startup a son histoire unique !",
				},
				className: "bg-secondary-200",
			},
			{
				id: 1,
				spanX: {
					mobile: 2,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Entrepreneurial profile",
					content:
						"Change your glasses and see opportunities where there are obstacles !",
				},
				fr: {
					title: "Profil entrepreneurial",
					content:
						"Changez de lunettes et voyez des opportunités là où il y a des obstacles !",
				},
				className: "bg-secondary-200",
			},
			{
				id: 2,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "BMC",
					content: "Simplify the Complex !",
				},
				fr: {
					title: "BMC",
					content: "Simplifiez le complexe !",
				},
				className: "bg-secondary-200",
			},
		],
	},
	{
		id: 2,
		rows: 3,
		cols: 3,
		items: [
			{
				id: 1,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Vision and Strategy",
					content: "See Far, See Big !",
				},
				fr: {
					title: "Vision et Stratégie",
					content: "Voyez loin, Voyez grand !",
				},
				className: "bg-secondary-200",
			},
			{
				id: 2,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Business plan",
					content: "Your GPS to reach your destination.",
				},
				fr: {
					title: "Plan d’affaires",
					content: "Votre GPS pour arriver à destination.",
				},
				className: "bg-secondary-200",
			},
			{
				id: 3,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Marketing / Communication",
					content:
						"A project without marketing is like a book without readers.",
				},
				fr: {
					title: "Marketing / Communication",
					content: "Un projet sans marketing est comme un livre sans lecteurs.",
				},
				className: "bg-primary-80",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Growth and Expansion",
					content: "Who said there are boundaries ?",
				},
				fr: {
					title: "Croissance et expansion",
					content: "Qui a dit qu'il y a des frontières ?",
				},
				className: "bg-tertiary-100",
			},
			{
				id: 5,
				spanX: {
					mobile: 1,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "personal-development",
					ext: "webp",
					alt: "Personal Development",
					subPath: "startup",
				},
				className: "w-full",
			},
			{
				id: 6,
				spanX: {
					mobile: 2,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "fund-raise-2",
					ext: "webp",
					alt: "Fund Raise",
					subPath: "startup",
				},
				className: "w-full",
			},
			{
				id: 7,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Fundraising",
					content: "Are you ready to open the doors of the safe ?",
				},
				fr: {
					title: "Levée de fonds",
					content: "Êtes-vous prêt à ouvrir les portes du coffre-fort ?",
				},
				className: "bg-tertiary-100",
			},
		],
	},
];

export const employabilityThemes: Cluster[] = [
	{
		id: 0,
		rows: 3,
		cols: 3,
		items: [
			{
				id: 1,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "CV / Cover letter",
					content: "Your CV, Your ticket to the professional world.",
				},
				fr: {
					title: "CV / Lettre de motivation",
					content: "Votre cv, votre billet d’entré au monde professionnel.",
				},
				className: "bg-secondary-200",
			},
			{
				id: 2,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Job interview",
					content:
						"“You'll never get a second chance to make a good first impression.”  David Swanson",
				},
				fr: {
					title: "Entretien d’embauche",
					content:
						"“Vous n'aurez jamais une deuxième chance de faire une bonne première impression.” David Swanson",
				},
				className: "bg-secondary-200",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Integration coaching",
					content:
						"30% of new hires leave their jobs after 6 months (HRDive, 2017).",
				},
				fr: {
					title: "Coaching d'intégration",
					content:
						"30% des recrus quittent leur emploi après 6 mois (HRDive, 2017). Faites partie de 70 % !",
				},
				className: "bg-tertiary-100",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Effective communication",
					content:
						"The art of expressing oneself with confidence and kindness !",
				},
				fr: {
					title: "Communication efficace",
					content: "L’art de s’exprimer avec affirmation et bienveillance !",
				},
				className: "bg-tertiary-100",
			},
			{
				id: 4,
				spanX: {
					mobile: 1,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "handshake-3",
					ext: "webp",
					alt: "test",
					subPath: "employability",
				},
				className: "w-full",
			},
			{
				id: 4,
				spanX: {
					mobile: 2,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "linkedin",
					ext: "webp",
					alt: "test",
					subPath: "employability",
				},
				className: "w-full",
			},
			{
				id: 3,
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Linkedin",
					content: "Mastering the crossroads of professional connections.",
				},
				fr: {
					title: "Linkedin",
					content: "Maîtriser le carrefour des connexions professionnelles.",
				},
				className: "bg-primary-80",
			},
		],
	},
	{
		id: 1,
		rows: 3,
		cols: 3,
		items: [
			{
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Orientation coaching",
					content: "Every journey needs a compass.",
				},
				fr: {
					title: "Coaching d’orientation",
					content: "Chaque parcours a besoin d’une boussole.",
				},
				className: "bg-secondary-200",
			},
			{
				spanX: {
					mobile: 1,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "coaching-orientation",
					ext: "webp",
					alt: "test",
					subPath: "employability",
				},
				className: "w-full",
			},
			{
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Public speaking",
					content: "Be the Conductor of Your Speech !",
				},
				fr: {
					title: "Public speaking",
					content: "Soyez le chef d'orchestre de votre discours !",
				},
				className: "bg-secondary-200",
			},
			{
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Job search",
					content: "Do you need help with your « Treasure Hunt » ?",
				},
				fr: {
					title: "Recherche d’emploi",
					content: "Voulez-vous de l'aide dans votre « Chasse aux trésors » ?",
				},
				className: "bg-primary-80",
			},
			{
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Stage fright management",
					content: "Who said there are boundaries ?",
				},
				fr: {
					title: "Stage fright management",
					content: "Or the art of mastering your entrance on stage !",
				},
				className: "bg-tertiary-100",
			},
			{
				spanX: {
					mobile: 2,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "public-speaking",
					ext: "webp",
					alt: "test",
					subPath: "employability",
				},
				className: "w-full",
			},
			{
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "TP  presentation",
					content:
						"There are the juries, there are also family and friends: Impress them.",
				},
				fr: {
					title: "Présentation PFE",
					content:
						"Il y a les jurys, il y a aussi la famille et les amis : Impressionnez-les.",
				},
				className: "bg-tertiary-100",
			},
		],
	},
];

export const researchersThemes: Cluster[] = [
	{
		id: 0,
		rows: 3,
		cols: 3,
		items: [
			{
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Commercial viability evaluation",
					content:
						"Près de 42% des startups échouent en raison d'un manque de marché pour leur produit ou service (CB Insights).",
				},
				fr: {
					title: "Evaluation de la viabilité commerciale",
					content:
						"Nearly 42% of startups fail due to a lack of market for their product or service (CB Insights).",
				},
				className: "bg-secondary-200",
			},
			{
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Technology transfer strategies",
					content:
						"From the laboratory to the marketplace: Dare to take the step",
				},
				fr: {
					title: "Stratégies de transfert de technologie",
					content: "Du laboratoire à la marketplace : Osez faire le pas",
				},
				className: "bg-secondary-200",
			},
			{
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Intellectual property management",
					content: "Don't let anyone touch your baby.",
				},
				fr: {
					title: "Gestion de la propriété intellectuelle",
					content: "Ne laissez personne toucher à votre bébé.",
				},
				className: "bg-primary-80",
			},
			{
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Commercialization of technological innovations",
					content:
						"An innovation without communication is like a dish without cooking.",
				},
				fr: {
					title: "Commercialisation des innovations technologiques",
					content:
						"Une innovation sans communication est comme un plat sans cuisson.",
				},
				className: "bg-tertiary-100",
			},
			{
				spanX: {
					mobile: 1,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "creative-idea-2",
					ext: "webp",
					alt: "test",
					subPath: "researcher",
				},
				className: "w-full",
			},
			{
				spanX: {
					mobile: 2,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "finance-4",
					ext: "webp",
					alt: "test",
					subPath: "researcher",
				},
				className: "w-full",
			},
			{
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Access to financing",
					content: "Who says no to winning the jackpot ?",
				},
				fr: {
					title: "Accès au financement",
					content: "Qui dit non pour gagner le gros lot ?",
				},
				className: "bg-tertiary-100",
			},
		],
	},
	{
		id: 1,
		rows: 3,
		cols: 3,
		items: [
			{
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Entrepreneurial culture",
					content: "There's the GC and then there's the CE",
				},
				fr: {
					title: "Culture entrepreneuriale",
					content: "Il ya la CG et il y a aussi la CE",
				},
				className: "bg-secondary-200",
			},
			{
				spanX: {
					mobile: 1,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "business-culture",
					ext: "webp",
					alt: "test",
					subPath: "researcher",
				},
				className: "w-full",
			},
			{
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Business creation and management",
					content: "Build a prosperous home for your innovation.",
				},
				fr: {
					title: "Création et gestion d'entreprise",
					content: "Bâtissez un foyer prospère pour votre innovation.",
				},
				className: "bg-tertiary-100",
			},
			{
				spanX: {
					mobile: 2,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Personal development and entrepreneurial leadership",
					content: "It's time to listen to the leader within you.",
				},
				fr: {
					title: "Développement personnel et leadership entrepreneurial",
					content: "Il est temps d'écouter le leader qui sommeille en vous.",
				},
				className: "bg-primary-80",
			},
			{
				spanX: {
					mobile: 2,
					laptop: 2,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				picture: {
					src: "scientific-marketing",
					ext: "webp",
					alt: "test",
					subPath: "researcher",
				},
				className: "w-full",
			},
			{
				spanX: {
					mobile: 1,
					laptop: 1,
				},
				spanY: {
					mobile: 1,
					laptop: 1,
				},
				en: {
					title: "Scientific marketing",
					content: "You and your innovation deserve to shine !",
				},
				fr: {
					title: "Marketing scientifique",
					content: "Vous et votre innovation ont le droit de briller !",
				},
				className: "bg-tertiary-100",
			},
		],
	},
];
