import Image from "@components/elements/Image";
import type { FooterSocialsProps } from "@components/layout/Footer/Footer.types.ts";

export const FooterSocials = ({ socials }: FooterSocialsProps) => {
	return (
		<ul className="flex justify-center gap-[20px] _tablet:justify-start md:gap-[20px]">
			{socials.map((social) => {
				return (
					<li key={social.title}>
						<a
							href={social.link}
							rel="noreferrer"
							target="_blank"
							className="text-teal-700 hover:text-teal-700/75 transition"
						>
							<Image
								width={24}
								height={24}
								src={`/svg/misc/footer/${social.icon}.svg`}
								alt={String(social.title)}
								aria-label={social.title}
								loading="lazy"
							/>
							<span className="sr-only">{social.title}</span>
						</a>
					</li>
				);
			})}
		</ul>
	);
};
