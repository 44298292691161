import type {
	AxiosError,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from "axios";
import { getItem } from "../localStorage";
import { appConfig } from "@config/appConfig.ts";
import { decryptToken, getCookie } from "@api/utils/sessionUtils.ts";

export interface ConsoleError {
	status: number;
	data: unknown;
}

export const requestInterceptor = (
	config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
	const encryptedToken = getCookie(appConfig.authTokenCookieKey);

	if (encryptedToken) {
		const decryptedData = decryptToken(encryptedToken);
		if (decryptedData?.token) {
			config.headers.Authorization = `Bearer ${decryptedData.token}`;
		}
	}
	return config;
};

export const successInterceptor = (response: AxiosResponse): AxiosResponse => {
	return response;
};

export const errorInterceptor = async (error: AxiosError): Promise<void> => {
	if (error.response?.status === 401) {
		await Promise.reject(error);
	} else {
		if (error.response) {
			const errorMessage: ConsoleError = {
				status: error.response.status,
				data: error.response.data,
			};
			console.error(errorMessage);
		} else if (error.request) {
			console.error(error.request);
		} else {
			console.error("Error", error.message);
		}
		await Promise.reject(error);
	}
};
