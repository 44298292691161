import { FooterLogo } from "@components/layout/Footer/FooterLogo.tsx";
import { cn } from "@core/utils";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import {
	footerLinks,
	footerSocials,
} from "@api/database/constant/misc.data.ts";
import { FooterLinks } from "@components/layout/Footer/FooterLinks.tsx";
import { FooterActions } from "@components/layout/Footer/FooterActions.tsx";
import FooterCopyright from "@components/layout/Footer/FooterCopyright.tsx";

const Footer = () => {
	return (
		<footer
			aria-label="Footer"
			aria-labelledby="Footer"
			className={cn("w-full bg-secondary-100")}
		>
			<SectionWrapper className="w-full flex-col pb-[20px] pt-[20px] _tablet:pt-[40px]">
				<div className="relative mb-[20px] flex h-full w-full flex-col items-center justify-between gap-y-[30px] _laptop:flex-row _laptop:items-start ">
					<FooterLogo socials={footerSocials} />
					<FooterLinks links={footerLinks} />
					<FooterActions />
				</div>
				<FooterCopyright />
			</SectionWrapper>
		</footer>
	);
};
export default Footer;
