import type {
	AccordionButtonProps,
	AccordionLinkProps,
	FooterAccordionProps,
} from "@components/layout/Footer/Footer.types.ts";
import { useTranslation } from "react-i18next";
import type React from "react";
import { useLocation } from "@tanstack/react-router";
import { cn } from "@core/utils";

const AccordionButton: React.FC<AccordionButtonProps> = ({
	title,
	children,
	isActive,
}) => {
	return (
		<button className="group relative flex max-w-max  flex-col items-center focus:outline-none">
			<div className="flex h-[30px] flex-col items-center gap-[2px] ">
				<div className="flex">
					<span className="labelLarge truncate">{title}</span>
					<svg
						className="h-4 w-4 transition group-focus:rotate-180"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
							clipRule="evenodd"
						/>
					</svg>
				</div>

				{isActive && (
					<div className="h-1 w-full self-center rounded bg-secondary-900" />
				)}
			</div>
			<div className="buttonMedium flex max-h-0 flex-col items-center overflow-hidden duration-300 group-focus:max-h-40">
				{children}
			</div>
		</button>
	);
};

const AccordionLink: React.FC<AccordionLinkProps> = ({
	text,
	href,
	isActive,
}) => {
	const activeClass = isActive ? "font-bold" : "font-normal";

	return (
		<a
			className={`flex min-h-8 max-w-max items-center px-4 text-primary-700 ${activeClass}`}
			href={href}
		>
			{text}
		</a>
	);
};

const FooterAccordion: React.FC<FooterAccordionProps> = ({
	footerLinks,
	isActiveLink,
}) => {
	const { t } = useTranslation("layout");
	const router = useLocation().pathname;
	const pathname = router.replace(/^\/(en|fr)(\/|$)/, "/");
	return (
		<ul
			className={cn(
				"mb-[10px] flex w-full items-center justify-center _tablet:hidden",
				"flex flex-col items-center gap-[10px] rounded-md"
			)}
		>
			{footerLinks.map((link, index) => {
				if (link.children) {
					const isActive = isActiveLink === link.title;

					return (
						<li key={index}>
							<AccordionButton title={t(link.title + "")} isActive={isActive}>
								{link.children.map((child, index) => {
									return (
										<AccordionLink
											key={index}
											text={t(child.title + "")}
											href={child.link}
											isActive={pathname === child.link}
										/>
									);
								})}
							</AccordionButton>
						</li>
					);
				}
				return null;
			})}
		</ul>
	);
};

export default FooterAccordion;
