import React from "react";

import {
	AccountCircleOutlined,
	Crop32Outlined,
	HMobiledataOutlined,
	HorizontalRuleOutlined,
	HtmlOutlined,
	ImageOutlined,
	LibraryAddOutlined,
	NotesOutlined,
	SmartButtonOutlined,
	ViewColumnOutlined,
} from "@mui/icons-material";

import { TEditorBlock } from "../../../../editor/core";

type TButtonProps = {
	label: string;
	icon: JSX.Element;
	block: () => TEditorBlock;
};
export const BUTTONS: TButtonProps[] = [
	{
		label: "Heading",
		icon: <HMobiledataOutlined />,
		block: () => ({
			type: "Heading",
			data: {
				props: { text: "Hello friend" },
				style: {
					padding: { top: 16, bottom: 16, left: 24, right: 24 },
				},
			},
		}),
	},
	{
		label: "Text",
		icon: <NotesOutlined />,
		block: () => ({
			type: "Text",
			data: {
				props: { text: "My new text block" },
				style: {
					padding: { top: 16, bottom: 16, left: 24, right: 24 },
					fontWeight: "normal",
				},
			},
		}),
	},

	{
		label: "Button",
		icon: <SmartButtonOutlined />,
		block: () => ({
			type: "Button",
			data: {
				props: {
					text: "Button",
					url: "https://www.coachini.net",
				},
				style: { padding: { top: 16, bottom: 16, left: 24, right: 24 } },
			},
		}),
	},
	{
		label: "Image",
		icon: <ImageOutlined />,
		block: () => ({
			type: "Image",
			data: {
				props: {
					url: "https://assets.coachini.net/sample-image.jpg",
					alt: "Sample product",
					contentAlignment: "middle",
					linkHref: null,
				},
				style: { padding: { top: 16, bottom: 16, left: 24, right: 24 } },
			},
		}),
	},
	{
		label: "Avatar",
		icon: <AccountCircleOutlined />,
		block: () => ({
			type: "Avatar",
			data: {
				props: {
					imageUrl: "https://ui-avatars.com/api/?size=128",
					shape: "circle",
				},
				style: { padding: { top: 16, bottom: 16, left: 24, right: 24 } },
			},
		}),
	},
	{
		label: "Divider",
		icon: <HorizontalRuleOutlined />,
		block: () => ({
			type: "Divider",
			data: {
				style: { padding: { top: 16, right: 0, bottom: 16, left: 0 } },
				props: {
					lineColor: "#CCCCCC",
				},
			},
		}),
	},
	{
		label: "Spacer",
		icon: <Crop32Outlined />,
		block: () => ({
			type: "Spacer",
			data: {},
		}),
	},
	{
		label: "Html",
		icon: <HtmlOutlined />,
		block: () => ({
			type: "Html",
			data: {
				props: { contents: "<strong>Hello world</strong>" },
				style: {
					fontSize: 16,
					textAlign: null,
					padding: { top: 16, bottom: 16, left: 24, right: 24 },
				},
			},
		}),
	},
	{
		label: "Columns",
		icon: <ViewColumnOutlined />,
		block: () => ({
			type: "ColumnsContainer",
			data: {
				props: {
					columnsGap: 16,
					columnsCount: 3,
					columns: [
						{ childrenIds: [] },
						{ childrenIds: [] },
						{ childrenIds: [] },
					],
				},
				style: { padding: { top: 16, bottom: 16, left: 24, right: 24 } },
			},
		}),
	},
	{
		label: "Container",
		icon: <LibraryAddOutlined />,
		block: () => ({
			type: "Container",
			data: {
				style: { padding: { top: 16, bottom: 16, left: 24, right: 24 } },
			},
		}),
	},

	// { label: 'ProgressBar', icon: <ProgressBarOutlined />, block: () => ({}) },
	// { label: 'LoopContainer', icon: <ViewListOutlined />, block: () => ({}) },
];
