import { useTranslation } from "react-i18next";

function FooterCopyright() {
	const { t } = useTranslation("layout");
	return (
		<div className="w-full border-t border-text-300  pt-[10px]">
			<div className="flex flex-col gap-[10px] text-center _tablet_md:flex-row _tablet_md:justify-center">
				<p className="text-gray-500 mt-4 flex flex-col justify-center gap-[2px] text-sm sm:mt-0 _tablet_md:order-last _tablet_md:flex-row _tablet_md:gap-[10px]">
					<span>&copy; 2024 Coachini</span>
					<span className="hidden _tablet_md:block">| </span>
					{t("footer.copyright")}
				</p>

				{/*<p className="buttonMedium flex flex-col justify-center gap-0 text-gray-500 _tablet:flex-row _tablet:gap-[10px]">*/}
				{/*  <a*/}
				{/*    className="inline-block text-text-500 transition hover:text-text-500/75"*/}
				{/*    href="#"*/}
				{/*  >*/}
				{/*    Terms & Conditions*/}
				{/*  </a>*/}

				{/*  <span>&middot;</span>*/}

				{/*  <a*/}
				{/*    className="inline-block text-text-500 transition hover:text-text-500/75"*/}
				{/*    href="#"*/}
				{/*  >*/}
				{/*    Privacy Policy*/}
				{/*  </a>*/}
				{/*  <span>&middot;</span>*/}

				{/*  <a*/}
				{/*    className="inline-block text-text-500 transition hover:text-text-500/75"*/}
				{/*    href="#"*/}
				{/*  >*/}
				{/*    Accessibility*/}
				{/*  </a>*/}
				{/*</p>*/}
			</div>
		</div>
	);
}

export default FooterCopyright;
