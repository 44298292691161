import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useAuth from "@core/guards/auth.util.ts";
import {
	sendOtp,
	submitForm,
	verifyOtp,
} from "@api/service/startgreen.services.ts";

interface Step3Props {
	nextStep: () => void;
	prevStep: () => void;
	formData: any;
}

function maskEmail(email) {
	const [localPart, domain] = email.split("@");
	const maskedLocalPart = localPart.replace(/(\w{2})\w+(\w{2})/, "$1**$2");
	return `${maskedLocalPart}@${domain}`;
}

const Step3: React.FC<Step3Props> = ({ prevStep, nextStep, formData }) => {
	const dispatch = useDispatch();

	const [otp, setOtp] = useState("");
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [resendLoading, setResendLoading] = useState(false);

	const { isAuthenticated, handleLogin } = useAuth();

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const otpResponse = await verifyOtp(formData.email, otp);
			const submissionAnswers = [
				{ values: formData.first_name, questionName: "first_name" }, //ADD
				{ values: formData.last_name, questionName: "last_name" }, //ADD
				// { values: formData.full_name, questionName: "full_name" }, DEL
				{ values: formData.email, questionName: "email" },
				{ values: formData.phone_number, questionName: "phone_number" },
				{ values: formData.region, questionName: "region" },
				{ values: formData.age, questionName: "age" },
				{ values: formData.gender, questionName: "gender" },
				{
					values: formData.professional_situation,
					questionName: "professional_situation",
				},
				{ values: formData.education_level, questionName: "education_level" },
				{
					values: formData.education_level_other,
					questionName: "education_level_other",
				},
				// ------------------------------------ //
				{
					values: formData.development_stage,
					questionName: "development_stage",
				},
				{
					values: formData.project_activity,
					questionName: "project_activity",
				},
				{
					// ADD
					values: formData.project_activity_other,
					questionName: "project_activity_other",
				},
				// ------------------------------------ //
				{ values: formData.employees_number, questionName: "employees_number" },
				{ values: formData.sales_revenue, questionName: "sales_revenue" },
				// ------------------------------------ //
				{ values: formData.support_needs, questionName: "support_needs" },
				{
					values: formData.support_needs_other,
					questionName: "support_needs_other",
				},
				// ------------------------------------ //
				{ values: formData.support_themes, questionName: "support_themes" },
				{
					values: formData.support_themes_other,
					questionName: "support_themes_other",
				},
				// ------------------------------------ //
				{
					values: formData.hackathon_participation,
					questionName: "hackathon_participation",
				},
				// ------------------------------------ //
				{ values: formData.previous_support, questionName: "previous_support" },
				{
					values: formData.previous_support_details,
					questionName: "previous_support_details",
				},
				// ------------------------------------ //
				{ values: formData.discovery_source, questionName: "discovery_source" },
				{
					values: formData.discovery_source_other,
					questionName: "discovery_source_other",
				},
			];

			const submissionData = {
				otp,
				email: formData.email,
				isVerified: true,
				type: "USER",
				phase: 1,
				status: "PENDING",
				answers: submissionAnswers,
			};
			if (otpResponse.data) {
				const submissionResponse = await submitForm(submissionData);
				if (
					submissionResponse.status === 201 ||
					submissionResponse.status === 200
				) {
					nextStep();
				} else {
					setErrorMessage(
						"Une erreur s'est produite lors de la soumission du formulaire."
					);
				}
			} else {
				setErrorMessage(
					"Code invalide, Veuillez vérifier votre courrier électronique"
				);
			}
		} catch (error) {
			setErrorMessage("An error occurred while verifying OTP.");
		} finally {
			setLoading(false);
		}
	};

	const handleResendOtp = async () => {
		try {
			setResendLoading(true);
			const fullName = `${formData.first_name} ${formData.last_name}`;
			await sendOtp(formData.email, fullName);
		} catch (error) {
			setErrorMessage("An error occurred while resending OTP.");
		} finally {
			setResendLoading(false);
		}
	};

	return (
		<div className="w-full">
			{/*{isAuthenticated ? <h1>authed</h1> : <h1>not authed</h1>}*/}
			{/*<pre className="mb-4">{JSON.stringify(formData, null, 2)}</pre>*/}

			<div className={"flex flex-col bg-white rounded-md p-12 gap-[30px]"}>
				<div className="flex flex-col items-center justify-center text-center space-y-2">
					<div className="font-semibold text-3xl">
						<p>Vérification de l'email</p>
					</div>
					<div className="flex flex-row text-sm font-medium text-gray-400">
						<p>
							Nous avons envoyé un code à votre email{" "}
							{maskEmail(formData.email)}
						</p>
					</div>
				</div>
				<input
					className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
					type="text"
					value={otp}
					onChange={(e) => {
						setOtp(e.target.value);
					}}
				/>

				{errorMessage && (
					<div className="text-red-500 text-sm text-center mt-2">
						{errorMessage}
					</div>
				)}

				<div className="flex flex-col items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
					<p>Vous n'avez pas reçu le code ?</p>
					<button
						onClick={handleResendOtp}
						disabled={resendLoading}
						className="flex flex-row items-center text-iptic_primary-100"
					>
						{resendLoading ? "Renvoi en cours..." : "Renvoyer"}
					</button>
				</div>
			</div>

			<div className="flex items-center justify-center space-x-4">
				<button
					type="button"
					onClick={prevStep}
					className="bg-iptic_primary-100/20 hover:bg-iptic_primary-100/30 text-iptic_primary-100 px-6 py-3 rounded-md mt-4"
				>
					Précédent
				</button>
				<button
					onClick={handleSubmit}
					type="submit"
					disabled={loading}
					className={`${
						loading
							? "bg-iptic_primary-100/50 cursor-not-allowed"
							: "bg-iptic_primary-100 hover:bg-iptic_primary-100/80"
					} text-white px-6 py-3 rounded-md mt-4`}
				>
					{loading ? "En cours..." : "Postulez"}
				</button>
			</div>
		</div>
	);
};

export default Step3;
