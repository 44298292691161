import React from "react";
import { useNavigate } from "@tanstack/react-router";
import useAuth from "@core/guards/auth.util.ts";

interface AuthGuardProps {
	children: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
	const { isAuthenticated, loading } = useAuth();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (!loading && !isAuthenticated) {
			navigate({
				to: "/startgreen-by-iptic/login",
			});
		}
	}, [loading, isAuthenticated, navigate]);

	// if (loading) {
	// 	return <div>Loading...</div>; // Or a spinner component
	// }

	return isAuthenticated ? <>{children}</> : null;
};

export default AuthGuard;
