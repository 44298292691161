import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { step1ValidationSchema } from "@pages/(startgreen)/views/(form)/validationSchema.ts";
import {
	SelectGroup,
	TextInputGroup,
} from "@pages/(startgreen)/views/(form)/Step2.tsx";
import {
	discoverySourceOptions,
	educationLevelOptions,
	genderOptions,
	hackathonParticipationOptions,
	previousSupportOptions,
	professionalSituationOptions,
	projectAreaOptions,
	projectDevelopmentStageOptions,
	regionOptions,
	supportNeedsOptions1,
	supportNeedsOptions2,
	supportThemesOptions,
} from "@pages/(startgreen)/views/(form)/FormStructure.ts";
import {
	CheckboxGroup,
	RadioGroup,
} from "@pages/(startgreen)/views/(form)/FormGroups.tsx";
import useResetFieldOnCondition from "@pages/(startgreen)/views/(form)/useRestFieldOnCondition.ts";
import { sendOtp } from "@api/service/startgreen.services.ts";
import toast, { Toaster } from "react-hot-toast";

interface Step1Props {
	nextStep: () => void;
	formData: any;
	setFormData: (values: any) => void;
}

const Step1: React.FC<Step1Props> = ({ nextStep, formData, setFormData }) => {
	const [questions, setQuestions] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleFormSubmit = async (values: any) => {
		try {
			setLoading(true);
			setFormData(values);
			const fullName = `${values.first_name} ${values.last_name}`;
			await sendOtp(values.email, fullName);
			nextStep();
		} catch (error: any) {
			console.error(error);

			if (error?.response?.data?.message) {
				toast.error(error.response.data.message);
			} else {
				toast.error("Une erreur s'est produite, veuillez réessayer plus tard.");
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<Formik
			initialValues={formData}
			validationSchema={step1ValidationSchema}
			onSubmit={handleFormSubmit}
		>
			{({
				values,
				handleSubmit,
				setFieldValue,
				validateForm,
				setTouched,
				setErrors,
				errors,
			}) => {
				const handleCustomSubmit = async (e: React.FormEvent) => {
					e.preventDefault(); // Prevent the default form submission
					const formErrors = await validateForm(); // Manually validate the form

					if (Object.keys(formErrors).length > 0) {
						// If there are validation errors
						setErrors(formErrors); // Set Formik's error state

						// Optionally, set all fields as touched to display errors
						setTouched(
							Object.keys(formErrors).reduce(
								(acc, key) => {
									acc[key] = true;
									return acc;
								},
								{} as { [field: string]: boolean }
							)
						);

						// Extract the first error message
						const firstError = Object.values(formErrors)[0];
						toast.error(
							firstError
								? `Des champs sont manquants: ${firstError}. Veuillez vérifier le formulaire.`
								: "Des champs sont manquants, veuillez vérifier le formulaire."
						);
					} else {
						// If no validation errors, proceed with Formik's handleSubmit
						handleSubmit(e);
					}
				};

				const isSupportThemesOtherDeselected = !values.support_themes?.includes(
					"support_themes_other"
				);
				useEffect(() => {
					void setFieldValue("project_activity", []);
					void setFieldValue("employees_number", "0");
					void setFieldValue("sales_revenue", "0");
					void setFieldValue("support_needs", "");
				}, [values.development_stage, setFieldValue]);

				useEffect(() => {
					void setFieldValue("support_needs_other", "");
				}, [values.support_needs, setFieldValue]);

				useResetFieldOnCondition(
					isSupportThemesOtherDeselected,
					"support_themes_other",
					setFieldValue,
					[values.support_themes]
				);
				return (
					<Form onSubmit={handleCustomSubmit}>
						<Toaster
							position="bottom-center"
							toastOptions={{
								duration: 3000,
							}}
							reverseOrder={false}
						/>
						<FormHeader />
						<FormContent values={values} />
						<SubmitButton loading={loading} />
					</Form>
				);
			}}
		</Formik>
	);
};

const FormHeader = () => (
	<div className="flex flex-col mb-[30px]">
		<h2 className="text-[24px] font-bold text-iptic_primary-100">
			Mon projet GreenTech
		</h2>
		{/*<h3 className="text-[18px] text-[#6F6C90]">*/}
		{/*	Mon idée de projet consiste à:*/}
		{/*</h3>*/}
	</div>
);

const FormContent = ({ values }: { values: any }) => (
	<div className="flex flex-col gap-[30px]">
		{/*TODO: CHANGE FORMDATA*/}
		<TextInputGroup title="NOM :" name="first_name" type="text" />
		{/*TODO: CHANGE FORMDATA*/}
		<TextInputGroup title="Prénom :" name="last_name" type="text" />
		<TextInputGroup title="Mon adresse e-mail :" name="email" type="email" />
		<TextInputGroup
			title="Mon numéro de téléphone :"
			name="phone_number"
			type="tel"
		/>
		<SelectGroup
			title="Ma région est :"
			name="region"
			options={regionOptions}
		/>
		<TextInputGroup title="J'ai (âge) :" name="age" type="number" />
		<RadioGroup title="Je suis :" name="gender" options={genderOptions} />
		<RadioGroup
			title="Mon niveau d'études actuel ou le plus élevé :"
			name="education_level"
			options={educationLevelOptions}
			conditionalField={{
				condition: values.education_level === "education_level_other",
				name: "education_level_other",
				label: "Lequel ?",
				placeholder: "Precisez...",
			}}
		/>
		<RadioGroup
			title="Ma situation professionnelle actuelle :"
			name="professional_situation"
			options={professionalSituationOptions}
		/>
		{/*--------------Project Details------------------*/}

		<RadioGroup
			title="Actuellement, je suis à ce stade :"
			name="development_stage"
			options={projectDevelopmentStageOptions}
		/>

		{/* Conditional Questions */}
		{/* If 'Idea Not Started' or 'Non-StartUp Act' is selected */}
		{/*TODO: EDIT FORMDATA AND CONDITIONAL project_activity_other*/}
		{values.development_stage === "project_idea_not_started" && (
			<CheckboxGroup
				title="L’idée de mon projet est dans :"
				name="project_activity"
				options={projectAreaOptions}
				conditionalField={{
					condition: values.project_activity?.includes(
						"project_activity_other"
					),
					name: "project_activity_other",
					label: "Lequel ?",
					placeholder: "Precisez...",
				}}
			/>
		)}

		{(values.development_stage === "no_startup_act_project" ||
			values.development_stage === "yes_startup_act_project") && (
			<CheckboxGroup
				title="L’activité de mon projet est dans :"
				name="project_activity"
				options={projectAreaOptions}
				conditionalField={{
					condition: values.project_activity?.includes(
						"project_activity_other"
					),
					name: "project_activity_other",
					label: "Lequel ?",
					placeholder: "Precisez...",
				}}
			/>
		)}

		{(values.development_stage === "no_startup_act_project" ||
			values.development_stage === "yes_startup_act_project") && (
			<>
				<TextInputGroup
					title="Nombre d’employés :"
					name="employees_number"
					type="number"
				/>
				<TextInputGroup title="Revenus :" name="sales_revenue" type="number" />
			</>
		)}

		{values.development_stage === "no_startup_act_project" && (
			<>
				<RadioGroup
					title="J’ai besoin de :"
					name="support_needs"
					options={supportNeedsOptions1}
					conditionalField={{
						condition: values.support_needs === "support_needs_other",
						name: "support_needs_other",
						label: "Lequel ?",
						placeholder: "Précisez...",
					}}
				/>
			</>
		)}

		{values.development_stage === "yes_startup_act_project" && (
			<>
				<RadioGroup
					title="J’ai besoin de :"
					name="support_needs"
					options={supportNeedsOptions2}
					conditionalField={{
						condition: values.support_needs === "support_needs_other",
						name: "support_needs_other",
						label: "Lequel ?",
						placeholder: "Precisez...",
					}}
				/>
			</>
		)}

		{/*------------------------------------------------------*/}
		<CheckboxGroup
			title="Je souhaite être accompagné dans le(s) thème(s) suivant(s) :"
			name="support_themes"
			options={supportThemesOptions}
			conditionalField={{
				condition: values.support_themes?.includes("support_themes_other"),
				name: "support_themes_other",
				label: "Lequel ?",
				placeholder: "Precisez...",
			}}
		/>
		{/*----------------------------------------------------------------------*/}
		<RadioGroup
			title="Je souhaite participer au Hackathon et soumettre mon projet ou futur projet à un panel de financiers :"
			name="hackathon_participation"
			options={hackathonParticipationOptions}
		/>
		<RadioGroup
			title="J'ai déjà bénéficié d'un accompagnement pour mon projet :"
			name="previous_support"
			options={previousSupportOptions}
			conditionalField={{
				condition: values.previous_support === "yes_received_support",
				name: "previous_support_details",
				label: "Lequel ?",
				placeholder: "Détaillez l'accompagnement reçu...",
			}}
		/>
		<CheckboxGroup
			title="J'ai découverat le programme StartGreen via :"
			name="discovery_source"
			options={discoverySourceOptions}
			conditionalField={{
				condition: values.discovery_source?.includes("discovery_source_other"),
				name: "discovery_source_other",
				placeholder: "Précisez...",
			}}
		/>
	</div>
);

const SubmitButton = ({ loading }: { loading: boolean }) => (
	<div className="flex items-center justify-center">
		{/*<button*/}
		{/*	type="submit"*/}
		{/*	className="bg-startgreen_primary-100 hover:bg-startgreen_primary-100/90 text-white px-6 py-3 rounded-md mt-4"*/}
		{/*>*/}
		{/*	Suivant*/}
		{/*</button>*/}

		<button
			type="submit"
			disabled={loading}
			className={`${
				loading
					? "bg-iptic_primary-100/50 cursor-not-allowed"
					: "bg-iptic_primary-100 hover:bg-iptic_primary-100/80"
			} text-white px-6 py-3 rounded-md mt-4`}
		>
			{loading ? "En cours..." : "Suivant"}
		</button>
	</div>
);

export default Step1;
