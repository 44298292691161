import React, { useEffect, useState } from "react";

import {
	Box,
	Button,
	Divider,
	Drawer,
	Stack,
	TextField,
	Typography,
	Snackbar,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";

import { PlusIcon } from "@heroicons/react/20/solid";

import {
	useDocument,
	useSamplesDrawerOpen,
} from "../../documents/editor/EditorContext";

import SidebarButton from "./SidebarButton";
import {
	createTemplate,
	deleteTemplate,
	fetchTemplates,
	updateTemplate,
} from "@api/service/startgreen.services.ts";
import useGenericQuery from "@hooks/useGenericQuery.ts";
import { useMutation } from "@tanstack/react-query";
import EMPTY_EMAIL_MESSAGE from "@/mail-builder/getConfiguration/sample/empty-email-message.ts";

export const SAMPLES_DRAWER_WIDTH = 240;

export default function SamplesDrawer() {
	const samplesDrawerOpen = useSamplesDrawerOpen();
	const [message, setMessage] = useState<{ type: string; text: string } | null>(
		null
	);
	const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
	const [editableTemplateId, setEditableTemplateId] = useState<string | null>(
		null
	);
	const [updatedName, setUpdatedName] = useState("");
	const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
	const [templateToDelete, setTemplateToDelete] = useState<string | null>(null);
	const [openUpdateConfirmation, setOpenUpdateConfirmation] = useState(false);

	useEffect(() => {
		if (message) {
			const timer = setTimeout(() => setMessage(null), 3000);
			return () => clearTimeout(timer);
		}
	}, [message]);

	const createTemplateMutation = useMutation({
		mutationFn: createTemplate,
		onSuccess: (data) => {
			setMessage({ type: "success", text: "Template created successfully." });
			const createdTemplate = data.data.template;
			setSelectedTemplate(createdTemplate.id);
			refetch();
		},
		onError: () => {
			setMessage({ type: "error", text: "Failed to create template." });
		},
	});
	const updateTemplateMutation = useMutation({
		mutationFn: updateTemplate,
		onSuccess: () => {
			setMessage({ type: "success", text: "Template updated successfully." });
			refetch();
			setOpenUpdateConfirmation(true);
			setUpdatedName(""); // Reset updated name after successful update
		},
		onError: () => {
			setMessage({ type: "error", text: "Failed to update template." });
		},
	});

	const deleteTemplateMutation = useMutation({
		mutationFn: (id: string) => deleteTemplate(id),
		onSuccess: () => {
			setMessage({ type: "success", text: "Template deleted successfully." });
			refetch();
		},
		onError: () => {
			setMessage({ type: "error", text: "Failed to delete template." });
		},
	});

	const {
		data: templates,
		isLoading: isTemplatesLoading,
		refetch,
	} = useGenericQuery({
		queryKey: ["mailTemplates"],
		queryFn: fetchTemplates,
	});

	const handleNameDoubleClick = (template) => {
		setEditableTemplateId(template.id);
		setUpdatedName(template.name);
	};

	const handleUpdateNameChange = (event) => {
		setUpdatedName(event.target.value);
	};

	const handleUpdateNameSubmit = (id) => {
		setEditableTemplateId(null);
		updateTemplateMutation.mutate({ id, name: updatedName });
		setUpdatedName(""); // Reset updated name after updating
	};
	const handleSelectTemplate = (templateId) => {
		setSelectedTemplate(templateId);
		setEditableTemplateId(null);
		setUpdatedName(""); // Clear the updated name on template selection
	};

	const handleDeleteTemplate = (id) => {
		setTemplateToDelete(id);
		setOpenDeleteConfirmation(true);
	};

	const confirmDeleteTemplate = () => {
		if (templateToDelete) {
			deleteTemplateMutation.mutate(templateToDelete);
			setOpenDeleteConfirmation(false);
			setTemplateToDelete(null);
		}
	};

	const cancelDeleteTemplate = () => {
		setOpenDeleteConfirmation(false);
		setTemplateToDelete(null);
	};

	const document = useDocument();

	return (
		<Drawer
			variant="persistent"
			anchor="left"
			open={samplesDrawerOpen}
			sx={{
				width: samplesDrawerOpen ? SAMPLES_DRAWER_WIDTH : 0,
			}}
		>
			<Stack
				spacing={3}
				py={1}
				px={2}
				width={SAMPLES_DRAWER_WIDTH}
				justifyContent="space-between"
				height="100%"
			>
				<Stack
					spacing={2}
					sx={{
						"& .MuiButtonBase-root": {
							width: "100%",
							justifyContent: "flex-start",
						},
					}}
				>
					<Typography variant="h6" component="h1" sx={{ p: 0.75 }}>
						EXP01 Mail Builder
					</Typography>

					<Stack alignItems="flex-start">
						{!isTemplatesLoading &&
							templates.map((template) => (
								<Box key={template.id} width="100%">
									{editableTemplateId === template.id ? (
										<TextField
											value={updatedName}
											onChange={handleUpdateNameChange}
											// onBlur={() => handleUpdateNameSubmit(template.id)}
											autoFocus
											fullWidth
											size="small"
										/>
									) : (
										<div
											onDoubleClick={() => handleNameDoubleClick(template)}
											onClick={() => handleSelectTemplate(template.id)}
										>
											<SidebarButton
												config={template.template}
												href={`#sample/${template.id}`}
												sx={{
													backgroundColor:
														template.id === selectedTemplate
															? "rgba(0, 0, 0, 0.08)"
															: "inherit",
												}}
											>
												{template.name}
											</SidebarButton>
										</div>
									)}
									{selectedTemplate === template.id && (
										<Stack direction="row" spacing={1} mt={1}>
											<Button
												variant="outlined"
												color="primary"
												size="small"
												onClick={() => {
													setEditableTemplateId(null);
													const templateName =
														updatedName.length > 0
															? updatedName
															: template.name;
													updateTemplateMutation.mutate({
														id: selectedTemplate,
														name: templateName,
														template: document,
													});
												}}
											>
												Update
											</Button>
											<Button
												variant="outlined"
												color="error"
												size="small"
												onClick={() => handleDeleteTemplate(template.id)}
											>
												Delete
											</Button>
										</Stack>
									)}
								</Box>
							))}
					</Stack>

					<Divider />

					<Stack alignItems="flex-start">
						<div
							onClick={() =>
								createTemplateMutation.mutate({
									name: "new_template_" + new Date().getTime(),
									template: EMPTY_EMAIL_MESSAGE,
									dynamicFields: [],
								})
							}
						>
							<SidebarButton
								href="#"
								startIcon={
									<Box component="span" sx={{ fontSize: "1.25rem", mr: 1 }}>
										+
									</Box>
								}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="size-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
									/>
								</svg>
								Add New Template
							</SidebarButton>
						</div>
					</Stack>
				</Stack>
			</Stack>

			{/* Snackbar for Toast Notifications */}
			<Snackbar
				open={!!message}
				autoHideDuration={3000}
				onClose={() => setMessage(null)}
				message={message?.text}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			/>

			{/* Delete Confirmation Dialog */}
			<Dialog open={openDeleteConfirmation} onClose={cancelDeleteTemplate}>
				<DialogTitle>Delete Template</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete this template? This action cannot be
						undone.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={cancelDeleteTemplate} color="primary">
						Cancel
					</Button>
					<Button onClick={confirmDeleteTemplate} color="error">
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			{/* Update Confirmation Dialog */}
			<Dialog
				open={openUpdateConfirmation}
				onClose={() => {
					setEditableTemplateId(null);
					setOpenUpdateConfirmation(false);
				}}
			>
				<DialogTitle>Update Successful</DialogTitle>
				<DialogContent>
					<DialogContentText>
						The template has been successfully updated.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setOpenUpdateConfirmation(false)}
						color="primary"
					>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</Drawer>
	);
}
