import Image from "@components/elements/Image";
import { cn } from "@core/utils";

const SVGComponent = ({
	className,
	svgShape,
}: {
	className?: string;
	svgShape: string;
}) => {
	const svgSource = `/svg/misc/sponsor-accessories/${svgShape}.svg`;

	return (
		<div
			className={cn(
				"pointer-events-none absolute select-none",
				svgShape === "circle-1" && "top-[-20px] w-[125%]",
				svgShape === "circle-2" &&
					"left-[-35%] top-[-5%] w-[125%] rotate-[-45deg] ",
				svgShape === "rectangle-1" && "top-[-20%]",
				svgShape === "rectangle-2" && "left-[-7%] top-[-25%]",
				svgShape === "square-1" && "absolute left-[-15%] h-[100%]",
				svgShape === "square-2" && "absolute left-[-15%] top-[-15%]",
				className
			)}
		>
			<Image
				className="h-full w-full"
				width={50}
				height={50}
				src={svgSource}
				alt="sponsor logo"
				aria-label="sponsor logo"
				loading="lazy"
			/>
		</div>
	);
};

export default SVGComponent;
