import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { Coaches } from "@pages/(app)/Coaches.tsx";
import { useTranslation } from "react-i18next";

const Page = () => {
	const { t: tMeta } = useTranslation("metadata");

	return (
		<>
			<Meta
				title={tMeta("coaches-page.title")}
				template={"%s | Coachini"}
				description={tMeta("coaches-page.description")}
				keywords={[
					"Coaches",
					"Coachini Coaches",
					"Associated Coaches",
					"Coaching",
				]}
				robots="index,follow"
				ogTitle={tMeta("coaches-page.title")}
				ogDescription={tMeta("coaches-page.description")}
			/>
			<Coaches />
		</>
	);
};
export const Route = createFileRoute("/(main)/coaches/")({
	component: Page,
});
