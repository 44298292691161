export const TemplateList = ({
	onTemplateSelect,
	templates,
	isLoading,
	onCreateTemplate,
	onDeleteTemplate,
}) => {
	if (isLoading) return <div>Chargement...</div>;

	return (
		<div className="template-list space-y-4">
			{/*<button*/}
			{/*	className="bg-startgreen_darkblue-100 text-white px-4 py-2 rounded hover:bg-blue-600"*/}
			{/*	onClick={onCreateTemplate}*/}
			{/*>*/}
			{/*	Créer un nouveau modèle*/}
			{/*</button>*/}
			{templates &&
				templates.map((template) => (
					<div
						key={template.id}
						onClick={() => onTemplateSelect(template)}
						className="template-item p-4 bg-white hover:bg-gray-100/10 cursor-pointer rounded shadow-md flex justify-between items-center"
					>
						<span className="cursor-pointer">{template.name}</span>
						{/*<button*/}
						{/*	onClick={() => onDeleteTemplate(template.id)}*/}
						{/*	className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"*/}
						{/*>*/}
						{/*	Supprimer*/}
						{/*</button>*/}
					</div>
				))}
		</div>
	);
};
