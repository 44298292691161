import type React from "react";

import { cn } from "@core/utils";

interface CompanyReviewCardProps {
	title: string;
	content: string;
	companyName: string;
	writer: string;
	date?: string;
}

const CompanyReviewCard: React.FC<CompanyReviewCardProps> = ({
	content,
	companyName,
	writer,
	date,
}) => {
	return (
		<div
			className={cn(
				"slide-content",
				"flex h-[550px] max-w-[90%] flex-col items-center justify-center gap-6 self-center rounded-md bg-logoWhite text-titleLarge leading-titleLarge text-text-black shadow-base2",
				"p-[44px] ",
				"_tablet:h-[400px]"
			)}
		>
			<svg
				className="min-h-[26px] min-w-[41px]"
				width="41"
				height="26"
				viewBox="0 0 41 26"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M10.7111 23.7001C10.4819 24.5816 9.68618 25.197 8.77537 25.197H2.01732C0.585606 25.197 -0.382396 23.7366 0.175266 22.418L9.13964 1.22099C9.45262 0.480905 10.1781 0 10.9817 0H14.2847C15.5936 0 16.5497 1.23636 16.2204 2.50311L10.7111 23.7001Z"
					fill="#334155"
					fillOpacity="0.3"
				/>
				<path
					d="M30.3292 1.49689C30.5583 0.615379 31.3541 0 32.2649 0H38.9119C40.3436 0 41.3116 1.46038 40.754 2.77902L31.7896 23.976C31.4766 24.7161 30.7511 25.197 29.9476 25.197H26.7555C25.4467 25.197 24.4906 23.9606 24.8198 22.6939L30.3292 1.49689Z"
					fill="#334155"
					fillOpacity="0.3"
				/>
			</svg>
			{/*<h3>{title}</h3>*/}
			<p className="text-center text-titleSmall leading-titleSmall _tablet:text-titleMedium _tablet:leading-titleMedium _laptop:text-titleLarge _laptop:leading-titleLarge">
				{content}
			</p>

			<div className="flex flex-col items-center">
				<div className="flex flex-row gap-2">
					<p className="text-center text-titleSmall font-semibold leading-titleSmall _tablet:text-titleMedium _tablet:leading-titleMedium _laptop:text-titleLarge _laptop:leading-titleLarge">
						{companyName}, {writer}
					</p>
				</div>
				<p className="text-center text-titleSmall leading-titleSmall _tablet:text-titleMedium _tablet:leading-titleMedium _laptop:text-titleLarge _laptop:leading-titleLarge">
					{date}
				</p>
			</div>
		</div>
	);
};

export default CompanyReviewCard;
