import type React from "react";
import { useState } from "react";
import { cn } from "@core/utils";
import Button from "@components/elements/Buttons/Button.tsx";
import toast, { Toaster } from "react-hot-toast";
import emailjs from "@emailjs/browser";
import { appConfig } from "@config/appConfig.ts";
import Spinner from "@components/elements/Spinner";
import { useTranslation } from "react-i18next";

interface InputFieldProps {
	className?: React.ComponentProps<"div">["className"];
	inputClassName?: React.ComponentProps<"input">["className"];
	buttonClassName?: React.ComponentProps<"button">["className"];
	placeholderText?: string;
	buttonLabel?: string;
	onButtonClick?: () => void;
}
emailjs.init(appConfig.emailJSPublicKey);

const InputField: React.FC<InputFieldProps> = ({
	className,
	inputClassName,
	buttonClassName,
	placeholderText = "Votre adresse mail",
	buttonLabel = "Request a Demo",
	onButtonClick,
}) => {
	const [inputValue, setInputValue] = useState("");
	const [loading, setLoading] = useState(false);
	const { t: tMisc } = useTranslation("misc");

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
	};

	const handleFormSubmit = async () => {
		if (!inputValue) {
			toast.error(tMisc("responses.newsletter-no-mail"));
			return;
		}

		setLoading(true);
		try {
			await emailjs.send(
				appConfig.emailJSServiceId,
				appConfig.emailJSNewsletterTemplateId,
				{ email: inputValue },
				appConfig.emailJSPublicKey
			);
			toast.success(tMisc("responses.newsletter-sent"));
			setInputValue("");
		} catch {
			toast.error(tMisc("responses.newsletter-error"));
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Toaster position="bottom-right" reverseOrder={false} />

			<div
				className={cn(
					"shadow-md flex flex-shrink-0 flex-row items-center self-center",
					"rounded-md bg-primary-50 p-[10px] ",
					"flex-col _tablet:flex-row _tablet:items-center _tablet:gap-2",
					className
				)}
			>
				<input
					type="text"
					placeholder={placeholderText}
					className={cn(
						"appearance-none rounded-lg border-none bg-primary-50 p-[10px] text-labelMedium",
						"px-4 py-3 text-center placeholder-text-400",
						"w-full focus:border-none focus:outline-none focus:ring-0",
						"_tablet:min-w-[60px] _tablet:p-2 _tablet:text-left",
						" _laptop_sm:w-full ",
						inputClassName
					)}
					value={inputValue}
					onChange={handleInputChange}
				/>
				<Button
					variant="primary"
					size="base"
					className={cn("w-full", buttonClassName)}
					onClick={handleFormSubmit}
					disabled={loading}
				>
					{loading && <Spinner />}
					{buttonLabel}
				</Button>
			</div>
		</>
	);
};

export default InputField;
