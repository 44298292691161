import { valuesData } from "@api/database/constant/misc.data.ts";
import { useTranslation } from "react-i18next";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { cn } from "@core/utils";
import { GridSection } from "@components/views/(app)/common/ValueSection/GridSection.tsx";
import Text from "@components/elements/Text";
import i18next from "i18next";

function ValueSection({ className }: { className?: string }) {
	const currentLanguageData = valuesData.map((item) => {
		return {
			...item,
			// @ts-ignore
			content: item[i18next.resolvedLanguage],
		};
	});
	const { t } = useTranslation("common");

	return (
		<SectionWrapper
			className={cn(
				"flex flex-col items-center gap-[50px]",
				"mt-[70px]",
				className
			)}
		>
			<div className="sm:max-w-7xl">
				<div className="flex flex-col gap-[10px]">
					<Text
						styled
						tag="h2"
						className="text-center"
						highlightStyle="font-semibold text-secondary-900"
						text={t("value-section.title")}
					/>
					<Text
						styled
						tag="p"
						className="_max-w-1 text-center"
						text={t("value-section.paragraph")}
					/>
				</div>
			</div>

			<GridSection
				showHashSign={true}
				titleStyle="text-text-900"
				itemStyle="min-w-[100%] _tablet:min-w-[400px] max-w-[200px]"
				descriptionStyle="text-text-700"
				items={currentLanguageData}
			/>
		</SectionWrapper>
	);
}

export default ValueSection;
