import React, { useState } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	FormControlLabel,
	Checkbox,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Typography,
	CircularProgress,
} from "@mui/material";
import { exportData } from "@api/service/startgreen.services.ts";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

const ExportDataDialog = ({ isOpen, onClose }) => {
	const [exportStatus, setExportStatus] = useState("ALL");
	const [sendEmail, setSendEmail] = useState(false);
	const [emailAddress, setEmailAddress] = useState("");
	const [fileFormat, setFileFormat] = useState("xlsx");

	const mutation = useMutation({
		mutationFn: exportData,
		onSuccess: (data) => {
			if (sendEmail) {
				toast.success("Mail envoyé !");
				onClose();
			} else {
				// Decode the Base64 string
				const byteCharacters = atob(data.data);
				const byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				const blob = new Blob([byteArray], {
					type: data.contentType,
				});
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = data.filename;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
				toast.success("Mail téléchargé !");
				onClose();
			}
		},
		onError: () => {
			toast.error("Échec de l'exportation des données. Veuillez réessayer.");
		},
		onSettled: () => {
			if (!sendEmail) onClose();
		},
	});

	const handleExport = () => {
		mutation.mutate({
			status: exportStatus,
			format: fileFormat,
			email: sendEmail ? emailAddress : undefined,
		});
	};

	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Exporter les Données</DialogTitle>
			<DialogContent>
				<FormControl fullWidth margin="normal">
					<InputLabel>Status</InputLabel>
					<Select
						value={exportStatus}
						onChange={(e) => setExportStatus(e.target.value)}
					>
						<MenuItem value="ALL">Tous</MenuItem>
						<MenuItem value="PENDING">En Attente</MenuItem>
						<MenuItem value="ACCEPTED">Accepté</MenuItem>
						<MenuItem value="DECLINED">Refusé</MenuItem>
					</Select>
				</FormControl>

				<FormControl fullWidth margin="normal">
					<InputLabel>Format</InputLabel>
					<Select
						value={fileFormat}
						onChange={(e) => setFileFormat(e.target.value)}
					>
						<MenuItem value="xlsx">Excel</MenuItem>
						<MenuItem value="csv">CSV</MenuItem>
					</Select>
				</FormControl>

				<FormControlLabel
					control={
						<Checkbox
							checked={sendEmail}
							onChange={() => setSendEmail(!sendEmail)}
						/>
					}
					label="Envoyer par email"
				/>

				{/* Email Input */}
				{sendEmail && (
					<TextField
						autoFocus
						margin="dense"
						label="Email"
						type="email"
						fullWidth
						value={emailAddress}
						onChange={(e) => setEmailAddress(e.target.value)}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Annuler</Button>
				<Button onClick={handleExport} disabled={mutation.isPending}>
					{mutation.isPending ? (
						<CircularProgress size={24} color="inherit" />
					) : (
						"Exporter"
					)}
				</Button>
			</DialogActions>

			{mutation.isError && (
				<Typography color="error" style={{ padding: "16px" }}>
					Erreur lors de l'exportation des données.
				</Typography>
			)}
		</Dialog>
	);
};

export default ExportDataDialog;
