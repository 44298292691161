import { useTranslation } from "react-i18next";
import { cn } from "@core/utils";
import Button from "@components/elements/Buttons/Button.tsx";
import Text from "@components/elements/Text";
import StatisticSection from "@components/views/(app)/common/StatisticSection";

function HeaderSection() {
	const { t } = useTranslation("about-page");
	const { t: buttons } = useTranslation("misc");
	return (
		<section
			className={cn(
				"relative w-full",
				"_tablet:h-full _laptop:h-[calc(100vh-64px)] _laptop:min-h-full",
				"flex flex-col items-center justify-center",
				"overflow-clip rounded-md"
			)}
		>
			<div
				className={cn(
					"flex flex-col items-center justify-center",
					"gap-[22px] bg-primary-50/50 px-[40px] ",
					"h-[55vh] min-h-[300px] w-full _laptop:h-[80%]"
				)}
			>
				<div
					className={cn(
						"flex flex-col items-center justify-center",
						"gap-[22px] px-[40px] ",
						"w-full max-w-[90%]"
					)}
				>
					<Text
						text={t("header-section.title")}
						className="displayLarge text-center text-text-black"
						highlightStyle="text-secondary-900 font-semibold"
					/>
					<Text
						tag="p"
						text={t("header-section.paragraph")}
						className="titleLarge max-w-[580px] text-center text-text-700"
					/>
				</div>
				<Button variant="primary" link={`/contact`}>
					{buttons("buttons.book-demo")}
				</Button>
			</div>
			<StatisticSection
				className="!mt-0 py-[50px] _large:py-[120px]"
				showTitle={false}
			/>
		</section>
	);
}

export default HeaderSection;
