import type React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "@tanstack/react-router";
import { cn } from "@core/utils";
import SubMenu from "@components/layout/Navbar/components/SubMenu.tsx";

const MenuItems: React.FC<any> = ({
	menus,
	activeSubmenu,
	locationName,
	toggleSubmenu,
	openSubmenuIndex,
	handleSubmenuLinkClick,
	activeChildLink,
}) => {
	const { t } = useTranslation("layout");
	return (
		<section className="flex items-center gap-8">
			<ul className="hidden gap-5 md:flex">
				{menus.map((item: any, index: number) => (
					<li
						key={index}
						className={`single-sidebar-menu  ${item.child ? "" : ""} ${
							activeSubmenu === index ? "" : ""
						} ${locationName === item.link ? "menu-item-active" : ""}`}
					>
						{!item.child && (
							<Link
								className="hover:text-gray-500/75 relative"
								to={`/${item.link}`}
							>
								<span className="menu-icon flex-grow-0 "></span>
								<div
									className={cn(
										"text-buttonLarge font-normal text-text-700",
										activeSubmenu === index &&
											"after:absolute after:bottom-0 after:left-0 after:top-full after:h-1 after:w-full after:rounded-[20px] after:bg-secondary-900 after:content-['']"
									)}
								>
									{t(item.title)}
								</div>
							</Link>
						)}

						{item.child && (
							<div
								className={`menu-link  cursor-pointer ${
									openSubmenuIndex === index ? "not-collapsed" : "collapsed"
								}`}
							>
								<div
									className="hover:text-gray-500/75 flex flex-1 items-start"
									onClick={() => toggleSubmenu(index)}
								>
									<span className="menu-icon"></span>
									<div className="relative flex items-center gap-1">
										<div
											className={cn(
												"text-buttonLarge font-normal text-text-700",
												activeSubmenu === index &&
													"after:absolute after:bottom-0 after:left-0 after:top-full after:h-1 after:w-full after:rounded-[20px] after:bg-secondary-900 after:content-['']"
											)}
										>
											{t(item.title)}
										</div>
										<svg
											className={cn(
												"transition-all duration-100",
												openSubmenuIndex === index
													? "rotate-180 transform"
													: "rotate-0"
											)}
											width="18"
											height="18"
											viewBox="0 0 18 15"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M14.25 6.375L9 11.625L3.75 6.375"
												stroke="#334155"
												strokeWidth="{1.5}"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
								</div>
								<SubMenu
									item={item}
									activeChildLink={activeChildLink}
									handleSubmenuLinkClick={handleSubmenuLinkClick}
									openSubmenuIndex={openSubmenuIndex}
									i={index}
								/>
							</div>
						)}
					</li>
				))}
			</ul>
		</section>
	);
};

export default MenuItems;
