import type React from "react";
import AccordionHeader from "@components/views/(app)/common/FaqAccordionSection/AccordionHeader.tsx";
import { cn } from "@core/utils";

interface AccordionItemProps {
	item: {
		title: string;
		content: string;
	};
	index: number;
	activeIndex: number | null;
	toggleAccordion: (index: number) => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
	item,
	index,
	activeIndex,
	toggleAccordion,
}) => {
	return (
		<div className="shadow-md rounded-lg bg-BGWhite transition hover:bg-primary-50/10">
			<AccordionHeader
				title={item.title}
				index={index}
				activeIndex={activeIndex}
				toggleAccordion={toggleAccordion}
			/>
			<div
				className={`accordion-content max-h-0 overflow-hidden rounded-md bg-primary-50 px-5 pt-0 ${
					activeIndex === index ? "max-h-screen" : ""
				}`}
			>
				<p
					className={cn(
						"mt-5 pb-10 font-light leading-6 tracking-tight",
						"text-[14px] _tablet:text-[16px] _laptop:text-[18px]",
						"_tablet:ml-[120px] _laptop:ml-[120px] _laptop:pr-9"
					)}
				>
					{item.content}
				</p>
			</div>
		</div>
	);
};

export default AccordionItem;
