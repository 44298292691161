import { useKeenSlider } from "keen-slider/react";
import type React from "react";
import { useEffect, useState } from "react";

import type {
	TargetDataDynamic,
	TargetSliderProps,
} from "@components/views/(app)/home/TargetSection/TargetSection.types.ts";
import { WheelControls } from "@utils/scrollwheel-plugin/ScrollWheelPlugin.ts";
import { Link } from "@tanstack/react-router";
import TargetCard from "@components/views/(app)/home/TargetSection/TargetCard.tsx";
import i18next from "i18next";

const TargetSlider: React.FC<TargetSliderProps> = ({ data }) => {
	const [sliderRef, slider] = useKeenSlider(
		{
			loop: false,
			mode: "snap",
			slides: {
				perView: 1,
				spacing: 30,
			},

			disabled: false,
			breakpoints: {
				"(min-width: 300px)": {
					slides: { perView: 1.25, spacing: 30 },
				},
				"(min-width: 518px)": {
					slides: { perView: 1.8, spacing: 30 },
				},
				"(min-width: 690px)": {
					slides: { perView: 2.2, spacing: 30 },
				},
				"(min-width: 880px)": {
					slides: { perView: 2.35, spacing: 30 },
				},
				"(min-width: 930px)": {
					slides: { perView: 2.55, spacing: 30 },
				},
				"(min-width: 1080px)": {
					slides: { perView: 3.2, spacing: 30 },
				},
				"(min-width: 1200px)": {
					slides: { perView: 3.2, spacing: 30 },
				},
				"(min-width: 1400px)": {
					slides: { perView: 4, spacing: 30 },
				},
			},
		},
		[WheelControls]
	);

	const [, setActiveIndex] = useState<number | undefined>(0);

	useEffect(() => {
		if (slider.current) {
			slider.current.on("slideChanged", () => {
				setActiveIndex(slider.current?.track.details.abs);
			});
		}
	}, [slider]);

	return (
		<section className="w-full">
			{/*<CarouselButtons*/}
			{/*  wrapperStyle={"mb-8 _phone_lg:hidden"}*/}
			{/*  disablePrevButton={activeIndex === 0}*/}
			{/*  disableNextButton={activeIndex === data.length - 1}*/}
			{/*  handleNextButtonClick={handleNext}*/}
			{/*  handlePrevButtonClick={handlePrev}*/}
			{/*/>*/}
			<div ref={sliderRef} className="keen-slider">
				{data.map((target, index) => {
					const dynamicTarget = target as TargetDataDynamic;
					return (
						<div key={index} className="keen-slider__slide ">
							<Link to={dynamicTarget.link ?? "/"}>
								<TargetCard
									textBeforeHighlight={
										dynamicTarget[i18next.resolvedLanguage as string].title
											.textBeforeHighlight
									}
									textAfterHighlight={
										dynamicTarget[i18next.resolvedLanguage as string].title
											.textAfterHighlight
									}
									highLightText={
										dynamicTarget[i18next.resolvedLanguage as string].title
											.highlightText
									}
									content={
										dynamicTarget[i18next.resolvedLanguage as string].content
									}
									buttonClassName={dynamicTarget.buttonClassName}
									wrapperClassName={dynamicTarget.wrapperClassName}
									hightLightStyle={dynamicTarget.highlightStyle}
								/>
							</Link>
						</div>
					);
				})}
			</div>
		</section>
	);
};

export default TargetSlider;
