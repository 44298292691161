import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { SolutionsResearcher } from "@pages/(app)/solutions/SolutionsResearcher.tsx";
import { useTranslation } from "react-i18next";

const Page = () => {
	const { t: tMeta } = useTranslation("metadata");

	return (
		<>
			<Meta
				title={tMeta("solutions-page.researcher.title")}
				template={"%s | Coachini"}
				description={tMeta("solutions-page.researcher.description")}
				keywords={[
					"researcher",
					"solutions",
					"research",
					"researcher themes",
					"chercheurs",
				]}
				robots="index,follow"
				ogTitle={tMeta("solutions-page.researcher.title")}
				ogDescription={tMeta("solutions-page.researcher.description")}
			/>
			<SolutionsResearcher />
		</>
	);
};
export const Route = createFileRoute("/(main)/solutions/researcher/")({
	component: Page,
});
