import { Fn } from './types';

export const noop = () => {};
export const isFunction = <T extends Fn>(val: any): val is T => typeof val === 'function';
export const isObject = (value: unknown): value is Record<any, any> =>
  value !== null && typeof value === 'object';
export const isString = (value: unknown): value is string => typeof value === 'string';
export const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean';
export const isNumber = (value: unknown): value is number => typeof value === 'number';
export const isUndef = (value: unknown): value is undefined => typeof value === 'undefined';

export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['addEventListener']> | [string, Function | null, ...any]
): void {
  // Check if obj is not null and obj.addEventListener is a function
  if (obj && typeof obj.addEventListener === 'function') {
    obj.addEventListener(...(args as Parameters<HTMLElement['addEventListener']>));
  }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['removeEventListener']> | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...(args as Parameters<HTMLElement['removeEventListener']>));
  }
}

export const isNil = (x) => x == null;

export const toPath = (x = '') => {
  if (Array.isArray(x)) {
    return x;
  }
  return x
    .toString()
    .replace(/[[\]]/g, '.')
    .split('.')
    .reduce((acc, item) => {
      if (item.trim() !== '') {
        // @ts-ignore
        acc.push(item);
      }
      return acc;
    }, []);
};
export const get = (obj, pathString, defaultValue) => {
  const path = toPath(pathString);
  // @ts-ignore
  const res = path.reduce((acc, key) => {
    if (isNil(acc)) {
      return undefined;
    }
    return acc[key];
  }, obj);
  return isNil(res) ? defaultValue : res;
};

export const isPlainObject = (value) => Object.prototype.toString.call(value) === '[object Object]';

const copyCurrentValue = (obj, nextKey) => {
  if (Array.isArray(obj)) {
    return [...obj];
  }
  if (isPlainObject(obj)) {
    return { ...obj };
  }
  if (/[0-9]+/.test(nextKey) && parseInt(nextKey, 10).toString() === nextKey.toString()) {
    return [];
  }
  return {};
};
export const set = (obj, pathString, value) => {
  const path = toPath(pathString);
  if (path.length === 0) {
    return value;
  }

  const result = copyCurrentValue(obj, path[0]);

  // @ts-ignore
  path.reduce((acc, currentKey, index) => {
    const hasNext = index !== path.length - 1;
    const nextKey = path[index + 1];
    if (hasNext) {
      acc[currentKey] = copyCurrentValue(acc[currentKey], nextKey);
    } else {
      acc[currentKey] = value;
    }
    return acc[currentKey];
  }, result);
  return result;
};
export const hasOwnProperty = <
  T extends Record<string | number | symbol, any>,
  K extends string | number | symbol
>(
  object: T,
  property: K
): object is T & Record<K, unknown> => Object.prototype.hasOwnProperty.call(object, property);

export const yieldTrue = () => true as const;
export const yieldFalse = () => false as const;

export const isBrowser = typeof window !== 'undefined';

export const isNavigator = typeof navigator !== 'undefined';

export const defaultWindow = /* #__PURE__ */ isBrowser ? window : undefined;
