import React, { useState } from "react";
import Image from "@components/elements/Image";
import Text from "@components/elements/Text";
import { cn } from "@core/utils";
import { useTranslation } from "react-i18next";

const faqData = [
	{
		question: "Qui peut participer à StartGreen by IPTIC ?",
		answer:
			"Les jeunes et les femmes de Sousse ayant une idée de projet dans le domaine de la Green Tech.",
		imagePath: "/images/startgreen/faq-image-1.webp",
	},
	{
		question: "Qu’est-ce que ça veut dire GreenTech ?",
		answer:
			"La GreenTech fait référence à des solutions technologiques et innovantes qui visent à promouvoir un développement durable et à relever les défis environnementaux actuels dans des domaines tels que :\n" +
			"~Les villes vertes\n" +
			"~L'agriculture\n" +
			"~L'alimentation écologique\n" +
			"~L'industrie à faible émission\n" +
			"~La protection de l'eau\n" +
			"~La biodiversité\n" +
			"~Le recyclage\n" +
			"~Les énergies propres\n" +
			"~Le transport écologique\n" +
			"~La gestion des risques environnementaux\n" +
			"~La technologie verte\n" +
			"~Le bien-être\n",
		imagePath: "/images/startgreen/faq-image-2.webp",
	},
	{
		question: "Qu’est-ce que je vais gagner ?",
		answer:
			"Une phase d'incubation accompagnée d’un financement pour les projets les plus brillants.",
		imagePath: "/images/startgreen/faq-image-3.webp",
	},
	{
		question: "Comment candidater à StartGreen by IPTIC?",
		answer:
			"Pour candidater à STARTGreen, il vous suffit de remplir ce formulaire.",
		imagePath: "/images/startgreen/faq-image-4.webp",
	},
	{
		question: "Quand puis-je participer à StartGreen by IPTIC?",
		answer: "Vous pouvez participer dès maintenant.",
		imagePath: "/images/startgreen/faq-image-5.webp",
	},
];

const FAQSection = () => {
	// Set the initial open index to 0 to have the first one always open by default
	const [openIndex, setOpenIndex] = useState(0);
	const { t } = useTranslation("startgreen-page");

	const toggleAccordion = (index) => {
		// Only update the openIndex if it's not already open
		setOpenIndex(index);
	};

	return (
		<div className="flex flex-row  w-full">
			{/* Left section: FAQ */}
			<div className="w-full p-4">
				<Text
					text={t("faq-section.title")}
					className={cn(
						"text-left text-displaySmall _tablet:text-displaySmall _laptop:text-displaySmall",
						"font-semibold"
					)}
					highlightStyle={"text-startgreen_tertiary-100"}
				/>

				<div className="mt-4 space-y-5">
					{faqData.map((faq, index) => (
						<div
							key={index}
							className={cn(
								"rounded-md px-4 py-2 bg-[#F5F5F5] border-[#D9D9D9] border",
								openIndex === index ? "bg-white" : "bg-[#F5F5F5]"
							)}
						>
							<button
								className="flex justify-between w-full py-2 text-left font-medium focus:outline-none"
								onClick={() => toggleAccordion(index)}
							>
								{faq.question}

								<span>
									{openIndex === index ? (
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M5 7.5L10 12.5L15 7.5"
												stroke="#1E1E1E"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									) : (
										<svg
											className={"rotate-90"}
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M5 7.5L10 12.5L15 7.5"
												stroke="#1E1E1E"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									)}
								</span>
							</button>
							{openIndex === index && (
								<div className="p-2">
									{faq.answer.includes("\n") ? (
										<ul className="list-disc list-inside">
											{faq.answer
												.split("\n")
												.map((line, idx) =>
													line.startsWith("~") ? (
														<li key={idx}>{line.substring(1).trim()}</li>
													) : (
														<p key={idx}>{line}</p>
													)
												)}
										</ul>
									) : (
										<p>{faq.answer}</p>
									)}
								</div>
							)}
						</div>
					))}
				</div>
			</div>

			{/* Right section: Image */}
			<div className="hidden lg:flex w-full p-4  justify-center items-start">
				<Image
					className="w-full h-auto rounded-lg hidden lg:block"
					width={112}
					height={112}
					src={faqData[openIndex].imagePath}
					alt={`FAQ Image ${openIndex}`}
					aria-label="StartGreen by IPTIC"
				/>
			</div>
		</div>
	);
};

export default FAQSection;
