import { useState } from "react";
import {
	getPhases,
	togglePhaseStatus,
} from "@api/service/startgreen.services.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PhaseCard } from "@pages/(startgreen)/views/phases/PhaseCard.tsx";

export const PhaseList = () => {
	const queryClient = useQueryClient();
	const {
		data: phases,
		isLoading,
		error,
		refetch,
	} = useQuery({
		queryKey: ["phases"],
		queryFn: getPhases,
	});

	const [selectedPhase, setSelectedPhase] = useState<string | null>(null);
	const [initialPhase, setInitialPhase] = useState<string | null>(null);
	const [message, setMessage] = useState<string | null>(null);

	if (isLoading) return <p>Chargement...</p>;
	if (error) return <p>Erreur de chargement des phases</p>;

	const sortedPhases = phases?.sort(
		(a: any, b: any) => a.phaseOrder - b.phaseOrder
	);

	const handleSelectPhase = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedPhase(event.target.value);
	};

	const handleUpdatePhase = async () => {
		try {
			if (selectedPhase) {
				await togglePhaseStatus(selectedPhase, true);
				await queryClient.invalidateQueries(["phases"]);
				await refetch();
				setInitialPhase(selectedPhase);
				setMessage("Phase mise à jour avec succès");

				setTimeout(() => {
					setMessage(null);
				}, 3000);
			}
		} catch (error) {
			setMessage("Erreur lors de la mise à jour de la phase active");

			setTimeout(() => {
				setMessage(null);
			}, 3000);
		}
	};

	const handleCancel = () => {
		setSelectedPhase(initialPhase);
	};

	if (!selectedPhase && sortedPhases) {
		const activePhase = sortedPhases.find((phase: any) => phase.isOpen);
		setInitialPhase(activePhase?.id);
		setSelectedPhase(activePhase?.id);
	}

	return (
		<div className="space-y-4">
			{/*<div className="bg-white drop-shadow-lg rounded-md p-6 mb-4 space-y-4">*/}
			{/*	<label className="block font-medium">*/}
			{/*		Sélectionnez la phase active*/}
			{/*	</label>*/}
			{/*	<select*/}
			{/*		value={selectedPhase || ""}*/}
			{/*		onChange={handleSelectPhase}*/}
			{/*		className="border border-green-500 p-2 w-full"*/}
			{/*	>*/}
			{/*		{sortedPhases.map((phase: any) => (*/}
			{/*			<option key={phase.id} value={phase.id}>*/}
			{/*				{phase.name}*/}
			{/*			</option>*/}
			{/*		))}*/}
			{/*	</select>*/}

			{/*	{message && <p className="text-green-500">{message}</p>}*/}

			{/*	<div className="mt-4 flex justify-between">*/}
			{/*		<button*/}
			{/*			onClick={handleUpdatePhase}*/}
			{/*			className="bg-startgreen_primary-100 text-white py-2 px-4 rounded-md"*/}
			{/*		>*/}
			{/*			Mise à jour de la phase active*/}
			{/*		</button>*/}
			{/*		<button*/}
			{/*			onClick={handleCancel}*/}
			{/*			className="bg-startgreen_tertiary-100 text-white py-2 px-4 rounded-md"*/}
			{/*		>*/}
			{/*			Annuler*/}
			{/*		</button>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{/*<hr className="my-8 border-t-2 border-gray-100/10" />*/}
			<div className="space-y-4">
				{sortedPhases?.map((phase: any) => {
					return (
						<>
							<PhaseCard
								key={phase.id}
								phase={phase}
								isActive={initialPhase === phase.id}
							/>
							<hr className="my-8 border-t-2 border-gray-100/10" />;
						</>
					);
				})}
			</div>
		</div>
	);
};
