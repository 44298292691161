import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { appConfig } from "@config/appConfig.ts";

interface AuthState {
	token: string | null;
	role: string | null;
	user: object | null;
	status: "idle" | "loading" | "failed";
}

const initialState: AuthState = {
	token: null,
	role: null,
	user: null,
	status: "idle",
};

export const loginWithOTP = createAsyncThunk(
	"auth/loginWithOTP",
	async (data: { email: string; otp: string }) => {
		const response = await axios.post("/api/login", data);
		return response.data; // Should return user, token, and role from API
	}
);

// Async thunks for handling login, logout, and role fetching
export const login = createAsyncThunk(
	"auth/login",
	async (credentials: { email: string; password: string }) => {
		const response = await axios.post(
			appConfig.startGreenUrl + "/submissions/test",
			credentials
		);
		return response.data;
	}
);

export const refreshToken = createAsyncThunk("auth/refreshToken", async () => {
	const response = await axios.post("/api/refresh-token");
	return response.data.token;
});

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		logout: (state: AuthState) => {
			state.token = null;
			state.role = null;
			state.user = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.fulfilled, (state: AuthState, action) => {
				state.token = action.payload.token;
				state.role = action.payload.role;
				state.user = action.payload.user;
				state.status = "idle";
			})
			.addCase(login.pending, (state: AuthState) => {
				state.status = "loading";
			})
			.addCase(login.rejected, (state: AuthState) => {
				state.status = "failed";
			})
			.addCase(loginWithOTP.fulfilled, (state: AuthState, action) => {
				state.token = action.payload.token;
				state.role = action.payload.role;
				state.user = action.payload.user;
				state.status = "idle";
			})
			.addCase(loginWithOTP.pending, (state: AuthState) => {
				state.status = "loading";
			})
			.addCase(loginWithOTP.rejected, (state: AuthState) => {
				state.status = "failed";
			})
			.addCase(refreshToken.fulfilled, (state: AuthState, action) => {
				state.token = action.payload;
			});
	},
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
