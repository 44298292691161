import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { useTranslation } from "react-i18next";
import { cn } from "@core/utils";
import FlexibilityCards from "@components/views/(app)/home/FlexibilitySection/FlexibilityCards.tsx";
import Text from "@components/elements/Text";

function FlexibilitySection() {
	const { t } = useTranslation("home-page");
	return (
		<SectionWrapper
			className={cn(
				"max-height-section  flex  w-full flex-col items-center justify-center",
				"margin-divider-xsm"
			)}
		>
			<div className="flex flex-col items-center justify-center">
				<Text
					tag="h2"
					className={cn(
						"displayLarge",
						"mb-[45px] text-center tablet:text-left",
						"_tablet_lg:hidden"
					)}
					highlightStyle="font-semibold text-secondary-900"
					text={t("flexibility-section.title")}
				/>
				<FlexibilityCards />
			</div>
		</SectionWrapper>
	);
}

export default FlexibilitySection;
