import { useRef, useState } from "react";
import { FreeMode, Mousewheel } from "swiper/modules";
import type { SwiperRef } from "swiper/react";
import { Swiper, SwiperSlide } from "swiper/react";
import ReviewDialog from "./ReviewDialog";
import type { ExpertReviewProps } from "@components/views/(app)/common/ExpertReviewSection/ExpertReviewSection.types.ts";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { cn } from "@core/utils";
import ExpertReviewCard from "@components/views/(app)/common/ExpertReviewSection/ExpertReviewCard.tsx";
import Text from "@components/elements/Text";

function ExpertReviewSection({
	titleText = "Coaches who Loved the experience",
	titleColor = "primary",
	cardsColor,
	data,
}: {
	titleText?: string;
	paragraphText?: string;
	titleColor?: "primary" | "secondary" | "tertiary";
	cardsColor?: "primary-1" | "primary-2" | "secondary" | "tertiary";
	data: ExpertReviewProps[];
}) {
	const carouselRef = useRef<SwiperRef | null>(null);
	const selectedTitleColor =
		titleColor === "primary"
			? "text-primary-400"
			: titleColor === "secondary"
				? "text-secondary-900"
				: titleColor === "tertiary"
					? "text-tertiary-900"
					: "text-primary-400";

	const slidesPerView = data.length < 3 ? 2.5 : 3.3;
	const breakpoints = {
		"1500": { slidesPerView, slidesOffsetAfter: 180 },
		"1280": {
			slidesPerView: 2.5,
			spaceBetween: 25,
			slidesOffsetAfter: 130,
		},
		"1024": {
			slidesPerView: 2.5,
			spaceBetween: 24,
			slidesOffsetAfter: 75,
		},
		"780": {
			slidesPerView: 1.5,
			spaceBetween: 24,
			slidesOffsetAfter: 75,
		},
		"480": {
			slidesPerView: 1.5,
			spaceBetween: 24,
			slidesOffsetAfter: 75,
		},
		"0": {
			slidesPerView: 1.2,
			spaceBetween: 24,
			slidesOffsetAfter: 25,
		},
	};

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [selectedReview, setSelectedReview] =
		useState<ExpertReviewProps | null>(null);

	const openDialog = (review: ExpertReviewProps) => {
		setSelectedReview(review);
		setIsDialogOpen(true);
	};

	const closeDialog = () => {
		setIsDialogOpen(false);
		setSelectedReview(null);
	};

	return (
		<SectionWrapper
			className={cn(
				"relative",
				"flex flex-col items-start gap-[40px]",
				" pr-0 _tablet:pr-0 _laptop:pr-0 _large:pr-0",
				"margin-divider-medium"
			)}
		>
			<Text
				className="displayLarge max-w-[250px] text-left _tablet:max-w-[450px]"
				highlightStyle={cn(selectedTitleColor, "font-semibold")}
				text={titleText}
			/>
			<Swiper
				ref={carouselRef}
				modules={[Mousewheel, FreeMode]}
				direction="horizontal"
				slidesPerView={3.6}
				spaceBetween={30}
				freeMode={{
					enabled: true,
					sticky: true,
				}}
				breakpoints={breakpoints}
				mousewheel={{
					enabled: true,
					forceToAxis: true,
					releaseOnEdges: false,
				}}
				className="flex h-full w-full cursor-grab"
			>
				{data.map((review, index) => (
					<SwiperSlide
						key={index}
						onClick={() => {
							openDialog(review);
						}}
					>
						<ExpertReviewCard
							content={review.content}
							cardColor={cardsColor ?? review.cardColor}
							expertName={review.expertName}
							expertProfession={review.expertProfession}
						/>
					</SwiperSlide>
				))}
			</Swiper>

			<ReviewDialog
				isOpen={isDialogOpen}
				onClose={closeDialog}
				review={selectedReview}
			/>
		</SectionWrapper>
	);
}

export default ExpertReviewSection;
