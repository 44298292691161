import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getGlobalMessage,
	getPlatformStatus,
	updateGlobalMessage,
} from "@api/service/startgreen.services";
import { ConfigCard } from "./ConfigCard";
import { useForm } from "react-hook-form";

export const ConfigList = () => {
	const [successMessage, setSuccessMessage] = useState<string | null>(null);

	const queryClient = useQueryClient();
	const { data: platformStatus, isLoading: statusLoading } = useQuery({
		queryKey: ["platformStatus"],
		queryFn: getPlatformStatus,
	});
	const { data: globalMessages, isLoading: messageLoading } = useQuery({
		queryKey: ["globalMessages"],
		queryFn: getGlobalMessage,
	});

	const { register, handleSubmit } = useForm({
		defaultValues: {
			display: globalMessages?.display ?? 0,
			message: globalMessages?.message,
		},
	});
	const onSubmit = async (data: any) => {
		try {
			await updateGlobalMessage(globalMessages.id, data.display, data.message);
			setSuccessMessage("Configuration mise à jour avec succès !");
			setTimeout(() => setSuccessMessage(null), 3000);
		} catch (error) {
			alert("Erreur lors de la mise à jour de l'état de la plateforme");
		}
	};

	if (statusLoading || messageLoading) return <p>Chargement...</p>;
	return (
		<div className="space-y-4 mt-4">
			<div className="space-y-4">
				<h2 className="text-xl font-bold">État de la plateforme</h2>
				{platformStatus && (
					<ConfigCard
						config={platformStatus}
						isActive={platformStatus.openStatus}
					/>
				)}
			</div>
			<hr className="my-8 border-t-2 border-gray-100/10" />

			<div className="space-y-4">
				<h2 className="text-xl font-bold">Message global</h2>
				<div className="bg-white drop-shadow-lg rounded-md p-6 mb-4 space-y-4">
					<form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
						<div className="flex items-center space-x-2">
							<input
								type="checkbox"
								{...register("display")}
								className="form-checkbox h-5 w-5 text-green-500 rounded focus:ring-green-500 focus:ring-2 checked:bg-green-500"
							/>
							<label className="font-medium">Afficher le statut</label>
						</div>

						<div>
							<label className="block font-medium">Message</label>
							<textarea
								{...register("message")}
								className="border border-green-500 p-2 w-full"
							/>
						</div>
						{successMessage && (
							<div className="text-green-600 font-medium">{successMessage}</div>
						)}
						<div className="mt-4 flex justify-between">
							<button
								type="submit"
								className="bg-startgreen_primary-100 text-white py-2 px-4 rounded-md"
							>
								Sauvegarder les modifications
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
