import layoutReducer from "./reducers/layoutReducer";
import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "@store/reducers/authReducer.ts";

const rootReducer = combineReducers({
	layout: layoutReducer,
	auth: authReducer,
});

export default rootReducer;
