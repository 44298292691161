import Button from "@components/elements/Buttons/Button.tsx";
import type { PricingPlanProps } from "@components/views/(app)/solutions/PackSection/PackSection.types.ts";
import { useTranslation } from "react-i18next";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { cn } from "@core/utils";
import PackCard from "@components/views/(app)/solutions/PackSection/PackCard.tsx";
import Text from "@components/elements/Text";

const PackSection = ({
	data,
	solutionType = "enterprise",
}: {
	data: PricingPlanProps[];
	solutionType?: "enterprise" | "startup" | "employability" | "researcher";
}) => {
	const { t } = useTranslation("solutions-page");
	const { t: tButtons } = useTranslation("misc");

	let titleColor;
	let bulletColor: string | undefined;
	let primaryCardColor: string | undefined;
	let secondaryCardColor: string | undefined;
	let primaryPopularCardColor: string | undefined;
	let secondaryPopularCardColor: string | undefined;
	switch (solutionType) {
		case "enterprise": {
			titleColor = "text-primary-400";
			bulletColor = "before:text-primary-400";
			primaryCardColor = "bg-secondary-200";
			secondaryCardColor = "bg-secondary-600";
			primaryPopularCardColor = "bg-primary-100";
			secondaryPopularCardColor = "bg-primary-400";
			break;
		}
		case "startup": {
			titleColor = "text-primary-400";
			bulletColor = "before:text-primary-400";
			primaryCardColor = "bg-secondary-200";
			secondaryCardColor = "bg-secondary-600";
			primaryPopularCardColor = "bg-primary-100";
			secondaryPopularCardColor = "bg-primary-400";
			break;
		}
		case "employability": {
			titleColor = "text-tertiary-900";
			bulletColor = "before:text-tertiary-900";
			primaryCardColor = "bg-primary-50";
			secondaryCardColor = "bg-primary-400";
			primaryPopularCardColor = "bg-tertiary-400";
			secondaryPopularCardColor = "bg-tertiary-900";
			break;
		}
		case "researcher": {
			titleColor = "text-secondary-900";
			bulletColor = "before:text-secondary-900";
			primaryCardColor = "bg-primary-50";
			secondaryCardColor = "bg-primary-400";
			primaryPopularCardColor = "bg-secondary-400";
			secondaryPopularCardColor = "bg-secondary-900";
			break;
		}
	}

	const sectionInfo = [
		{
			paragraph: t(`pack-section.${solutionType}.section-info.paragraph-1`),
		},
		{
			paragraph: t(`pack-section.${solutionType}.section-info.paragraph-2`),
		},
	];

	return (
		<div className="bg-primary-50/50">
			<SectionWrapper
				className={cn(
					"flex-col items-center justify-center gap-[60px] py-cXLg _laptop:gap-[100px]",
					"margin-divider-medium"
				)}
			>
				<div className="flex flex-col items-end justify-between gap-[30px] _laptop:w-full _laptop:flex-row">
					<div
						className={cn(
							"flex max-w-[480px] flex-col self-center",
							"_laptop:max-w-[460px] _laptop:self-start"
						)}
					>
						<Text
							tag="h2"
							className={cn("displayLarge text-center _tablet:text-left")}
							highlightStyle={cn("font-semibold", titleColor)}
							text={t(`pack-section.${solutionType}.title`)}
						/>
						<Text
							tag="p"
							className={cn(
								"titleLarge mt-[8px] text-center text-text-500 _laptop:text-left"
							)}
							text={t(`pack-section.${solutionType}.paragraph`)}
						/>
					</div>

					<div className="flex flex-col items-center gap-[15px] _laptop:items-start">
						<ul
							className={cn(
								"ml-6 list-disc marker:invisible marker:text-[#3f83f800]",
								// bulletColor,
								"max-w-[420px] space-y-[0px] _tablet:max-w-[480px] _laptop:max-w-[550px] _large:max-w-[600px]"
							)}
						>
							{sectionInfo.map((info, index) => (
								<li key={index} className="bodyText relative text-text-700">
									<p
										className={cn(
											'before:absolute before:left-[-25px] before:text-[30px] before:content-["•"]',
											bulletColor
										)}
									>
										{info.paragraph}
									</p>
								</li>
							))}
						</ul>
						<Button link={`/contact`}>{tButtons(`buttons.start-now`)}</Button>
						{/*<Button*/}
						{/*    className={"max-w-max"}*/}
						{/*    primary*/}
						{/*    label={t(`packSection.sectionInfo.buttonLabel`)}*/}
						{/*/>*/}
					</div>
				</div>
				<div className="grid w-full place-items-center gap-6 _tablet:grid-cols-2 _tablet:items-start sm:gap-8 _laptop:grid-cols-4 _laptop:items-center">
					{data.map((plan, index) => (
						<PackCard
							key={index}
							plan={plan}
							solutionType={solutionType}
							primaryCardColor={primaryCardColor}
							secondaryCardColor={secondaryCardColor}
							primaryPopularCardColor={primaryPopularCardColor}
							secondaryPopularCardColor={secondaryPopularCardColor}
						/>
					))}
				</div>
			</SectionWrapper>
		</div>
	);
};

export default PackSection;
