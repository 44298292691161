import React from "react";
import { useCountUp } from "react-countup";
import { cn } from "@core/utils";
import type { StatisticItemProps } from "@components/views/(app)/common/StatisticSection/StatisticSection.types.ts";
import Image from "@components/elements/Image";
import i18next from "i18next";

export const StatisticItem: React.FC<StatisticItemProps> = ({
	item,
	numberStyle,
	descriptionStyle,
}) => {
	const countUpRef = React.useRef(null);
	const { start, pauseResume, reset, update } = useCountUp({
		ref: countUpRef,
		start: 0,
		end: Number(item.number),
		prefix: "+",
		duration: 1.75,
		scrollSpyOnce: true,
		enableScrollSpy: true,
	});

	return (
		<div className="m-2 flex flex-col items-center justify-center gap-[10px] _laptop:flex-row _laptop:items-start">
			<div className="flex items-center justify-center pl-[10px] _laptop:pl-0 _laptop:pt-[10px]">
				<Image
					className="pointer-events-none h-auto w-auto"
					width={50}
					height={50}
					src={`/svg/statistics/${item.icon}.svg`}
					alt={String(item.icon)}
					aria-label={`Coachini Statistic Item ${item.icon}`}
					loading="lazy"
				/>
			</div>
			<div className="flex flex-col items-center _laptop:items-start">
				<p
					ref={countUpRef}
					className={cn(
						"text-headlineSmall font-semibold",
						"_tablet:text-displaySmall _laptop:text-displayMedium _middle:text-displayLarge",
						numberStyle
					)}
				/>
				<p className={cn("titleSmall text-text-600", descriptionStyle)}>
					{item.content[i18next.resolvedLanguage as "en" | "fr"].description}
				</p>
			</div>
		</div>
	);
};
