import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { updatePlatformStatus } from "@api/service/startgreen.services";
import { Tooltip } from "@pages/(startgreen)/views/(dashboard)/Tooltip.tsx";

type ConfigProps = {
	config: {
		id: string;
		openStatus: boolean;
		applyStatus: boolean;
		message: string;
		passcode: string | null;
	};
};

export const ConfigCard = ({ config }: ConfigProps) => {
	const { register, handleSubmit } = useForm({
		defaultValues: {
			openStatus: config.openStatus,
			applyStatus: config.applyStatus,
			message: config.message,
			passcode: config.passcode ?? "",
		},
	});

	const [successMessage, setSuccessMessage] = useState<string | null>(null);

	const onSubmit = async (data: any) => {
		try {
			await updatePlatformStatus(
				config.id,
				data.openStatus,
				data.applyStatus,
				data.message,
				data.passcode
			);
			setSuccessMessage("Configuration mise à jour avec succès !");
			setTimeout(() => setSuccessMessage(null), 3000);
		} catch (error) {
			alert("Erreur lors de la mise à jour de l'état de la plateforme");
		}
	};

	return (
		<div className="bg-white drop-shadow-lg rounded-md p-6 mb-4 space-y-4">
			<form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
				<div
					className={
						"flex flex-col gap-2 p-2 border rounded  border-startgreen_primary-100/30"
					}
				>
					<div className="flex items-center space-x-2">
						<input
							type="checkbox"
							{...register("openStatus")}
							className="form-checkbox h-5 w-5 text-green-500 rounded focus:ring-green-500 focus:ring-2 checked:bg-green-500"
						/>
						<div className={"flex gap-2"}>
							<label className="font-medium">Plateforme Ouverte</label>
							<Tooltip
								title="Plateforme Ouverte"
								description="Indique si la plateforme est ouverte. Si elle est fermée, un mot de passe est requis pour y accéder."
							/>
						</div>
					</div>
					<div>
						<div className={"flex gap-2"}>
							<label className="block font-medium">Mot de passe</label>
							<Tooltip
								title="Mot de passe"
								description="Le mot de passe requis pour accéder à la plateforme si elle est fermée."
							/>
						</div>
						<input
							type="text"
							{...register("passcode")}
							className="border border-green-500 p-2 w-full"
						/>
					</div>
				</div>
				<div
					className={
						"flex flex-col gap-2 p-2 border rounded  border-startgreen_primary-100/30"
					}
				>
					<div className="flex items-center space-x-2">
						<input
							type="checkbox"
							{...register("applyStatus")}
							className="form-checkbox h-5 w-5 text-green-500 rounded focus:ring-green-500 focus:ring-2 checked:bg-green-500"
						/>
						<div className={"flex gap-2"}>
							<label className="font-medium">Application Ouverte</label>
							<Tooltip
								title="Application Ouverte"
								description="Permet aux candidats de postuler et de remplir le formulaire lorsque cette option est activée."
							/>
						</div>
					</div>
					<div>
						<label className="block font-medium">Message</label>
						<textarea
							{...register("message")}
							className="border border-green-500 p-2 w-full"
						/>
					</div>
				</div>
				{successMessage && (
					<div className="text-green-600 font-medium">{successMessage}</div>
				)}
				<div className="mt-4 flex justify-between">
					<button
						type="submit"
						className="bg-startgreen_primary-100 text-white py-2 px-4 rounded-md"
					>
						Sauvegarder les modifications
					</button>
				</div>
			</form>
		</div>
	);
};
