import "@i18n/i18n";
import type { ReactNode } from "react";
import { Navbar } from "@components/layout/Navbar";
import { Footer } from "@components/layout/Footer";

type NavWrapper = {
	children: ReactNode;
	className?: string;
};

export default function NavigationWrapper({ children }: NavWrapper) {
	return (
		<>
			<Navbar />
			{children}
			<Footer />
		</>
	);
}
