import { useTranslation } from "react-i18next";
import Button from "@components/elements/Buttons/Button.tsx";

export const FooterActions = () => {
	const { t } = useTranslation("misc");
	return (
		<div className="max-w-paragraph-small flex flex-col gap-[20px] text-center sm:text-left">
			<Button variant="primary" link={`/contact`}>
				{t("buttons.contact")}
			</Button>
			{/*<Button label={"Contactez Nous"} />*/}
		</div>
	);
};
