import type { ClassValue } from "clsx";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/** Merge classes with tailwind-merge with clsx full feature */
export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function getAssetURL(source) {
	source.startsWith("/") && (source = source.slice(1));
	return new URL(`/src/assets/${source}?react`, import.meta.url).href;
}
