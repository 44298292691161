import type { KeenSliderOptions } from "keen-slider";
import { useKeenSlider } from "keen-slider/react";
import { useEffect, useState } from "react";

import { expertList } from "@api/database/constant/home.data.ts";
import { WheelControls } from "@utils/scrollwheel-plugin/ScrollWheelPlugin.ts";
import ExpertList from "@components/views/(app)/home/ExpertSection/ExpertList.tsx";

const ExpertScroll = () => {
	const options: KeenSliderOptions = {
		loop: false,
		mode: "snap",
		slides: {
			perView: 1,
			spacing: 30,
			origin: "center",
		},
		initial: 5,
		disabled: false,
		breakpoints: {
			"(min-width: 318px)": {
				initial: 5,
				slides: {
					perView: 1.3,
					spacing: 30,
					origin: "center",
				},
			},
			"(min-width: 518px)": {
				initial: 5,
				slides: { perView: 1.5, spacing: 30, origin: "center" },
			},
			"(min-width: 744px)": {
				initial: 0,
				slides: { perView: 2.25, spacing: 30, origin: "auto" },
			},
			"(min-width: 880px)": {
				slides: { perView: 2.5, spacing: 30 },
			},
			"(min-width: 1080px)": {
				slides: { perView: 3.5, spacing: 30 },
			},
			"(min-width: 1200px)": {
				slides: { perView: 4, spacing: 30 },
			},
		},
	};
	const [sliderRef, slider] = useKeenSlider(options, [WheelControls]);
	const [, slider2] = useKeenSlider(options, [WheelControls]);
	const [, setActiveIndex1] = useState<number | undefined>(0);
	const [, setActiveIndex2] = useState<number | undefined>(0);
	let expertListLength = 0;
	useEffect(() => {
		expertList.map((item) => {
			expertListLength = expertListLength + item.children.length;
		});

		if (slider.current) {
			slider.current.on("slideChanged", () => {
				setActiveIndex1(slider.current?.track.details.abs);
			});
		}
		if (slider2.current) {
			slider2.current.on("slideChanged", () => {
				setActiveIndex2(slider2.current?.track.details.abs);
			});
		}
	}, [slider, slider2]);

	// const handlePrevious = () => {
	// 	if (slider) slider.current?.prev();
	// 	if (slider2) slider2.current?.prev();
	// };
	//
	// const handleNext = () => {
	// 	if (slider) slider.current?.next();
	// 	if (slider2) slider2.current?.next();
	// };

	return (
		<section className="w-full">
			{/*<CarouselButtons*/}
			{/*  wrapperStyle='mb-8 _tablet_sm:hidden'*/}
			{/*  disablePrevButton={activeIndex1 === 0 && activeIndex2 === 0}*/}
			{/*  disableNextButton={activeIndex1 === expertListLength - 1}*/}
			{/*  handleNextButtonClick={handleNext}*/}
			{/*  handlePrevButtonClick={handlePrev}*/}
			{/*/>*/}
			<div className="flex flex-col gap-5">
				<ExpertList sliderRef={sliderRef} expertList={expertList} />
			</div>
		</section>
	);
};

export default ExpertScroll;
