import type { FunctionComponent } from "types/types.ts";
import { Link, useLocation } from "@tanstack/react-router";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { cn } from "@core/utils";
import { contactInfo, socials } from "@api/database/constant/contact.data.ts";
import { useTranslation } from "react-i18next";
import Image from "@components/elements/Image";
import Text from "@components/elements/Text";
import ContactForm from "@components/views/(app)/contact/ContactForm";
import NavigationWrapper from "@components/layout/common/NavigationWrapper.tsx";
import NewsLetterSection from "@components/views/(app)/common/NewsletterSection";

export const Contact = (): FunctionComponent => {
	const location = useLocation();
	const queryParameters = new URLSearchParams(location.search);
	const testParameter = queryParameters.get("test");

	// const test2Parameter = location.search?.test2;
	const { t } = useTranslation("contact-page");

	return (
		<NavigationWrapper>
			<main>
				<section
					className={cn(
						"flex flex-col items-center justify-center gap-[30px] px-[23px]",
						"h-[_calc(45vh_-_65px)] _laptop:h-[_calc(55vh_-_70px)]",
						"bg-gradient-to-r from-primary-900/20 to-secondary-900/20",
						" _tablet:px-[75px] _laptop:px-[130px] _large:px-[180px]"
					)}
				>
					<Text
						className="displayLarge max-w-[80%] _tablet:max-w-[100%]"
						highlightStyle="font-semibold text-primary-400"
						text={t("hero-section.title")}
					/>
				</section>
				<SectionWrapper
					className={cn(
						"dark:text-gray-800 flex max-w-[80%]  flex-col items-start justify-start gap-[80px] dark:bg-gray-50",
						"margin-divider-small mb-[40px]",
						"_tablet:max-w-[90%] _tablet_lg:flex-row _laptop:max-w-[80%]"
					)}
				>
					<div className="container flex h-full flex-col items-start justify-start gap-[32px] self-start  px-5">
						<h4 className="titleLarge max-w-[360px]">
							{t("contact-info.title")}
						</h4>
						<div className="flex flex-col items-start gap-[10px]">
							{contactInfo.map((item, index) => {
								return (
									<div
										className="flex flex-row items-center justify-center gap-[12px]"
										key={index}
									>
										<Image
											src={`/svg/misc/contact/${item.icon}.svg`}
											alt={`${item.icon}`}
											width={30}
											height={30}
										/>
										<p className="bodyText">{item.content}</p>
									</div>
								);
							})}
						</div>
						<div className="flex flex-row  items-center gap-[12px]">
							{socials.map((item, index) => {
								return (
									<Link
										href={item.url}
										className="flex flex-row items-center justify-center gap-[12px]"
										key={index}
									>
										<Image
											src={`/svg/misc/contact/socials/${item.icon}.svg`}
											alt={`${item.icon}`}
											width={24}
											height={24}
										/>
									</Link>
								);
							})}
						</div>
					</div>
					<ContactForm />
				</SectionWrapper>
				<NewsLetterSection />
			</main>
		</NavigationWrapper>
	);
};
