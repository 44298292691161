// ------------------------- //
export const genderOptions = [
	{ label: "Homme", value: "Male" },
	{ label: "Femme", value: "Female" },
];
// ------------------------- //
export const regionOptions = [
	{ label: "Sélectionner", value: "" },
	{ label: "Bouficha", value: "bouficha" },
	{ label: "Enfidha", value: "enfidha" },
	{ label: "Hergla", value: "hergla" },
	{ label: "Sidi Bou Ali", value: "sidi_bou_ali" },
	{ label: "Sidi El Heni", value: "sidi_el_heni" },
	{ label: "Kondar", value: "kondar" },
	{ label: "Kalla kbira", value: "kalla_kbira" },
	{ label: "Kalla sghira", value: "kalla_sghira" },
	{ label: "Hamam Sousse", value: "hamam_sousse" },
	{ label: "Akouda", value: "akouda" },
	{ label: "Sousse centre", value: "sousse_centre" },
	{ label: "Sidi Abdelhamid", value: "sidi_abdelhamid" },
	{ label: "Sousse Sud (Zaouia, Ksiba, Thrayet)", value: "sousse_sud" },
	{ label: "Sousse Riadh (Riadh, Zouhour)", value: "sousse_riadh" },
];
// --------------------------//
export const educationLevelOptions = [
	{ label: "Primaire", value: "primaire" },
	{ label: "Secondaire", value: "secondaire" },
	{ label: "Baccalauréat", value: "baccalaureat" },
	{ label: "Licence", value: "licence" },
	{ label: "Master", value: "master" },
	{ label: "Doctorat", value: "doctorat" },
	{ label: "Autres (Veuillez préciser)", value: "education_level_other" },
];
// --------------------------//
export const professionalSituationOptions = [
	{ label: "Employé", value: "employe" },
	{ label: "Étudiant", value: "etudiant" },
	{ label: "Profession libérale", value: "profession_libérale" },
	{ label: "En recherche d'emploi", value: "en_recherche_emploi" },
	{ label: "Entrepreneur", value: "entrepreneur" },
];
// --------------------------//
export const supportThemesOptions = [
	{ label: "Profil du créateur d’entreprise", value: "creator_profile" },
	{
		label: "Idéation et innovation : Du problème à l’idée de projet",
		value: "ideation_innovation",
	},
	{ label: "Pitch et Pitch Deck", value: "pitch_deck" },
	{
		label: "GBMC : Green Business Model Canvas",
		value: "green_business_model_canvas",
	},
	{
		label: "Ecosystème entrepreneurial et avantages aux startups (Startup Act)",
		value: "startup_ecosystem",
	},
	{
		label: "Le cadre règlementaire de la création d’entreprise",
		value: "regulatory_framework",
	},
	{ label: "Autres (Veuillez préciser)", value: "support_themes_other" },
];

export const projectDevelopmentStageOptions = [
	{
		label:
			"Je n’ai pas encore d’idée de projet mais j’ai envie de créer une start-up dans la GreenTech.",
		value: "no_project_idea",
	},
	{
		label:
			"J’ai une idée de projet dans la GreenTech, mais je ne l’ai pas encore démarré",
		value: "project_idea_not_started",
	},
	{
		label:
			"J’ai une activité économique ou je dirige une entreprise (avec un statut juridique ou non)",
		value: "no_startup_act_project",
	},
	{
		label: "Je gère une start-up labellisée",
		value: "yes_startup_act_project",
	},
];

export const projectAreaOptions = [
	{
		label: "Agriculture et alimentation écologiques",
		value: "eco_agriculture_food",
	},
	{ label: "Constructions et villes vertes", value: "green_buildings_cities" },
	{ label: "Industrie à faible émission", value: "low_emission_industry" },
	{
		label: "Protection de l'eau et de la biodiversité",
		value: "water_biodiversity_protection",
	},
	{ label: "Recyclage et réutilisation", value: "recycling_reuse" },
	{ label: "Énergies propres", value: "clean_energy" },
	{ label: "Transport écologique", value: "eco_transport" },
	{
		label: "Gestion des risques environnementaux",
		value: "environment_risk_management",
	},
	{ label: "Technologie verte", value: "green_technology" },
	{ label: "Bien-être et environnement", value: "wellbeing_environment" },
	{ label: "Autres (Veuillez préciser)", value: "project_activity_other" },
];

export const supportNeedsOptions1 = [
	{ label: "Incubation", value: "incubation" },
	{ label: "Appui financier", value: "financial_support" },
	{ label: "Accélération", value: "acceleration" },
	{ label: "Autres (Veuillez préciser)", value: "support_needs_other" },
];
export const supportNeedsOptions2 = [
	{ label: "Incubation", value: "incubation" },
	{ label: "Accélération", value: "acceleration" },
	{ label: "Autres (Veuillez préciser)", value: "support_needs_other" },
];

export const hackathonParticipationOptions = [
	{ label: "Oui", value: "yes_participate_hackathon" },
	{ label: "Non", value: "no_participate_hackathon" },
];

export const previousSupportOptions = [
	{ label: "Oui", value: "yes_received_support" },
	{ label: "Non", value: "no_received_support" },
];

export const discoverySourceOptions = [
	{
		label: "Les réseaux sociaux (Facebook, Instagram, LinkedIn, …)",
		value: "social_media",
	},
	{ label: "Le bouche-à-oreille", value: "word_of_mouth" },
	{ label: "Une campagne de sensibilisation", value: "awareness_campaign" },
	{ label: "Une newsletter ou un e-mail", value: "newsletter_email" },
	{ label: "La radio", value: "radio" },
	{ label: "Des partenaires ou associations", value: "partners_associations" },
	{ label: "Autres (Veuillez préciser)", value: "discovery_source_other" },
];
