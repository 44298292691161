import type React from "react";
import { useTranslation } from "react-i18next";
import { cn } from "@core/utils";
import { affirmations } from "@api/database/constant/affirmations.data.ts";
import i18next from "i18next";
import { pickRandomField } from "@utils/affirmation.util.ts";

interface AffirmationCardProps {
	className?: string;
}
const AffirmationCard: React.FC<AffirmationCardProps> = ({ className }) => {
	const pickedAffirmation = pickRandomField(affirmations);
	const { t } = useTranslation("home-page");
	return (
		<div
			className={cn(
				"relative z-[0] flex min-h-28 w-full items-center rounded-[10px] bg-logoWhite shadow-base2 sm:w-80",
				className
			)}
		>
			<svg
				className="absolute left-0 h-full"
				viewBox="0 0 81 105"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_308_3208)">
					<path
						d="M80.5062 105H10C4.47716 105 0 100.523 0 95V10C0 4.47715 4.47715 0 10 0H78.5571C78.5571 0 73.0062 20.8442 73.0062 52.2413C73.0062 83.1237 80.5062 105 80.5062 105Z"
						fill="#ECB800"
					/>
					<path
						d="M35.1353 76.1765C34.6597 75.9034 34.1958 75.5948 34.1494 74.9775C34.103 74.4433 34.1378 73.9092 34.1378 73.3394C33.6738 73.3394 33.2794 73.3394 32.885 73.3394C30.3679 73.3156 28.5931 71.4875 28.5583 68.9116C28.5467 67.6295 28.5699 66.3475 28.4539 65.0773C28.3031 63.451 27.6767 62.0146 26.54 60.8157C24.0925 58.2279 22.9441 55.094 23.0021 51.4971C23.0949 45.8466 27.0272 40.8253 32.3978 39.4958C37.6757 38.19 42.9883 40.3267 46.0042 44.9682C49.0433 49.6453 48.5793 56.2692 44.8326 60.3765C43.1739 62.1927 42.3967 64.2701 42.4431 66.7273C42.4547 67.6414 42.4895 68.5673 42.3967 69.4695C42.1531 71.7605 40.4712 73.28 38.2208 73.3275C37.7916 73.3394 37.3625 73.3275 36.8985 73.3275C36.8521 74.4196 37.1653 75.6185 35.8777 76.1765C35.6341 76.1765 35.3789 76.1765 35.1353 76.1765ZM31.2494 64.7687C34.1146 64.7687 36.9333 64.7687 39.7636 64.7687C40.1 62.4182 41.028 60.4002 42.6055 58.6671C44.7978 56.2573 45.5866 53.3846 45.053 50.1439C44.3571 45.8466 40.7264 42.4041 36.4925 42.0243C33.1286 41.7275 30.2867 42.8196 28.0711 45.443C26.1108 47.7697 25.4844 50.5237 25.9252 53.527C26.2268 55.6044 27.178 57.3613 28.5699 58.8926C29.0455 59.4031 29.4747 59.9729 29.8459 60.5664C30.6463 61.8366 31.029 63.2729 31.2494 64.7687ZM31.3422 67.6533C31.3422 68.1756 31.319 68.6504 31.3422 69.1252C31.377 69.885 31.9454 70.4666 32.6878 70.4785C34.5669 70.5022 36.4461 70.4904 38.3252 70.4785C38.9632 70.4666 39.52 70.0274 39.6012 69.3864C39.6824 68.8166 39.6128 68.2349 39.6128 67.6651C36.8637 67.6533 34.1262 67.6533 31.3422 67.6533Z"
						fill="#FCFBF7"
					/>
					<path
						d="M59 52.3887C58.6476 53.2718 58.042 53.5534 57.2272 53.5278C55.6085 53.4766 53.9788 53.5278 52.3602 53.5022C51.3802 53.4894 50.7525 52.4399 51.0939 51.4287C51.3031 50.8143 51.7105 50.456 52.2721 50.456C54.089 50.4304 55.8948 50.4432 57.7117 50.456C58.3613 50.456 58.7247 50.9679 59 51.5695C59 51.8511 59 52.1199 59 52.3887Z"
						fill="#FCFBF7"
					/>
					<path
						d="M37 31.9061C37 32.7783 37 33.6506 37 34.5341C36.9875 35.4177 36.375 36.0294 35.5 36.0294C34.625 36.0294 34.0125 35.4177 34 34.5341C34 32.7897 34 31.0339 34 29.2894C34 28.4058 34.625 27.7941 35.5 27.7941C36.375 27.7941 36.9875 28.4058 37 29.2894C37 30.1616 37 31.0339 37 31.9061Z"
						fill="#FCFBF7"
					/>
					<path
						d="M16 53.5294C15.1515 53.5294 14.303 53.5294 13.4435 53.5294C12.595 53.5165 12 52.8704 12 51.9788C12 51.0743 12.595 50.4412 13.4545 50.4412C15.1515 50.4412 16.8595 50.4412 18.5565 50.4412C19.405 50.4412 20 51.1002 20 51.9918C20 52.8963 19.405 53.5165 18.5455 53.5294C17.708 53.5294 16.8485 53.5294 16 53.5294Z"
						fill="#FCFBF7"
					/>
					<path
						d="M25 39.7301C24.9517 40.4128 24.6979 40.8181 24.154 41.0421C23.6101 41.2662 23.0783 41.2128 22.6191 40.8608C22.1961 40.5301 21.8093 40.1675 21.4105 39.8261C20.4436 38.9621 19.4526 38.1088 18.4857 37.2341C18.0506 36.8501 17.8814 36.3914 18.0869 35.8687C18.2803 35.3887 18.6791 35.1007 19.2471 35.0154C19.7306 34.9514 20.1415 35.09 20.4799 35.378C21.8456 36.5834 23.2234 37.7781 24.5649 39.0048C24.8066 39.2288 24.9033 39.5701 25 39.7301Z"
						fill="#FCFBF7"
					/>
					<path
						d="M47.3978 41.1765C46.7315 41.1231 46.3358 40.8991 46.1275 40.4085C45.9089 39.9285 45.9714 39.4485 46.315 39.0645C46.7315 38.5951 47.1896 38.1471 47.6269 37.6992C48.3662 36.9312 49.1158 36.1738 49.8655 35.4165C50.2403 35.0325 50.6881 34.8938 51.1983 35.0858C51.6668 35.2565 52 35.6192 52 36.1205C52 36.4725 51.8855 36.9098 51.6668 37.1552C50.5111 38.4031 49.3241 39.6085 48.1163 40.8031C47.8872 41.0058 47.5644 41.0911 47.3978 41.1765Z"
						fill="#FCFBF7"
					/>
				</g>
				<defs>
					<clipPath id="clip0_308_3208">
						<path
							d="M0 10C0 4.47715 4.47715 0 10 0H80.5062V105H10C4.47716 105 0 100.523 0 95V10Z"
							fill="white"
						/>
					</clipPath>
				</defs>
			</svg>

			<div className="flex flex-col justify-center gap-[7px] p-3 pl-[110px]">
				<h2 className="text-left text-labelSmall text-text-400 xSm:text-labelMedium">
					{t("header-section.affirmation.title")}
				</h2>
				<p className="text-left text-titleSmall text-primary-900">
					{
						// @ts-ignore
						pickedAffirmation?.description[i18next.resolvedLanguage]
					}
				</p>
			</div>
		</div>
	);
};

export default AffirmationCard;
