import { useTranslation } from "react-i18next";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { cn } from "@core/utils";
import InputField from "@components/elements/InputField";
import Text from "@components/elements/Text";

function NewsLetterSection() {
	const { t } = useTranslation("common");

	return (
		<SectionWrapper
			// outerWrapperStyle='!mt-0 py-[50px] _laptop:py-[80px] _large:py-[130px]'
			className={cn("py-[100px] _laptop:py-[130px] _large:py-[130px]")}
		>
			<div className="mx-auto flex w-full max-w-6xl flex-col items-center justify-between space-y-8 p-4 md:p-10 _laptop_md:flex-row _laptop_md:space-x-12 _laptop_md:space-y-0">
				<Text
					tag="h2"
					styled={false}
					text={t("newsletter-section.title")}
					className={cn(
						"headlineMedium max-w-[330px] text-center font-semibold  _laptop_md:text-left"
					)}
					highlightStyle="text-secondary-900"
				/>

				<InputField
					className={cn(
						"shadow-md h-full w-[100%] flex-col items-center gap-4 self-center rounded-md  p-2",
						"_phone_lg:w-[80%] _tablet:w-[70%] _tablet_lg:max-w-[450px] _tablet_lg:flex-row lg:self-center"
					)}
					// inputClassName='rounded-lg text-center  _tablet:text-left bg-primary-50 py-3 px-4 _tablet:p-2'
					buttonClassName="px-4 _tablet_lg:max-w-max"
					placeholderText={t("newsletter-section.input-field.placeholder")}
					buttonLabel={t("newsletter-section.input-field.buttonLabel")}
				/>
			</div>
		</SectionWrapper>
	);
}

export default NewsLetterSection;
