export const ScheduledMailList = ({
	scheduledMails,
	isLoading,
	onCancel,
	onCancelAll,
}) => {
	if (isLoading) return <div>Chargement...</div>;

	return (
		<div className="scheduled-mails-list space-y-4">
			<button
				onClick={onCancelAll}
				className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mb-4"
			>
				Annuler tous les emails planifiés
			</button>
			{scheduledMails &&
				scheduledMails.map((mail) => (
					<div
						key={mail.id}
						className="scheduled-mail-item p-4 bg-white rounded shadow-md"
					>
						<div>
							<strong>À :</strong> {mail.to}
						</div>
						<div>
							<strong>ID du modèle :</strong> {mail.template}
						</div>
						<div>
							<strong>Objet :</strong> {mail.subject}
						</div>
						<div>
							<strong>Envoyer à :</strong>{" "}
							{new Date(mail.sendAt).toLocaleString()}
						</div>
						<button
							onClick={() => onCancel(mail.id)}
							className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mt-4"
						>
							Annuler
						</button>
					</div>
				))}
		</div>
	);
};
