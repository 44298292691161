import React, { useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import useAuth from "@core/guards/auth.util.ts";

const LoginPage: React.FC = () => {
	const { isAuthenticated, handleLogin } = useAuth();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const navigate = useNavigate();

	React.useEffect(() => {
		if (isAuthenticated) {
			navigate({ to: "/startgreen-by-iptic/dashboard" });
		}
	}, [isAuthenticated, navigate]);

	const handleSubmit = async () => {
		if (!email || !password) {
			setErrorMessage("Veuillez entrer votre adresse e-mail et mot de passe.");
			return;
		}

		setLoading(true);
		setErrorMessage("");

		try {
			const result = await handleLogin({ email, password });
			if (result) {
				navigate({ to: "/startgreen-by-iptic/dashboard" });
			}
		} catch (error) {
			setErrorMessage(
				"Échec de la connexion. Vérifiez vos identifiants et réessayez."
			);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="flex items-center justify-center h-screen">
			<div className="w-full max-w-md bg-white rounded-md p-10 shadow-lg">
				{!isAuthenticated && (
					<div className="flex flex-col items-center text-center mb-6">
						<h2 className="font-semibold text-2xl text-gray-800">Connexion</h2>
					</div>
				)}
				{!isAuthenticated && (
					<div className="flex flex-col space-y-4">
						<input
							className="w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 ring-blue-500"
							type="email"
							placeholder="E-mail"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<input
							className="w-full px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 ring-blue-500"
							type="password"
							placeholder="Mot de passe"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						{errorMessage && (
							<p className="text-xs text-red-500 text-center mt-2">
								{errorMessage}
							</p>
						)}
					</div>
				)}
				<div className="flex items-center justify-center mt-6">
					<button
						onClick={handleSubmit}
						type="submit"
						disabled={loading}
						className={`${
							loading
								? "bg-startgreen_primary-100/50 cursor-not-allowed"
								: "bg-startgreen_primary-100 hover:bg-startgreen_primary-100/90"
						} w-full text-white text-sm px-4 py-2 rounded-lg`}
					>
						{loading ? "En cours..." : "Connexion"}
					</button>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
