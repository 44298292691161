import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { useTranslation } from "react-i18next";
import axios from "axios";
import CandidateDetailPage from "@pages/(startgreen)/views/(participants)/CandidateDetailPage.tsx";

const getStartGreen = async () => {
	const response = await axios.get("http://localhost:4400/v2");
	return response.data;
};
const Page = () => {
	const { t: tMeta } = useTranslation("metadata");
	return (
		<>
			<Meta
				title={"StartGreen - Participants"}
				template={"%s | Coachini"}
				description={tMeta("about-page.description")}
				keywords={[
					"about us",
					"about coachini",
					"know more",
					"coaching",
					"coachini",
				]}
				robots="index,follow"
				ogTitle={tMeta("about-page.title")}
				ogDescription={tMeta("about-page.description")}
			/>

			<CandidateDetailPage />
		</>
	);
};

export const Route = createFileRoute(
	"/(main)/startgreen-by-iptic/dashboard/participants/$cid"
)({
	// loader: async ({ params: { postId } }) => fetchPost(postId),

	component: Page,
});

function PostComponent() {
	const post = Route.useLoaderData();

	return (
		<div className="space-y-2">
			{/*<h4 className="text-xl font-bold underline">{post.title}</h4>*/}
			{/*<div className="text-sm">{post.body}</div>*/}
			<h1>test</h1>
			{/*<Link*/}
			{/*	to="/posts/$postId/deep"*/}
			{/*	params={{*/}
			{/*		postId: post.id,*/}
			{/*	}}*/}
			{/*	activeProps={{ className: "text-black font-bold" }}*/}
			{/*	className="block py-1 text-blue-800 hover:text-blue-600"*/}
			{/*>*/}
			{/*	Deep View*/}
			{/*</Link>*/}
		</div>
	);
}
