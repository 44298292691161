import type React from "react";
import type {
	ExpertDetailsProps,
	ExpertImageProps,
	ExpertItemProps,
} from "@components/views/(app)/home/ExpertSection/ExpertSection.types.ts";
import { cn } from "@core/utils";
import Image from "@components/elements/Image";

const ExpertImage: React.FC<ExpertImageProps> = ({ image }) =>
	image ? (
		<Image
			className={cn(
				"w-full rounded-none object-cover object-center",
				"aspect-[4/3.5] _tablet:aspect-[3/3.1] _laptop:aspect-[3/3.1]"
				// 'transform-gpu transition duration-300 ease-in-out hover:scale-[1.10]',
			)}
			width={500}
			height={500}
			src={`/images/coaches/${image}.webp`}
			alt="Coachini Associated Coach"
			aria-label="Coachini Associated Coach"
		/>
	) : null;

const GradientOverlay: React.FC = () => (
	<div
		aria-hidden={true}
		className="to-transparent pointer-events-none absolute bottom-0 z-[10] h-full w-full rounded-lg bg-gradient-to-t from-text-black opacity-80 mix-blend-multiply"
	/>
);

const ExpertDetails: React.FC<ExpertDetailsProps> = ({
	name,
	profession,
	teaserUrl,
	teaserPath,
}) => {
	const splitProfession = profession && profession.en.length < 25;
	return (
		<div
			className={cn(
				"absolute bottom-0 flex w-[100%] items-center justify-between",
				"pointer-events-none z-[10] pr-[12px]"
			)}
		>
			<div
				className={cn(
					"bodyText max-w-[80%] text-left font-semibold text-white",
					"py-4 pl-[16px]"
				)}
			>
				<p>
					{name},{!splitProfession && " "}
					{profession && profession.en.length < 25 && <br />}
					<span>{profession?.en}</span>
				</p>
			</div>
			{(teaserUrl || teaserPath) && (
				<Image
					className="min-h[40px] min-w-[40px]"
					src="/svg/misc/camera-icon.svg"
					alt="Teaser Icon"
					width={40}
					height={40}
				/>
			)}
		</div>
	);
};

const ExpertItem: React.FC<ExpertItemProps> = ({
	name,
	profession,
	image,
	teaserUrl,
	teaserPath,
	onClick,
}) => {
	return (
		<div
			onClick={onClick}
			className={cn(
				"mt-0 min-w-[185px] overflow-clip rounded-lg",
				(teaserUrl || teaserPath) && "cursor-pointer"
			)}
		>
			<div className="relative mb-2 sm:break-inside-avoid">
				<ExpertImage image={image} />
				<GradientOverlay />
				<ExpertDetails
					name={name}
					profession={profession}
					teaserUrl={teaserUrl}
					teaserPath={teaserPath}
				/>
			</div>
		</div>
	);
};

export default ExpertItem;
