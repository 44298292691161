import type React from "react";
import { Helmet } from "react-helmet";
import { appConfig } from "@config/appConfig.ts";
import { cn } from "@core/utils";

interface MetaProps {
	title?: string;
	description?: string;
	keywords?: string | string[];
	robots?: string;
	locale?: string;
	ogTitle?: string;
	ogDescription?: string;
	ogImage?: string;
	ogUrl?: string;
	template?: string;
	sitemap?: string;
	robotsTxt?: string;
	twitterCardTitle?: string;
	twitterCardDescription?: string;
	twitterCardImage?: string;
	twitterCardSite?: string;
	twitterCardCreator?: string;
	className?: string;
	favicon?: string;
	faviconKey?: string;
}

const Meta: React.FC<MetaProps> = ({
	title,
	description,
	keywords,
	robots,
	locale,
	ogTitle,
	ogDescription,
	ogImage,
	ogUrl,
	template = "",
	sitemap = "",
	robotsTxt = "",
	twitterCardTitle = "",
	twitterCardDescription = "",
	twitterCardImage = "",
	twitterCardSite = "https://coachini.net",
	twitterCardCreator = "@coachiniapp",
	className = "",
	favicon,
	faviconKey = "",
}) => {
	const finalTitle = template ? template.replace("%s", title || "") : title;
	let keywordContent = "";
	if (Array.isArray(keywords)) {
		keywordContent = keywords.join(", ");
	} else if (typeof keywords === "string") {
		if (keywords.includes(",")) {
			const keywordsArray = keywords.split(",");
			keywordContent = keywordsArray.join(", ");
		} else {
			keywordContent = keywords;
		}
	}

	return (
		<Helmet>
			<meta charSet="utf8" />
			{locale && <meta httpEquiv="Content-Language" content={locale ?? "fr"} />}

			{/* - */}
			{sitemap && <meta name="sitemap-url" content={sitemap} />}
			{robotsTxt && <meta name="robots-txt-url" content={robotsTxt} />}
			{twitterCardTitle && (
				<meta name="twitter:title" content={twitterCardTitle} />
			)}
			{twitterCardDescription && (
				<meta name="twitter:description" content={twitterCardDescription} />
			)}
			{twitterCardImage && (
				<meta name="twitter:image" content={twitterCardImage} />
			)}
			{twitterCardSite && (
				<meta name="twitter:site" content={twitterCardSite} />
			)}
			{twitterCardCreator && (
				<meta name="twitter:creator" content={twitterCardCreator} />
			)}

			{/* - */}
			{finalTitle && <title>{finalTitle}</title>}
			{description && <meta name="description" content={description} />}
			{keywordContent && <meta name="keywords" content={keywordContent} />}
			{robots && <meta name="robots" content={robots} />}
			{locale && <html lang={locale ?? "fr"} />}

			{ogTitle && <meta property="og:title" content={ogTitle} />}
			{ogDescription && (
				<meta property="og:description" content={ogDescription} />
			)}
			{ogImage && <meta property="og:image" content={ogImage} />}
			{ogUrl && <meta property="og:url" content={ogUrl} />}

			<meta
				name="google-site-verification"
				content={appConfig.googleSearchConsoleId}
			/>
			{favicon && <link rel="icon" key={faviconKey} href={favicon} />}
			<body className={cn(`bg-BGPage font-poppins`, className)} />
		</Helmet>
	);
};

export default Meta;
