import { cn } from "@core/utils";
import { useTranslation } from "react-i18next";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import InputField from "@components/elements/InputField";
import ImageSection from "@components/views/(app)/home/BannerSection/ImageSection.tsx";
import ImageSlider from "@components/views/(app)/home/BannerSection/ImageSlider.tsx";
import { bannerSlidesData } from "@api/database/constant/home.data.ts";
import Text from "@components/elements/Text";

export default function BannerSection() {
	const { t } = useTranslation("home-page");
	const { t: tButtons } = useTranslation("misc");
	return (
		<main>
			<SectionWrapper className="flex flex-col items-center justify-center px-5">
				<div
					className={cn(
						"flex flex-col items-center justify-center _laptop:flex-row",
						"h-full w-full pt-[64px] text-center _laptop:pt-0",
						"_laptop:h-[calc(100vh-64px)] _laptop:min-h-full"
					)}
				>
					<div
						className={cn(
							"flex flex-col  gap-[20px]",
							"text-left",
							"w-full max-w-[430px] _tablet:max-w-[520px]",
							"items-center justify-center _laptop:items-start _laptop:justify-start"
						)}
					>
						<Text
							className={cn(
								"text-headlineSmall _tablet:text-displaySmall _laptop:text-displaySmall",
								"_laptop:text-left"
							)}
							highlightStyle={cn(
								"text-headlineSmall font-semibold _tablet:text-displaySmall _laptop:text-displayExtra"
							)}
							text={t("header-section.title")}
						/>

						<p
							className={cn(
								"text-titleSmall text-text-500 _tablet:text-titleMedium _laptop:text-titleLarge",
								"max-w-paragraph-large text-center _laptop:text-start"
							)}
						>
							{t("header-section.paragraph")}
						</p>
						<InputField
							className={cn(
								"w-full",
								"shadow-md w-full flex-shrink-0 flex-col  items-center gap-4 self-center rounded-md px-[10px] sm:flex-row sm:p-[10px] lg:w-[100%] lg:self-start _laptop:max-w-[90%]"
							)}
							inputClassName="rounded-lg bg-primary-50 py-3  sm:p-2 text-center _tablet:min-w-max _laptop:text-left"
							placeholderText={t("header-section.input.placeholder")}
							buttonLabel={tButtons("buttons.book-demo")}
						/>
					</div>
					<ImageSection />
					<ImageSlider data={bannerSlidesData} />
				</div>
			</SectionWrapper>
		</main>
	);
}
