import { useForm } from "react-hook-form";
import {
	togglePhaseStatus,
	updatePhase,
} from "@api/service/startgreen.services.ts";
import { formatISO } from "date-fns";
import { useState } from "react";
import { cn } from "@core/utils";

type PhaseProps = {
	phase: {
		id: string;
		name: string;
		phaseName: string;
		startDate: string;
		endDate: string;
		canApply: "USER" | "VISITOR" | "NONE";
		isOpen: boolean;
		phaseDescription: string;
		phaseOrder: number;
	};
	isActive: boolean;
};

export const PhaseCard = ({ phase, isActive }: PhaseProps) => {
	const { register, handleSubmit } = useForm({
		defaultValues: {
			name: phase.name,
			phaseName: phase.phaseName,
			startDate: phase.startDate ? phase.startDate.slice(0, 16) : "",
			endDate: phase.endDate ? phase.endDate.slice(0, 16) : "",
			// canApply: phase.canApply,
			isOpen: phase.isOpen,
			phaseDescription: phase.phaseDescription,
		},
	});

	const [isOpen, setIsOpen] = useState(phase.isOpen);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);

	const onSubmit = async (data: any) => {
		try {
			const payload = {
				...data,
				startDate: data.startDate
					? formatISO(new Date(data.startDate))
					: undefined,
				endDate: data.endDate ? formatISO(new Date(data.endDate)) : undefined,
			};
			await updatePhase(phase.id, payload);
			setSuccessMessage("Phase mise à jour avec succès !"); // Success message in French
			setTimeout(() => setSuccessMessage(null), 3000); // Hide message after 3 seconds
		} catch (error) {
			alert("Erreur lors de la mise à jour de la phase");
		}
	};

	const handleTogglePhaseStatus = async () => {
		try {
			await togglePhaseStatus(phase.id, !isOpen);
			setIsOpen(!isOpen);
		} catch (error) {
			console.error("Erreur lors de l'activation de l'état de la phase", error);
			alert("Erreur lors de l'activation de l'état de la phase");
		}
	};

	return (
		<div className="bg-white drop-shadow-lg rounded-md p-6 mb-4 space-y-4">
			{/*<div*/}
			{/*	className={cn(*/}
			{/*		isActive ? "bg-green-500" : "bg-startgreen_tertiary-100",*/}
			{/*		"rounded-lg text-white  p-2 max-w-max"*/}
			{/*	)}*/}
			{/*>*/}
			{/*	{isActive ? "Actif" : "Inactif"}*/}
			{/*</div>*/}
			<form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
				<div>
					<label className="block font-medium">Nom de la phase</label>
					<input
						{...register("name")}
						className="border border-green-500 p-2 w-full"
					/>
				</div>
				<div>
					<label className="block font-medium">Date de début</label>
					<input
						type="datetime-local"
						{...register("startDate")}
						className="border border-green-500 p-2 w-full"
					/>
				</div>
				<div>
					<label className="block font-medium">Date de fin</label>
					<input
						type="datetime-local"
						{...register("endDate")}
						className="border border-green-500 p-2 w-full"
					/>
				</div>
				{/*<div>*/}
				{/*	<label className="block font-medium">Peut postuler</label>*/}
				{/*	<select*/}
				{/*		{...register("canApply")}*/}
				{/*		className="border border-green-500 p-2 w-full"*/}
				{/*	>*/}
				{/*		<option value="USER">CANDIDAT</option>*/}
				{/*		<option value="VISITOR">VISITEUR</option>*/}
				{/*		<option value="NONE">AUCUN</option>*/}
				{/*	</select>*/}
				{/*</div>*/}
				<div>
					<label className="block font-medium">Description de la phase</label>
					<textarea
						{...register("phaseDescription")}
						className="border border-green-500 p-2 w-full"
					/>
				</div>

				{/* Conditional Success Message */}
				{successMessage && (
					<div className="text-green-600 font-medium">{successMessage}</div>
				)}

				<div className="mt-4 flex justify-between">
					<button
						type="submit"
						className="bg-startgreen_primary-100 text-white py-2 px-4 rounded-md"
					>
						Enregistrer les modifications
					</button>
				</div>
			</form>
		</div>
	);
};
