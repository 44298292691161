import { useEffect, useState } from "react";
import { isBrowser, off, on } from "@hooks/util/util.ts";

const getValue = (search, param) => new URLSearchParams(search).get(param);

export type UseQueryParam = () => [
	Record<string, string | null>,
	(newParams: Record<string, string | null>) => void,
];

const useSearchParams: UseQueryParam = () => {
	const location = window.location;

	// Initialize state with all params as an object
	const [params, setParams] = useState(() => {
		const searchParams = new URLSearchParams(location.search);
		const initialParams = {};
		for (const [key, value] of searchParams.entries()) {
			initialParams[key] = value;
		}
		return initialParams;
	});

	useEffect(() => {
		const onChange = () => {
			const updatedParams = {};
			const searchParams = new URLSearchParams(location.search);
			for (const [key, value] of searchParams.entries()) {
				updatedParams[key] = value;
			}
			setParams(updatedParams);
		};

		on(window, "popstate", onChange);
		on(window, "pushstate", onChange);
		on(window, "replacestate", onChange);

		return () => {
			off(window, "popstate", onChange);
			off(window, "pushstate", onChange);
			off(window, "replacestate", onChange);
		};
	}, []);

	const setSearchParams = (newParams: Record<string, string | null>) => {
		const searchParams = new URLSearchParams(location.search);

		for (const [key, value] of Object.entries(newParams)) {
			if (value === null) {
				searchParams.delete(key); // Remove param if null
			} else if (value !== "") {
				searchParams.set(key, value); // Set or update param only if not empty
			} else {
				searchParams.delete(key); // Remove param if empty string
			}
		}

		// Update the URL without reloading the page
		const newUrl = `${location.pathname}?${searchParams.toString()}`;
		window.history.pushState({}, "", newUrl);
		setParams((prevParams) => ({ ...prevParams, ...newParams })); // Update local state
	};

	return [params, setSearchParams]; // Return params and setter function
};

const useSearchParameterServer = () => [null, () => null];

export default isBrowser ? useSearchParams : useSearchParameterServer;
