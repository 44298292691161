export const contactInfo = [
  {
    icon: 'phone',
    content: '+216 28 28 52 52',
  },
  {
    icon: 'whatsapp',
    content: '+216 96 28 19 91',
  },
  {
    icon: 'mail',
    content: 'Contact@coachini.net',
  },
  {
    icon: 'gps',
    content: 'Novation City - Technopole de Sousse',
  },
];
export const socials = [
  {
    icon: 'linkedin',
    url: 'https://www.linkedin.com/company/coachini-moncoach-tn/',
  },
  {
    icon: 'facebook',
    url: 'https://www.facebook.com/CoachiniStartup/',
  },
  {
    icon: 'instagram',
    url: 'https://www.instagram.com/coachini.app/',
  },
  {
    icon: 'youtube',
    url: 'https://www.youtube.com/@coachiniapp',
  },
];
