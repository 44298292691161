import Image from "@components/elements/Image";

function Logo() {
	return (
		<>
			<span className="sr-only">Home</span>
			<Image
				loading="eager"
				className="hidden h-auto w-28 rounded-none _tablet_sm:block"
				width={112}
				height={112}
				src="images/misc/logo/logo-coachini-big.png"
				alt="Coachini Logo"
				aria-label="Coachini Home Page"
			/>
			<Image
				loading="lazy"
				className="h-auto w-10 rounded-none _tablet_sm:hidden"
				width={40}
				height={40}
				src="images/misc/logo/logo-coachini-sm.png"
				alt="Coachini Logo"
				aria-label="Coachini Home Page"
			/>
		</>
	);
}

export default Logo;
