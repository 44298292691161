import type { FunctionComponent } from "types/types.ts";
import { useTranslation } from "react-i18next";
import NavigationWrapper from "@components/layout/common/NavigationWrapper.tsx";
import { cn } from "@core/utils";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import Button from "@components/elements/Buttons/Button.tsx";
import Image from "@components/elements/Image";

export const Studio = (): FunctionComponent => {
	const { t } = useTranslation("studio-page");
	const { t: tButtons } = useTranslation("misc");
	return (
		<NavigationWrapper>
			<main>
				<SectionWrapper
					className={cn(
						"flex  items-center justify-center",
						"min-h-[_calc(100vh_-_70px)]"
					)}
				>
					<div
						className={cn(
							"flex  flex-col items-center justify-center gap-[40px]",
							" max-w-[900px] select-none text-center",
							" _tablet:gap-[70px] _laptop:gap-[40px] _large:gap-[70px]"
						)}
					>
						<Image
							className="pointer-events-none h-full w-[300px] _tablet:w-[500px] _laptop:w-[400px] _large:w-[500px]"
							width={300}
							height={300}
							src="/svg/misc/under-construction.svg"
							alt="Under Construction Image"
							aria-label="Under Construction Image"
							loading="eager"
						/>
						<div className="flex flex-col gap-[20px]">
							<h2 className="displayMedium font-extrabold text-text-700">
								<span className="sr-only">Under Construction</span>
								{t("under-construction.title")}
							</h2>
							<p className="displaySmall font-semibold text-text-700">
								{t("under-construction.subTitle")}
							</p>
						</div>

						<Button variant="primary" link={"/"}>
							{tButtons("buttons.return-home")}
						</Button>
					</div>
				</SectionWrapper>
			</main>
		</NavigationWrapper>
	);
};
