import React from "react";
import LanguageToggle from "@components/layout/Navbar/components/LanguageToggle.tsx";
import { cn } from "@core/utils";
import useAuth from "@core/guards/auth.util.ts";
import QRScanner from "@pages/(startgreen)/views/(settings)/QRScanner.tsx";

function Navbar({
	title = "Tableau de bord StartGreen by IPTIC",
	toggleSidebar,
}: {
	title?: string;
	toggleSidebar: () => void;
}) {
	const { handleLogout } = useAuth();

	return (
		<nav
			className={cn(
				"static top-0 bg-white shadow-md p-4 flex justify-between items-center z-10",
				"outline outline-1 outline-gray-100/10 "
			)}
		>
			<h1 className="text-xl font-semibold">{title}</h1>

			<div className="flex items-center space-x-4">
				{/* Language Selector */}
				<button
					className="block lg:hidden bg-green-600 text-white p-2 m-4 rounded"
					onClick={toggleSidebar}
				>
					Menu
				</button>
				<QRScanner />
				{/* Logout Button */}
				<button
					onClick={handleLogout}
					className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
				>
					Déconnexion
				</button>
			</div>
		</nav>
	);
}

export default Navbar;
