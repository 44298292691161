import React from "react";
import { cn } from "@core/utils";
import Image from "@components/elements/Image";
import Text from "@components/elements/Text";
import { useTranslation } from "react-i18next";
import Button from "@components/elements/Buttons/Button.tsx";

function CTASection() {
	const { t } = useTranslation("startgreen-page");

	return (
		<div
			className={cn(
				"bg-startgreen_lightcream-100 p-5 rounded-md",
				"flex flex-col lg:flex-row items-center justify-center",
				"gap-[30px] drop-shadow-md"
			)}
		>
			<div
				className={
					"flex justify-center items-center bg-startgreen_primary-100 w-full  lg:h-[250px] rounded-md p-3"
				}
			>
				<Image
					className="  rounded-lg aspect-auto"
					width={300}
					height={300}
					src={"images/startgreen/cta-start.webp"}
					alt={"Startgreen"}
					aria-label="StartGreen"
				/>
			</div>

			<div
				className={
					"flex flex-col w-full items-center lg:items-center gap-[30px]"
				}
			>
				<div
					className={cn(
						"flex flex-col gap-[10px]",
						"text-center !leading-normal text-[25px] _tablet:text-[30px] _laptop:text-[40px]",
						"font-semibold text-startgreen_primary-100"
					)}
				>
					<h2>
						Start Responsible
						<br />
						Start Conscious
					</h2>
					{/*<h2>Start Conscious</h2>*/}
					{/*<Text*/}
					{/*	text={t("cta-section.call-1")}*/}
					{/*	className={cn(*/}
					{/*		"text-left text-titleLarge _tablet:text-displaySmall _laptop:text-displaySmall",*/}
					{/*		"font-semibold"*/}
					{/*	)}*/}
					{/*	highlightStyle={"text-startgreen_primary-100"}*/}
					{/*/>*/}
					{/*<Text*/}
					{/*	text={t("cta-section.call-1")}*/}
					{/*	className={cn(*/}
					{/*		"text-left text-titleLarge _tablet:text-displaySmall _laptop:text-displaySmall",*/}
					{/*		"font-semibold"*/}
					{/*	)}*/}
					{/*	highlightStyle={"text-startgreen_darkblue-100"}*/}
					{/*/>*/}
				</div>
				<Button
					variant="startgreen"
					size={"extra"}
					link={"/startgreen-by-iptic/apply"}
					className={"w-full hidden lg:flex"}
				>
					{t("buttons.apply")}
				</Button>
				<Button
					variant="startgreen"
					size={"startgreen-sm"}
					link={"/startgreen-by-iptic/apply"}
					className={"w-full lg:hidden flex"}
				>
					{t("buttons.apply")}
				</Button>
			</div>
		</div>
	);
}

export default CTASection;
