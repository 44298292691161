import { Link } from "@tanstack/react-router";

const VIEWS = {
	TEMPLATES: "templates",
	TEMPLATE_EDITOR: "template-editor",
	TEMPLATE_CREATOR: "template-creator",
	SEND: "send",
	SCHEDULED: "scheduled",
};
const Tabs = ({
	view,
	handleTabChange,
	handleRefetchAll,
	handleRefetchTemplates,
	isTemplatesLoading,
}) => {
	const renderButton = ({ label, viewType, colorClasses, extraProps }) => (
		<button
			className={`tab px-6 py-3 rounded-md border transition duration-300 ease-in-out ${
				view === viewType
					? `bg-startgreen_primary-100 text-white font-semibold  border-${colorClasses}-700`
					: `text-gray-600 hover:text-${colorClasses}-700 hover:bg-gray-200`
			}`}
			onClick={() => handleTabChange(viewType)}
			{...extraProps}
		>
			{label}
		</button>
	);

	return (
		<div className="tabs flex space-x-4 p-4 border-b-2 border-gray-200">
			{renderButton({
				label: "Modèles",
				viewType: VIEWS.TEMPLATES,
				colorClasses: "blue",
				extraProps: { disabled: isTemplatesLoading },
			})}
			{renderButton({
				label: "Envoyer un email",
				viewType: VIEWS.SEND,
				colorClasses: "green",
			})}
			{renderButton({
				label: "Emails planifiés",
				viewType: VIEWS.SCHEDULED,
				colorClasses: "yellow",
			})}
			<button
				onClick={() => handleRefetchAll}
				className="flex flex-row gap-2 items-center bg-startgreen_darkblue-100 hover:bg-startgreen_darkblue-100/80 text-white font-bold py-2 px-4 rounded"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth={1.5}
					stroke="currentColor"
					className="size-6"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
					/>
				</svg>
				Actualiser
			</button>
			<Link
				to="/startgreen-by-iptic/dashboard/mail-builder"
				target="_blank"
				rel="noopener noreferrer"
				className={
					"flex flex-row gap-2 items-center bg-startgreen_tertiary-100 text-white font-bold py-2 px-4 rounded"
				}
			>
				<button onClick={handleRefetchTemplates} className="">
					Constructeur
				</button>
			</Link>
		</div>
	);
};

export default Tabs;
