import type React from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import CustomScrollRestoration from "@components/utils/CustomScrollRestoration.tsx";
import { ScrollRestoration } from "@tanstack/react-router";

function DevelopmentToolsWrapper({
	children,
	RouterTools = false,
	QueryTools = false,
}: {
	children: React.ReactNode;
	RouterTools?: any;
	QueryTools?: any;
}) {
	return (
		<>
			<CustomScrollRestoration />
			<ScrollRestoration />
			{children}
			{RouterTools && <TanStackRouterDevtools position="bottom-left" />}
			{QueryTools && <ReactQueryDevtools initialIsOpen={false} />}
		</>
	);
}

export default DevelopmentToolsWrapper;
