import { cn } from "@core/utils";

const StatusTabs = ({ selectedTab, setSelectedTab }) => {
	const statusTranslations = {
		pending: "En attente",
		accepted: "Accepté",
		declined: "Refusé",
	};

	return (
		<div className="flex border-b border-gray-100/20 mb-8 shadow-sm p-2">
			{["pending", "accepted", "declined"].map((status) => (
				<button
					key={status}
					onClick={() => setSelectedTab(status)}
					className={cn(
						"px-6 py-3 rounded-lg transition-all duration-300 mx-1 focus:outline-none",
						selectedTab === status
							? status === "accepted"
								? "bg-green-500 text-white border-green-600 shadow-md hover:bg-green-600"
								: status === "pending"
									? "bg-orange-500 text-white border-orange-600 shadow-md hover:bg-orange-600"
									: "bg-red-500 text-white border-red-600 shadow-md hover:bg-red-600"
							: "text-gray-700 border border-gray-100/30 hover:bg-gray-200 hover:text-gray-900"
					)}
				>
					{statusTranslations[status]}
				</button>
			))}
		</div>
	);
};

export default StatusTabs;
