import type { FunctionComponent } from "types/types.ts";
import { useTranslation } from "react-i18next";
import Sidebar from "@pages/(startgreen)/views/(dashboard)/Sidebar";
import Navbar from "@pages/(startgreen)/views/(dashboard)/Navbar";
import { useState } from "react";
import { ConfigList } from "@pages/(startgreen)/views/(settings)/ConfigList.tsx";
import TooltipCard from "@pages/(startgreen)/views/(dashboard)/TooltipCard.tsx";
import QrScannerCam from "@pages/(startgreen)/views/(settings)/QrScannerCam.tsx";
import { FaQrcode } from "react-icons/fa";
import QRScanner from "@pages/(startgreen)/views/(settings)/QRScanner.tsx"; // QR code icon

export const SettingsScreen = (): FunctionComponent => {
	const { t } = useTranslation("coaches-page");
	const navItems = [
		{ label: "Dashboard", path: "/startgreen-by-iptic/dashboard" },
		{
			label: "Participants",
			path: "/startgreen-by-iptic/dashboard/participants",
		},
		{ label: "Phases", path: "/startgreen-by-iptic/dashboard/phases" },
		{ label: "Emails", path: "/startgreen-by-iptic/dashboard/emails" },
		{ label: "Settings", path: "/startgreen-by-iptic/dashboard/settings" },
	];
	const [isOpen, setIsOpen] = useState(false);
	const [isQROpen, setQROpen] = useState(false);

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	const tooltipPoints = [
		"Nous pouvons verrouiller la plateforme avec un mot de passe.",
		"Nous pouvons fermer la soumission des formulaires.",
	];

	const openScanner = () => {
		setQROpen(true);
	};

	const closeScanner = () => {
		setQROpen(false);
	};

	return (
		<div className="flex  ">
			<Sidebar
				isOpen={isOpen}
				toggleSidebar={toggleSidebar}
				navItems={navItems}
			/>
			<div className="flex-grow">
				<Navbar title={"Paramètres"} toggleSidebar={toggleSidebar} />
				<main className="p-5">
					<TooltipCard
						title="Configuration de la plateforme"
						description="Cette section nous permet de gérer l'état de la plateforme."
						points={tooltipPoints}
					/>
					<ConfigList />

					<hr className="my-8 border-t-2 border-gray-100/10" />
				</main>
			</div>
		</div>
	);
};

export default SettingsScreen;
