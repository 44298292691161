import { useCallback, useEffect, useRef } from "react";
import { useLocation } from "@tanstack/react-router";

const throttle = (fn: (...args: any[]) => void, limit: number) => {
	let lastFn: ReturnType<typeof setTimeout>;
	let lastRan: number;
	return (...args: any[]) => {
		if (lastRan) {
			clearTimeout(lastFn);
			lastFn = setTimeout(
				() => {
					if (Date.now() - lastRan >= limit) {
						fn(...args);
						lastRan = Date.now();
					}
				},
				limit - (Date.now() - lastRan)
			);
		} else {
			fn(...args);
			lastRan = Date.now();
		}
	};
};

const CustomScrollRestoration = () => {
	const location = useLocation();
	const previousLocationRef = useRef<string>(location.pathname);

	const saveScrollPosition = useCallback(
		throttle(() => {
			sessionStorage.setItem(
				"tsr-scroll-restoration-v1",
				JSON.stringify({
					pathname: location.pathname,
					scrollY: window.scrollY,
				})
			);
		}, 200),
		[location.pathname]
	);

	useEffect(() => {
		const savedPosition = sessionStorage.getItem("tsr-scroll-restoration-v1");
		if (savedPosition) {
			const { pathname, scrollY } = JSON.parse(savedPosition) as {
				pathname: string;
				scrollY: number;
			};
			if (location.pathname === pathname) {
				window.scrollTo(0, scrollY);
			}
		}

		const handleBeforeUnload = () => {
			saveScrollPosition();
		};
		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [location.pathname, saveScrollPosition]);

	useEffect(() => {
		if (location.pathname !== previousLocationRef.current) {
			window.scrollTo(0, 0);
			previousLocationRef.current = location.pathname;
		}
	}, [location.pathname]);

	useEffect(() => {
		const handleScroll = () => {
			saveScrollPosition();
		};
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [saveScrollPosition]);

	return null;
};

export default CustomScrollRestoration;
