import { useKeenSlider } from "keen-slider/react";
import type React from "react";
import { useEffect, useState } from "react";

import type { KeenSliderProps } from "@components/views/(app)/home/BannerSection/BannerSection.types.ts";
import { cn } from "@core/utils";
import AffirmationCard from "@components/views/(app)/home/BannerSection/AffirmationCard.tsx";
import Image from "@components/elements/Image";

const ImageSlider: React.FC<KeenSliderProps> = ({ data }) => {
	const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
		initial: 0,
		loop: false,

		mode: "free-snap",
		slides: { perView: 1.1, spacing: 20 },
		breakpoints: {
			"(min-width: 400px)": {
				slides: { perView: 1.25, spacing: 20 },
			},
		},
	});

	const [, setActiveIndex] = useState(0);

	useEffect(() => {
		if (instanceRef.current) {
			instanceRef.current.on("slideChanged", () => {
				// @ts-ignore
				setActiveIndex(instanceRef.current?.track.details.abs);
			});
		}
	}, [instanceRef]);

	return (
		<div
			className={cn(
				"mt-[54px] flex w-full flex-col gap-[30px] px-[0]",
				"sm:hidden"
			)}
		>
			<div className="mx-auto w-full max-w-[430px] _tablet:max-w-[520px]">
				<AffirmationCard />
			</div>

			<div ref={sliderRef} className="keen-slider ">
				<div className="keen-slider__slide number-slide1">
					<div className="relative h-[360px]  w-auto overflow-hidden rounded-lg shadow-base2 ">
						<Image
							src={`/images/markup/landing/${data[0].url}.webp`}
							alt="Coachini"
							width={488}
							height={378}
							className="h-full w-full  object-cover object-center"
						/>
						<div className="absolute top-0 h-full w-full bg-gradient-to-r from-secondary-500 to-primary-500 opacity-60 mix-blend-multiply"></div>
					</div>
				</div>
				<div className="keen-slider__slide number-slide2">
					<div className="relative h-[360px] w-auto  overflow-hidden rounded-lg shadow-base2 lg:max-h-[378px] lg:max-w-[488px]">
						<Image
							src={`/images/markup/landing/${data[1].url}.webp`}
							alt="Coachini"
							width={488}
							height={378}
							className="h-full w-full  object-cover object-center"
						/>
						<div className="to-transparent absolute top-0 h-full w-full bg-gradient-to-r from-secondary-300 mix-blend-multiply" />
					</div>
				</div>
				<div className="keen-slider__slide number-slide3">
					<div className="relative h-[360px] w-auto  overflow-hidden rounded-lg shadow-base2 lg:max-h-[378px] lg:max-w-[488px]">
						<Image
							src={`/images/markup/landing/${data[2].url}.webp`}
							alt="Coachini"
							width={488}
							height={378}
							className="h-full w-full  object-cover object-center"
						/>
						<div className="to-transparent absolute top-0 h-full w-full bg-gradient-to-r from-primary-300 mix-blend-multiply" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ImageSlider;
