// src/components/FormField.tsx
import React from "react";
import { useField } from "formik";
import { cn } from "@core/utils";

interface FormFieldProps {
	label?: string;
	name: string;
	type: "text" | "email" | "tel" | "select" | "radio" | "checkbox";
	options?: Array<{ label: string; value: string }>;
	placeholder?: string;
	visible?: boolean; // New prop to handle visibility
}

const FormField: React.FC<FormFieldProps> = ({
	label,
	name,
	type,
	options,
	placeholder,
	visible = true, // Default to true
}) => {
	const [field, meta, helpers] = useField(name);

	// Return null if the field is not visible
	if (!visible) {
		return null;
	}

	const handleChange = (value: string | string[]) => {
		if (type === "checkbox") {
			const currentValue = field.value || [];
			if (currentValue.includes(value as string)) {
				// Remove the value if it's already selected
				helpers.setValue(currentValue.filter((item: string) => item !== value));
			} else {
				// Add the value if it's not selected
				helpers.setValue([...currentValue, value]);
			}
		} else if (type === "radio") {
			// Set the value for radio buttons
			helpers.setValue(value as string);
		} else {
			// For text, email, tel, and select
			helpers.setValue(value);
		}
	};

	return (
		<div>
			<label className="block text-sm font-medium mb-1">{label}</label>
			{type === "select" ? (
				<select
					{...field}
					className="w-full border px-3 py-2 rounded-md"
					onChange={(e) => handleChange(e.target.value)}
				>
					{options?.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
			) : type === "radio" ? (
				options?.map((option) => {
					const isChecked = field.value
						? (field.value as string[]).includes(option.value)
						: false;

					return (
						<label
							key={option.value}
							className={cn(
								"flex items-center mb-[10px] p-[10px] rounded-md cursor-pointer",
								isChecked ? "bg-iptic_secondary-100/[16%]" : ""
							)}
						>
							<input
								type="radio"
								value={option.value}
								checked={field.value === option.value} // Check if this radio is selected
								onChange={() => handleChange(option.value)} // Use the general handleChange function
								className="hidden" // Hide the native radio button
								aria-label={option.label} // Accessibility
							/>
							<span
								className={cn(
									"relative h-5 w-5 mr-2 rounded-full border-2 flex-shrink-0 transition-colors",
									isChecked
										? "bg-iptic_primary-100 border-0 border-transparent"
										: ""
								)}
								// className="h-5 w-5 inline-block mr-2 rounded-full border-2 border-gray-300 transition-colors duration-200 ease-in-out"
							>
								{isChecked && (
									<span className="absolute top-1 left-1 block h-3 w-3 rounded-full bg-white" />
								)}
								{field.value === option.value && (
									<span className="block h-3 w-3 rounded-full mx-auto" />
								)}
							</span>
							<span className="text-sm text-gray-700">{option.label}</span>
						</label>
					);
				})
			) : type === "checkbox" ? (
				options?.map((option) => {
					const isChecked = field.value
						? (field.value as string[]).includes(option.value)
						: false;

					return (
						<label
							key={option.value}
							className={cn(
								"flex items-center mb-[10px] p-[10px] rounded-md cursor-pointer",
								isChecked ? "bg-iptic_secondary-100/[16%]" : ""
							)}
						>
							{/* Hidden native checkbox */}
							<input
								type="checkbox"
								checked={isChecked}
								onChange={() => handleChange(option.value)}
								className="hidden"
							/>

							{/* Custom styled checkbox */}
							<span
								className={cn(
									"relative h-5 w-5 rounded-md border-2 flex-shrink-0 transition-colors",
									isChecked
										? "bg-iptic_primary-100 border-0 border-transparent"
										: ""
								)}
							>
								{/* Checkmark */}
								{isChecked && (
									<svg
										className="absolute top-0 left-0 w-full h-full text-white"
										fill="none"
										stroke="currentColor"
										strokeWidth="2"
										viewBox="0 0 24 24"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M5 13l4 4L19 7"
										/>
									</svg>
								)}
							</span>

							<span className="ml-2 text-sm text-gray-700">{option.label}</span>
						</label>
					);
				})
			) : (
				<input
					type={type}
					{...field}
					placeholder={placeholder}
					className="w-full border px-3 py-2 rounded-md"
					onChange={(e) => handleChange(e.target.value)} // Use the general handleChange function
				/>
			)}
			{meta.touched && meta.error ? (
				<div className="text-iptic_tertiary-100 text-sm mt-1">{meta.error}</div>
			) : null}
		</div>
	);
};

export default FormField;
