import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { StartGreenDashboard } from "@pages/(startgreen)/views/(dashboard)";
import AuthGuard from "@core/guards/auth.guard.tsx";

const getStartGreen = async () => {
	const response = await axios.get("http://localhost:4400/v1");
	return response.data;
};
const Page = () => {
	const { t: tMeta } = useTranslation("metadata");
	// const {
	// 	data: startGreenData,
	// 	isLoading,
	// 	error,
	// } = useQuery({
	// 	queryKey: ["startGreen"],
	// 	queryFn: getStartGreen,
	// });
	//
	// if (isLoading) return <div>Loading...</div>;
	// if (error) return <div>Error: {error.message}</div>;
	return (
		<>
			<Meta
				title={"StartGreen - Dashboard"}
				template={"%s | Coachini"}
				description={tMeta("about-page.description")}
				keywords={[
					"about us",
					"about coachini",
					"know more",
					"coaching",
					"coachini",
				]}
				robots="index,follow"
				ogTitle={tMeta("about-page.title")}
				ogDescription={tMeta("about-page.description")}
			/>
			{/*<AuthGuard>*/}
			<StartGreenDashboard />
			{/*</AuthGuard>*/}
		</>
	);
};

export const Route = createFileRoute("/(main)/startgreen-by-iptic/dashboard/")({
	component: Page,
});
