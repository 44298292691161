import type { GridItemProps } from "@components/views/(app)/common/ValueSection/ValueSection.types.ts";

export const advantagesData: GridItemProps[] = [
	{
		// icon: "svgwrapper",
		en: {
			title: "Demo",
			description: "To discover how our platform works.",
		},
		fr: {
			title: "Démo",
			description: "Pour découvrir le fonctionnement de notre plateforme.",
		},
	},
	{
		// icon: "svgwrapper",
		en: {
			title: "Tasting",
			description: "For a preview of immersion into our coaching.",
		},
		fr: {
			title: "Dégustation",
			description: "Pour un avant-goût d'immersion dans notre accompagnement.",
		},
	},
	{
		// icon: "svgwrapper",
		en: {
			title: "Diagnosis",
			description:
				"To better understand your needs and guide you towards tailored coaching.",
		},
		fr: {
			title: "Diagnostic",
			description:
				"Pour mieux cerner vos besoins et vous orienter vers un accompagnement sur mesure.",
		},
	},
	{
		// icon: "svgwrapper",
		en: {
			title: "Reframing",
			description: "For adaptive support and realignment with objectives.",
		},
		fr: {
			title: "Recadrage",
			description:
				"Pour un accompagnement évolutif et un réalignement aux objectifs.",
		},
	},
];
