import { createFileRoute, Navigate, redirect } from "@tanstack/react-router";
import { useLocation } from "@tanstack/react-router";

export const Route = createFileRoute("/(main)/startgreen/")({
	component: () => {
		const location = useLocation();
		const newPath = location.pathname.replace(
			"/startgreen",
			"/startgreen-by-iptic"
		);
		return <Navigate to={newPath} />;
	},
	// redirect: {
	// 	to: "/startgreen-by-iptic",
	// },
	// loader: () => {
	// 	const location = useLocation();
	// 	const newPath = location.pathname.replace(
	// 		"/startgreen",
	// 		"/startgreen-by-iptic"
	// 	);
	// 	redirect({
	// 		to: newPath,
	// 		throw: true,
	// 	});
	// },
});
