import type { FunctionComponent } from "types/types.ts";
import NavigationWrapper from "@components/layout/common/NavigationWrapper.tsx";
import HeaderSection from "@components/views/(app)/solutions/HeaderSection";
import ThemeSection from "@components/views/(app)/solutions/ThemeSection";
import { startupPlans } from "@api/database/constant/plans.data.ts";
import PackSection from "@components/views/(app)/solutions/PackSection";
import ModelSection from "@components/views/(app)/solutions/ModelSection";
import { companyReviewData } from "@api/database/constant/reviews.data.ts";
import CompanyReviewSection from "@components/views/(app)/solutions/CompanyReviewSection";
import { startupThemes } from "@api/database/constant/themes.data.ts";
import NewsletterSection from "@components/views/(app)/common/NewsletterSection";

export const SolutionsStartup = (): FunctionComponent => {
	return (
		<NavigationWrapper>
			<main>
				<HeaderSection solutionType="startup" />
				<ThemeSection solutionType="startup" data={startupThemes} />
				<PackSection solutionType="startup" data={startupPlans} />
				<ModelSection solutionType="startup" />
				<CompanyReviewSection data={companyReviewData} solutionType="startup" />
				<NewsletterSection />
			</main>
		</NavigationWrapper>
	);
};
