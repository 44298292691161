import { Link } from "@tanstack/react-router";

const VisitorsTable = ({
	refetch,
	candidates = [], // Default to an empty array if candidates is undefined
	toggleCandidateSelection,
	selectedCandidates,
}) => (
	<div className="overflow-hidden rounded-lg drop-shadow-md border border-gray-100/20 bg-gray-50">
		<table className="min-w-full bg-white">
			<thead className="bg-startgreen_primary-100/20">
				<tr>
					<th className="py-3 px-5 border-b text-left font-medium text-gray-700">
						Email
					</th>
				</tr>
			</thead>
			<tbody>
				{candidates?.length === 0 ? (
					<tr>
						<td colSpan={2} className="py-3 px-5 text-center text-gray-500">
							Aucun visiteur.
						</td>
					</tr>
				) : (
					candidates.map((candidate, index) => (
						<tr
							key={candidate.id}
							className={
								index % 2 === 0 ? "bg-white" : "bg-startgreen_darkblue-100/10"
							}
						>
							<td className="py-3 px-5 border-b text-gray-700">
								{candidate.email} {/* Directly displaying the email */}
							</td>
						</tr>
					))
				)}
			</tbody>
		</table>
	</div>
);

export default VisitorsTable;
