import createWebStorage from "redux-persist/lib/storage/createWebStorage";
import type { Storage } from "redux-persist";

const createNoopStorage = (): Storage => {
	return {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		getItem(_key: string): Promise<string | null> {
			return Promise.resolve(null);
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		setItem(_key: string, value: string): Promise<void> {
			return Promise.resolve();
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		removeItem(_key: string): Promise<void> {
			return Promise.resolve();
		},
	};
};

const storage: Storage =
	typeof window === "undefined"
		? createNoopStorage()
		: createWebStorage("local");

export default storage;
