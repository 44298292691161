import React from "react";
import { cn } from "@core/utils";
import Text from "@components/elements/Text";
import { useTranslation } from "react-i18next";
import Button from "@components/elements/Buttons/Button.tsx";
import Image from "@components/elements/Image";

function PhaseCard({ phase, title, showButton, description, disabled }) {
	const { t: t } = useTranslation("startgreen-page");

	return (
		<div
			className={cn(
				"relative flex flex-col lg:items-start items-stretch lg:flex-row",
				"bg-startgreen_lightcream-100 drop-shadow-md w-full h-full rounded-md p-6 gap-[22px]",
				"md:min-h-[300px] lg:min-h-[360px] xl:min-h-[300px]"
			)}
		>
			<div className={"flex flex-col justify-center items-center  gap-[14px]"}>
				<div
					className={cn(
						"h-[150px] w-full flex justify-center items-center  rounded-xl lg:aspect-square",
						disabled
							? "bg-startgreen_darkblue-100"
							: "bg-startgreen_secondary-100"
					)}
				>
					<Text
						className={"!text-[90px] font-semibold text-white"}
						text={phase}
					/>
				</div>
				{showButton && (
					<Button
						variant="startgreen-sm"
						size={"startgreen-sm"}
						link={disabled ? "" : "/startgreen-by-iptic/apply"}
						className={"w-full hidden lg:flex gap-1"}
						disabled={disabled}
					>
						{disabled && (
							<Image
								className=" rounded-md aspect-auto"
								width={24}
								height={24}
								src="/images/startgreen/lock.svg"
								alt="StartGreen"
								aria-label="StartGreen"
							/>
						)}

						{!disabled && t("buttons.apply")}
					</Button>
				)}
			</div>

			<div className={"flex flex-col justify-start items-start gap-2"}>
				<h2
					className={cn(
						"!leading-normal text-left text-[28px] _tablet:text-[30px] _laptop:text-[35px]",
						"font-semibold"
					)}
				>
					{title}
				</h2>
				<h2
					className={cn(
						"!leading-normal text-left text-[16px] _tablet:text-[16px] _laptop:text-[16px]",
						"font-light"
					)}
				>
					{description}
				</h2>
			</div>
			{showButton && (
				<Button
					variant="startgreen-sm"
					size={"startgreen-sm"}
					link={disabled ? "" : "/startgreen-by-iptic/apply"}
					className={"flex lg:hidden w-full gap-1"}
					disabled={disabled}
				>
					{disabled && (
						<Image
							className=" rounded-md aspect-auto"
							width={24}
							height={24}
							src="/images/startgreen/lock.svg"
							alt="StartGreen"
							aria-label="StartGreen"
						/>
					)}
					{!disabled && t("buttons.apply")}
				</Button>
			)}
		</div>
	);
}

export default PhaseCard;
