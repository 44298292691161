import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import "@i18n/i18n";
import store, { persistor } from "@store/index";
import { ThemeContextProvider } from "@contexts/theme.provider";
import type { ReactNode } from "react";
import { PersistGate } from "reduxjs-toolkit-persist/lib/integration/react";
import { AuthProvider } from "@core/guards/AuthProvider.tsx";

type AppProviderWrapper = {
	children: ReactNode;
	className?: string;
};

export default function AppProviderWrapper({ children }: AppProviderWrapper) {
	const queryClient = new QueryClient(/* optional config */);
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<AuthProvider>
					<ThemeContextProvider>
						<QueryClientProvider client={queryClient}>
							<>{children}</>
						</QueryClientProvider>
					</ThemeContextProvider>
				</AuthProvider>
			</PersistGate>
		</Provider>
	);
}
