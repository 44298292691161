import { useEffect, useState } from "react";
import { Reader } from "@usewaypoint/email-builder";

export const TemplateEditor = ({ selectedTemplate, onSave }) => {
	const [templateName, setTemplateName] = useState(
		selectedTemplate ? selectedTemplate.name : ""
	);
	const [templateSubject, setTemplateSubject] = useState(
		selectedTemplate ? selectedTemplate.subject : ""
	);
	const [templateContent, setTemplateContent] = useState(
		selectedTemplate ? selectedTemplate.template : ""
	);
	const [dynamicFields, setDynamicFields] = useState(
		selectedTemplate ? selectedTemplate.dynamicFields : []
	);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		if (selectedTemplate) {
			setTemplateName(selectedTemplate.name);
			setTemplateSubject(selectedTemplate.subject || "");
			setTemplateContent(selectedTemplate.template);
			setDynamicFields(selectedTemplate.dynamicFields || []);
		}
	}, [selectedTemplate]);

	const handleAddField = () => {
		setDynamicFields([...dynamicFields, ""]);
	};

	const handleFieldChange = (index, value) => {
		const newFields = [...dynamicFields];
		newFields[index] = value;
		setDynamicFields(newFields);
	};

	const handleRemoveField = (index) => {
		const newFields = dynamicFields.filter((_, i) => i !== index);
		setDynamicFields(newFields);
	};

	const handleSave = () => {
		setIsSaving(true);
		onSave({
			id: selectedTemplate.id,
			name: templateName,
			subject: templateSubject,
			template: templateContent,
			dynamicFields,
		});
		setIsSaving(false);
	};

	return (
		<div className="template-editor space-y-4">
			<input
				type="text"
				value={templateName}
				onChange={(e) => setTemplateName(e.target.value)}
				placeholder="Nom du modèle"
				className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
				disabled={isSaving}
			/>
			<input
				type="text"
				value={templateSubject}
				onChange={(e) => setTemplateSubject(e.target.value)}
				placeholder="Sujet du modèle"
				className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
				disabled={isSaving}
			/>
			<div className="dynamic-fields space-y-2">
				<label className="block text-gray-700">Champs dynamiques :</label>
				{dynamicFields.map((field, index) => (
					<div key={index} className="flex space-x-2 items-center">
						<input
							type="text"
							value={field}
							onChange={(e) => handleFieldChange(index, e.target.value)}
							placeholder={`Champ ${index + 1}`}
							className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
							disabled={isSaving}
						/>
						<button
							onClick={() => handleRemoveField(index)}
							className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
							disabled={isSaving}
						>
							Supprimer
						</button>
					</div>
				))}

				<button
					className="bg-startgreen_darkblue-100 text-white px-4 py-2 rounded hover:bg-blue-600"
					onClick={handleAddField}
					disabled={isSaving}
				>
					Ajouter un champ dynamique
				</button>
			</div>
			<Reader document={JSON.parse(templateContent)} rootBlockId="root" />
			<button
				onClick={handleSave}
				className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
				disabled={isSaving}
			>
				{isSaving ? "Enregistrement..." : "Enregistrer le modèle"}
			</button>
		</div>
	);
};
