import SponsorCardWrapper from "@components/views/(app)/home/SponsorListSection/SponsorCardWrapper.tsx";
import { DynamicImage } from "@components/views/(app)/home/SponsorListSection/DynamicImage.tsx";

export interface Image {
	shape: string;
	name: string;
	width: number | string;
}

const SponsorImage = ({
	shape,
	svgShape,
	currentIndex,
	images,
	className,
}: {
	shape: "rectangle" | "circle" | "square";
	svgShape:
		| "circle-1"
		| "circle-2"
		| "rectangle-1"
		| "rectangle-2"
		| "square-1"
		| "square-2";
	currentIndex: number;
	images: Image[];
	className?: string;
}) => {
	return (
		<SponsorCardWrapper shape={shape} svgShape={svgShape} className={className}>
			<DynamicImage images={images} shape={shape} currentIndex={currentIndex} />
		</SponsorCardWrapper>
	);
};

export default SponsorImage;
