import { TEditorConfiguration } from "../../documents/editor/core";

const WELCOME: TEditorConfiguration = {
	root: {
		type: "EmailLayout",
		data: {
			backdropColor: "#F2F5F7",
			canvasColor: "#FFFFFF",
			textColor: "#242424",
			fontFamily: "MODERN_SANS",
			childrenIds: [
				"block-1709571212684",
				"block-1709571228545",
				"block-1709571234315",
				"block-1709571247550",
				"block-1709571258507",
				"block-1709571281151",
				"block-1709571302968",
				"block-1709571282795",
			],
		},
	},
	"block-1709571212684": {
		type: "Image",
		data: {
			style: {
				padding: {
					top: 24,
					bottom: 24,
					right: 24,
					left: 24,
				},
			},
			props: {
				url: "https://d1iiu589g39o6c.cloudfront.net/live/platforms/platform_A9wwKSL6EV6orh6f/images/wptemplateimage_JTNBBPGrNs2Ph4JL/marketbase.png",
				alt: "Marketbase",
				linkHref: "https://marketbase.app",
				contentAlignment: "middle",
			},
		},
	},
	"block-1709571228545": {
		type: "Text",
		data: {
			style: {
				fontWeight: "normal",
				padding: {
					top: 0,
					bottom: 16,
					right: 24,
					left: 24,
				},
			},
			props: {
				text: "Hi Anna 👋,",
			},
		},
	},
	"block-1709571234315": {
		type: "Text",
		data: {
			style: {
				fontWeight: "normal",
				padding: {
					top: 0,
					bottom: 16,
					right: 24,
					left: 24,
				},
			},
			props: {
				text: "Welcome to Marketbase! Marketbase is how teams within fast growing marketplaces effortlessly monitor conversations to prevent disintermediation, identify problematic users, and increase trust & safety within their community.",
			},
		},
	},
	"block-1709571247550": {
		type: "Text",
		data: {
			style: {
				fontWeight: "normal",
				padding: {
					top: 0,
					bottom: 16,
					right: 24,
					left: 24,
				},
			},
			props: {
				text: "Best of all, you can connect your existing messaging services in minutes:",
			},
		},
	},
	"block-1709571258507": {
		type: "Image",
		data: {
			style: {
				padding: {
					top: 16,
					bottom: 16,
					right: 24,
					left: 24,
				},
			},
			props: {
				url: "https://d1iiu589g39o6c.cloudfront.net/live/platforms/platform_A9wwKSL6EV6orh6f/images/wptemplateimage_oWB821TUkDXvr2f4/Screenshot%202023-11-22%20at%2011.51.30%20AM.png",
				alt: "Video thumbnail",
				linkHref: "https://capture.dropbox.com/NBQEmoCKKP9RGBWr",
				contentAlignment: "middle",
			},
		},
	},
	"block-1709571281151": {
		type: "Text",
		data: {
			style: {
				fontWeight: "normal",
				padding: {
					top: 16,
					bottom: 16,
					right: 24,
					left: 24,
				},
			},
			props: {
				text: "If you ever need help, just reply to this email and one of us will get back to you shortly. We’re here to help.",
			},
		},
	},
	"block-1709571282795": {
		type: "Image",
		data: {
			style: {
				padding: {
					top: 16,
					bottom: 40,
					right: 24,
					left: 24,
				},
			},
			props: {
				url: "https://d1iiu589g39o6c.cloudfront.net/live/platforms/platform_A9wwKSL6EV6orh6f/images/wptemplateimage_cAK8FpmBEGoSRNi3/Screenshot%202023-11-22%20at%2011.48.53%20AM.png",
				alt: "Illustration",
				linkHref: null,
				contentAlignment: "middle",
			},
		},
	},
	"block-1709571302968": {
		type: "Button",
		data: {
			style: {
				fontSize: 14,
				padding: {
					top: 16,
					bottom: 24,
					right: 24,
					left: 24,
				},
			},
			props: {
				buttonBackgroundColor: "#0079cc",
				buttonStyle: "rectangle",
				text: "Open dashboard",
				url: "https://www.coachini.net",
			},
		},
	},
};

export default WELCOME;
