import { useCallback, useEffect, useState } from "react";
import SponsorImage from "@components/views/(app)/home/SponsorListSection/SponsorImage.tsx";

export interface Image {
	shape: string;
	name: string;
	width: number | string;
}

interface SponsorCardCollectionProps {
	images: Image[];
}

function SponsorGrid({ images }: SponsorCardCollectionProps) {
	const [indexes, setIndexes] = useState({
		rectangle: 0,
		circle: 0,
		square: 0,
	});

	const shapes = ["rectangle", "circle", "square"];
	const [currentShapeIndex, setCurrentShapeIndex] = useState(0);

	const getNextLogoIndex = useCallback(
		(shape: string, currentIndex: number) => {
			const logos = images.filter((img) => img.shape === shape);
			return (currentIndex + 1) % logos.length;
		},
		[images]
	);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setIndexes((previousIndexes) => {
				const currentShape = shapes[currentShapeIndex];
				const nextIndex = getNextLogoIndex(
					currentShape,
					previousIndexes[currentShape as keyof typeof previousIndexes]
				);

				setCurrentShapeIndex(
					(previousIndex) => (previousIndex + 1) % shapes.length
				);

				return {
					...previousIndexes,
					[currentShape]: nextIndex,
				};
			});
		}, 2200);

		return () => {
			clearInterval(intervalId);
		};
	}, [getNextLogoIndex, currentShapeIndex]);

	return (
		<div className="hidden flex-col items-start gap-[50px] -space-y-1 _tablet_sm:flex sm:gap-[10px] md:items-start">
			<div className="grid">
				<div className="flex flex-col items-start justify-center gap-[20px] _phone_lg:space-y-0 _tablet:flex-row _tablet:gap-10">
					<div className="mt-[-22px] flex flex-col items-center gap-[50px] _phone_lg:flex-row _tablet_sm:items-end _tablet:flex-col _tablet:gap-8">
						<SponsorImage
							shape="rectangle"
							svgShape="rectangle-1"
							currentIndex={indexes.rectangle}
							images={images}
						/>
						<SponsorImage
							shape="circle"
							svgShape="circle-1"
							currentIndex={indexes.circle}
							images={images}
						/>
					</div>
					<div className="flex flex-col gap-[50px] self-start px-3 _tablet:gap-10">
						<div className="flex flex-row items-center gap-8 _phone_lg:flex-row _phone_lg:items-start">
							<SponsorImage
								shape="square"
								svgShape="square-1"
								currentIndex={indexes.square}
								images={images}
							/>
							<SponsorImage
								shape="circle"
								svgShape="circle-2"
								currentIndex={indexes.circle + 1}
								images={images}
							/>
						</div>
						<div className="flex flex-col items-end gap-10 _phone_lg:flex-row _phone_lg:items-start">
							<SponsorImage
								shape="rectangle"
								svgShape="rectangle-2"
								currentIndex={indexes.rectangle + 1}
								images={images}
							/>
							<SponsorImage
								shape="square"
								svgShape="square-1"
								currentIndex={indexes.square + 1}
								images={images}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col items-center justify-center _phone_lg:flex-row ">
				<div className="mt-[-72px] flex w-full flex-col items-start gap-[50px] px-3 _phone_lg:mt-0 _phone_lg:flex-row _tablet:gap-8">
					<SponsorImage
						shape="square"
						svgShape="square-2"
						currentIndex={indexes.square + 2}
						images={images}
					/>
					<SponsorImage
						shape="rectangle"
						svgShape="rectangle-1"
						currentIndex={indexes.rectangle + 2}
						images={images}
					/>
				</div>
			</div>
		</div>
	);
}

export default SponsorGrid;
