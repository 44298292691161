import { useTranslation } from "react-i18next";
import { cn } from "@core/utils";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import Button from "@components/elements/Buttons/Button.tsx";
import Text from "@components/elements/Text";

interface CoachCTAProps {
	isDivider?: boolean;
	dividerColor?: string;
	className?: string;
	primaryButtonFn?: () => void;
	showPrimaryButton?: boolean;
	secondaryButtonFn?: () => void;
	showSecondaryButton?: boolean;
}

const CoachCtaSection: React.FC<CoachCTAProps> = ({
	isDivider = true,
	dividerColor = "bg-primary-50",
	className = "",
	showSecondaryButton = true,
	showPrimaryButton = true,
}) => {
	// const navigate = useNavigate();

	// const { t, i18n } = useTranslation('misc');
	// const buttonClasses = classNames('mb-4', {
	//   'sm:mb-0 sm:mr-4': showSecondaryButton && showPrimaryButton,
	// });
	// const primaryRedirect = () => navigate('/coaches');
	// const secondaryRedirect = () => navigate('/coaches');

	const { t } = useTranslation("common");
	const { t: tButtons } = useTranslation("misc");
	return (
		<section className={cn(isDivider && dividerColor)}>
			<SectionWrapper
				tag="div"
				className={cn(
					"flex flex-col items-center gap-12",
					"margin-divider-medium",
					isDivider && "py-c",
					className
				)}
			>
				{/*<Text*/}
				{/*  className={cn(*/}
				{/*    'w-full text-center text-headlineSmall _tablet_sm:text-displaySmall _tablet:!text-displayMedium_fluid _tablet:leading-displayMedium _laptop:!text-displayLarge_fluid _laptop:leading-displayLarge',*/}
				{/*  )}*/}
				{/*  highlightStyle='text-secondary-900 font-semibold'*/}
				{/*  text={t('coach-cta-section.title')}*/}
				{/*/>*/}
				<Text
					className={cn(
						"w-full text-center",
						" text-headlineSmall _tablet_sm:text-displaySmall _tablet:text-displayMedium  _laptop:text-displayLarge"
					)}
					highlightStyle="text-secondary-900 font-semibold"
					text={t("coach-cta-section.text-1")}
				/>
				<Text
					styled={false}
					className={cn(
						"w-full text-center",
						"text-[21px] _tablet_sm:text-[32px] _tablet:text-[40px] _tablet:leading-displayMedium _laptop:text-[47px] _laptop:leading-displayLarge"
					)}
					highlightStyle="text-secondary-900 font-semibold"
					text={t("coach-cta-section.text-2")}
				/>
				<div className="flex flex-col gap-4 sm:flex-row  ">
					{showSecondaryButton && (
						<Button variant="secondary" link={`/coaches`}>
							{tButtons("buttons.see-more")}
						</Button>
					)}
					{showPrimaryButton && (
						<Button variant="primary" link={`/contact`}>
							{tButtons("buttons.join-us")}
						</Button>
					)}
				</div>
			</SectionWrapper>
		</section>
	);
};

export default CoachCtaSection;
