import type React from "react";
import { cn } from "@core/utils";

type AllowedTags = "section" | "div" | "article" | "aside" | "main";

function SectionWrapper({
	children,
	className,
	tag: Tag = "section",
}: {
	children: React.ReactNode;
	className?: string;
	tag?: AllowedTags;
}) {
	return (
		<Tag
			className={cn(
				"max-w-u-grid",
				"mx-auto flex items-center",
				"px-u-grid-sm _tablet:px-u-grid-md _laptop:px-u-grid-lg _large:px-u-grid-xl",
				className
			)}
		>
			{children}
		</Tag>
	);
}

export default SectionWrapper;
