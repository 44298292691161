import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { SolutionsEmployability } from "@pages/(app)/solutions/SolutionsEmployability.tsx";
import { useTranslation } from "react-i18next";

const Page = () => {
	const { t: tMeta } = useTranslation("metadata");

	return (
		<>
			<Meta
				title={tMeta("solutions-page.employability.title")}
				template={"%s | Coachini"}
				description={tMeta("solutions-page.employability.description")}
				keywords={[
					"employability",
					"recent graduates",
					"employability solutions",
					"nouveaux etudiants",
					"employability themes",
					"récents diplômés",
				]}
				robots="index,follow"
				ogTitle={tMeta("solutions-page.employability.title")}
				ogDescription={tMeta("solutions-page.employability.description")}
			/>
			<SolutionsEmployability />
		</>
	);
};
export const Route = createFileRoute("/(main)/solutions/employability/")({
	component: Page,
});
