import type React from "react";
import { Helmet } from "react-helmet";

interface ScriptProps {
	attributes: React.ScriptHTMLAttributes<HTMLScriptElement>;
	innerHTML?: string;
}

interface ScriptsProps {
	scripts: ScriptProps[];
}

const Scripts: React.FC<ScriptsProps> = ({ scripts }) => {
	return (
		<Helmet>
			{scripts.map((script, index) => (
				<script key={index} {...script.attributes}>
					{script.innerHTML}
				</script>
			))}
		</Helmet>
	);
};

export default Scripts;
