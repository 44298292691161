import type { GridItemProps } from "@components/views/(app)/common/ValueSection/ValueSection.types.ts";
import type { StatisticItemsProps } from "@components/views/(app)/common/StatisticSection/StatisticSection.types.ts";
import type { LinkItem, SocialIconLink } from "types/types.ts";

export const valuesData: GridItemProps[] = [
	// {
	//   en: {
	//     title: 'Solidarity',
	//     description:
	//       "It's not just large companies that engage in CSR, we do too !",
	//   },
	//   fr: {
	//     title: 'Solidarité',
	//     description:
	//       "Il n'y a pas que les grandes entreprises qui font du RSE, nous aussi !",
	//   },
	// },
	{
		en: {
			title: "Personalization",
			description: "Experience tailored coaching.",
		},
		fr: {
			title: "Personnalisation",
			description: "Goûtez au coaching sur mesure.",
		},
	},
	{
		en: {
			title: "Professionalism",
			description: "An app made for REAL Coaches.",
		},
		fr: {
			title: "Professionnalisme",
			description: "Une application faite pour les VRAIS Coachs.",
		},
	},
	{
		en: {
			title: "Synergy",
			description: "Your passion + Our expertise = Energizing cocktail.",
		},
		fr: {
			title: "Synergie",
			description: "Votre passion + Notre expertise = Cocktail énergisant.",
		},
	},

	{
		en: {
			title: "Proximity",
			description: "Who said there's no HOME COACH ?",
		},
		fr: {
			title: "Proximité",
			description: "Qui a dit qu’il n’y a pas de COACH À DOMICILE ?",
		},
	},
	{
		en: {
			title: "Diversity",
			description: "Each person's diversity enriches us all.",
		},
		fr: {
			title: "Diversité",
			description: "La diversité de chacun fait la richesse de tous.",
		},
	},
	{
		en: {
			title: "Internationalization",
			description: "What does border even mean ?",
		},
		fr: {
			title: "Internationalisation",
			description: "Qu’est ce que ça veut dire frontière ? ",
		},
	},
];

export const statisticData: StatisticItemsProps = [
	{
		number: "+500",
		icon: "downloads",
		content: {
			en: {
				description: "Downloads",
			},
			fr: {
				description: "Téléchargements",
			},
		},
	},
	{
		number: "+40",
		icon: "coach-signup",
		content: {
			en: {
				description: "Registered Coaches",
			},
			fr: {
				description: "Coachs inscrits",
			},
		},
	},
	{
		number: "+110",
		icon: "services",
		content: {
			en: {
				description: "Offered Services",
			},
			fr: {
				description: "Services Offerts",
			},
		},
	},
	{
		number: "+200",
		icon: "matching",
		content: {
			en: {
				description: "Matching",
			},
			fr: {
				description: "Matching",
			},
		},
	},
	{
		number: "+70",
		icon: "themes",
		content: {
			en: {
				description: "Themes",
			},
			fr: {
				description: "Thèmes",
			},
		},
	},
];

export const footerSocials: SocialIconLink[] = [
	{
		title: "Facebook",
		link: "https://www.facebook.com/CoachiniStartup/",
		icon: "facebook",
	},
	{
		title: "Linkedin",
		link: "https://www.linkedin.com/company/coachini-moncoach-tn/",
		icon: "linkedin",
	},
	{
		title: "instagram",
		link: "https://www.instagram.com/coachini.app/",
		icon: "instagram",
	},
	{
		title: "Youtube",
		link: "https://www.youtube.com/@coachiniapp",
		icon: "youtube",
	},
];

export const footerLinks: LinkItem[] = [
	{ title: "navbar.home", link: "/" },
	{ title: "navbar.about", link: "/about" },
	{ title: "navbar.coaches", link: "/coaches" },
	{ title: "navbar.faq", link: "/faq" },
	{
		title: "navbar.coachiniStudio.title",
		children: [
			{ title: "navbar.coachiniStudio.cv", link: "/studio" },
			{ title: "navbar.coachiniStudio.pitch", link: "/studio" },
			{ title: "navbar.coachiniStudio.podcast", link: "/studio" },
		],
	},

	{
		title: "navbar.services.title",
		children: [
			{
				title: "footer.links.services.enterprise",
				link: "/solutions/enterprise",
			},
			{ title: "footer.links.services.startup", link: "/solutions/startup" },
			{
				title: "footer.links.services.employability",
				link: "/solutions/employability",
			},
			{
				title: "footer.links.services.researcher",
				link: "/solutions/researcher",
			},
		],
	},
];
