import { createFileRoute } from "@tanstack/react-router";
import { Contact } from "@pages/(app)/Contact.tsx";
import Meta from "@components/layout/common/Meta.tsx";
import { useTranslation } from "react-i18next";

const Page = () => {
	const { t: tMeta } = useTranslation("metadata");

	return (
		<>
			<Meta
				title={tMeta("contact-page.title")}
				template={"%s | Coachini"}
				description={tMeta("contact-page.description")}
				keywords={[
					"contact",
					"contact information",
					"coachini contact",
					"contact us",
				]}
				robots="index,follow"
				ogTitle={tMeta("contact-page.title")}
				ogDescription={tMeta("contact-page.description")}
			/>
			<Contact />
		</>
	);
};

export const Route = createFileRoute("/(main)/contact/")({
	component: Page,
});
