import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { cn } from "@core/utils";
import Text from "@components/elements/Text";
import PhaseCard from "@pages/(startgreen)/views/(landing)/PhasesSection/PhaseCard.tsx";
import { appConfig } from "@config/appConfig.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getPhases } from "@api/service/startgreen.services.ts";
import Spinner from "@components/elements/Spinner";

function PhasesSection() {
	const { t } = useTranslation("startgreen-page");

	// Define state variables to store phase information
	const [phaseName, setPhaseName] = useState("");
	const [isPhaseOpen, setIsPhaseOpen] = useState(false);
	const [canApply, setCanApply] = useState(false);
	const [loading, setLoading] = useState(true);
	const [isApplicationOpen, setIsApplicationOpen] = useState(false);
	const {
		data: phases,
		isLoading,
		error,
		refetch,
	} = useQuery({
		queryKey: ["phases"],
		queryFn: getPhases,
	});
	const sortedPhases = phases?.sort(
		(a: any, b: any) => a.phaseOrder - b.phaseOrder
	);

	useEffect(() => {
		const fetchPhaseInfo = async () => {
			try {
				const [statusResponse, phaseResponse, allPhases] = await Promise.all([
					axios.get(appConfig.startGreenUrl + "/status"),
					axios.get(appConfig.startGreenUrl + "/platform/phases/current"),
					await axios.get(`${appConfig.startGreenUrl}/platform/phases/all`),
				]);
				const phaseData = phaseResponse.data;
				const statusData = statusResponse.data;

				const isApplicationOpen = statusData.applyStatus === true;
				// const phaseResponse = await axios.get(
				// 	appConfig.startGreenUrl + "/platform/phases/current"
				// );
				setIsApplicationOpen(statusData.applyStatus === true);
				// Store relevant information in the state
				setPhaseName(phaseData.phaseName);
				setCanApply(phaseData.canApply !== "NONE");
				setIsPhaseOpen(phaseData.isOpen);
			} catch (error) {
				console.error("Error fetching phase info", error);
			} finally {
				setLoading(false);
			}
		};

		fetchPhaseInfo();
	}, []);

	const phaseInfo = (phase: string) => ({
		isOpen: phaseName === phase && isPhaseOpen,
		showButton: phaseName === phase && canApply,
	});

	// Info for each phase
	const phase1Info = phaseInfo("phase_1");
	const phase2Info = phaseInfo("phase_2");
	const phase3Info = phaseInfo("phase_3");
	const phase4Info = phaseInfo("phase_4");
	if (isLoading)
		return (
			<div className={"flex items-center justify-center"}>
				<Spinner />
			</div>
		);

	return (
		<section
			className={cn(
				"relative flex w-full h-full",
				"flex flex-col items-center justify-center",
				"gap-10 md:gap-10"
			)}
		>
			<div className={"flex flex-col items-center gap-[10px]"}>
				<Text
					text={t("phases-section.title")}
					className={cn(
						"text-displaySmall _tablet:text-displayLarge _laptop:text-displayLarge",
						"font-bold"
					)}
					highlightStyle={"text-startgreen_secondary-100"}
				/>
				<hr
					className={
						"w-[40%] border-4 border-startgreen_secondary-100 rounded-md"
					}
				/>
			</div>

			{/* Phase Cards */}
			<div
				className={
					"flex flex-col lg:flex-row items-stretch justify-center gap-[25px] w-full"
				}
			>
				<PhaseCard
					phase={"1"}
					showButton={true}
					title={sortedPhases[0].name}
					disabled={!isApplicationOpen}
					description={sortedPhases[0].phaseDescription}
				/>
				<PhaseCard
					phase={"2"}
					showButton={false}
					title={sortedPhases[1].name}
					disabled={true}
					description={sortedPhases[1].phaseDescription}
				/>
			</div>
			<div className={"flex flex-col lg:flex-row items-center gap-[25px]"}>
				<PhaseCard
					phase={"3"}
					showButton={false}
					title={sortedPhases[2].name}
					disabled={true}
					description={sortedPhases[2].phaseDescription}
				/>
				<PhaseCard
					phase={"4"}
					showButton={false}
					title={sortedPhases[3].name}
					disabled={true}
					description={sortedPhases[3].phaseDescription}
				/>
			</div>
		</section>
	);
}

export default PhasesSection;
