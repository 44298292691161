import { createRootRoute, Outlet } from "@tanstack/react-router";
import DevToolsWrapper from "@utils/development-tools/DevelopmentToolsWrapper";
import Meta from "@components/layout/common/Meta.tsx";
import Scripts from "@components/layout/common/Scripts.tsx";
import { googleAnalyticsScripts } from "@config/appConfig.ts";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export const Route = createRootRoute({
	component: RootComponent,
});

function RootComponent() {
	const { t: tMeta } = useTranslation("metadata");
	return (
		<DevToolsWrapper>
			<Meta
				title={tMeta("home-page.title")}
				description={tMeta("home-page.description")}
				keywords={tMeta("home-page.keywords")}
				robots="index,follow"
				locale={i18next.resolvedLanguage}
				ogTitle={tMeta("home-page.title")}
				ogDescription={tMeta("home-page.description")}
				ogImage="https://coachini.net/"
				ogUrl="https://coachini.net"
			/>
			{/*#region Google Analytics*/}
			<Scripts scripts={googleAnalyticsScripts} />
			{/*#endregion*/}
			<Outlet />
		</DevToolsWrapper>
	);
}
