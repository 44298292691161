import { useTranslation } from "react-i18next";
import { enterpriseModelCards } from "@api/database/constant/models.data.ts";
import type {
	LanguageFields,
	ModelDataProps,
} from "@components/views/(app)/solutions/ModelSection/ModelSection.types.ts";
import SectionWrapper from "@components/layout/common/SectionWrapper.tsx";
import { cn } from "@core/utils";
import Button from "@components/elements/Buttons/Button.tsx";
import ModelCard from "@components/views/(app)/solutions/ModelSection/ModelCard.tsx";
import Text from "@components/elements/Text";
import i18next from "i18next";

function ModelSection({
	solutionType = "enterprise",
}: {
	solutionType?: "enterprise" | "startup" | "employability" | "researcher";
}) {
	let titleColor;
	let cardColor: string | undefined;
	let bulletColor: string | undefined;
	switch (solutionType) {
		case "enterprise": {
			titleColor = "text-primary-400";
			cardColor = "fill-primary-400";
			bulletColor = "marker:text-primary-400";
			break;
		}
		case "startup": {
			titleColor = "text-primary-400";
			cardColor = "fill-primary-400";
			bulletColor = "marker:text-primary-400";
			break;
		}
		case "employability": {
			titleColor = "text-tertiary-900";
			cardColor = "fill-tertiary-900";
			bulletColor = "marker:text-tertiary-900";
			break;
		}
		case "researcher": {
			titleColor = "text-secondary-900";
			cardColor = "fill-secondary-900";
			bulletColor = "marker:text-secondary-900";
			break;
		}
	}

	const { t } = useTranslation("solutions-page");
	const { t: buttons } = useTranslation("misc");
	const cardsToRender = enterpriseModelCards.map((card: ModelDataProps) => {
		const cardToRender = card;
		const languageKey = i18next.resolvedLanguage as keyof LanguageFields;
		return {
			...card,
			title: cardToRender[languageKey].title,
			features: cardToRender[languageKey].features,
		};
	});
	return (
		<SectionWrapper
			className={cn(
				"flex flex-col place-content-center justify-between gap-[50px] ",
				"margin-divider-medium",
				"_tablet:gap-[5vw]  _tablet_lg:flex-row _laptop_md:justify-center _laptop_md:gap-[120px] _middle:gap-[15em] _large:gap-[20em]"
			)}
		>
			<div className="flex flex-col items-center gap-[20px] self-center _tablet_lg:max-w-[300px] _tablet_lg:items-start _tablet_lg:self-start  _laptop:max-w-[400px]">
				<Text
					text={t(`model-section.${solutionType}.title`)}
					className={cn(
						"text-center text-headlineSmall _tablet:text-displaySmall",
						"_tablet_lg:text-start _laptop:text-displayMedium _large:text-displayLarge"
					)}
					highlightStyle={cn("font-semibold", titleColor)}
				/>
				<Button variant="primary" link={`/contact`}>
					{buttons("buttons.contact")}
				</Button>
			</div>
			<div className="flex flex-col gap-[20px] _tablet:gap-[40px] _laptop:gap-[30px] _large:gap-[40px]">
				{cardsToRender.map((data, index) => (
					<ModelCard
						key={index}
						data={data}
						iconBGColor={cardColor}
						bulletColor={bulletColor}
					/>
				))}
			</div>
		</SectionWrapper>
	);
}

export default ModelSection;
