import { cn } from "@core/utils";
import AffirmationCard from "@components/views/(app)/home/BannerSection/AffirmationCard.tsx";
import { Link } from "@tanstack/react-router";
import Image from "@components/elements/Image";

export function LeftSide() {
	return (
		<div className="relative  z-10  mt-[-80px] flex flex-col items-start justify-center  gap-6 pt-[0vh]">
			<AffirmationCard className="w-80 " />
			<div
				className={cn(
					"relative aspect-[4/3] overflow-hidden rounded-xl shadow-base2",
					" w-[380px] _laptop:w-[300px] _laptop_lg:w-[350px] _middle:w-[380px] _large:w-[480px]"
				)}
			>
				<Link href="/" rel="preload">
					<Image
						className="hidden aspect-[4/3.2] w-full rounded-none  object-cover object-center _tablet_sm:block"
						width={500}
						height={500}
						src="/images/markup/landing/team-md.webp"
						alt="Coachini Consulting"
						aria-label="Coachini Consulting"
						loading="eager"
					/>
				</Link>
				<div className="absolute top-0 z-[10] h-full w-full bg-gradient-to-r from-secondary-500 to-primary-500 opacity-60 mix-blend-multiply"></div>
			</div>
		</div>
	);
}

export function RightSide() {
	return (
		<div className="relative z-[1] flex flex-col items-end justify-center gap-[70px] _laptop:gap-[60px] _large:gap-[170px]">
			{/*-------------------------------*/}
			<div
				className={cn(
					"relative aspect-[2/2.2] self-end overflow-hidden rounded-xl shadow-base2",
					"w-[260px] _laptop:w-[200px] _laptop_lg:w-[240px] _middle:w-[260px] _large:w-[280px]"
				)}
			>
				<Image
					className="hidden aspect-[2/2.2] w-full rounded-none object-cover object-center _tablet_sm:block"
					// as={NextImage}
					width={1080}
					height={720}
					src="/images/markup/landing/consult.webp"
					alt="Coachini Consulting"
					aria-label="Coachini Consulting"
					loading="eager"
				/>
				<div className="to-transparent absolute top-0 z-[10] h-full w-full bg-gradient-to-r from-secondary-300 mix-blend-multiply" />
			</div>
			{/*-------------------------------*/}
			<div
				className={cn(
					"relative mr-[-20px] aspect-[4/3]  self-end overflow-hidden rounded-lg shadow-base2",
					"w-[360px] _laptop:w-[330px] _laptop_lg:w-[360px] _middle:w-[400px] _large:w-[450px]"
				)}
			>
				<Image
					className={cn(
						"hidden aspect-[4/3] w-full rounded-none object-cover object-center _tablet_sm:block"
					)}
					// as={NextImage}
					width={1080}
					height={720}
					src="/images/markup/landing/solo.webp"
					alt="Coachini Consulting"
					aria-label="Coachini Consulting"
					loading="eager"
				/>
				<div className="to-transparent absolute top-0 z-[10] h-full w-full bg-gradient-to-r from-primary-300 mix-blend-multiply" />
			</div>
		</div>
	);
}

function ImageSection() {
	return (
		<div className="mx-auto hidden h-full w-[80%] flex-row justify-center gap-4 space-x-[-110px] pb-[3.9462vi] pt-[60px] sm:flex _laptop:justify-end _laptop:space-x-[-170px] _laptop_lg:w-[80%] _middle:space-x-[-170px]  _large:space-x-[-200px] _large:py-[0px]">
			{/*-------------------*/}
			<LeftSide />
			{/*--------*/}
			<RightSide />
			{/*-------------------*/}
		</div>
	);
}

export default ImageSection;
