import type React from "react";
import { cn } from "@core/utils";

// Define the prop types for the component
interface TargetCardNewProps {
	textBeforeHighlight?: string;
	textAfterHighlight?: string;
	content: string;
	wrapperClassName?: string;
	buttonClassName?: string;
	highLightText: string;
	hightLightStyle?: string;
}

// Define the functional component with TypeScript
const TargetCard: React.FC<TargetCardNewProps> = ({
	textBeforeHighlight,
	textAfterHighlight,
	highLightText,
	hightLightStyle,
	content,
	wrapperClassName,
	buttonClassName,
}) => {
	return (
		<div
			className={cn(
				"shadow-md bg-blue-400 hover:bg-blue-500 group relative flex min-h-[400px] min-w-64 cursor-pointer rounded-lg p-6 transition _tablet:min-h-[480px]",
				wrapperClassName
			)}
		>
			<div className="flex flex-col space-y-4 sm:max-w-7xl">
				<div className="sm:max-w-1">
					<h1 className="font headlineLarge text-left">
						{textBeforeHighlight && textBeforeHighlight}{" "}
						<span className={cn("font-semibold", hightLightStyle)}>
							{" "}
							{highLightText} <br />
						</span>
						{textAfterHighlight && textAfterHighlight}
					</h1>
				</div>
				<p className={cn("font bodyText text-left text-text-600 ")}>
					{content}
				</p>
			</div>

			<div className="absolute bottom-[-1px] right-[-1px]  ">
				<svg
					width="120"
					height="107"
					viewBox="0 0 120 107"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={cn("")}
				>
					<path
						d="M120 97V0L120 2.00058C120 7.52319 115.523 12 110 12L39.0003 12L22.2299 11.826C16.6668 11.7684 12.1262 16.2621 12.1262 21.8255V96.9999C12.1262 102.523 7.64909 107 2.12624 107H110C115.523 107 120 102.523 120 97Z"
						fill="#FCFBF7"
					/>
				</svg>

				<div
					className={cn(
						" absolute bottom-[0px] right-[0px] flex h-[72px] w-[85px] items-center justify-center rounded-md bg-primary-100 transition",
						buttonClassName
					)}
				>
					<svg
						width="55"
						height="40"
						viewBox="0 0 55 40"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M14.166 8.33329L25.8327 20L14.166 31.6666"
							stroke="#094BA4"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M29.166 8.33329L40.8327 20L29.166 31.6666"
							stroke="#094BA4"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
			</div>
		</div>
	);
};

export default TargetCard;
