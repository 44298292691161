import { useState } from "react";

export const TemplateCreator = ({ onSave }) => {
	const [templateName, setTemplateName] = useState("");
	const [templateContent, setTemplateContent] = useState("");
	const [dynamicFields, setDynamicFields] = useState([]);
	const [isSaving, setIsSaving] = useState(false);

	const handleAddField = () => {
		setDynamicFields([...dynamicFields, ""]);
	};

	const handleFieldChange = (index, value) => {
		const newFields = [...dynamicFields];
		newFields[index] = value;
		setDynamicFields(newFields);
	};

	const handleSave = () => {
		setIsSaving(true);
		onSave({
			name: templateName,
			template: templateContent,
			dynamicFields,
		});
		setIsSaving(false);
	};

	return (
		<div className="template-creator space-y-4">
			<input
				type="text"
				value={templateName}
				onChange={(e) => setTemplateName(e.target.value)}
				placeholder="Nom du modèle"
				className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
				disabled={isSaving}
			/>
			<textarea
				value={templateContent}
				onChange={(e) => setTemplateContent(e.target.value)}
				placeholder="Contenu du modèle"
				className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
				disabled={isSaving}
			/>
			<div className="dynamic-fields space-y-2">
				<label className="block text-gray-700">Champs dynamiques :</label>
				{dynamicFields.map((field, index) => (
					<input
						key={index}
						type="text"
						value={field}
						onChange={(e) => handleFieldChange(index, e.target.value)}
						placeholder={`Champ ${index + 1}`}
						className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
						disabled={isSaving}
					/>
				))}
				<button
					onClick={handleAddField}
					className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
					disabled={isSaving}
				>
					Ajouter un champ dynamique
				</button>
			</div>
			<button
				onClick={handleSave}
				className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
				disabled={isSaving}
			>
				{isSaving ? "Enregistrement..." : "Enregistrer le modèle"}
			</button>
		</div>
	);
};
