import { createFileRoute } from "@tanstack/react-router";
import Meta from "@components/layout/common/Meta.tsx";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { StartgreenLanding } from "@pages/(startgreen)/Landing.tsx";
import ParticipantsScreen from "@pages/(startgreen)/views/(participants)";
import GenericParticipantsScreen from "@pages/(startgreen)/views/(visitors)/VisitorsPage.tsx";
import JudgeManagement from "@pages/(startgreen)/views/(judges)/JudgeManagment.tsx";
import AdminManagment from "@pages/(startgreen)/views/(admins)/AdminManagment.tsx";
import MailBuilderMain from "@/mail-builder/App";
import MailBuilderTheme from "@/mail-builder/mail-builder.theme.ts";
import { CssBaseline, ThemeProvider } from "@mui/material";
import RoleGuard from "@core/guards/role.guard.tsx";

const getStartGreen = async () => {
	const response = await axios.get("http://localhost:4400/v2");
	return response.data;
};
const Page = () => {
	const { t: tMeta } = useTranslation("metadata");
	return (
		<>
			<Meta
				title={"StartGreen by IPTIC - Mail Builder"}
				template={"%s | Coachini"}
				description={tMeta("about-page.description")}
				keywords={[
					"about us",
					"about coachini",
					"know more",
					"coaching",
					"coachini",
				]}
				robots="index,follow"
				ogTitle={tMeta("about-page.title")}
				ogDescription={tMeta("about-page.description")}
			/>
			<RoleGuard requiredRole={"admin"}>
				<ThemeProvider theme={MailBuilderTheme}>
					<CssBaseline />
					<MailBuilderMain />
				</ThemeProvider>
			</RoleGuard>
		</>
	);
};

export const Route = createFileRoute(
	"/(main)/startgreen-by-iptic/dashboard/mail-builder/"
)({
	component: Page,
});
